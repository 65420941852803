import { Box } from "@mui/material";
import { styled } from "@mui/system";
import Background from "../../../assets/img/ProgramBackground.png";

export const SectionNavigation = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  "@media screen and (max-width: 1024px)": {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
export const InfoContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  width: "100%",
  gap: 40,
  marginTop: "24px",
  "@media screen and (max-width: 1440px)": {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
export const Header = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
  height: 200,
}));
export const BackgroundImage = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 155,
  width: "100%",
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));
export const ProgramImage = styled(`img`)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 155,
  width: 213,
  backgroundColor: "white",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "absolute",
  left: 56,
  borderRadius: 16,
  bottom: "-26px",
}));
export const DescriptionWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  justifyContent: "space-between",
  gap: 16,
  fontFamily: "Inter",
  fontSize: 16,
  marginTop: 24,
  letterSpacing: " -0.001em",
  "@media screen and (max-width: 1024px)": {
    fontSize: 14,
  },
}));
export const DefaultDescriptionWrapper = styled(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: props.isVideo ? 600 : 400,
  width: "100%",
  backgroundColor: "#EBE6DD",
  borderRadius: 4,
}));
export const Description = styled(Box)(() => ({
  fontWeight: 500,
  color: "#5F6D7E",
  textAlign: "start",
  lineHeight: 1.5,
}));
export const SekcijaButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 200,
  height: 40,
  fontWeight: 600,
  textAlign: "center",
  color: "#FFFFFF",
  marginTop: 24,
  borderRadius: 4,
  backgroundColor: "#CB9C2D",
  transition: "opacity 300ms ease-in-out",
  "&:hover": {
    opacity: 0.89,
    cursor: "pointer",
  },
  "@media screen and (max-width: 1024px)": {
    width: "100%",
  },
}));
export const DropdownNavWrapper = styled(Box)(() => ({
  border: "1px solid #E0E0E0",
  padding: 16,
  borderRadius: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  justifyContent: "center",
  gap: 16,
}));

export const SmallTitle = styled(Box)(({ color }) => ({
  fontFamily: "Inter",
  fontSize: 20,
  letterSpacing: " -0.001em",
  color: color,
  fontWeight: 600,
}));
