import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Question from "../../../assets/img/Help.png";
import profilePhoto from "../../../assets/img/blank_profile_photo.jpeg";
import Modal from "../../../components/Modal/Modal";
import { useUserState } from "../../../context/UserContext.js";
import { useAuthentication } from "../../hooks/useAuthentication.js";
import styles from "../TerapeutProfil/TerapeutProfil.module.css";
import { deleteTherapist } from "../api/deleteTherapist";
import { getTherapistProfile } from "../api/getTherapistProfile.js";
import backToArrow from "../assets/BackToArrow.svg";
import TerapeutIcon from "../assets/TerapeutIcon.svg";
import locationIcon from "../assets/locationIcon.svg";
import smsIcon from "../assets/smsIcon.svg";
import whiteSmsIcon from "../assets/whiteSmsIcon.svg";
import EditUserInformation from "./EditProfile.jsx";
import TerapijeCard from "./components/DostupneTerapijeCard.jsx";
import KontaktirajModal from "./components/KontaktirajModal";

const TerapeutProfil = () => {
  const location = useLocation();
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const therapistData = location.state ? location.state.user : null;
  const { id } = useParams();
  const loggedTherapistId = userData.id;
  const [isEditing, setIsEditing] = useState(false);
  const [isKontaktirajOpen, setIsKontaktirajOpen] = useState(false);
  const [isBrisanjeOpen, setIsBrisanjeOpen] = useState(false);
  const [therapistProfile, setTherapistProfile] = useState();
  const navigate = useNavigate();
  const { role } = useUserState();
  const userId = role;

  useEffect(() => {
    if (authToken) getTherapistProfile(id, authToken, setTherapistProfile);
  }, [id, authToken]);

  function handleGoBackClick() {
    navigate("/terapeuti");
  }
  const isPolaznikTerapeut = userId;

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (authToken) {
      setModalButtons((prevButtons) => [
        prevButtons[0],
        {
          ...prevButtons[1],
          action: async () => {
            try {
              await deleteTherapist(id, authToken);
              navigate("/terapeuti");
            } catch (error) {
              console.error("Error in action:", error);
            }
          },
        },
      ]);
    }
  }, [authToken]);

  const [modalButtons, setModalButtons] = useState([
    {
      label: "Odustani",
      action: () => setIsBrisanjeOpen(false),
      className: "modal-btn-deletePage modal-btn-discontinue-deletePage",
    },
    {
      label: "Ukloni",
      action: () => {},
      className: "modal-btn-deletePage modal-btn-delete-deletePage",
    },
  ]);

  const handleDeleteTherapist = async () => {
    setIsBrisanjeOpen(true);
  };

  return (
    <div>
      {isKontaktirajOpen && (
        <KontaktirajModal
          setIsKontaktirajOpen={setIsKontaktirajOpen}
          nameTo={therapistData.firstName + " " + therapistData.lastName}
          nameFrom={userData.firstName + " " + userData.lastName}
          emailTo={therapistData.emailAddress}
          emailFrom={userData.emailAddress}
        />
      )}
      {isBrisanjeOpen && (
        <Modal
          image={Question}
          title={"Jeste li sigurni da želite ukloniti korisnika?"}
          buttons={modalButtons}
        />
      )}
      <div className={styles.text}>
        Na ovoj stranici pronaći ćeš popis praktičara i terapeuta Integralne
        tjelesne psihoterapije. Praktičari ITP-a su terapeuti odobreni od strane
        školskog tim-a za rad s polaznicima programa, a trenutno su na 5. ili 6.
        god edukacije i polaznici su Voditeljskog treninga (VTa). Njihove
        terapije priznat će vam se retrogradno kada diplomiraju.
      </div>
      <div className={styles.textSecond}>
        Za sve informacije i rezervaciju termina, javite se direktno terapeutima
        na njihove kontakte.
      </div>
      <div className={styles.backToContainer} onClick={handleGoBackClick}>
        <img src={backToArrow} alt="go back" className={styles.backToArrow} />
        <h1 className={styles.backToh1}>Natrag na popis</h1>
      </div>
      <div className={styles.ProfilePhotoBkgd}>
        <div className={styles.ProfilePic}>
          <img
            src={therapistData.imageUrl || profilePhoto}
            alt="Profile Photo"
            className={styles.ProfilePhoto}
          />
        </div>
      </div>
      <div className={styles.userContainer}>
        <div className={styles.name}>
          {therapistData.firstName} {therapistData.lastName}
        </div>
        <div className={styles.btnsContainer}>
          {loggedTherapistId === therapistData.id && (
            <button
              className={`${styles.Button} ${
                isEditing && isPolaznikTerapeut ? styles.editButton : ""
              }`}
              onClick={
                parseInt(id, 10) === userData.id
                  ? handleEditClick
                  : () => setIsKontaktirajOpen(true)
              }
              disabled={!isPolaznikTerapeut && userId === "Polaznik"}
            >
              {!isPolaznikTerapeut && !isEditing && (
                <img src={whiteSmsIcon} alt="Contact Icon" />
              )}
              <div className={styles.btnText}>
                {(!isPolaznikTerapeut && userId === "Polaznik") ||
                loggedTherapistId !== therapistData.id
                  ? "Kontaktiraj"
                  : "Uredi"}
              </div>
            </button>
          )}
          {userId === "Referada" && (
            <button
              className={`${styles.Button} ${styles.deleteBtn}`}
              onClick={() => handleDeleteTherapist()}
            >
              <div className={styles.btnText}>Ukloni</div>
            </button>
          )}
        </div>
      </div>
      <div className={styles.userInfo}>
        <div className={styles.infoRow}>
          <img className={styles.icon} src={locationIcon} alt="Icon 1" />
          <div className={styles.infoText}>
            {therapistProfile?.availableCitiesLive ? (
              <>
                {" "}
                {therapistProfile?.availableCitiesLive.map((city, index) => (
                  <span key={city}>
                    {city}
                    {index < therapistProfile?.availableCitiesLive.length - 1
                      ? ", "
                      : ""}
                  </span>
                ))}
              </>
            ) : (
              "Podatak nije upisan"
            )}
          </div>
        </div>
        <div className={styles.infoRow}>
          <img className={styles.icon} src={TerapeutIcon} alt="Icon 2" />
          <div className={styles.infoText}>
            {therapistData.therapistRole === 1
              ? "Terapeut"
              : therapistData.therapistRole === 2
              ? "Praktičar"
              : "Supervizor"}
          </div>
        </div>
        <div className={styles.infoRow}>
          <img className={styles.icon} src={smsIcon} alt="Icon 3" />
          <div className={styles.infoText}>
            {therapistData?.emailAddress || "Podatak nije upisan"}
          </div>
        </div>
        <button
          className={`${styles.mobileButton} ${
            isEditing && isPolaznikTerapeut ? styles.editButton : ""
          }`}
          onClick={
            parseInt(id, 10) === userData.id
              ? handleEditClick
              : () => setIsKontaktirajOpen(true)
          }
          disabled={
            userId === "Voditelj" ||
            userId === "Referada" ||
            (!isPolaznikTerapeut && userId === "Polaznik")
          }
        >
          {!isPolaznikTerapeut && !isEditing && (
            <img src={whiteSmsIcon} alt="Contact Icon" />
          )}
          <div className={styles.btnText}>
            {userId === "Voditelj" ||
            userId === "Referada" ||
            (!isPolaznikTerapeut && userId === "Polaznik")
              ? "Kontaktiraj"
              : "Uredi"}
          </div>
        </button>
      </div>
      {isEditing ? (
        <EditUserInformation
          onSave={handleSaveClick}
          onCancel={handleCancelClick}
          setTherapistProfile={setTherapistProfile}
          availableCitiesLive={therapistProfile?.availableCitiesLive}
          availableLive={therapistProfile?.availableLive}
          availableOnline={therapistProfile?.availableOnline}
          biography={therapistProfile?.biography}
        />
      ) : (
        <div>
          <div className={styles.terapijaContainer}>
            <h1 className={styles.terapijaTitle}>Terapije</h1>
            <TerapijeCard
              live={therapistProfile?.availableLive}
              online={therapistProfile?.availableOnline}
            />
          </div>
          <div className={styles.BioText}>
            <h1 className={styles.BioHeader}>Biografija</h1>
            <div className={styles.BioPartOne}>
              {therapistProfile?.biography
                ? therapistProfile.biography.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))
                : "Biografija nije upisana."}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TerapeutProfil;
