import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const postMentorReview = async (
  accessToken,
  userId,
  applicationId,
  review,
  setShowSuccess
) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/programApplication/${applicationId}`,
      {
        userId: userId,
        mentorReview: review,
        visibleForReferada: true,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      setShowSuccess(true);
    }
  } catch (error) {
    console.error("Error updating application data:", error);
    throw error;
  }
};
