import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useUserState } from "../../../../context/UserContext";
import { auth } from "../../../../firebase";
import styles from "./UserMenuDropdown.module.css";

const UserMenuDropdown = ({ dropRef }) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { role, userData } = useUserState();
  const userId = role;
  return (
    <div ref={dropRef} className={styles.container}>
      {/* Dropdown items */}
      {userId === "Referada" && (
        <div
          className={styles.dropdownItem}
          onClick={() => navigate("/slanje-obavijesti")}
        >
          <p className={styles.dropdownItemText}>Slanje obavijesti</p>
        </div>
      )}
      <div
        className={styles.dropdownItem}
        onClick={() => {
          cookies.set("user", 1);
          if (!userData?.firstLogin) navigate("/moj-profil");
        }}
      >
        <p className={styles.dropdownItemText}>Moj profil</p>
      </div>
      <div
        className={styles.dropdownItem}
        onClick={() => {
          if (!userData?.firstLogin) navigate("/postavke");
        }}
      >
        <p className={styles.dropdownItemText}>Postavke</p>
      </div>
      <div
        className={styles.dropdownItem}
        onClick={() => {
          cookies.remove("loggedUser");
          localStorage.removeItem("evidentionFullName");
          localStorage.removeItem("evidentionUserID");
          localStorage.removeItem("authToken");
          localStorage.removeItem("sessionTime");
          signOut(auth);
          navigate("/");
        }}
      >
        <p className={styles.dropdownItemText}>Odjava</p>
      </div>
    </div>
  );
};
export default UserMenuDropdown;
