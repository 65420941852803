import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const archiveUser = async (userId, accessToken) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/users/archive`,
      { userIds: [userId], isArchived: true },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error at archive user: ", error);
    throw error;
  }
};
