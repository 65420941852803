function TrashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M10.354 14.383v-2.47M13.648 14.383v-2.47M4.588 7.794H19.41v0c-.767 0-1.15 0-1.453.125a1.647 1.647 0 00-.892.892c-.125.302-.125.686-.125 1.454v4.94c0 1.554 0 2.33-.483 2.812-.482.483-1.258.483-2.811.483h-3.294c-1.553 0-2.33 0-2.812-.483-.483-.482-.483-1.258-.483-2.811v-4.941c0-.768 0-1.152-.125-1.454a1.647 1.647 0 00-.891-.892c-.303-.125-.687-.125-1.454-.125v0zM10.41 4.805c.094-.087.301-.165.589-.22.287-.055.64-.085 1.002-.085a5.5 5.5 0 011.003.085c.288.055.495.133.588.22"
      ></path>
    </svg>
  );
}

export default TrashIcon;
