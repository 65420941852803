import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const typeMapping = {
  "fakultativne-radionice": "facultativeWorkshop",
  "grupne-supervizije": "supervision",
  "ljetni-intenzivi": "intensive",
  "vikend-intenzivi": "intensive",
};

const defaultPrijavnicaName = "eModuleLive";

export const deleteUserFromPrijavnica = async (
  type,
  userId,
  prijavnicaId,
  accessToken
) => {
  const endpoint = typeMapping[type] || defaultPrijavnicaName;
  try {
    const response = await axios.delete(
      `${apiUrl}/api/${endpoint}/${prijavnicaId}/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error deleting user: ", error);
    throw error;
  }
};
