import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const handleDodajPolaznika = async (
  selectedPolazniciIds,
  accessToken,
  groupId,
  setIsModalOpen
) => {
  try {
    console.log("Request Payload:", {
      userIds: selectedPolazniciIds,
      role: "polaznik",
      groupId: groupId,
    });
    const response = await axios.post(
      `${apiUrl}/api/groups/${groupId}/add-users`,
      {
        userIds: selectedPolazniciIds,
        role: "polaznik",
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      setIsModalOpen(true);
    } else {
      console.error("Request failed:", response.data);
    }
  } catch (error) {
    console.error("Error making API request:", error);
  }
};
export const fetchCities = async (setMjestoUpisa, accessToken) => {
  try {
    const response = await fetch(`${apiUrl}/api/cities`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    const updatedMjestoUpisa = [
      { value: "0", label: "----" },
      ...data.map((city) => ({ label: city.cityName, value: city.id })),
    ];
    setMjestoUpisa(updatedMjestoUpisa);
  } catch (error) {
    console.error("Error fetching cities:", error);
  }
};

export const getUsersCount = async (accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/usersCount`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};

export const getAllPolaznici = async (page, limit, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/all-users/?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
