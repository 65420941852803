import React from "react";
import Anon from "../../../../../assets/img/Anonymous.png";
import { formatDate } from "../../../../myProfile/utils";
import RenderTableHeader from "./RenderTableHeader";
import "./Table.css";

function RenderTable({ data }) {
  const columnNames = ["Datum", "Supervizor"];

  return (
    <div style={{ width: "30%", marginTop: 6, marginBottom: 24 }}>
      <table className="table-supervizije-suglasnost">
        <thead>
          <RenderTableHeader columnNames={columnNames} />
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td>{item?.date ? formatDate(item?.date) : "/"}</td>
              <td>
                <div className="table-supervizije-suglasnost-row">
                  <img
                    alt=""
                    className="table-supervizije-suglasnost-avatar"
                    src={item?.imageUrl ? item?.imageUrl : Anon}
                  />
                  {item?.fullName ? item?.fullName : "/"}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RenderTable;
