import React from "react";
import { useCreateEventState } from "../../../context/CreateEventContext";
import "./TablePolaznici.css";
function RenderTableData({ data = [] }) {
  const { createEventData, updateCreateEventData } = useCreateEventState();
  const deletePolaznik = (id) => {
    updateCreateEventData({
      ...createEventData,
      polazniciData: createEventData.polazniciData?.filter(
        (item) => item.id !== id
      ),
    });
  };
  return (
    <>
      {data?.map((polaznik, index) => (
        <tr key={index}>
          <td style={{ color: "#2E3646" }}>
            <div className="table-polaznici-row">
              <div className="table-polaznici-avatar">
                <img alt="" src={polaznik?.imageUrl} />
              </div>
              {polaznik?.firstName}
              {polaznik?.lastName}
            </div>
          </td>
          <td style={{ color: "#5F6D7E" }}>
            <div className="table-polaznici-column">
              <p style={{ marginBottom: "-16px" }}>
                {polaznik.aktivniProgramResponse?.[0]?.title?.slice(0, 3) ||
                  "N/A"}
                {/*privremeno*/}
              </p>
              <p style={{ color: "#7E818C" }}>
                {polaznik.aktivniProgramResponse?.[0]?.name || "N/A"}
              </p>
            </div>
          </td>
          <td style={{ color: "#5F6D7E" }}>{polaznik?.emailAddress}</td>
          <td style={{ color: "#5F6D7E" }}>
            <div className="table-polaznici-row">
              {polaznik.mobitel || "0123456789"}
              <button
                className="table-polaznici-button"
                onClick={() => deletePolaznik(polaznik.id)}
              />
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}

export default RenderTableData;
