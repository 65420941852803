import React, { useEffect, useState } from "react";
import Export from "../../assets/img/Export.png";
import { useAuthentication } from "../hooks/useAuthentication";
import { formatDate } from "../polazniciPregled/utils/utils";
import SelectDropdown from "../singleGroup/components/selectDropdown/SelectDropdown";
import { handlePregledTotalnogObracunaZaSobu } from "./API/exportPregledTotalnogObracunaZaSobu";
import { getPregledTotalnogObracunaZaSobu } from "./API/fetchPregledTotalnogObracunaZaSobu";
import styles from "./ObracunSoba.module.css";
import ObracunComponent from "./components/ObracunComponent/ObracunComponent";
import DatePicker from "./components/SingleInputDatePicker/DatePicker";
import {
  periodObracunaSobeColumnNames,
  totalniObracunSobaColumnNames,
} from "./utils/columnNames";
import { Sobe, handleSelectRoomChange } from "./utils/utils";

function PregledTotalnogObracunaZaSobu() {
  const today = new Date();
  const initialDate = today.toISOString().split("T")[0];
  const [pregledTotalnogObracunaZaSobu, setPregledTotalnogObracunaZaSobu] =
    useState({});
  const [startDate, setStartDate] = useState(initialDate);
  const [roomStats, setRoomStats] = useState([]);
  const [endDate, setEndDate] = useState(initialDate);
  const { authToken } = useAuthentication();
  const [roomId, setRoomId] = useState(1);
  const [roomLabel, setRoomLabel] = useState(
    Sobe.length > 0 ? Sobe[0].label : ""
  );
  const handleDateChange = (newDates) => {
    setStartDate(newDates[0]);
    setEndDate(newDates[1]);
  };
  useEffect(() => {
    if (authToken)
      getPregledTotalnogObracunaZaSobu(
        authToken,
        roomId,
        startDate,
        endDate,
        setPregledTotalnogObracunaZaSobu
      );
  }, [authToken, startDate, endDate, roomId]);
  const obracun = Array.isArray(
    pregledTotalnogObracunaZaSobu?.statistics?.appointments
  )
    ? [...pregledTotalnogObracunaZaSobu?.statistics?.appointments]
    : [];
  useEffect(() => {
    if (
      pregledTotalnogObracunaZaSobu.statistics &&
      pregledTotalnogObracunaZaSobu.statistics.roomStats &&
      Array.isArray(pregledTotalnogObracunaZaSobu.statistics.roomStats)
    ) {
      setRoomStats((prevObracun) => {
        const newArray = pregledTotalnogObracunaZaSobu.statistics.roomStats.map(
          (stats) => {
            return {
              period: `${formatDate(startDate)} - ${formatDate(endDate)}`,
              grad: roomLabel,
              sumOfReservations: obracun.length || 0,
              totalSpend: stats.totalSpent || "0",
              cancelled: stats.cancelled || "0",
              percentageCancelled: stats.percentageCancelled || "",
            };
          }
        );

        return newArray.filter(Boolean);
      });
    }
  }, [pregledTotalnogObracunaZaSobu, startDate, endDate, roomLabel]);
  return (
    <div className={styles.obracunWrapper}>
      <span className={styles.title}>Pregled totalnog obračuna za sobu</span>
      <div className={styles.inputRow}>
        <div className={styles.column}>
          <span className={styles.label}>Soba</span>
          <SelectDropdown
            options={Sobe}
            value={roomId || (Sobe.length > 0 ? Sobe[0].value : null)}
            onChange={(e) => handleSelectRoomChange(e, setRoomId, setRoomLabel)}
          />
        </div>
        <div className={styles.column}>
          <span className={styles.label}>Vremenski period</span>
          <DatePicker
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
          />
        </div>
      </div>
      <ObracunComponent
        title={`Pregled potrošnje za: ${roomLabel}`}
        label={` (${formatDate(startDate)} - ${formatDate(endDate)})`}
        columnNames={totalniObracunSobaColumnNames}
        data={obracun}
        backgroundColor={"#87AD77"}
        isObracunPoSobama
      />
      <ObracunComponent
        columnNames={periodObracunaSobeColumnNames}
        data={roomStats}
        backgroundColor={"#87AD77"}
        isObracunPoSobama
      />
      <button
        className={styles.exportBtn}
        onClick={() =>
          handlePregledTotalnogObracunaZaSobu(
            authToken,
            roomId,
            startDate,
            endDate,
            roomLabel
          )
        }
      >
        Export tablica <img alt="" src={Export} />
      </button>
    </div>
  );
}

export default PregledTotalnogObracunaZaSobu;
