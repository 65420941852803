import { useEffect, useRef, useState } from "react";
import CloseIcon from "../../../../../assets/svg/CloseIcon";
import CrossIcon from "../../../../../assets/svg/CrossIcon";
import DocumentIcon from "../../../../../assets/svg/DocumentIcon";
import { useUserState } from "../../../../../context/UserContext";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import { addRegulation } from "../api/addRegulation";
import { deleteRegulation } from "../api/deleteRegulation";
import { getAllRegulations } from "../api/getAllRegulations";
import styles from "../Regulations.module.css";

const RegulationSection = ({ title, id }) => {
  const [editMode, setEditMode] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [selectedFilesNames, setSelectedFilesNames] = useState([]);
  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const [isEditor, setIsEditor] = useState(role === "Referada" ? true : false);
  const [regulations, setRegulations] = useState([]);
  const fileInputRef = useRef(null);
  const handleEditMode = () => {
    setPreviousFiles([...selectedFiles]);
    setEditMode(true);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
    const filesNames = Array.from(files).map((file) => file.name.split(".")[0]);
    setSelectedFilesNames([...selectedFilesNames, ...filesNames]);
  };

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveItem = async (regulationId) => {
    const response = await deleteRegulation(regulationId, authToken);
    setRegulations(response);
  };

  const handleSaveClick = async () => {
    const formData = new FormData();
    const firstFile = selectedFiles[0];

    if (firstFile) {
      formData.append("document", firstFile);
      formData.append("name", selectedFilesNames[0]);
      formData.append("seenBy", id);

      try {
        await addRegulation(formData, authToken);
        const response = await getAllRegulations(userData.id, authToken);
        setRegulations(response);
      } catch (err) {
        console.log(err);
      }
    }
    setSelectedFiles([]);
    setSelectedFilesNames([]);
    setEditMode(false);
  };

  const handleCancelClick = () => {
    setSelectedFiles([]);
    setSelectedFilesNames([]);
    setEditMode(false);
  };

  useEffect(() => {
    const fetchRegulations = async () => {
      try {
        const response = await getAllRegulations(userData.id, authToken);
        setRegulations(response);
      } catch (error) {
        console.error("Error fetching regulations:", error);
      }
    };

    if (authToken) fetchRegulations();
  }, [authToken]);

  return (
    <div className={styles.filesContainer}>
      <div className={styles.filesHeaderRow}>
        <p className={styles.filesHeading}>{title}</p>
        {isEditor && (
          <div
            className={editMode ? styles.editButtonDisabled : styles.editButton}
            onClick={handleEditMode}
          >
            <p className={styles.editButtonText}>uredi</p>
          </div>
        )}
      </div>
      {!isEditor && (
        <div
          className={
            regulations.length === 0
              ? styles.filesContentEmpty
              : styles.filesContent
          }
        >
          {regulations.length === 0 ? (
            <div className={styles.emptyContent}>
              <DocumentIcon className={styles.emptyContentIcon} />
              <p className={styles.emptyContentText}>Nema dokumenata</p>
            </div>
          ) : (
            regulations.map((item, index) => (
              <a
                href={item.url}
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.regulations} key={index}>
                  <DocumentIcon className={styles.uploadedContentIcon} />
                  <h3 className={styles.h3}>{item.name.replace(/_/g, " ")}</h3>
                </div>
              </a>
            ))
          )}
        </div>
      )}
      {isEditor && (
        <div className={styles.filesContent}>
          {regulations.map(
            (file, index) =>
              file.seenBy === id && (
                <div key={index} className={styles.uploadedContent}>
                  {editMode && (
                    <>
                      <DocumentIcon className={styles.uploadedContentIcon} />

                      <div
                        className={styles.removeItemButton}
                        onClick={() => handleRemoveItem(file.id)}
                      >
                        <CloseIcon className={styles.removeItemIcon} />
                      </div>
                      <p className={styles.uploadedContentText}>
                        {file.name.replace(/_/g, " ")}
                      </p>
                    </>
                  )}
                  {!editMode && (
                    <a
                      href={file.url}
                      className={styles.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DocumentIcon className={styles.uploadedContentIcon} />
                      <p className={styles.uploadedContentText}>
                        {file.name.replace(/_/g, " ")}
                      </p>
                    </a>
                  )}
                </div>
              )
          )}

          {regulations.length > 0 ? (
            editMode && (
              <>
                <input
                  type="file"
                  accept=".pdf"
                  className={styles.fileInput}
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  multiple
                />
                <div
                  className={styles.uploadItemContent}
                  onClick={handleEditClick}
                >
                  <CrossIcon className={styles.uploadItemIcon} />
                  <input
                    type="text"
                    className={styles.selectedFilesInput}
                    value={selectedFilesNames.join(", ")}
                    readOnly
                  />
                </div>
              </>
            )
          ) : (
            <div className={styles.emptyContent}>
              <DocumentIcon className={styles.emptyContentIcon} />
              <p className={styles.emptyContentText}>Nema dokumenata</p>
            </div>
          )}
        </div>
      )}
      {editMode && (
        <div className={styles.editControlRow}>
          <div className={styles.cancelButton} onClick={handleCancelClick}>
            <p className={styles.cancelButtonText}>odustani</p>
          </div>
          <div className={styles.saveButton} onClick={handleSaveClick}>
            <p className={styles.saveButtonText}>Spremi promjene</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default RegulationSection;
