import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const exportPregledTotalnogObracunaZaSobu = async (
  accessToken,
  roomId,
  startDate,
  endDate
) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Both startDate and endDate are required.");
    }
    const response = await axios.get(
      `${apiUrl}/api/exportAppointmentsStatisticsByRoom/${roomId}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching pregled totalnog obračuna za sobu:", error);
    throw error;
  }
};

export const handlePregledTotalnogObracunaZaSobu = async (
  accessToken,
  roomId,
  startDate,
  endDate,
  room
) => {
  try {
    const response = await exportPregledTotalnogObracunaZaSobu(
      accessToken,
      roomId,
      startDate,
      endDate
    );
    const blob = new Blob([response], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `exported_pregled_totalnog_obracuna_${room}_${startDate}-${endDate}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(
      "Greška prilikom exporta pregleda totalnog obračuna za sobu:",
      error
    );
  }
};
