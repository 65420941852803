import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components } from "react-select";
import AddIcon from "../../assets/svg/AddIcon.svg";
import TrashIcon from "../../assets/svg/TrashIcon.svg";
import styles from "./IPDTeam.module.css";

export const handleCardSelect = (
  voditelj,
  setSelectedVoditelji,
  selectedVoditelji
) => {
  const isVoditeljSelected = selectedVoditelji.includes(voditelj.id);
  let updatedSelectedVoditelji;
  if (isVoditeljSelected) {
    updatedSelectedVoditelji = selectedVoditelji.filter(
      (selectedVoditelj) => selectedVoditelj !== voditelj.id
    );
  } else {
    updatedSelectedVoditelji = [...selectedVoditelji, voditelj.id];
  }
  setSelectedVoditelji((prevSelectedVoditelji) => {
    return updatedSelectedVoditelji;
  });
};

export const selectStylesEnabled = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    height: "40px",
    width: "300px",
    borderRadius: "4px",
    border: "1px solid #D1D9E2 !important",
    cursor: "pointer",
    boxShadow: "none",
    "&:focus": {
      border: "0 !important",
    },
    "@media screen and (max-width: 1000px)": {
      width: "100%",
      marginBottom: "40px",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    color: "#5F6D7EC7",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    ":hover": {
      backgroundColor: "#F2F2F2",
    },
  }),
};

export const selectStylesDisabled = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F4F4F4",
    color: "#4D4D4D",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    height: "40px",
    width: "300px",
    borderRadius: "4px",
    border: "1px solid #D1D9E2 !important",
    cursor: "not-allowed",
    boxShadow: "none",
    "&:focus": {
      border: "0 !important",
    },
  }),
};
export const mogucnosti2 = [{ value: "", label: "Više mogućnosti" }];

export const mogucnosti = (navigate, setSelectedDropdownOption) => [
  {
    value: "dodaj",
    label: (
      <div
        className={styles.dropdownMoreOptionsRow}
        onClick={() => navigate("/DodajDjelatnika")}
      >
        <img src={AddIcon} alt="" />
        <span>Dodaj djelatnika</span>
      </div>
    ),
  },

  {
    value: "obrisi",
    label: (
      <div
        onClick={() => setSelectedDropdownOption("obrisi")}
        className={styles.dropdownMoreOptionsRow}
      >
        <img src={TrashIcon} alt="" />
        <span style={{ color: "#CB2D2D" }}>Obriši djelatnika</span>
      </div>
    ),
  },
];
export const handleOdustaniClick = (
  setShowDeleteDjelatnikButtons,
  setIsDeleteDjelatnik,
  setSelectedDropdownOption
) => {
  setShowDeleteDjelatnikButtons(false);
  setIsDeleteDjelatnik(false);
  setSelectedDropdownOption("");
};

export const EllipsisDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faEllipsisV} style={{ color: "#5F6D7E" }} />
    </components.DropdownIndicator>
  );
};
