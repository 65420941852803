import { getIntrospectionsByUser } from "../api/getIntrospectionsByUser";
import { programByModule } from "./utils";

export const fetchOtherData = async (
  selectedPolaznik,
  accessToken,
  setIntrospections
) => {
  try {
    const response = await getIntrospectionsByUser(
      selectedPolaznik,
      accessToken
    );
    const sortedResponse = [...response].sort(
      (x, y) => x.introspectionId - y.introspectionId
    );
    setIntrospections(
      sortedResponse.map((introspection) => ({
        ...introspection,
        programId:
          programByModule.find((module) =>
            module.moduleIds.includes(
              introspection.introspection.theoreticalModuleId
            )
          )?.id || null,
      }))
    );
  } catch (err) {
    console.log("Error fetching data : ", err);
  }
};
