import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Back from "../../../assets/img/BackArrow.png";
import { useAuthentication } from "../../hooks/useAuthentication";
import { getGroupIntrospekcija } from "./API";
import styles from "./Introspekcije.module.css";
import IntrospekcijeTable from "./IntrospekcijeTable/IntrospekcijeTable";

function Introspekcije() {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const location = useLocation();
  const { authToken } = useAuthentication();
  const [introspekcijeData, setIntrospekcijeData] = useState();
  const groupName = location?.state?.groupName || "";
  const date = location?.state?.date || "";

  useEffect(() => {
    if (authToken)
      getGroupIntrospekcija(groupId, authToken, setIntrospekcijeData);
  }, [groupId, authToken]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.description}>
        <div
          className={styles.navigationRow}
          onClick={() => navigate(`/grupe/${groupId}`)}
        >
          <img alt="" src={Back} className={styles.backArrow} />
          <span>Natrag na Pregled grupe</span>
        </div>
        <span className={styles.title}>Grupa {groupName}</span>
        <span className={styles.label}>Datum kreiranja: {date}</span>
      </div>
      <div className={styles.tableWrapper}>
        <IntrospekcijeTable data={introspekcijeData} />
      </div>
    </div>
  );
}

export default Introspekcije;
