import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Down from "../../../assets/img/ArrowDown.png";
import Question from "../../../assets/img/Help.png";
import Plus from "../../../assets/img/PlusIcon.png";
import Success from "../../../assets/img/Success.png";
import Trash from "../../../assets/svg/TrashIcon2.svg";
import Modal from "../../../components/Modal/Modal";
import { useCreateEventState } from "../../../context/CreateEventContext";
import { getRooms } from "../../FakultativnaRadionicaEditCreate/api/getRooms";
import {
  AddVoditeljButton,
  BackgroundImage,
  ButtonColumn,
  DatePlaceRow,
  DropdownArrow,
  EditButton,
  EditFooter,
  Header,
  ImageButton,
  Input,
  InputContainer,
  InputWrapper,
  ProgramImage,
  SectionTitle,
  SectionTitleRow,
  Select,
  SmallInputContainer,
  TableContainer,
  TextArea,
  Title,
  TitleBold,
  UrediButton,
  VoditeljiContainer,
  Wrapper,
} from "../../FakultativnaRadionicaEditCreate/EditCreate/FakultativnaRadionicaEdit.styles";
import TablePolaznici from "../../FakultativnaRadionicaEditCreate/PolazniciTable/TablePolaznici";
import { columnNames } from "../../FakultativnaRadionicaEditCreate/utils";
import VoditeljiCard from "../../FakultativnaRadionicaEditCreate/VoditeljiCard/VoditeljiCard";
import { useAuthentication } from "../../hooks/useAuthentication";
import { addUsersToSupervision } from "../api/addUsersToSupervision";
import { createSupervision } from "../api/createSupervision";
import { deleteSupervision } from "../api/deleteSupervision";
import { updateSupervision } from "../api/updateSupervision";
import { vrijemeOdrzavanja, vrstaSupervizije } from "../utils";
function SupervizijeEditCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const { authToken } = useAuthentication();
  const [selectedImage, setSelectedImage] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [isRoomLarge, setIsRoomLarge] = useState(false);
  const isEditMode = location?.state?.isEditMode || false;
  const isCreateMode = location?.state?.isCreateMode || false;
  const supervizijaData = isEditMode && location?.state?.supervizijaData;
  const [showTable, setShowTable] = useState(false);
  const [showImage, setShowImage] = useState(null);
  const { createEventData, updateCreateEventData, resetCreateEventData } =
    useCreateEventState();
  useEffect(() => {
    if (
      createEventData.voditeljiData?.length === 0 &&
      createEventData.polazniciData?.length === 0
    ) {
      updateCreateEventData({
        ...createEventData,
        voditeljiData: supervizijaData.usersResponse?.voditeljResponse,
        polazniciData: supervizijaData.usersResponse?.newPolazniciResponse,
        showImage: supervizijaData.imageURL,
      });
    }
  }, []);

  const toggleDropdown = () => {
    setShowTable(!showTable);
  };

  const clearSelectedImage = () => {
    setSelectedImage(null);
    setShowImage(null);
    updateCreateEventData({
      ...createEventData,
      imageURL: null,
      showImage: null,
    });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      const formData = new FormData();
      formData.append("image", file);
      setSelectedImage(formData);
      const imageFile = formData.get("image");
      setShowImage(imageUrl);
      setSelectedImage(imageFile);
      updateCreateEventData({
        ...createEventData,
        imageURL: imageFile,
        showImage: imageUrl,
      });
    }
  };

  const handleDeleteSupervision = async () => {
    try {
      await deleteSupervision(supervizijaData.id, authToken);
      navigate("/programi/seven");
    } catch (error) {
      console.log(error);
    }
  };

  const deleteVoditelj = (id) => {
    updateCreateEventData((prevState) => ({
      ...prevState,
      voditeljiData: prevState.voditeljiData.filter((item) => item.id !== id),
    }));
  };

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await getRooms(authToken);
        const updatedRooms = [
          { value: 99, label: "Bez sobe", isLarge: false },
          ...(response.rooms?.map((room) => ({
            value: room.id,
            label: room.roomName,
            isLarge: room.isLarge,
          })) || []),
        ];

        setRooms(updatedRooms);
      } catch (error) {
        console.log(error);
      }
    };

    if (authToken) fetchRooms();
  }, [authToken]);

  const handleRoomSelection = (selectedRoomId) => {
    const roomId = selectedRoomId;
    const selectedRoom = rooms.find((room) => room.value == roomId);

    setFormData({
      ...formData,
      roomId,
    });
    updateCreateEventData({
      ...createEventData,
      roomId,
    });

    setIsRoomLarge(selectedRoom ? selectedRoom.isLarge : true);
  };

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    date: "",
    supervisionType: "",
    time: "",
    roomId: "",
    masterAbbreviation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "time") {
      const [timeFrom, timeTo] = value.split("-");

      setFormData({
        ...formData,
        timeFrom,
        timeTo,
        [name]: value,
      });

      updateCreateEventData({
        ...createEventData,
        timeFrom,
        timeTo,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      updateCreateEventData({
        ...createEventData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    if (isEditMode && supervizijaData && !selectedImage) {
      setSelectedImage(supervizijaData.imageURL);
    }
  }, [isEditMode, supervizijaData, selectedImage]);
  useEffect(() => {
    if (isEditMode && supervizijaData) {
      setFormData({
        name: supervizijaData.name || "",
        description: supervizijaData.description || "",
        date: supervizijaData.date || "",
        supervisionType: supervizijaData.supervisionType || "",
        roomId: supervizijaData.roomId || "",
        time: supervizijaData.time || "",
        masterAbbreviation: supervizijaData.masterAbbreviation || "",
      });
    }
  }, [isEditMode, supervizijaData]);

  const handleSubmit = async () => {
    try {
      if (isCreateMode) {
        try {
          const polaznici = createEventData.polazniciData?.map(
            (polaznik) => polaznik.id
          );
          const voditelji = createEventData.voditeljiData?.map(
            (voditelj) => voditelj.id
          );
          const newData = {
            name: createEventData.name,
            description: createEventData.description,
            date: createEventData.date,
            supervisionType: createEventData.supervisionType,
            roomId: createEventData.roomId,
            masterAbbreviation: createEventData.masterAbbreviation,
            timeFrom: createEventData.timeFrom,
            timeTo: createEventData.timeTo,
            imageURL: createEventData.imageURL,
            voditelji,
            polaznici,
          };

          const formData = new FormData();

          Object.entries(newData).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((item) => {
                formData.append(`${key}[]`, item);
              });
            } else {
              formData.append(key, value);
            }
          });

          const response = await createSupervision(formData, authToken);
          const responseData = response.data;
          if (response.status === 200) {
            setModalContent({
              image: Success,
              description: "Uspješno ste kreirali superviziju",
              buttons: [
                {
                  label: "Uredu",
                  action: () => navigate("/programi/one"),
                  className: "modal-btn-success",
                },
              ],
            });
            setShowModal(true);
          } else {
            setModalContent({
              image: Question,
              description: "Nešto je pošlo po krivu. Pokušajte ponovo",
              buttons: [
                {
                  label: "Uredu",
                  action: () => navigate("/programi/one"),
                  className: "",
                },
              ],
            });
            setShowModal(true);
            console.log("Error occurred:", responseData.opis);
          }
          return responseData;
        } catch (error) {
          console.log(error);
          return null;
        }
      } else if (isEditMode) {
        try {
          const polaznici = createEventData.polazniciData?.map(
            (polaznik) => polaznik.id
          );
          const voditelji = createEventData.voditeljiData?.map(
            (voditelj) => voditelj.id
          );
          const updatedData = {
            name: createEventData.name || supervizijaData.name,
            description:
              createEventData.description || supervizijaData.description,
            date: createEventData.date || supervizijaData.date,
            supervisionType:
              createEventData.supervisionType ||
              supervizijaData.supervisionType,
            roomId: createEventData.roomId || supervizijaData.roomId,
            masterAbbreviation:
              createEventData.masterAbbreviation ||
              supervizijaData.masterAbbreviation,
            imageURL: createEventData.imageURL || supervizijaData.imageURL,
            time: createEventData.time || supervizijaData.time,
          };

          const formData = new FormData();

          Object.entries(updatedData).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((item) => {
                formData.append(`${key}[]`, item);
              });
            } else {
              formData.append(key, value);
            }
          });

          const response = await updateSupervision(
            supervizijaData.id,
            formData,
            authToken
          );

          const userData = {
            supervisionId: supervizijaData.id,
            polaznici: polaznici,
            voditelji: voditelji,
          };

          const supervisionResponse = await addUsersToSupervision(
            userData,
            authToken
          );

          const responseData = response.data;
          if (response.status === 200) {
            setModalContent({
              image: Success,
              description: "Promjene su spremljene",
              buttons: [
                {
                  label: "Uredu",
                  action: () => navigate("/programi/one"),
                  className: "modal-btn-success",
                },
              ],
            });
            setShowModal(true);
          } else {
            setModalContent({
              image: Question,
              description: "Nešto je pošlo po krivu. Pokušajte ponovo",
              buttons: [
                {
                  label: "Uredu",
                  action: () => navigate("/programi/one"),
                  className: "",
                },
              ],
            });
            setShowModal(true);
            console.log("Error occurred:", responseData.opis);
          }
          resetCreateEventData();
          return responseData;
        } catch (error) {
          console.log(error);
          return null;
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Wrapper>
        <Header>
          <BackgroundImage />
          {isCreateMode || isEditMode ? (
            <>
              <input
                type="file"
                accept="image/*"
                id="imageInput"
                style={{ display: "none", cursor: "pointer" }}
                onChange={handleImageUpload}
              />
              <ImageButton
                selectedImage={createEventData.showImage}
                onClick={clearSelectedImage}
              >
                <label htmlFor="imageInput">
                  {!createEventData.showImage &&
                  !showImage &&
                  !createEventData.imageURL ? (
                    <ButtonColumn>
                      <img alt="" src={Plus} />
                      Dodaj sliku
                    </ButtonColumn>
                  ) : null}
                </label>
              </ImageButton>
            </>
          ) : (
            <ProgramImage backgroundimage={supervizijaData.imageURL} />
          )}
        </Header>
        <Title>
          {isCreateMode && <Title>Nova grupna supervizija</Title>}
          {isEditMode && (
            <>
              {formData.name}
              <UrediButton disabled>Uredi</UrediButton>
            </>
          )}
        </Title>
        <InputWrapper>
          <InputContainer>
            <SectionTitle>Naziv supervizije</SectionTitle>
            <Input
              type="text"
              name="name"
              placeholder="Upiši"
              defaultValue={createEventData.name || formData.name}
              onChange={handleInputChange}
            />
          </InputContainer>
          <SectionTitle>Opis</SectionTitle>
          <TextArea
            placeholder="Dodaj opis"
            name="description"
            defaultValue={createEventData.description || formData.description}
            rows={5}
            cols={50}
            onChange={handleInputChange}
          />
          <DatePlaceRow>
            <InputContainer>
              <SectionTitle>Datum</SectionTitle>
              <Input
                type="date"
                id="dob"
                name="date"
                value={
                  isEditMode
                    ? supervizijaData.date?.split("T")[0]
                    : createEventData.date || formData.date
                }
                placeholder="Odaberi"
                onChange={handleInputChange}
              />
            </InputContainer>
            <InputContainer>
              <SectionTitle>Vrsta supervizije</SectionTitle>
              <Select
                type="text"
                name="supervisionType"
                value={
                  createEventData.supervisionType || formData.supervisionType
                }
                onChange={handleInputChange}
                style={{ width: "100%" }}
              >
                <option value="" disabled selected>
                  Odaberi
                </option>
                {vrstaSupervizije.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </DatePlaceRow>
          <DatePlaceRow>
            <InputContainer>
              <SectionTitle>Mjesto održavanja</SectionTitle>
              <Select
                type="text"
                name="roomId"
                placeholder="Odaberi sobu"
                value={createEventData.roomId || formData.roomId}
                onChange={(e) => handleRoomSelection(e.target.value)}
                style={{ width: "100%" }}
              >
                <option value="" disabled selected>
                  Odaberi sobu
                </option>
                {rooms.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </InputContainer>
            <InputContainer>
              <SectionTitle>Kratica za master kalendar</SectionTitle>
              <Input
                type="text"
                name="masterAbbreviation"
                placeholder="----"
                defaultValue={
                  createEventData.masterAbbreviation ||
                  formData.masterAbbreviation
                }
                onChange={handleInputChange}
                // disabled={!isRoomLarge}
              />
            </InputContainer>
          </DatePlaceRow>
          <SmallInputContainer>
            <SectionTitle>Vrijeme</SectionTitle>
            <Select
              type="text"
              name="time"
              placeholder="00:00"
              value={createEventData.time || formData.time}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            >
              <option value="" disabled selected>
                {isEditMode
                  ? supervizijaData.timeFrom?.slice(0, 5) +
                    " - " +
                    supervizijaData.timeTo?.slice(0, 5)
                  : "00:00 - 00:00"}
              </option>
              {vrijemeOdrzavanja.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </SmallInputContainer>
        </InputWrapper>
        <TitleBold>
          Supervizori {createEventData?.voditeljiData?.length}
        </TitleBold>
        <VoditeljiContainer>
          {createEventData?.voditeljiData?.length > 0 ? (
            <>
              {createEventData?.voditeljiData.map((item, index) => (
                <VoditeljiCard
                  key={index}
                  voditeljAvatar={item.imageUrl}
                  voditeljIme={item.firstName}
                  voditeljPrezime={item.lastName}
                  voditeljBiografija={item.bio}
                  voditeljEmail={item.emailAddress}
                  deleteVoditelj={() => deleteVoditelj(item.id)}
                />
              ))}
              <AddVoditeljButton>
                <ButtonColumn
                  onClick={() =>
                    navigate("/djelatnici", {
                      state: {
                        isAddVoditeljToEvent: true,
                        isAddVoditelj: false,
                        voditeljiNow: createEventData.voditeljiData,
                      },
                    })
                  }
                >
                  <img alt="" src={Plus} />
                  Dodaj supervizora
                </ButtonColumn>
              </AddVoditeljButton>
            </>
          ) : (
            <AddVoditeljButton>
              <ButtonColumn
                onClick={() =>
                  navigate("/djelatnici", {
                    state: {
                      isAddVoditeljToEvent: true,
                      isAddVoditelj: false,
                      voditeljiNow: createEventData.voditeljiData,
                    },
                  })
                }
              >
                <img alt="" src={Plus} />
                Dodaj supervizora
              </ButtonColumn>
            </AddVoditeljButton>
          )}
        </VoditeljiContainer>
        <SectionTitleRow onClick={() => toggleDropdown()}>
          <TitleBold>
            Polaznici {createEventData?.polazniciData?.length}
          </TitleBold>
          <DropdownArrow alt="" src={Down} rotated={showTable} />
        </SectionTitleRow>
        {!isCreateMode && (
          <>
            {showTable && (
              <TableContainer>
                <TablePolaznici
                  columnNames={columnNames}
                  data={createEventData?.polazniciData}
                />
              </TableContainer>
            )}
          </>
        )}
        <EditFooter>
          {!isCreateMode && (
            <EditButton
              color="#ffffff"
              backgroundColor="#CB2D2D"
              onClick={() => handleDeleteSupervision()}
              style={{ display: "flex", alignItems: "center", gap: 5 }}
            >
              Obriši
              <img src={Trash} alt="" />
            </EditButton>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 10,
              width: "100%",
            }}
          >
            <EditButton
              color="#808080"
              backgroundColor="#EBEBEB"
              onClick={() => {
                navigate("/programi/five");
                resetCreateEventData();
              }}
            >
              Odustani
            </EditButton>
            <EditButton
              color="#FFFFFF"
              backgroundColor="#CB9C2D"
              // disabled={!areAllFieldsFilled(formData)}
              onClick={handleSubmit}
            >
              {isCreateMode ? "Kreiraj radionicu" : " Spremi promjene"}
            </EditButton>
          </div>
        </EditFooter>
        {showModal && (
          <Modal
            image={modalContent.image}
            description={modalContent.description}
            buttons={modalContent.buttons}
          />
        )}
      </Wrapper>
    </>
  );
}

export default SupervizijeEditCreate;
