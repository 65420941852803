const OR_FORM = [
  {
    attendee: "Marija Matić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    question5: "Odgovor 5",
    question6: "Odgovor 6",
    check1: true,
    check2: true,
  },
  {
    attendee: "Ante Antić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    question5: "Odgovor 5",
    question6: "Odgovor 6",
    check1: true,
    check2: true,
  },
  {
    attendee: "Ivo Ivić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    question5: "Odgovor 5",
    question6: "Odgovor 6",
    check1: true,
    check2: true,
  },
  {
    attendee: "Mate Matić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    question5: "Odgovor 5",
    question6: "Odgovor 6",
    check1: true,
    check2: true,
  },
];
const eOR_FORM = [
  {
    attendee: "Marija Matić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    choice: "Odabir 1",
    question5: "Odgovor 5",
    question6: "Odgovor 6",
    check1: true,
    check2: true,
  },
  {
    attendee: "Ante Antić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    choice: "Odabir 1",
    question5: "Odgovor 5",
    question6: "Odgovor 6",
    check1: true,
    check2: true,
  },
  {
    attendee: "Ivo Ivić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    choice: "Odabir 1",
    question5: "Odgovor 5",
    question6: "Odgovor 6",
    check1: true,
    check2: true,
  },
  {
    attendee: "Mate Matić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    choice: "Odabir 1",
    question5: "Odgovor 5",
    question6: "Odgovor 6",
    check1: true,
    check2: true,
  },
];
const TP_FORM = [
  {
    attendee: "Marija Matić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    choice1: "Odabir 1",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    question5: "Odgovor 5",
    choice2: "Odabir 2",
    select: "Tomislav Kuljiš",
    question6: "Odgovor 6",
    choice3: "Odabir 3",
  },
  {
    attendee: "Ante Antić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    choice1: "Odabir 1",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    question5: "Odgovor 5",
    choice2: "Odabir 2",
    select: "Tomislav Kuljiš",
    question6: "Odgovor 6",
    choice3: "Odabir 3",
  },
  {
    attendee: "Ivo Ivić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    choice1: "Odabir 1",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    question5: "Odgovor 5",
    choice2: "Odabir 2",
    select: "Tomislav Kuljiš",
    question6: "Odgovor 6",
    choice3: "Odabir 3",
  },
  {
    attendee: "Mate Matić",
    question1: "Odgovor 1",
    question2: "Odgovor 2",
    choice1: "Odabir 1",
    question3: "Odgovor 3",
    question4: "Odgovor 4",
    question5: "Odgovor 5",
    choice2: "Odabir 2",
    select: "Tomislav Kuljiš",
    question6: "Odgovor 6",
    choice3: "Odabir 3",
  },
];
const TT_FORM = [
  {
    attendee: "Marija Marić",
    question1: "Odgovor 1",
    select1: "Tomislav Kuljiš",
    select2: "Marija Matić",
    question2: "Odgovor 2",
    check: true,
  },
  {
    attendee: "Ante Antić",
    question1: "Odgovor 1",
    select1: "Tomislav Kuljiš",
    select2: "Marija Matić",
    question2: "Odgovor 2",
    check: true,
  },
  {
    attendee: "Ivo Ivić",
    question1: "Odgovor 1",
    select1: "Tomislav Kuljiš",
    select2: "Marija Matić",
    question2: "Odgovor 2",
    check: true,
  },
  {
    attendee: "Mate Matić",
    question1: "Odgovor 1",
    select1: "Tomislav Kuljiš",
    select2: "Marija Matić",
    question2: "Odgovor 2",
    check: true,
  },
];
export const formData = { OR_FORM, eOR_FORM, TP_FORM, TT_FORM };
