import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgramCard, {
  cardTypes,
} from "../../../../components/ProgramCard/Card";
import { useAuthentication } from "../../../hooks/useAuthentication";
import styles from "../Završeni/Završeni.module.css";
import { getActivePrograms } from "./api";

function Aktivni({ userId }) {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const [programData, setProgramData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const idProgramData = await getActivePrograms(authToken);
        setProgramData(idProgramData);
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };
    if (authToken) fetchData();
  }, [authToken]);

  const [showErr, setShowErr] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowErr(true);
    }, 1000);
  }, []);

  if (programData?.length === 0 || programData === null) {
    return showErr ? (
      <div className={styles.emptyContainer}>
        <span className={styles.emptySpan}>
          Trenutno nema aktivnih programa.
        </span>
      </div>
    ) : (
      <></>
    );
  }
  return (
    <>
      {programData
        ?.sort((a, b) => a.id - b.id)
        ?.map((item, index) => (
          <ProgramCard
            key={index}
            variant={cardTypes.defaultCard}
            title={item.title}
            userId={userId}
            opis={item.description}
            brojPredavanja={item.brojPredavanja}
            trajanje={item?.title.includes("RAR") ? "2 mjeseca" : "12 mjeseci"}
            image={item.image}
            level={item.degree}
            polaznikProgrami
            onClick={() => navigate(`/pojediniprogram/${item.id}/one`)}
          />
        ))}
    </>
  );
}

export default Aktivni;
