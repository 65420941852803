import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getTherapistProfile = async (
  userId,
  accessToken,
  setTherapistProfile
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/therapist-profiles/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const res = response.data;
    setTherapistProfile(res);
  } catch (error) {
    console.error("Error fetching therapist profile data", error);
    throw error;
  }
};
