function ArchiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <g
        stroke="#5F6D7E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.125"
      >
        <path d="M24.438 15.234v8.707c0 1.983-.496 2.975-2.976 2.975h-8.925c-2.479 0-2.975-.992-2.975-2.975v-8.707"></path>
        <path d="M10.059 7.083h13.883c1.984 0 2.975.992 2.975 2.975v1.983c0 1.984-.991 2.975-2.975 2.975H10.06c-1.983 0-2.975-.991-2.975-2.975v-1.983c0-1.983.992-2.975 2.975-2.975z"></path>
        <path d="M15.195 18.983h3.61"></path>
      </g>
    </svg>
  );
}

export default ArchiveIcon;
