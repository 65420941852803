import { Navigate, Outlet } from "react-router-dom";
import { useUserState } from "../context/UserContext";

const PrivateRoutes = () => {
  const { userData } = useUserState();
  return !userData.firstLogin && userData.settingsSaved ? (
    <Outlet />
  ) : userData.firstLogin ? (
    <Navigate to="/moj-profil" state={{ isFirstLogin: true }} />
  ) : userData.settingsSaved ? (
    <Outlet />
  ) : userData.settingsSaved ? (
    <Navigate to="/postavke" />
  ) : (
    <></>
  );
};

export default PrivateRoutes;
