import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const Title = styled(Typography)(() => ({
  marginTop: "20px",
  color: "#4D4E4E",
  fontSize: "30px",
  fontWeight: "600",
}));

export const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "48px",
  width: "80%",
}));

export const TopBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "40px",
}));
export const Label = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "22px",
  fontFamily: "Inter",
  color: "#4D4E4E",
}));
export const ButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "80%",
  height: "102px",
  background: `linear-gradient(0deg, rgba(255, 255, 255, 0.80) 38.73%, rgba(255, 255, 255, 0.00) 76.96%)`,
}));
