import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getPregledTotalnogObracunaZaSobu = async (
  accessToken,
  roomId,
  startDate,
  endDate,
  setPregledTotalnogObracunaZaSobu
) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Both startDate and endDate are required.");
    }
    const response = await axios.get(
      `${apiUrl}/api/UserStatisticsByRoom/${roomId}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const resData = response.data;
    setPregledTotalnogObracunaZaSobu(resData);
  } catch (error) {
    console.error("Error fetching pregled totalnog obracuna za sobu:", error);
    throw error;
  }
};
