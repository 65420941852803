export const SupervizijeSuglasnostMock = [
  {
    date: "15.4.2023.",
    imageUrl: require("../../../../../assets/img/TomislavAvatar.png"),
    supervizor: "Tomislav Kuljiš",
  },
  {
    date: "14.6.2023.",
    imageUrl: require("../../../../../assets/img/LjerkaAvatar.png"),
    supervizor: "Ljerka Zorić",
  },
];
