import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import { useUserState } from "../../context/UserContext";
import { getGroupsILead } from "../groups/api/getGroupsILead";
import { useAuthentication } from "../hooks/useAuthentication";
import { getGroupEvidention } from "./api/getGroupEvidention";
import EvidencijaDolazakaCard from "./components/EvidencijaDolazakaCard";
import styles from "./EvidencijaDolazaka.module.css";

function EvidencijaDolazaka() {
  const { role } = useUserState();
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const [groupEvidention, setGroupEvidention] = useState([]);
  const [groups, setGroups] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (role === "Polaznik") {
      navigate("/dashboard");
    }
  }, [role, authToken]);

  useEffect(() => {
    const fetchGroups = async () => {
      const response = await getGroupsILead(authToken);
      const groupIds = response?.regularGroups?.map((group) => group.id);
      if (groupIds.length > 0) {
        const evidention = await getGroupEvidention(groupIds, authToken);
        setGroupEvidention(evidention);
        setGroups(true);
      }
      setIsLoading(false);
    };
    if (authToken) fetchGroups();
  }, [authToken]);

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <BeatLoader color="#cb9c2d" />
        </div>
      ) : (
        <>
          {groups ? (
            Object.keys(groupEvidention).map((groupName, index) => (
              <EvidencijaDolazakaCard
                key={index}
                group={groupName}
                data={groupEvidention[groupName]}
              />
            ))
          ) : (
            <div className={styles.info}>
              <img src={InfoIcon} alt="" />
              <p className={styles.infoP}>Trenutno nemate grupa koje vodite.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default EvidencijaDolazaka;
