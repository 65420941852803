import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import { useUserState } from "../../context/UserContext";
import { getGroupsILead } from "../groups/api/getGroupsILead";
import { useAuthentication } from "../hooks/useAuthentication";
import { getGroupEvidention } from "./api/getGroupEvidention";
import EvidencijaDolazakaCard from "./components/EvidencijaDolazakaCard";
import styles from "./EvidencijaDolazaka.module.css";
import { fetchGroups } from "./utils";

function EvidencijaDolazaka() {
  const { role } = useUserState();
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const [groupEvidention, setGroupEvidention] = useState([]);
  const [groups, setGroups] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (role === "Polaznik") {
      navigate("/dashboard");
    }
  }, [role, authToken, navigate]);

  useEffect(() => {
    if (authToken)
      fetchGroups(
        getGroupsILead,
        authToken,
        getGroupEvidention,
        setGroupEvidention,
        setGroups,
        setIsLoading
      );
  }, [authToken]);
  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <BeatLoader color="#cb9c2d" />
        </div>
      ) : (
        <>
          {groups ? (
            Object.keys(groupEvidention).map((groupName, index) => (
              <EvidencijaDolazakaCard
                key={index}
                group={groupName}
                data={groupEvidention[groupName]}
                refreshData={async () => {
                  await fetchGroups(
                    getGroupsILead,
                    authToken,
                    getGroupEvidention,
                    setGroupEvidention,
                    setGroups,
                    setIsLoading
                  );
                }}
              />
            ))
          ) : (
            <div className={styles.info}>
              <img src={InfoIcon} alt="" />
              <p className={styles.infoP}>Trenutno nemate grupa koje vodite.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default EvidencijaDolazaka;
