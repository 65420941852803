import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getNotificationPreference = async (userId, accessToken) => {
  if (userId)
    try {
      const response = await axios.get(
        `${apiUrl}/api/notification-preference/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access. Redirect to login page.");
      } else {
        console.error("Error fetching notification preferences:", error);
      }

      return [];
    }
};

export const postNotificationPreference = async (
  accessToken,
  userId,
  requestBody
) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/notification-preference/${userId}/create`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error posting notification preferences:", error);
    if (error.response) {
      console.error("Response from server:", error.response.data);
    }
  }
};

export const updateNotificationPreference = async (
  accessToken,
  userId,
  requestBody
) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/notification-preference/${userId}/update`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error updating notification preferences:", error);
    return [];
  }
};

export const changeSettingsSaved = async (accessToken) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/updateSettingsSaved`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error updating saved settings:", error);
    return [];
  }
};
