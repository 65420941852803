import { deleteGroupUser } from "./deleteGroupUser";
import { getGroup } from "./getGroup";

export const apiCallToRemoveStudent = async (
  selectedItemIndex,
  groupId,
  accessToken,
  setAttendeeData,
  setAttendeesRemoveModal,
  setIsAllowed
) => {
  try {
    const studentId = selectedItemIndex;
    await deleteGroupUser(groupId, studentId, accessToken);
    const fetchedGroupData = await getGroup(groupId, accessToken, setIsAllowed);
    const updatedStudentsData = fetchedGroupData.polaznici;
    setAttendeeData(updatedStudentsData);
    setAttendeesRemoveModal(false);
  } catch (error) {
    console.error("Error deleting student:", error);
  }
};
