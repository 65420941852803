import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getIntrospectionText = async (url, accessToken) => {
  try {
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching pojedini program:", error);
    return [];
  }
};

export const updateIntrospection = async (reqData, accessToken) => {
  try {
    const response = await axios.put(`${apiUrl}/api/introspection`, reqData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error updating introspection:", error);
    return [];
  }
};

export const createIntrospection = async (reqData, accessToken) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/userInIntrospection`,
      reqData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error updating introspection:", error);
    return [];
  }
};

export const getMyMentor = async (userId, programId, accessToken) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/group/myMentor`,
      { userId: userId, programId: programId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching pojedini program:", error);
    return [];
  }
};
