import React, { createContext, useContext, useState } from "react";

const GroupContext = createContext();

const GroupProvider = ({ children }) => {
  const initialGroupData = {
    name: "",
    programId: null,
    cityId: null,
    calendarFieldColour: "#FFFFFF",
    calendarTextColour: "#000000",
    noviPolaznici: [],
    noviVoditelji: [],
  };
  const [groupData, setGroupData] = useState(initialGroupData);
  const updateGroupData = (data) => {
    setGroupData(data);
  };
  const resetGroupData = () => {
    setGroupData(initialGroupData);
  };

  return (
    <GroupContext.Provider
      value={{ groupData, updateGroupData, resetGroupData }}
    >
      {children}
    </GroupContext.Provider>
  );
};
export const useGroupState = () => useContext(GroupContext);

export default GroupProvider;
