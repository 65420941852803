import { addHours, addMinutes, format } from "date-fns";
import React from "react";

const TimeSlot = ({ startTime }) => {
  // Calculate endTime by adding 2 hours and 15 minutes to startTime
  const endTime = addMinutes(addHours(startTime, 2), 15);

  return (
    <div className="time-slot">
      {format(startTime, "HH:mm")} - {format(endTime, "HH:mm")}
    </div>
  );
};

export default TimeSlot;
