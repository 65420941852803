import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const Title = styled(Typography)(() => ({
  marginTop: "20px",
  color: "#4D4E4E",
  fontSize: "30px",
  fontWeight: "600",
}));
export const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "48px",
  width: "80%",
}));
export const Row = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "40px",
}));
export const Label = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "22px",
  fontFamily: "Inter",
  color: "#4D4E4E",
}));
export const InputRow = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
}));
export const TableArhiviraj = styled(`div`)(() => ({
  width: "100%",
  marginTop: 24,
}));
