import {
  createEvent,
  deleteMasterEvent,
  getCalendarData,
  updateEvent,
} from "../calendar.api";

export const selectStyles = {
  backgroundColor: "#FFFFFF",
  borderColor: "#f2f0f0",
  borderWidth: "2px",
  borderStyle: "solid",
  fontSize: "16px",
  fontFamily: ["Source Sans Pro", "sans-serif"],
  width: "100%",
  height: "55px",
  borderRadius: "8px",
  padding: "6px 5px",
  border: "1px solid #D1D9E2 !important",
  boxShadow: "none",
  "&:focus": {
    border: "0 !important",
  },
};

export const handleInputChange = (name, value, setFormValues) => {
  setFormValues((prevValues) => ({
    ...prevValues,
    [name]: value,
  }));
};

export const postData = (eventType, formValues) => {
  switch (eventType) {
    case "regular_workshop":
      return {
        eventType: formValues.eventType,
        eventId: formValues.regularWorkshop,
        date: formValues.date,
        timeFrom: formValues.timeFrom,
        timeTo: formValues.timeTo,
        isPublished: formValues.isPublished,
        masterAbbreviation: formValues.masterAbbreviation,
        modulId: formValues.modulId,
        groupId: formValues.groupId,
        roomId: formValues.roomId,
      };
    case "meeting_with_mentor":
      return {
        eventType: formValues.eventType,
        date: formValues.date,
        timeFrom: formValues.timeFrom,
        timeTo: formValues.timeTo,
        isPublished: formValues.isPublished,
        masterAbbreviation: formValues.masterAbbreviation,
        groupId: formValues.groupId,
        roomId: formValues.roomId,
      };
    case "interactive_lesson":
      return {
        eventType: formValues.eventType,
        eventId: formValues.interactiveLessons,
        date: formValues.date,
        timeFrom: formValues.timeFrom,
        timeTo: formValues.timeTo,
        isPublished: formValues.isPublished,
        masterAbbreviation: formValues.masterAbbreviation,
        programId: formValues.programId,
        roomId: formValues.roomId,
      };
    default:
      return [];
  }
};

export const formatDate = (dateString) => {
  const dateObj = new Date(dateString);
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Month (0-11) -> (01-12)
  const day = dateObj.getDate().toString().padStart(2, "0"); // Day of the month (1-31) -> (01-31)
  const year = dateObj.getFullYear(); // Full year (YYYY)
  return `${year}-${month}-${day}`;
};
export const formatTime = (timeString) => {
  const timeObj = new Date(timeString);
  const hours = timeObj.getHours().toString().padStart(2, "0"); // Hours (0-23) -> (00-23)
  const minutes = timeObj.getMinutes().toString().padStart(2, "0"); // Minutes (0-59) -> (00-59)
  const seconds = timeObj.getSeconds().toString().padStart(2, "0"); // Seconds (0-59) -> (00-59)
  return `${hours}:${minutes}:${seconds}`;
};

export const handleCreateEvent = async (
  formValues,
  accessToken,
  startYear,
  endYear,
  setCalendarData,
  setOpenModal
) => {
  try {
    const response = await createEvent(
      formValues?.eventType,
      formValues,
      accessToken
    );
    if (response.status === 200) {
      getCalendarData(accessToken, startYear, endYear, setCalendarData);
      setOpenModal(false);
    }
  } catch (error) {
    console.error("Error creating event", error);
  }
};
export const handleUpdateEvent = async (
  formValues,
  accessToken,
  startYear,
  endYear,
  setCalendarData,
  setOpenModal
) => {
  try {
    const response = await updateEvent(formValues, accessToken);
    if (response.status === 200) {
      getCalendarData(accessToken, startYear, endYear, setCalendarData);
      setOpenModal(false);
    }
  } catch (error) {
    console.error("Error updating event", error);
  }
};
export const handleDeleteEvent = async (
  formValues,
  accessToken,
  startYear,
  endYear,
  setCalendarData,
  setOpenModal
) => {
  try {
    const response = await deleteMasterEvent(
      formValues?.eventType,
      formValues?.id,
      accessToken
    );
    if (response.status === 200) {
      getCalendarData(accessToken, startYear, endYear, setCalendarData);
      setOpenModal(false);
    }
  } catch (error) {
    console.error("Error deleting event", error);
  }
};
