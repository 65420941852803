import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getPotpuniObracunSoba = async (
  accessToken,
  startDate,
  endDate,
  setPotpuniObracunSoba,
  setLoading
) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Both startDate and endDate are required.");
    }
    const response = await axios.get(
      `${apiUrl}/api/UserStatisticsByAllCities/?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const resData = response.data;
    setPotpuniObracunSoba(resData);
    setLoading(false);
  } catch (error) {
    setLoading(false);

    console.error("Error fetching potpuni obracun sobe:", error);
    throw error;
  }
};
