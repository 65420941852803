import React from "react";
import Select from "react-select";
import Close from "../../../../assets/img/ExitIcon.png";
import { useAuthentication } from "../../../hooks/useAuthentication";
import {
  handleSuccessAddZakupiteljClick,
  selectStyles,
} from "../../utils/utils";
import styles from "./AddUserToModal.module.css";

function AddUserToModal({
  onClose,
  addPolaznikToZakupitelj,
  addPolaznikToGroup,
  title,
  data,
  setIsModalZakupiteljOpen,
  setModalContent,
  setSuccessModal,
  pojediniPolaznikID,
  setUserData,
  setUserInGroups,
  setPolozeniProgram,
  setIsZakupitelj,
  setMjestoUpisaOptions,
  setAllPrograms,
}) {
  const { authToken } = useAuthentication();

  return (
    <div className={styles.overlay}>
      <button className={styles.closeIcon} onClick={onClose}>
        <img alt="" src={Close} />
      </button>
      <div
        className={`${styles.container} ${
          addPolaznikToZakupitelj ? styles.zakupiteljContainer : ""
        } ${addPolaznikToGroup ? styles.groupContainer : ""}`}
      >
        <div className={styles.wrapper}>
          <div className={styles.title}>{title}</div>
          {addPolaznikToZakupitelj && (
            <>
              <div className={styles.subtitle}>
                Polaznik: {data?.firstName} {data?.lastName}
              </div>
              <div className={styles.selectContainer}>
                <div className={styles.label}>
                  Dodavanjem polaznika dopuštate mu da vidi izbornik za gradove
                  u kojima bi bio zakupitelj na tabu postavke profila.
                </div>
              </div>
            </>
          )}
          {addPolaznikToGroup && (
            <>
              <div className={styles.selectContainer}>
                <div className={styles.label}>Ime i prezime polaznika</div>
                <Select
                  placeholder={`${data?.firstName} ${data?.lastName}`}
                  isSearchable={false}
                  styles={selectStyles}
                  components={{ IndicatorSeparator: () => null }}
                  isDisabled={true}
                />
              </div>
              <div className={styles.selectContainer}>
                <div className={styles.label}>
                  Program koji upisuje polaznik
                </div>
                <Select
                  placeholder={"Program name"}
                  isSearchable={false}
                  styles={selectStyles}
                  components={{ IndicatorSeparator: () => null }}
                  isDisabled={true}
                />
              </div>
              <div className={styles.selectContainer}>
                <div className={styles.label}>Grad u kojem pohađa program</div>
                <Select
                  placeholder={data?.programCity || "Nepoznato"}
                  isSearchable={false}
                  styles={selectStyles}
                  components={{ IndicatorSeparator: () => null }}
                  isDisabled={true}
                />
              </div>
              <div className={styles.selectContainer}>
                <div className={styles.label}>Grupa</div>
                <Select
                  placeholder="Odaberi grupu"
                  isSearchable={false}
                  styles={selectStyles}
                  components={{ IndicatorSeparator: () => null }}
                  isDisabled={true}
                />
              </div>
            </>
          )}

          <div className={styles.btnRow}>
            <button className={styles.cancelBtn} onClick={onClose}>
              Odustani
            </button>
            {addPolaznikToZakupitelj && (
              <button
                className={styles.acceptBtn}
                onClick={() =>
                  handleSuccessAddZakupiteljClick(
                    setIsModalZakupiteljOpen,
                    setModalContent,
                    setSuccessModal,
                    pojediniPolaznikID,
                    authToken,
                    setUserData,
                    setUserInGroups,
                    setPolozeniProgram,
                    setIsZakupitelj,
                    setMjestoUpisaOptions,
                    setAllPrograms
                  )
                }
              >
                Dodaj
              </button>
            )}
            {addPolaznikToGroup && (
              <button className={styles.acceptBtn} disabled>
                Dodaj polaznika
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUserToModal;
