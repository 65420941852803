import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import styles from "./EvidencijaPage.module.css";
import { getProgramsByUserId } from "./api/getProgramsByUserId";
import DiplomePotvrde from "./components/DiplomePotvrde/DiplomePotvrde";
import NastupnoPredavanje from "./components/NastupnoPredavanje/NastupnoPredavanje";
import OsobniPsihoterapeutskiRad from "./components/OsobniPsihoterapeutskiRad/OsobniPsihoterapeutskiRad";
import OsobniRadKlijentiGrupe from "./components/OsobniRadKlijentiGrupe/OsobniRadKlijentiGrupe";
import Polaznik from "./components/Polaznik/Polaznik";
import SudjelovanjeNaPredavanjima from "./components/SudjelovanjeNaPredavanjima/SudjelovanjeNaPredavanjima";
import Supervizije from "./components/Supervizije/Supervizije";
import ZavrsniRadovi from "./components/ZavrsniRadovi/ZavrsniRadovi";
import {
  fetchMySudjelovanje,
  fetchUserSudjelovanje,
  getPolaznikLevel,
} from "./util";

function EvidencijaPage() {
  const [evidentionData, setEvidentionData] = useState({});
  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();

  const location = useLocation();
  const localFullName = localStorage.getItem("evidentionFullName");
  const localUserEvidentionID = localStorage.getItem("evidentionUserID");
  const [fullName, setFullName] = useState(
    location?.state?.fullName ||
      localFullName ||
      `${userData.firstName} ${userData.lastName}`
  );
  const [userEvidentionID, setUserEvidentionID] = useState(
    location?.state?.userEvidentionID || localUserEvidentionID || userData.id
  );
  const [userPrograms, setUserPrograms] = useState({});
  const userId = role;
  const [programsArray, setProgramsArray] = useState([]);
  const [deleteFlag, setDeleteFlag] = useState([]);

  useEffect(() => {
    if (fullName !== undefined && userId === "Referada") {
      localStorage.setItem("evidentionFullName", fullName);
    } else {
      localStorage.setItem(
        "evidentionFullName",
        `${userData.firstName} ${userData.lastName}`
      );
    }
    if (userEvidentionID !== undefined && userId === "Referada") {
      localStorage.setItem("evidentionUserID", userEvidentionID);
    } else {
      localStorage.setItem("evidentionUserID", userData.id);
    }
  }, [fullName, userEvidentionID, userData.firstName, userData.lastName]);

  useEffect(() => {
    if (authToken) {
      // getSupervisionEntry(userEvidentionID, authToken)
      //   .then((data) => console.log(data.data))
      //   .catch(() => console.log("gg"));
      if (userData.id !== userEvidentionID) {
        fetchUserSudjelovanje(userEvidentionID, authToken, setEvidentionData);
      } else {
        fetchMySudjelovanje(authToken, setEvidentionData);
      }
    }
  }, [userEvidentionID, authToken, deleteFlag]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getProgramsByUserId(authToken, userEvidentionID);
        setUserPrograms(response.map((program) => program.id));
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    if (authToken) {
      fetchUser();
    }
  }, [authToken, userEvidentionID]);

  useEffect(() => {
    if (evidentionData && evidentionData.lessonsAndWorkshops) {
      const programNames = Object.keys(evidentionData.lessonsAndWorkshops);
      setProgramsArray(programNames);
    }
  }, [evidentionData, deleteFlag]);

  const polaznikLevel = getPolaznikLevel(
    evidentionData ? evidentionData.zavrsniRadovi : null
  );
  const tpIttData = {
    tp2:
      (evidentionData &&
        evidentionData?.lessonsAndWorkshops &&
        evidentionData?.lessonsAndWorkshops["TP2 - Terapeutski praktikum"]) ||
      null,
    tt5:
      (evidentionData &&
        evidentionData?.lessonsAndWorkshops &&
        evidentionData?.lessonsAndWorkshops["TT5 - Terapeutski trening"]) ||
      null,
    tt6:
      (evidentionData &&
        evidentionData?.lessonsAndWorkshops &&
        evidentionData?.lessonsAndWorkshops["TT6 - Terapeutski trening"]) ||
      null,
  };

  return (
    <div className={styles.wrapper}>
      <Polaznik
        polaznikLevel={polaznikLevel}
        userEvidentionID={userEvidentionID}
      />
      <SudjelovanjeNaPredavanjima
        userId={userId}
        polaznikLevel={polaznikLevel}
        evidentionUserID={userEvidentionID}
        setEvidentionData={setEvidentionData}
        data={evidentionData?.lessonsAndWorkshops}
      />
      <OsobniPsihoterapeutskiRad
        userId={userId}
        polaznikLevel={polaznikLevel}
        data={evidentionData?.personalPsychotherapyWork}
        setDeleteFlag={setDeleteFlag}
        deleteFlag={deleteFlag}
        evidentionUserID={userEvidentionID}
      />
      {polaznikLevel !== "rar" && (
        <Supervizije
          userId={userId}
          polaznikLevel={polaznikLevel}
          data={evidentionData?.supervisionObject}
          setDeleteFlag={setDeleteFlag}
          deleteFlag={deleteFlag}
          evidentionUserID={userEvidentionID}
          tpIttData={tpIttData}
          shouldShowCertificate={
            evidentionData?.zavrsniRadovi?.caseStudy1 &&
            evidentionData?.zavrsniRadovi?.zavrsniOR
          }
        />
      )}
      {polaznikLevel !== "rar" && (
        <OsobniRadKlijentiGrupe
          userId={userId}
          polaznikLevel={polaznikLevel}
          data={evidentionData?.workWithClients}
          setDeleteFlag={setDeleteFlag}
          deleteFlag={deleteFlag}
          evidentionUserID={userEvidentionID}
        />
      )}
      {userPrograms.length > 0 && userPrograms?.some((num) => num >= 11) && (
        <NastupnoPredavanje
          userId={userId}
          data={evidentionData?.presentations}
          evidentionUserID={userEvidentionID}
        />
      )}
      <ZavrsniRadovi
        userId={userId}
        polaznikLevel={polaznikLevel}
        data={evidentionData?.zavrsniRadovi}
        evidentionUserID={userEvidentionID}
        setDeleteFlag={setDeleteFlag}
        deleteFlag={deleteFlag}
      />
      <DiplomePotvrde userId={userId} polaznikLevel={polaznikLevel} />
    </div>
  );
}

export default EvidencijaPage;
