export const OstaloMockData = [
  {
    title: "Pauzirani program",
    image: require("../../../../assets/img/EModulImage.png"),
    description:
      "Grupna supervizija AGS posvećen je dubljem spoznavanju sebe na temu koliko doista živimo svoj život u punini, te kako više možemo živjeti život iz vlastite autentičnosti. Vježbe koje ćemo raditi kroz radionice imaju namjeru da sagledamo gdje smo trenutno u životu sa temama koje su baza osjećaja životnosti, a to su: prepuštenost, užitak, osobna moć, sposobnost za dublji kontakt sa sobom i drugima, te ekspresija onoga što jesmo u sebi – na van.",
    polaznici: 64,
  },
  {
    title: "Produženi program",
    image: require("../../../../assets/img/EModulImage.png"),
    description:
      "Grupna supervizija AGS posvećen je dubljem spoznavanju sebe na temu koliko doista živimo svoj život u punini, te kako više možemo živjeti život iz vlastite autentičnosti. Vježbe koje ćemo raditi kroz radionice imaju namjeru da sagledamo gdje smo trenutno u životu sa temama koje su baza osjećaja životnosti, a to su: prepuštenost, užitak, osobna moć, sposobnost za dublji kontakt sa sobom i drugima, te ekspresija onoga što jesmo u sebi – na van.",
    polaznici: 32,
  },
];
