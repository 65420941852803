import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserState } from "../../context/UserContext";
import { auth } from "../../firebase";

export const Countdown = () => {
  const [counter, setCounter] = useState(60);
  const { setAccessToken, setUserData } = useUserState();
  const nav = useNavigate();
  useEffect(() => {
    if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
    else {
      signOut(auth);
      setAccessToken("");
      setUserData(null);
      localStorage.removeItem("authToken");
      localStorage.removeItem("sessionTime");
      nav("/");
    }
  }, [counter]);
  return (
    <div style={{ color: "white", fontSize: "28px", margin: "0px auto" }}>
      {counter}
    </div>
  );
};
