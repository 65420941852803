import React from "react";
import RoundCheckIcon from "../../assets/RoundCheckIcon.svg";
import RoundExitIcon from "../../assets/RoundExitIcon.svg";
import styles from "./DostupneTerapijeCard.module.css";

const TerapijeCard = ({ live, online }) => {
  return (
    <div className={styles.card}>
      <div className={styles.titleRow}>Dostupan/na za terapije</div>
      <div className={styles.row}>
        <div className={styles.text}>Uživo</div>
        <div className={styles.text}>Online</div>
      </div>
      <div className={styles.rowTwo}>
        {live ? (
          <img src={RoundCheckIcon} alt="Icon 1" />
        ) : (
          <img src={RoundExitIcon} alt="Icon 2" />
        )}
        {online ? (
          <img src={RoundCheckIcon} alt="Icon 1" />
        ) : (
          <img src={RoundExitIcon} alt="Icon 2" />
        )}
      </div>
    </div>
  );
};

export default TerapijeCard;
