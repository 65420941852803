import React from "react";
import PozivniDropdown from "../../myProfile/components/PozivniDropdown/PozivniDropdown";
import CustomInput from "../../myProfile/components/customInput/CustomInput";
import SelectDropdown from "../../singleGroup/components/selectDropdown/SelectDropdown";
import styles from "./Form.module.css";
import { gradovi } from "./utils";

function Form({
  userData,
  handleInputChange,
  numericPhoneNumber,
  selectedValue,
  handleSelectChange,
}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.profileForm}>
        <div className={styles.profileFormRow}>
          <div className={styles.profileFormColumn}>
            <CustomInput
              name="firstName"
              onChange={handleInputChange}
              value={userData?.firstName}
              labelText="Ime"
              placeholder="Upiši"
            />
            <CustomInput
              name="lastName"
              onChange={handleInputChange}
              value={userData?.lastName}
              labelText="Prezime"
              placeholder="Upiši"
            />
            <SelectDropdown
              name="gender"
              onChange={handleInputChange}
              title="Spol"
              value={userData?.gender}
              options={[
                { value: "Muško", label: "Muško" },
                { value: "Žensko", label: "Žensko" },
                { value: "Ostalo", label: "Ostalo" },
              ]}
            />
            <CustomInput
              name="city"
              onChange={handleInputChange}
              value={userData?.city}
              labelText="Mjesto stanovanja"
              type="text"
              placeholder="Upiši"
            />
            <SelectDropdown
              name="programCity"
              onChange={handleInputChange}
              title="Grad u kojem sudjeluje na programu"
              value={userData?.programCity}
              options={gradovi}
            />
            <CustomInput
              name="title"
              value={userData?.title}
              onChange={handleInputChange}
              labelText="Zvanje - Titula"
              placeholder="Upiši"
            />
            <div className={styles.mobileColumn}>
              <span className={styles.label}>Broj mobitela</span>
              <div className={styles.mobileRow}>
                <PozivniDropdown
                  selectedValue={selectedValue}
                  handleSelectChange={handleSelectChange}
                />
                <div style={{ marginLeft: "-6px", width: "100%" }}>
                  <CustomInput
                    type="tel"
                    name="phoneNumber"
                    value={numericPhoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.profileFormColumn}>
            <CustomInput
              name="dateOfBirth"
              value={userData?.dateOfBirth}
              onChange={handleInputChange}
              labelText="Datum rođenja"
              type="date"
            />
            <CustomInput
              name="address"
              value={userData?.address}
              onChange={handleInputChange}
              labelText="Adresa stanovanja"
              placeholder="Upiši"
            />

            <CustomInput
              name="country"
              onChange={handleInputChange}
              value={userData?.country}
              labelText="Država stanovanja"
              type="text"
              placeholder="Upiši"
            />
            <SelectDropdown
              name="qualification"
              onChange={handleInputChange}
              title="Stručna sprema"
              value={userData?.qualification}
              options={[
                {
                  value: "doktorZnanosti",
                  label: "Doktor znanosti",
                },
                {
                  value: "doktoricaZnanosti",
                  label: "Doktorica znanosti",
                },
              ]}
            />
            <CustomInput
              name="OIB"
              onChange={handleInputChange}
              value={userData?.OIB}
              labelText="Identifikacijski broj (OIB/EMŠO/JMBG i sl.)"
              type="number"
              placeholder="Upiši"
            />
            <CustomInput
              name="emailAddress"
              onChange={handleInputChange}
              value={userData?.emailAddress}
              labelText="Email adresa"
              type="email"
              placeholder="Upiši"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
