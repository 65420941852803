export const optionsObrazovanje = [
  {
    value: "Doktor znanosti",
    label:
      "Doktor znanosti - 180 ECTS bodova (+ 300 ECTS bodova s preddiplomskog i diplomskog studija, integriranog preddiplomskog i ",
  },
  {
    value: "Magistar znanosti",
    label:
      "Master of Science / Magistar znanosti (prije Bologne) i sveučilišni specijalist (poslijediplomski specijalistički studij) - 60 do 90 ECTS bodova",
  },
  {
    value: "Magistar struke",
    label:
      "Master's Degree / Magistar struke (diplomski sveučilišni drudij), stručni specijalist (diplomski stručni studij), VSS (dodiplomski studij prije Bologne)",
  },
  {
    value: "Sveučilišni prvostupnik",
    label:
      "Bachelor's Degree / Sveučilišni prvostupnik (Baccalaureus) stručni prvostupnik i VŠS (prije Bologne) - 180 do 240 ECTS",
  },
  {
    value: "Stručni prvostupnik",
    label:
      "Associate Degree / Stručni prvostupnik (Baccalaureus)  - 120 Bodova do 150 ECTS bodova",
  },
  {
    value: "Četverogodišnja srednja škola",
    label: "High school diploma (4. years) / Četverogodišnja srednja škola",
  },
  {
    value: "Trogodišnja srednja škola",
    label: "High school diploma (3. years) / Trogodišnja srednja škola",
  },
  {
    value: "Osnovna škola",
    label: "Primary education / Osnovna škola i manje",
  },
];

export const Spol = [
  { value: "musko", label: "Muški" },
  { value: "zensko", label: "Ženski" },
  { value: "ostalo", label: "Ostalo" },
];

export const Roles = [
  { value: 1, label: "Zakupitelj" },
  { value: 2, label: "Polaznik" },
  { value: 3, label: "Terapeut" },
  { value: 4, label: "Voditelj" },
  { value: 5, label: "Supervizor" },
  { value: 6, label: "Referada" },
  { value: 7, label: "Učitelj" },
  { value: 8, label: "Polaznik VT-a" },
];
