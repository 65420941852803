import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getObracunData = async (
  accessToken,
  userId,
  startDate,
  endDate,
  setObracunData
) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Both startDate and endDate are required.");
    }
    const response = await axios.get(
      `${apiUrl}/api/AllUserStatistics?userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const resData = response.data;
    setObracunData(resData);
  } catch (error) {
    console.error("Error fetching obracun sobe:", error);
    throw error;
  }
};
