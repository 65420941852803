export const odobrenjeAplikacijeTabs = [
  { value: "1", label: "Aplikacije za RAR" },
  { value: "2", label: "Aplikacije za eRAR" },
  { value: "3", label: "Aplikacije za OR1" },
  { value: "4", label: "Aplikacije za OR2" },
  { value: "5", label: "Aplikacije za OR3" },
  { value: "6", label: "Aplikacije za OR4" },
  { value: "7", label: "Aplikacije za eOR1" },
  { value: "8", label: "Aplikacije za eOR2" },
  { value: "9", label: "Aplikacije za eOR3" },
  { value: "10", label: "Aplikacije za eOR4" },
  { value: "11", label: "Aplikacije za TT5" },
  { value: "12", label: "Aplikacije za TT6" },
  { value: "13", label: "Aplikacije za TP1" },
  { value: "14", label: "Aplikacije za TP2" },
];

export const countOccurrences = (array) => {
  const countObject = {};
  array.forEach((id) => {
    countObject[id] = (countObject[id] || 0) + 1;
  });
  return countObject;
};
export const columnNames = (programName) => {
  let names = [];
  names = ["Ime i prezime", `${programName}`, "Evaluacija mentora"];
  return names;
};
