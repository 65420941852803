import React from "react";
import RenderTableData from "./RenderTableData";
import RenderTableHeader from "./RenderTableHeader";
import "./TableOsvrti.css";

function TableOsvrti({ columnNames = [], data = [] }) {
  return (
    <table className="table-osvrti-voditelja">
      <thead>
        <RenderTableHeader columnNames={columnNames} />
      </thead>
      <tbody>
        <RenderTableData data={data} />
      </tbody>
    </table>
  );
}

export default TableOsvrti;
