import React from "react";
import { convertToDateSortableFormat } from "../../utils";
import styles from "./ViewForm.module.css";

function ViewForm({ userData }) {
  return (
    <div className={styles.leftProfileBox}>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>Datum rođenja</p>
        <p className={styles.profileItemText}>
          {convertToDateSortableFormat(userData.dateOfBirth)}
        </p>
      </div>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>Spol</p>
        <p className={styles.profileItemText}>{userData.gender}</p>
      </div>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>Mjesto stanovanja</p>
        <p className={styles.profileItemText}>{userData.city}</p>
      </div>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>Adresa stanovanja</p>
        <p className={styles.profileItemText}>{userData.address}</p>
      </div>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>Država stanovanja</p>
        <p className={styles.profileItemText}>{userData.country}</p>
      </div>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>Grad u kojem pohađa program</p>
        <p className={styles.profileItemText}>{userData.programCity}</p>
      </div>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>Stručna sprema</p>
        <p className={styles.profileItemText}>{userData.qualification}</p>
      </div>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>Zvanje</p>
        <p className={styles.profileItemText}>{userData.title}</p>
      </div>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>
          Identifikacijski broj (OIB, JMBG, JMŠO)
        </p>
        <p className={styles.profileItemText}>{userData.OIB}</p>
      </div>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>Broj mobitela</p>
        <p className={styles.profileItemText}>{userData.phoneNumber}</p>
      </div>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>Email adresa</p>
        <p className={styles.profileItemText}>{userData.emailAddress}</p>
      </div>
      <div className={styles.profileInfoRow}>
        <p className={styles.profileItemTitle}>Završeni programi</p>
        <p className={styles.profileItemText}>---</p>
      </div>
    </div>
  );
}

export default ViewForm;
