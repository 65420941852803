import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const ImageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "backgroundImage",
})(({ backgroundImage }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 248,
  width: 336,
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  "@media screen and (max-width: 455px)": {
    width: 280,
    height: 200,
  },
  "@media screen and (max-width: 390px)": {
    width: 250,
    height: 175,
  },
}));

export const InfoContainer = styled(Box)(({ flexDirection }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "baseline",
  gap: 20,
  width: "90%",
  "@media screen and (max-width: 455px)": {
    width: 280,
  },
  "@media screen and (max-width: 390px)": {
    width: 250,
  },
}));

export const TitleRow = styled(Box)(({ justifyContent }) => ({
  display: "flex",
  justifyContent: justifyContent,
  alignItems: "center",
  gap: 16,
  width: "100%",
  "@media screen and (max-width: 765px)": {
    marginBottom: 16,
  },
}));

export const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "backgroundImage",
})(({ backgroundImage }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "100%",
  backgroundSize: "cover",
  width: 64,
  height: 64,
  "@media screen and (max-width: 825px)": {
    width: 56,
    height: 56,
  },
}));

export const Title = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontSize: 20,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#4D4D4D",
  "@media screen and (max-width: 825px)": {
    fontSize: 18,
  },
  "@media screen and (max-width: 400px)": {
    lineHeight: 1.1,
  },
}));

export const OpisContainer = styled(Box)(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: 16,
  letterSpacing: " -0.001em",
  color: "#5F6D7E",
  textAlign: "left",
  lineHeight: 1.5,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  display: "-webkit-box",
  WebkitLineClamp: 6,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media screen and (max-width: 575px)": {
    fontSize: 14,
  },
}));

export const OsobeRow = styled(Box)(({ flexDirection, alignItems }) => ({
  display: "flex",
  alignItems: alignItems,
  justifyContent: "flex-start",
  gap: 48,
  "@media screen and (max-width: 900px)": {
    flexDirection: flexDirection,
    gap: flexDirection === "row" ? 16 : 0,
    alignItems: alignItems,
  },
  "@media screen and (max-width: 764px)": {
    marginTop: "-24px",
    paddingTop: 15,
  },
  "@media screen and (min-width: 900px) and (max-width:1024px) ": { gap: 24 },
}));

export const BrojOsoba = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#9FA7B2",
  "@media screen and (max-width: 575px)": {
    fontSize: 12,
  },
}));
export const ButtonUredi = styled(`button`)(() => ({
  display: "flex",
  paddingLeft: 50,
  paddingRight: 50,
  paddingTop: 20,
  paddingBottom: 20,
  alignItems: "center",
  justifyContent: "center",
  height: 40,
  border: "1px solid #D1D9E2",
  fontFamily: "Inter",
  fontSize: 15,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "center",
  color: "#5F6D7E",
  borderRadius: 4,
  backgroundColor: "#FFFFFF",
  transition: "backgroundColor 300ms ease-in-out",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#D1D9E2",
  },
  "@media screen and (max-width: 825px)": {
    paddingLeft: 33,
    paddingRight: 33,
  },
}));
export const Divider = styled(`div`)(() => ({
  width: 1,
  marginLeft: 10,
  marginRight: 10,
  backgroundColor: "#E2E2E2",
  border: "1px solid #E2E2E2",
}));
export const Datum = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#9FA7B2",
}));
