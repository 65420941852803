import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAuthentication } from "../hooks/useAuthentication";
import styles from "./EvidencijaPage.module.css";
import Polaznik from "./components/Polaznik/Polaznik";
import AktualniRadKlijenti from "./components/RucniUnos/AktualniRadKlijenti";
import FakultativneRadionice from "./components/RucniUnos/FakultativneRadionice";
import GrupneSupervizije from "./components/RucniUnos/GrupneSupervizije";
import IndividualneProgramskeTerapije from "./components/RucniUnos/IndividualneProgramskeTerapije";
import IndividualneSupervizije from "./components/RucniUnos/IndividualneSupervizije";
import IntenziviRetroaktivno from "./components/RucniUnos/IntenziviRetroaktivno";
import OsobniRadGrupa from "./components/RucniUnos/OsobniRadGrupa";
import RetroaktivniRadKlijenti from "./components/RucniUnos/RetroaktivniRadKlijenti";
import { fetchTerapeutiAndSupervizori } from "./util";

function RucniUnosPage({ userName }) {
  const location = useLocation();
  const { authToken } = useAuthentication();
  const { program } = useParams();
  const userEvidentionID = location?.state?.userEvidentionID;
  const [terapeuti, setTerapeuti] = useState([]);
  const currentAcademicYearStart = 2023;
  const currentAcademicYearEnd = 2024;
  const [supervizori, setSupervizori] = useState([]);
  const [arrayTerapeuti, setArrayTerapeuti] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [arraySupervizori, setArraySupervizori] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  useEffect(() => {
    if (authToken) {
      fetchTerapeutiAndSupervizori(authToken, setTerapeuti, setSupervizori);
    }
  }, [authToken]);

  useEffect(() => {
    if (terapeuti) {
      const mappedTerapeuti = terapeuti?.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      }));
      const mappedSupervizori = supervizori?.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      }));
      const mappedData = [...mappedTerapeuti, ...mappedSupervizori];
      const sortedMappedData = mappedData.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setArrayTerapeuti([
        ...sortedMappedData,
        { label: "Vanjski terapeut", value: "vanjski" },
      ]);
    } else {
      setArrayTerapeuti([]);
    }
  }, [terapeuti]);

  useEffect(() => {
    if (supervizori) {
      const mappedSupervizori = supervizori?.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      }));
      setArraySupervizori([
        ...mappedSupervizori,
        { label: "Vanjski supervizor", value: "vanjski" },
      ]);
    } else {
      setArraySupervizori([]);
    }
  }, [supervizori]);

  return (
    <div className={styles.wrapper}>
      <Polaznik userEvidentionID={userEvidentionID} />
      {program === "individualneTerapije" && (
        <IndividualneProgramskeTerapije
          terapeuti={arrayTerapeuti}
          userEvidentionID={userEvidentionID}
        />
      )}
      {program === "fakultativne" && (
        <FakultativneRadionice userEvidentionID={userEvidentionID} />
      )}
      {program === "grupneSupervizije" && (
        <GrupneSupervizije
          supervizori={arraySupervizori}
          userEvidentionID={userEvidentionID}
        />
      )}
      {program === "klijentiRetroaktivno" && (
        <RetroaktivniRadKlijenti userEvidentionID={userEvidentionID} />
      )}
      {program === "intenzivi" && (
        <IntenziviRetroaktivno userEvidentionID={userEvidentionID} />
      )}
      {program === "individualneSupervizije" && (
        <IndividualneSupervizije
          supervizori={arraySupervizori}
          userEvidentionID={userEvidentionID}
        />
      )}
      {program === "klijentiAktualno" && (
        <AktualniRadKlijenti
          userEvidentionID={userEvidentionID}
          currentAcademicYearEnd={currentAcademicYearEnd}
          currentAcademicYearStart={currentAcademicYearStart}
        />
      )}
      {program === "osobniRad" && (
        <OsobniRadGrupa userEvidentionID={userEvidentionID} />
      )}
    </div>
  );
}

export default RucniUnosPage;
