const apiUrl = process.env.REACT_APP_API_URL;

export const getAllGroupsForPolaznik = async (polaznikID, accessToken) => {
  try {
    const response = await fetch(`${apiUrl}/api/myEnrolledGroups`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching groups for polaznik:", error);
    throw error;
  }
};
