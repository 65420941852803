import React from "react";
import CardAccordion from "../../CardAccordionMain/CardAccordion";
import styles from "../OsobniPsihoterapeutskiRad.module.css";

function OsobniPsihoterapijskiRadAccordions({
  data,
  userId,
  setDeleteFlag,
  deleteFlag,
  evidentionUserID,
}) {
  return (
    <>
      <div className={styles.osobniRadAccordion}>
        {data.map((item, index) => {
          let hours = 0;
          let isNameClickable = false;
          {
            item?.modules.forEach((module) => {
              module.topics.forEach((topic) => {
                hours += topic.hours;
                isNameClickable = topic.isNameClickable;
              });
            });
          }
          const maxTopObracun = item.modules[0].maxTopObracun || 0;
          const maxBottomObracun = item.modules[0].maxBottomObracun || 0;
          const totalMaxObracun = maxTopObracun + maxBottomObracun;
          const errorFlag = hours > totalMaxObracun;
          return (
            <CardAccordion
              key={index}
              title={item?.title}
              modules={[item?.modules?.[0]?.topics]}
              maxTopObracun={maxTopObracun}
              maxBottomObracun={maxBottomObracun}
              showModulName={false}
              variant="hours"
              isNameClickable={isNameClickable}
              totalAttendance={hours}
              attendedCount={hours}
              userId={userId}
              errorFlag={errorFlag}
              setDeleteFlag={setDeleteFlag}
              deleteFlag={deleteFlag}
              evidentionUserID={evidentionUserID}
            />
          );
        })}
      </div>
    </>
  );
}

export default OsobniPsihoterapijskiRadAccordions;
