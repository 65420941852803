import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const addRegulation = async (data, accessToken) => {
  try {
    const response = await axios.post(`${apiUrl}/api/rulebooks`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error adding regulation:", error);
    throw error;
  }
};
