import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Back from "../../../../assets/img/BackArrow.png";
import ReusableTabs from "../../../../components/TabMenu/Tabs";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { getApplicationsData } from "./API/fetchApplications";
import styles from "./OdobrenjeAplikacija.module.css";
import OdobrenjeTable from "./Table/OdobrenjeTable";
import {
  columnNames,
  countOccurrences,
  odobrenjeAplikacijeTabs,
} from "./utils";

function OdobrenjeAplikacija() {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const { selectedTab } = useParams();
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedTab || "1");
  const [applicationsData, setApplicationsData] = useState([]);
  const [programIds, setProgramIds] = useState([]);
  const [tabOccurrences, setTabOccurrences] = useState({});
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (selectedTab === undefined) {
      setCurrentTabIndex("1");
      navigate(`/programi/${currentTabIndex}`);
    }
  }, [selectedTab, navigate, currentTabIndex]);
  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  useEffect(() => {
    if (authToken) getApplicationsData(authToken, setApplicationsData);
  }, [authToken]);

  useEffect(() => {
    const extractedProgramIds = applicationsData.map((item) =>
      String(item.programId)
    );
    setProgramIds(extractedProgramIds);
  }, [applicationsData]);

  useEffect(() => {
    const newMatchingTableData = applicationsData.filter(
      (item) => String(item.programId) === currentTabIndex
    );
    const newUserArray = newMatchingTableData.map((item) => ({
      applicationId: item.id,
      userId: item.userId,
      firstName: item.User.firstName,
      lastName: item.User.lastName,
      imageUrl: item.User.imageUrl,
    }));
    setUserData(newUserArray);
  }, [applicationsData, currentTabIndex]);

  const idOccurrences = countOccurrences(programIds);
  const matchingTabs = odobrenjeAplikacijeTabs.filter((tab) =>
    programIds.includes(tab.value)
  );
  matchingTabs.forEach((tab) => {
    tabOccurrences[tab.value] = idOccurrences[tab.value] || 0;
  });
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.navigationRow}
        onClick={() => navigate("/dokumenti")}
      >
        <img alt="" src={Back} className={styles.backArrow} />
        <span style={{ fontSize: 20 }}>Natrag</span>
      </div>
      {matchingTabs.length > 0 && (
        <ReusableTabs
          currentTabIndex={currentTabIndex}
          handleTabChange={handleTabChange}
          tabsData={matchingTabs}
          tabOccurrences={tabOccurrences}
          isTabOccurences
        />
      )}
      <div className={styles.tableWrapper}>
        {matchingTabs.map((tab) => (
          <React.Fragment key={tab.value}>
            {currentTabIndex === tab.value && (
              <OdobrenjeTable
                data={userData}
                columnNames={columnNames(tab.label)}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default OdobrenjeAplikacija;
