import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Exit from "../../../../../assets/img/ExitIcon.png";
import { useUserState } from "../../../../../context/UserContext";
import { editNastupnoPredavanje } from "../../../api/editNastupnoPredavanje";
import { postNastupnoPredavanje } from "../../../api/postNastupnoPredavanje";

import { useAuthentication } from "../../../../hooks/useAuthentication";
import styles from "./DodajNastupnoPredavanjeModal.module.css";
import { formatDate, handleInputChange, options } from "./utils";

function DodajNastupnoPredavanjeModal({
  onClose,
  nazivPredavanja,
  datumPredavanja,
  theoreticalId,
  gostTopic,
  gostAttendance,
  setPresentations,
  setNastupnaPredavanja,
  setShowModal,
}) {
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const props = {
    nazivPredavanja: nazivPredavanja,
    datumPredavanja: formatDate(datumPredavanja),
  };
  const [formData, setFormData] = useState({
    nazivPredavanja: "",
    datumPredavanja: "",
    tema: "",
    tipPredavanja: "",
    ...props,
  });
  useEffect(() => {
    if (gostTopic !== "" || gostAttendance !== "") {
      setFormData({
        ...formData,
        tema: gostTopic,
        tipPredavanja: gostAttendance,
      });
      setIsEdit(true);
    }
  }, [gostTopic, gostAttendance]);

  return (
    <div className={styles.modalOverlay}>
      <img
        alt=""
        src={Exit}
        className={styles.exitBtn}
        onClick={() => onClose()}
      />
      <div className={styles.modalWrapper}>
        <div className={styles.infoModal}>
          <p className={styles.title}>Zapiši se na predavanje</p>
          <div className={styles.inputWrapper}>
            <div className={styles.inputColumn}>
              <span className={styles.label}>Naziv predavanja</span>
              <input
                name="nazivPredavanja"
                type="text"
                value={formData.nazivPredavanja}
                className={styles.modalInput}
                readOnly
              />
            </div>
            <div className={styles.inputColumn}>
              <span className={styles.label}>Datum održavanja</span>
              <input
                name="datumPredavanja"
                value={formData.datumPredavanja}
                type="date"
                className={styles.modalInput}
                readOnly
              />
            </div>
            <div className={styles.divider} />
            <div className={styles.inputColumn}>
              <span className={styles.label} style={{ color: "#828D9A" }}>
                Tvoja tema
              </span>
              <input
                name="tema"
                value={formData.tema}
                placeholder="Upiši"
                className={styles.modalInput}
                onChange={(e) =>
                  handleInputChange(
                    "tema",
                    e.target.value,
                    setFormData,
                    formData
                  )
                }
              />
              {errors.tema && <div className={styles.error}>{errors.tema}</div>}
            </div>
            <div className={styles.inputColumn}>
              <span className={styles.label} style={{ color: "#828D9A" }}>
                Prisustvovat cu predavanju:
              </span>
              <select
                className={styles.modalSelect}
                type="text"
                value={formData.tipPredavanja}
                name="tipPredavanja"
                onChange={(e) =>
                  handleInputChange(
                    "tipPredavanja",
                    e.target.value,
                    setFormData,
                    formData
                  )
                }
              >
                <option value="" disabled>
                  Odaberi
                </option>
                {options.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {errors.tipPredavanja && (
                <div className={styles.error}>{errors.tipPredavanja}</div>
              )}
            </div>
          </div>
          <div className={styles.btnRow}>
            <button onClick={() => onClose()} className={styles.cancelBtn}>
              Odustani
            </button>
            <button
              className={styles.successBtn}
              onClick={() => {
                isEdit
                  ? editNastupnoPredavanje(
                      setErrors,
                      formData,
                      userData.id,
                      theoreticalId,
                      formData.tema,
                      formData.tipPredavanja,
                      authToken,
                      setPresentations,
                      setNastupnaPredavanja,
                      setShowModal
                    )
                  : postNastupnoPredavanje(
                      setErrors,
                      formData,
                      userData.id,
                      theoreticalId,
                      formData.tema,
                      datumPredavanja,
                      formData.tipPredavanja,
                      authToken,
                      setPresentations,
                      setNastupnaPredavanja,
                      setShowModal
                    );
              }}
            >
              Zapiši se
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DodajNastupnoPredavanjeModal;
