import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
export const getAllIntensives = async (accessToken) => {
  try {
    const responseSummer = await axios.get(`${apiUrl}/api/intensive`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const responseWeekend = await axios.get(
      `${apiUrl}/api/intensive?isWeekend=true`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const combinedData = [
      ...responseSummer.data.active,
      ...responseWeekend.data.active,
    ];

    return combinedData;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
