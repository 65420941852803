import { useEffect, useRef, useState } from "react";
import { BeatLoader } from "react-spinners";
import left from "../../../assets/img/ArrowLeft.png";
import right from "../../../assets/img/ArrowRight.png";
import EditArrowDown from "../../../assets/png/EditArrowDown.png";
import { useAuthentication } from "../../hooks/useAuthentication";
import { addEvidentionEntry } from "../api/addEvidentionEntry";
import { deleteEvidentionEntry } from "../api/deleteEvidentionEntry";
import styles from "./EvidencijaDolazakaCard.module.css";
import { handleChangeCheckbox } from "./utils";

function EvidencijaDolazakaCard({ data, group, refreshData }) {
  const [dropdownStates, setDropdownStates] = useState({});
  const { authToken } = useAuthentication();
  const [loadingInteractiveRows, setLoadingInteractiveRows] = useState({});
  const [loadingModulRows, setLoadingModulRows] = useState({});

  const handleDropdown = (index) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index] || false,
    }));
  };

  const rowsCount = data[0].evidention.modules.map((module) => module.length);
  const rowsCountInteractive = data[0].evidention?.interactiveLessons?.length;
  const renderedRowsInteractive = Array.from(
    { length: rowsCountInteractive },
    (_, index) => `P${index + 1}`
  );

  const dropdownRefs = useRef([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      for (const ref of dropdownRefs.current) {
        if (
          ref &&
          ref.current &&
          event.target &&
          !ref.current.contains(event.target)
        ) {
          setDropdownStates((prevState) => ({
            ...prevState,
            [ref.current.getAttribute("data-index")]: false,
          }));
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className={styles.container}>
      <h3 className={styles.h3}>{group}</h3>
      <div className={styles.dropdownsContainer}>
        {data[0].evidention.interactiveLessons && (
          <div key={0}>
            <button
              className={styles.dropdown}
              onClick={() => handleDropdown(0)}
              name={"Predavanja"}
            >
              <span className={styles.btnText}>{"Predavanja"}</span>
              <img src={EditArrowDown} alt="" />
            </button>
            {dropdownStates[0] && (
              <div
                className={styles.rucniunosdropdown}
                ref={(ref) => (dropdownRefs.current[0] = ref)}
                data-0={0}
              >
                <div className={styles.polazniciContainer}>
                  <div className={styles.firstRow}>
                    <h4 className={styles.h4}>Polaznici</h4>
                    {renderedRowsInteractive.map((item, index) => (
                      <p key={index} className={styles.p}>
                        {item}
                      </p>
                    ))}
                  </div>

                  {data.map((korisnik, index) => (
                    <div className={styles.dropedDiv} key={index}>
                      <div className={styles.polaznik}>
                        <img
                          src={korisnik.user.imageUrl}
                          alt=""
                          className={styles.profile}
                        />
                        <p className={styles.names}>
                          {korisnik.user.firstName} {korisnik.user.lastName}
                        </p>
                      </div>
                      {korisnik?.evidention.interactiveLessons.map(
                        (lesson, index) => (
                          <div className={styles.input} key={index}>
                            <input
                              type="checkbox"
                              checked={lesson?.attendance}
                              onChange={(e) =>
                                handleChangeCheckbox(
                                  setLoadingInteractiveRows,
                                  setLoadingModulRows,
                                  addEvidentionEntry,
                                  deleteEvidentionEntry,
                                  authToken,
                                  refreshData,
                                  e,
                                  korisnik.user.id,
                                  lesson.id,
                                  "interactive_lesson"
                                )
                              }
                              className={styles.checkbox}
                            />
                          </div>
                        )
                      )}
                      {loadingInteractiveRows[korisnik.user.id] && (
                        <div className={styles.loadingContainer}>
                          <BeatLoader color="#cb9c2d" />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className={styles.lastRow}>
                  <img src={left} alt="" className={styles.img} />
                  <img src={right} alt="" className={styles.img} />
                </div>
              </div>
            )}
          </div>
        )}
        {data[0].evidention.modules.map((item, index) => (
          <div key={index + 1}>
            <button
              className={styles.dropdown}
              onClick={() => handleDropdown(index + 1)}
              name={`Modul ${index + 1}`}
            >
              <span className={styles.btnText}>{`Modul ${index + 1}`}</span>
              <img src={EditArrowDown} alt="" />
            </button>
            {dropdownStates[index + 1] && (
              <div
                className={styles.rucniunosdropdown}
                ref={(ref) => (dropdownRefs.current[index + 1] = ref)}
                data-index={index + 1}
              >
                <div className={styles.polazniciContainer}>
                  <div className={styles.firstRow}>
                    <h4 className={styles.h4}>Polaznici</h4>
                    {Array.from({ length: rowsCount[index] }, (_, i) => (
                      <p key={i} className={styles.p}>
                        R{i + 1}
                      </p>
                    ))}
                  </div>
                  {data.map((korisnik, indexK) => (
                    <div className={styles.dropedDiv} key={indexK}>
                      <div className={styles.polaznik}>
                        <img
                          src={korisnik.user.imageUrl}
                          alt=""
                          className={styles.profile}
                        />
                        <p className={styles.names}>
                          {korisnik.user.firstName} {korisnik.user.lastName}
                        </p>
                      </div>
                      {korisnik.evidention.modules[index].map(
                        (moduleItem, index2) => (
                          <div className={styles.input} key={index2}>
                            <input
                              type="checkbox"
                              checked={moduleItem.attendance}
                              onChange={(e) => {
                                handleChangeCheckbox(
                                  setLoadingInteractiveRows,
                                  setLoadingModulRows,
                                  addEvidentionEntry,
                                  deleteEvidentionEntry,
                                  authToken,
                                  refreshData,
                                  e,
                                  korisnik.user.id,
                                  moduleItem.id,
                                  "regular_workshop",
                                  index
                                );
                              }}
                              className={styles.checkbox}
                            />
                          </div>
                        )
                      )}
                      {loadingModulRows[`${korisnik.user.id}-${index}`] && (
                        <div className={styles.loadingContainer}>
                          <BeatLoader color="#cb9c2d" />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className={styles.lastRow}>
                  <img src={left} alt="" className={styles.img} />
                  <img src={right} alt="" className={styles.img} />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
export default EvidencijaDolazakaCard;
