import { getAllCities } from "../../groups/api/getAllCities";
import { getAllProgramsReferada } from "../../groups/api/getAllProgramsReferada";
import { formatDate } from "../utils/Utils";
import { getGroup } from "./getGroup";

export const fetchData = async (
  groupId,
  accessToken,
  setGroupData,
  setFormValues,
  setAttendeeData,
  setTeachersData,
  setAllCities,
  setAllPrograms,
  setIsAllowed
) => {
  try {
    const fetchedGroupData = await getGroup(groupId, accessToken, setIsAllowed);
    fetchedGroupData.createdDate = formatDate(fetchedGroupData.createdDate);
    setGroupData(fetchedGroupData);
    setFormValues({
      name: fetchedGroupData.name || "",
      programId: fetchedGroupData.programId || null,
      cityId: fetchedGroupData.cityId || null,
      calendarFieldColour: fetchedGroupData.calendarFieldColour || "",
      calendarTextColour: fetchedGroupData.calendarTextColour || "",
    });
    setAttendeeData(fetchedGroupData.polaznici);
    setTeachersData(fetchedGroupData.voditelji);
    const formattedCityOptions = await getAllCities(accessToken);
    setAllCities(formattedCityOptions);
    const formattedProgramOptions = await getAllProgramsReferada(accessToken);
    setAllPrograms(formattedProgramOptions);
  } catch (error) {
    console.error("Error in fetchData:", error);
  }
};
