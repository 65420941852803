import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReusableTabs from "../../components/TabMenu/Tabs";
import { tabsData } from "../../components/TabMenu/utils";
import IntenziviEditCreate from "./EditCreate/IntenziviEditCreate";

function IntenziviPage() {
  const navigate = useNavigate();
  const { selectedTab } = useParams();
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedTab || "six");
  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
    navigate(`/programi/${newValue}`);
  };
  return (
    <>
      <ReusableTabs
        currentTabIndex={currentTabIndex}
        handleTabChange={handleTabChange}
        tabsData={tabsData}
      />
      <IntenziviEditCreate />
    </>
  );
}

export default IntenziviPage;
