import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const typeMapping = {
  "fakultativne-radionice": "FacultativeWorkshop",
  "grupne-supervizije": "Supervision",
  "ljetni-intenzivi": "Intensive",
  "vikend-intenzivi": "Intensive",
};

const defaultPrijavnicaName = "EModuleLive";

export const addUserToPrijavnica = async (type, data, accessToken) => {
  const endpoint = typeMapping[type] || defaultPrijavnicaName;
  try {
    const response = await axios.post(
      `${apiUrl}/api/assignMyselfTo${endpoint}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error adding user: ", error);
    throw error;
  }
};
