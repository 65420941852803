import axios from "axios";
import Help from "../../../../assets/img/Help.png";
import Success from "../../../../assets/img/Success.png";

const apiUrl = process.env.REACT_APP_API_URL;

export const arhivirajDjelatnika = async (selectedIds, accessToken) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/users/archive`,
      {
        userIds: selectedIds,
        isArchived: false,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error archiving user:", error);
    throw error;
  }
};

export const urediDjelatnika = async (selectedId, data, accessToken) => {
  try {
    const response = await axios.put(`${apiUrl}/api/user/${selectedId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error archiving user:", error);
    throw error;
  }
};

export const addUser = async (groupId, userId, accessToken) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/groups/${groupId}/add-users`,
      {
        userIds: userId,
        role: "polaznik",
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error adding user to group:", error);
    throw error;
  }
};

export const handleArhivirajDjelatnika = async (
  selectedUserUuid,
  accessToken,
  navigate,
  groupId,
  setModalContent,
  setModalOpen
) => {
  try {
    const response = await arhivirajDjelatnika(selectedUserUuid, accessToken);
    const addingResponse = await addUser(
      groupId,
      selectedUserUuid,
      accessToken
    );
    if (response.status === 200 && addingResponse.status === 200) {
      setModalOpen(true);
      setModalContent({
        image: Success,
        title: "Polaznik uspješno dodan u grupu.",
        description: "",
        buttons: [
          {
            label: "Ok",
            action: () => {
              navigate("/polaznici");
              setModalOpen(false);
            },
            className: "modal-btn-success",
          },
        ],
      });
    }
  } catch (error) {
    console.error("Error in action:", error);
    setModalOpen(true);
    setModalContent({
      image: Help,
      title: "Greška nije moguće dodati korisnika.",
      description: "Pokušajte ponovno kasnije",
      buttons: [
        {
          label: "Ok",
          action: () => {
            setModalOpen(false);
          },
          className: "modal-btn-success",
        },
      ],
    });
  }
};
export const handleEditDjelatnika = async (
  selectedUserId,
  accessToken,
  navigate,
  formData,
  setModalContent,
  setModalOpen
) => {
  try {
    const response = await urediDjelatnika(
      selectedUserId,
      formData,
      accessToken
    );
    if (response.status === 200) {
      setModalOpen(true);
      setModalContent({
        image: Success,
        title: "Polaznik uspješno uređen.",
        description: "",
        buttons: [
          {
            label: "Ok",
            action: () => {
              navigate("/polaznici");
              setModalOpen(false);
            },
            className: "modal-btn-success",
          },
        ],
      });
    }
  } catch (error) {
    console.error("Error in action:", error);
    setModalOpen(true);
    setModalContent({
      image: Help,
      title: "Greška, nije moguće urediti korisnika.",
      description: "Pokušajte ponovno kasnije",
      buttons: [
        {
          label: "Ok",
          action: () => {
            setModalOpen(false);
          },
          className: "modal-btn-success",
        },
      ],
    });
  }
};
