import axios from "axios";
import { putData } from "../utils";
import { getEvents } from "./getEvents";
const apiUrl = process.env.REACT_APP_API_URL;

export const updateEvent = async (
  eventType,
  formValues,
  accessToken,
  startDate,
  endDate,
  selectedRooms,
  setEvents,
  setIsCreateOpen,
  reRender,
  setReRender
) => {
  try {
    const newData = putData(eventType, formValues);
    console.log(newData);
    const response = await axios.put(`${apiUrl}/api/updateEventIPD`, newData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      setIsCreateOpen(false);
      getEvents(
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0],
        selectedRooms,
        accessToken,
        setEvents
      );
      setReRender(!reRender);
    }
  } catch (error) {
    console.error("Error updating event", error);
  }
};
