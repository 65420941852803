import { Box } from "@mui/material";
import {
  addDays,
  addWeeks,
  differenceInCalendarWeeks,
  format,
  getMonth,
  setHours,
  setMinutes,
  startOfWeek,
} from "date-fns";
import hrLocale from "date-fns/locale/hr";
import React, { useEffect, useState } from "react";
import EmptyCell from "./EmptyCell";
import EventCell from "./EventCell";
import TimeSlot from "./TimeSlot";
import "./WeekTable.css";

const WeekTable = ({
  selectedAcademicYear,
  weekIndex,
  events,
  eventsCell,
  groupList,
  onEventClick,
  headerColor,
  setOpenModal,
  setActionType,
  tableHeadColor,
  setSelectedEmptyTimeSlot,
  setEventData,
  room,
}) => {
  const [eventovi, setEventovi] = useState([]);
  // Define the new time intervals
  const timeIntervals = [
    "09:00 - 12:15",
    "12:15 - 13:30",
    "13:30 - 16:45",
    "18:00 - 21:15",
  ];
  const weekDays = [];
  const startOfYearDate = startOfWeek(selectedAcademicYear.start, {
    weekStartsOn: 1,
  });
  for (let i = 0; i < 7; i++) {
    const day = addDays(addWeeks(startOfYearDate, weekIndex), i);
    weekDays.push(day);
  }
  useEffect(() => {
    // Only update the state if the events prop is not an empty array
    if (Array.isArray(events) && events.length > 0) {
      setEventovi(events);
    }
  }, [events]);
  const times = [];
  for (let i = 9; i <= 17; i += 2) {
    const time = setMinutes(setHours(weekDays[0], i), 0);
    times.push(<TimeSlot key={time} startTime={time} />);
  }

  // Calculate the week number based on the difference in days, considering the academic year's start
  const weekNumber =
    differenceInCalendarWeeks(weekDays[0], selectedAcademicYear.start) + 1;

  if (weekNumber < 1 || weekNumber > 52) {
    return null;
  }

  return (
    <Box display="flex">
      <div className="sticky-container">
        <div className="custom-box" style={{ backgroundColor: tableHeadColor }}>
          <div className="month-number">
            {/* Display the month number here */}
            {weekDays.length > 0 && <span>{getMonth(weekDays[0]) + 1}.</span>}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <table className="week-table">
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: "#ffffff",
                  }}
                >
                  TERMINI/TJEDAN
                </th>
                {weekDays?.map((day) => (
                  <th
                    key={day}
                    className="day-header"
                    style={{ backgroundColor: headerColor }}
                  >
                    {format(day, "EEEE", { locale: hrLocale })
                      .charAt(0)
                      .toUpperCase() +
                      format(day, "EEEE", { locale: hrLocale }).slice(1)}
                  </th>
                ))}
              </tr>

              <tr>
                <th
                  className="empty-cell"
                  style={{
                    backgroundColor: headerColor,
                  }}
                >
                  Tjedan {weekNumber}
                </th>
                {weekDays?.map((day, index) => (
                  <th
                    key={`${day}-${index}`}
                    className="day-header"
                    style={{
                      backgroundColor: headerColor,
                      position: "sticky",
                      top: 42,
                      zIndex: 800,
                    }}
                  >
                    {format(day, "dd.MM.yyyy")}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {timeIntervals.map((interval, timeIndex) => {
                const [intervalStart, intervalEnd] = interval.split(" - ");
                const intervalEvents = {};

                weekDays?.forEach((day, index) => {
                  const existingEvents = eventovi
                    .concat(eventsCell)
                    .filter((event) => {
                      const eventDate = new Date(event.date);
                      const eventStartTime = new Date(
                        `1970-01-01T${event.timeFrom}`
                      );
                      const eventEndTime = new Date(
                        `1970-01-01T${event.timeTo}`
                      );
                      const isSameDay =
                        (eventDate instanceof Date &&
                          eventDate.toDateString() === day.toDateString()) ||
                        new Date(event.date).toDateString() ===
                          day.toDateString();

                      const adjustedStartTime =
                        eventStartTime > new Date(`1970-01-01T${intervalStart}`)
                          ? eventStartTime
                          : new Date(`1970-01-01T${intervalStart}`);
                      const adjustedEndTime =
                        eventEndTime < new Date(`1970-01-01T${intervalEnd}`)
                          ? eventEndTime
                          : new Date(`1970-01-01T${intervalEnd}`);

                      const isEdgeCase =
                        eventStartTime.getHours() === 8 &&
                        eventEndTime.getHours() === 9;
                      const isWithinInterval =
                        (eventStartTime < adjustedEndTime &&
                          eventEndTime > adjustedStartTime) ||
                        (isEdgeCase && interval === "09:00 - 12:15");

                      return isSameDay && isWithinInterval;
                    });

                  if (existingEvents.length > 0) {
                    intervalEvents[day] = existingEvents;
                  }
                });
                return (
                  <tr key={timeIndex}>
                    <td className="time-column">{interval}</td>
                    {weekDays?.map((day, index) => (
                      <td
                        key={`${day}-${index + 1000}`}
                        style={{
                          boxSizing: "border-box",
                          border: intervalEvents[day]
                            ? intervalEvents.isPublished
                              ? "none"
                              : "2px solid yellow"
                            : " 1px solid #ccc",
                          backgroundColor: intervalEvents[day]
                            ? groupList?.find(
                                (item) =>
                                  item.class_name === intervalEvents.class_name
                              )?.color_box ?? "transparent"
                            : "transparent",
                          color: intervalEvents[day]
                            ? groupList?.find(
                                (item) =>
                                  item.class_name === intervalEvents.class_name
                              )?.color_text ?? "black"
                            : "black",
                        }}
                        onClick={() => {
                          const date = `${day.getFullYear()}-${
                            day.getMonth() + 1 < 10 ? "0" : ""
                          }${day.getMonth() + 1}-${
                            day.getDate() < 10 ? "0" : ""
                          }${day.getDate()}`;
                          setSelectedEmptyTimeSlot({
                            start: `${date} ${intervalStart}:00`,
                            end: `${date} ${intervalEnd}:00`,
                            room: room,
                          });
                        }}
                      >
                        {intervalEvents[day] ? (
                          <EventCell
                            events={intervalEvents[day]}
                            setOpenModal={setOpenModal}
                            setEventData={setEventData}
                            setActionType={setActionType}
                            onEventClick={onEventClick}
                            radionica={intervalEvents[day][0].name}
                            grupa={intervalEvents[day][0].groupId}
                          />
                        ) : (
                          <EmptyCell
                            intervalStart={intervalStart}
                            intervalEnd={intervalEnd}
                            setActionType={setActionType}
                            setOpenModal={setOpenModal}
                            onClick={() => {
                              setActionType("add-local");
                              setOpenModal();
                              setSelectedEmptyTimeSlot({
                                start: `${day.getFullYear()}-${
                                  day.getMonth() + 1 < 10 ? "0" : ""
                                }${day.getMonth() + 1}-${
                                  day.getDate() < 10 ? "0" : ""
                                }${day.getDate()} ${intervalStart}:00`,
                                end: `${day.getFullYear()}-${
                                  day.getMonth() + 1 < 10 ? "0" : ""
                                }${day.getMonth() + 1}-${
                                  day.getDate() < 10 ? "0" : ""
                                }${day.getDate()} ${intervalEnd}:00`,
                                room: room,
                              });
                            }}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};

export default WeekTable;
