import React from "react";
import { useNavigate } from "react-router-dom";
import "./OdobrenjeTable.css";

function RenderTableData({ data = [] }) {
  const navigate = useNavigate();
  return (
    <>
      {data.map((item, index) => (
        <tr key={index}>
          <td>
            <div className="table-odobrenje-row" style={{ color: "#2E3646" }}>
              <img
                alt=""
                src={item.imageUrl}
                className="table-odobrenje-aplikacija-avatar"
              />
              {item.firstName} {item.lastName}
            </div>
          </td>
          <td>
            <span
              onClick={() =>
                navigate(
                  `/dokumenti/aplikacije-za-upis/anketa/${item.applicationId}`
                )
              }
              className="table-odobrenje-aplikacija-link"
            >
              Link na anketu
            </span>
          </td>
          <td style={{ maxWidth: 400 }}>
            {item?.mentorReview?.length > 0 && <div>{item?.mentorReview}</div>}
          </td>
        </tr>
      ))}
    </>
  );
}

export default RenderTableData;
