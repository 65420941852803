import moment from "moment";

export const Sobe = [
  { value: 1, label: "ZG-1" },
  { value: 2, label: "ZG-2" },
  { value: 3, label: "ZG-3" },
  { value: 4, label: "ZG-4" },
  { value: 5, label: "ZG-5" },
  { value: 6, label: "ZG-6" },
  { value: 7, label: "ZG-7" },
  { value: 8, label: "ZG-8" },
  { value: 9, label: "ZG-9" },
  { value: 10, label: "ZG-10" },
  { value: 11, label: "ST-1" },
  { value: 12, label: "BG-1" },
  { value: 13, label: "BG-2" },
  { value: 14, label: "LJ-1" },
  { value: 15, label: "LJ-2" },
  { value: 16, label: "LJ-3" },
  { value: 17, label: "LJ-4" },
  { value: 18, label: "PG-1" },
];

export const Gradovi = [
  { value: 1, label: "Split" },
  { value: 2, label: "Zagreb" },
  { value: 3, label: "Ljubljana" },
  { value: 4, label: "Beograd" },
  { value: 5, label: "Podgorica" },
  { value: 6, label: "Rijeka" },
];

export const formatTime = (time) => {
  const formattedTime = moment(time).format("HH:mm");
  return formattedTime;
};

export const handleSelectRoomChange = (e, setRoomId, setRoomLabel) => {
  const selectedOption = Sobe.find(
    (option) => option.value === parseInt(e.target.value, 10)
  );
  if (selectedOption) {
    setRoomId(selectedOption.value);
    setRoomLabel(selectedOption.label);
  } else {
    console.error(
      `Option with value ${e.target.value} not found in Sobe array`
    );
  }
};

export const handleSelectCityChange = (e, setCityId, setCityLabel) => {
  const selectedOption = Gradovi.find(
    (option) => option.value === parseInt(e.target.value, 10)
  );
  if (selectedOption) {
    setCityId(selectedOption.value);
    setCityLabel(selectedOption.label);
  } else {
    console.error(
      `Option with value ${e.target.value} not found in Gradovi array`
    );
  }
};
export const handleSelectZakupiteljChange = (
  e,
  setZakupiteljId,
  setZakupiteljLabel,
  data
) => {
  const selectedOption = data.find((option) => option.value === e.target.value);
  if (selectedOption) {
    setZakupiteljId(selectedOption.value);
    setZakupiteljLabel(selectedOption.label);
  } else {
    console.error(
      `Option with value ${e.target.value} not found in zakupitelji array`
    );
  }
};
