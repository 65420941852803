export const PrimateljData = [
  "Polaznici",
  "Grupa",
  "Program",
  "Djelatnici",
  "IPD Centar",
  "Zakupitelj soba",
  "GPR",
];

export const MjestaData = [
  "Svi gradovi",
  "Zagreb",
  "Split",
  "Rijeka",
  "Ljubljana",
  "Beograd",
  "Podgorica",
  "Online upis",
];

export const ProgramData = [
  "RAR",
  "QR1",
  "QR2",
  "QR3",
  "QR4",
  "TP1",
  "TP2",
  "TT1",
  "TT2",
  "VT",
  "POR",
  "PP",
  "Roditeljstvo 0-6",
  "RPO",
  "Pauzirani program",
];

export const CentriData = [
  "Svi IPD Centri",
  "IPD Hrvatska",
  "IPD Online",
  "IPD Beograd",
  "IPD Slovenija",
  "IPD Crna Gora",
];

export const GrupaData = ["23-OR1-ZG-H", " 23-OR2-BG"];

export const GprData = ["Gpr data 1", "Gpr data 2"];
export const GprNazivData = ["Naziv Gpr 1"];

export const SlanjeObavijestiData = {
  PrimateljData,
  MjestaData,
  ProgramData,
  CentriData,
  GrupaData,
  GprData,
  GprNazivData,
};
