import axios from "axios";
import Success from "../../assets/img/Success.png";

const apiUrl = process.env.REACT_APP_API_URL;

export const handleDodajVoditelje = async (
  groupId,
  selectedVoditeljiIDs,
  setShowModal,
  setModalContent,
  accessToken,
  navigate
) => {
  try {
    const uniqueSelectedVoditeljiIDs = Array.from(
      new Set(selectedVoditeljiIDs)
    );
    const response = await axios.post(
      `${apiUrl}/api/groups/${groupId}/add-users`,
      {
        userIds: uniqueSelectedVoditeljiIDs,
        role: "voditelj",
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      setModalContent({
        image: Success,
        description: "Uspješno ste dodali voditelje",
        buttons: [
          {
            label: "U redu",
            action: () => navigate(`/grupe/${groupId}`),
            className: "modal-btn-success",
          },
        ],
      });
      setShowModal(true);
    } else {
      console.error("Request failed:", response.data);
    }
  } catch (error) {
    console.error("Error making API request:", error);
  }
};

export const fetchCities = async (accessToken, setMjestoUpisa) => {
  try {
    const response = await fetch(`${apiUrl}/api/cities`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      throw new Error("Invalid content type. Expected JSON.");
    }

    const data = await response.json();
    const updatedMjestoUpisa = data.map((city) => ({
      label: city.cityName,
      value: city.id,
    }));
    setMjestoUpisa(updatedMjestoUpisa);
  } catch (error) {
    console.error("Error fetching cities:", error);
  }
};
export const fetchData = async (
  accessToken,
  setVoditeljiData,
  setTeacherData,
  setUserData,
  fetchedGroupTeachers,
  setSelectedVoditelji,
  setEmployeeData
) => {
  try {
    const response = await axios.get(`${apiUrl}/api/users/employees`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const resData = response.data;
    const combinedArray = resData.UciteljiArray.concat(
      resData.VoditeljiArray
    ).concat(resData.PolazniciVTArray);
    setEmployeeData(combinedArray);
    setVoditeljiData(
      resData.VoditeljiArray.sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      )
    );
    setTeacherData(
      resData.UciteljiArray.sort((a, b) => a.lastName.localeCompare(b.lastName))
    );
    setUserData(
      resData.PolazniciVTArray.sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      )
    );
    const existingVoditeljiIds = fetchedGroupTeachers.map((v) => v.element.id);
    setSelectedVoditelji(existingVoditeljiIds);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
