import React, { useState } from "react";
import SearchIcon from "../../../assets/svg/SearchIcon.svg";
import styles from "./SearchStudents.module.css";

const SearchStudents = ({ students, setSearchResults }) => {
  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);

    const searchName = input.trim().toLowerCase();
    if (searchName === "") {
      setSearchResults([]);
    } else {
      // const filteredStudents = students.filter((student) => {
      //   const fullName = (
      //     student.dataValues.firstName +
      //     " " +
      //     student.dataValues.lastName
      //   ).toLowerCase();
      //   const firstName = student.dataValues.firstName.toLowerCase();
      //   const lastName = student.dataValues.lastName.toLowerCase();
      //   return (
      //     fullName.includes(searchName) ||
      //     firstName.includes(searchName) ||
      //     lastName.includes(searchName)
      //   );
      // });
      setSearchResults(searchName);
    }
  };

  return (
    <label className={styles.searchStudentsInputContainer}>
      <img
        alt="search"
        src={SearchIcon}
        className={styles.searchStudentsIcon}
      />
      <input
        type="search"
        placeholder="Pretraži"
        className={styles.searchStudentsInput}
        value={searchInput}
        onChange={handleSearchInputChange}
      />
    </label>
  );
};

export default SearchStudents;
