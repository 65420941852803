export const areAllFieldsFilled = (formData) => {
  for (const key in formData) {
    if (!formData[key]) {
      return false;
    }
  }
  return true;
};
export const handleInputChangeArhiviraj = (
  name,
  value,
  type,
  checked,
  setFormData,
  formData
) => {
  const inputValue = type === "checkbox" ? checked : value;
  setFormData({
    ...formData,
    [name]: inputValue,
  });
};
