import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const deleteEvidentionEntry = async (eventId, userId, accessToken) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/api/deleteEvidentionEntry?eventId=${eventId}&userId=${userId}`,

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error deleting evidention entry data", error);
    throw error;
  }
};
