import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import ReusableTabs from "../../components/TabMenu/Tabs";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import { getGroupsILead } from "./api/getGroupsILead";
import { getMentorshipsCount } from "./api/getMentorshipsCount";
import GroupsTable from "./components/groupsTable/GroupsTable";
import GroupCard from "./components/PolaznikGrupe/GroupCard";
import styles from "./Groups.module.css";
import { fetchGroupsForPolaznik, tabsData } from "./utils/utils";
const Groups = () => {
  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const userId = role;
  const polaznikID = userData.id;
  const [allGroupsPolaznik, setAllGroupsPolaznik] = useState();
  const [showErr, setShowErr] = useState(true);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [mentorships, setMentorships] = useState(0);
  const [groupsILead, setGroupsILead] = useState([]);
  const [regularGroups, setRegularGroups] = useState([]);
  const [archivedGroups, setArchivedGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (userId !== "Referada") {
      if (authToken)
        fetchGroupsForPolaznik(authToken, polaznikID, setAllGroupsPolaznik);
    }
  }, [authToken, polaznikID, userId]);

  useEffect(() => {
    const fetchMentorships = async () => {
      const response = await getMentorshipsCount(authToken);
      const groupsResponse = await getGroupsILead(authToken);
      setIsLoading(false);
      setMentorships(response);
      setGroupsILead(groupsResponse);
      setRegularGroups(groupsResponse.regularGroups);
      setArchivedGroups(groupsResponse.archivedGroups);
    };

    if (authToken) fetchMentorships();
  }, [authToken, userId]);

  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };
  return (
    <div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <BeatLoader color="#cb9c2d" />
        </div>
      ) : (
        <>
          {userId === "Polaznik" && (
            <div className={styles.cardWrapper}>
              {allGroupsPolaznik && allGroupsPolaznik.groups.length > 0 ? (
                allGroupsPolaznik.groups
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((item, index) => (
                    <GroupCard
                      title={item.name}
                      key={index}
                      route={`/grupe/${item.id}`}
                    />
                  ))
              ) : showErr ? (
                <div className={styles.noGroupsWrapper}>
                  <img alt="" src={InfoIcon} className={styles.noGroupsIcon} />
                  Polaznik trenutno ne pripada nijednoj grupi.
                </div>
              ) : null}
            </div>
          )}
          {userId === "Referada" && <GroupsTable />}
          {userId !== "Referada" && userId !== "Polaznik" && (
            <div>
              <ReusableTabs
                currentTabIndex={currentTabIndex}
                handleTabChange={handleTabChange}
                tabsData={tabsData}
              />
              <div className={styles.cardWrapper}>
                {currentTabIndex === 0 && (
                  <>
                    {regularGroups && regularGroups.length > 0 ? (
                      regularGroups.map((item, index) => (
                        <GroupCard
                          title={item.name}
                          key={index}
                          route={`/grupe/${item.id}`}
                        />
                      ))
                    ) : showErr ? (
                      <div
                        className={styles.noGroupsWrapper}
                        style={{ marginTop: 10, marginBottom: 30 }}
                      >
                        <img
                          alt=""
                          src={InfoIcon}
                          className={styles.noGroupsIcon}
                        />
                        Trenutno ne vodite nijednu grupu.
                      </div>
                    ) : null}
                  </>
                )}
                {currentTabIndex === 1 && (
                  <>
                    {(allGroupsPolaznik &&
                      allGroupsPolaznik.groups.length > 0 &&
                      allGroupsPolaznik.groups.map((item, index) => (
                        <GroupCard
                          title={item.name}
                          key={index}
                          route={`/grupe/${item.id}`}
                        />
                      ))) || (
                      <div className={styles.noGroupsWrapper}>
                        <img
                          alt=""
                          src={InfoIcon}
                          className={styles.noGroupsIcon}
                        />
                        Trenutno ne pripadate nijednoj grupi.
                      </div>
                    )}
                  </>
                )}
                {currentTabIndex === 2 && (
                  <>
                    {archivedGroups && archivedGroups.length > 0 ? (
                      archivedGroups.map((item, index) => (
                        <GroupCard
                          title={item.name}
                          key={index}
                          route={`/grupe/${item.id}`}
                        />
                      ))
                    ) : showErr ? (
                      <div
                        className={styles.noGroupsWrapper}
                        style={{ marginTop: 10, marginBottom: 30 }}
                      >
                        <img
                          alt=""
                          src={InfoIcon}
                          className={styles.noGroupsIcon}
                        />
                        Trenutno nemate ni jednu grupu koju ste vodili.
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              {currentTabIndex === 0 && showErr && (
                <p className={styles.numberOfMentorships}>
                  Ukupan broj mentorstava: {mentorships}
                </p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Groups;
