import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAllCities } from "../groups/api/getAllCities";
import { useAuthentication } from "../hooks/useAuthentication";
import {
  getFacultativeById,
  getLjetniById,
  getModuliById,
  getSupervisionById,
  getWeekendById,
} from "./api/getPrijavnicaById";
import PrijavnicaContainer from "./PrijavnicaContainer/PrijavnicaContainer";

function PojedinaPrijavnicaPage() {
  const { someParam, anotherParam, ...restParams } = useParams();
  const [prijavnicaData, setPrijavnicaData] = useState([]);
  const [cities, setCities] = useState([]);
  const { authToken } = useAuthentication();

  const paramKeys = Object.keys(restParams);

  const lastTwoKeys = paramKeys.slice(-2);

  const prijavnicaType = restParams[lastTwoKeys[0]];
  const prijavnicaId = restParams[lastTwoKeys[1]];

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (prijavnicaType === "fakultativne-radionice") {
          response = await getFacultativeById(prijavnicaId, authToken);
        } else if (prijavnicaType === "grupne-supervizije") {
          response = await getSupervisionById(prijavnicaId, authToken);
        } else if (prijavnicaType === "ljetni-intenzivi") {
          response = await getLjetniById(prijavnicaId, authToken);
        } else if (prijavnicaType === "vikend-intenzivi") {
          response = await getWeekendById(prijavnicaId, authToken);
        } else if (prijavnicaType === "moduli-uživo") {
          response = await getModuliById(prijavnicaId, authToken);
        } else {
          console.log("Invalid prijavnicaType:", prijavnicaType);
          return;
        }
        setPrijavnicaData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchCities = async () => {
      try {
        const response = await getAllCities(authToken);
        setCities(response);
      } catch (err) {
        console.log("Error fetching cities : ", err);
      }
    };

    if (authToken) {
      fetchData();
      fetchCities();
    }
  }, [authToken]);

  const userId = "Polaznik";
  const voditeljiData = prijavnicaData.usersResponse;
  const voditelji = voditeljiData ? voditeljiData.voditeljResponse || [] : [];
  const polaznici = voditeljiData
    ? voditeljiData.newPolazniciResponse || []
    : [];

  const cityId = prijavnicaData.roomId;
  const city = cityId ? cities[cityId - 1]?.label || "" : "";

  return (
    <div>
      {prijavnicaData && (
        <PrijavnicaContainer
          userId={userId}
          prijavnicaData={prijavnicaData}
          location={city}
          voditelji={voditelji}
          polaznici={polaznici}
          prijavnicaType={prijavnicaType}
        />
      )}
    </div>
  );
}

export default PojedinaPrijavnicaPage;
