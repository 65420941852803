import React, { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import style from "../MyProfile.module.css";

export const Crop = ({
  src,
  setSelected,
  setOpen,
  setUploadedFile,
  setMyProfileData,
  myProfileData,
}) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const imgRef = useRef(null);

  const img = new Image();
  img.src = src;

  img.onload = () => {
    setWidth(img.width);
    setHeight(img.height);
  };

  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 180,
    height: 215,
  });
  const [croper, setCroper] = useState();

  const onCropComplete = (cropData) => {
    const imgObj = imgRef.current;

    if (cropData.width && cropData.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imgObj.naturalWidth / imgObj.width;
      const scaleY = imgObj.naturalHeight / imgObj.height;
      canvas.width = cropData.width;
      canvas.height = cropData.height;
      console.log(scaleX, scaleY, "img", imgObj);
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        imgObj,
        cropData.x * scaleX,
        cropData.y * scaleY,
        cropData.width * scaleX,
        cropData.height * scaleY,
        0,
        0,
        cropData.width,
        cropData.height
      );
      const croppedImageUrl = canvas.toDataURL("image/png");
      setSelected(croppedImageUrl); // Set the cropped image in the setSelected prop
      if (typeof src === "string") {
        const img = new Image();
        img.src = src;
        setOpen(false);
        console.log(img.height, img.width);
      }
      canvas.toBlob((blob) => {
        const file = new File([blob], "image.png");
        setUploadedFile(file);
      });
    }
  };
  console.log(width, height);
  return (
    <div
      style={{
        height: "100vh",
        background: "rgba(0,0,0,0.7)",
        width: "calc(100% - 278px)",
        zIndex: 9999,
        overflow: "scroll",
        position: "fixed",
        top: 0,
        bottom: 0,
        paddingTop: 50,
      }}
    >
      {height && width && (
        <ReactCrop
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: width,
            height: height,
          }}
          crop={crop}
          locked={false}
          aspect={0.83}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCroper(c)}
        >
          <img ref={imgRef} src={src} alt="" />
        </ReactCrop>
      )}
      <div
        style={{
          width: "85%",
          position: "fixed",
          bottom: 20,
          zIndex: 9999,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <button
          className={style.cropButton}
          onClick={() => {
            setOpen(false);
            setUploadedFile(null);
            setSelected(null);
            setMyProfileData({ ...myProfileData, imageUrl: null });
          }}
          style={{ color: "#808080", backgroundColor: "#ebebeb" }}
        >
          Odustani
        </button>
        <button
          className={style.cropButton}
          onClick={() => onCropComplete(croper)}
        >
          Izreži
        </button>
      </div>
      <div
        style={{
          width: 200,
          fontSize: 20,
          fontWeight: 500,
          position: "absolute",
          top: 30,
          right: 50,
          color: "white",
          backgroundColor: "black",
          padding: "10px 20px",
          borderRadius: 6,
          backgroundOpacity: 0.5,
        }}
      >
        NAPOMENA: Za rezanje pomaknite okvir (cropper) na poziciju u slici koju
        želite.
      </div>
    </div>
  );
};
