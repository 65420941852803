import { getGroup } from "../../singleGroup/api/getGroup";
import { getGroupUsers } from "../../singleGroup/api/getGroupUsers";
import { formatDate } from "../../singleGroup/utils/Utils";
import { GetMyMentorshipsInGroup } from "../api/getMentorstva";

export const fetchData = async (
  accessToken,
  setProgram,
  setGroupData,
  groupId,
  setAttendeeData,
  setUsersIMentor,
  setUserIdsIMentor,
  setIsAllowed
) => {
  try {
    // data za grupu
    const fetchedGroupData = await getGroup(groupId, accessToken, setIsAllowed);
    setProgram(fetchedGroupData.programName);
    fetchedGroupData.createdDate = formatDate(fetchedGroupData.createdDate);
    setGroupData(fetchedGroupData);

    // data za polaznike u grupi
    const fetchedGroupUsers = await getGroupUsers(groupId, accessToken);
    setAttendeeData(fetchedGroupUsers);
    const fetchedUsersIMentor = await GetMyMentorshipsInGroup(
      groupId,
      accessToken
    );
    setUsersIMentor(fetchedUsersIMentor.userInGroups);
    const userIds = fetchedUsersIMentor.userInGroups.map((user) => user.userId);
    setUserIdsIMentor(userIds);
  } catch (error) {
    console.error("Error in fetchData:", error);
  }
};
