import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getAllProgramsReferada = async (accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/all-programs`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = response.data;
    return data.map((program) => ({
      label: program.title,
      value: program.id,
    }));
  } catch (error) {
    console.error("Error fetching programs:", error);
    return [];
  }
};
