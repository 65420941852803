import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getEORGroups = async (accessToken) => {
  try {
    const response1 = await axios.get(
      `${apiUrl}/api/groupByProgramId?programId=7`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const response2 = await axios.get(
      `${apiUrl}/api/groupByProgramId?programId=8`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const response3 = await axios.get(
      `${apiUrl}/api/groupByProgramId?programId=9`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const response4 = await axios.get(
      `${apiUrl}/api/groupByProgramId?programId=10`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = [
      response1.data,
      response2.data,
      response3.data,
      response4.data,
    ];
    return data;
  } catch (error) {
    console.error("Error getting current user data", error);
    throw error;
  }
};
