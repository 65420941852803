import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Right from "../../../../assets/img/ArrowRight.png";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { getAllFacultativeWorkshops } from "../../../Prijavnice/PolaznikVoditelj/FakultativneRadionice/api/getAllFacultativeWorkshops";
import { fetchData } from "../../api/getAllProgramsData";
import Card from "../Card";
import CarouselCard from "../CarouselCard";
import { PolaznikCardData } from "../MockData";
import PolaznikCard from "../PolaznikCard/Card";
import styles from "./LeftContainer.module.css";

function LeftContainer({ userId, ReferadaCardData }) {
  const [isFirstItem, setIsFirstItem] = useState(true);
  const [isLastItem, setIsLastItem] = useState(false);
  const [programsData, setProgramsData] = useState([]);
  const navigate = useNavigate();
  const [facultative, setFacultative] = useState([]);
  const { authToken } = useAuthentication();

  useEffect(() => {
    if (authToken) fetchData(authToken, setProgramsData);
  }, [authToken]);

  useEffect(() => {
    const fetchFW = async () => {
      try {
        const response = await getAllFacultativeWorkshops(authToken);
        setFacultative(response.active);
      } catch (error) {
        console.error("Error fetching regulations:", error);
      }
    };

    if (authToken) fetchFW();
  }, [authToken]);

  useEffect(() => {
    const handleScroll = () => {
      const container = document.getElementById("carouselContainer");
      setIsFirstItem(container.scrollLeft === 0);
      setIsLastItem(
        container.scrollLeft + container.offsetWidth >= container.scrollWidth
      );
    };
    const container = document.getElementById("carouselContainer");
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll();
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <div className={styles.leftContainer}>
      {userId !== "Referada" ? (
        <div className={styles.polaznikCardContainer}>
          {PolaznikCardData.filter((item) => item.number !== 0).map(
            (item, index) => (
              <PolaznikCard
                key={index}
                backgroundImage={item.backgroundImage}
                icon={item.icon}
                title={item.title}
                onClick={() => {
                  navigate(`${item.route}`);
                }}
              />
            )
          )}
          {/* {userId === "Voditelj" && (
            // <PolaznikCard
            //   icon={Dolasci}
            //   title="EVIDENCIJA DOLAZAKA"
            //   onClick={() => {
            //     navigate("/evidencija-dolazaka");
            //   }}
            //   backgroundImage={Evidencija}
            //   customHeight={185}
            //   customRight={"-60px"}
            //   customTop={15}
            //   customWidth={185}
            // />
          )} */}
        </div>
      ) : (
        <div className={styles.cardsContainer}>
          {ReferadaCardData.map((item, index) => (
            <Card
              key={index}
              title={item.title}
              icon={item.icon}
              number={item.number}
              onClick={() => {
                navigate(`${item.route}`);
              }}
            />
          ))}
        </div>
      )}
      {
        //trenutno samo referada vidi ovaj dio, promjenit uvjet kad bude trebalo da i ostali vide
      }
      {userId === "Referada" && (
        <div className={styles.sliderContainer}>
          <div className={styles.sliderHeaderContainer}>
            <h3 className={styles.sliderHeading}>
              {userId === "Voditelj"
                ? "Nadolazeće fakult. aktivnosti na koje se mogu prijaviti"
                : "Programi Škole ITP-a"}
            </h3>
            <div
              className={styles.seeMoreContainer}
              onClick={() =>
                navigate(
                  userId === "Voditelj"
                    ? // ? "/prijavnice/one"
                      "#"
                    : "/programi/one"
                )
              }
            >
              <p className={styles.seeMoreText}>Prikaži više</p>
              <img alt="" src={Right} className={styles.seeMoreIcon} />
            </div>
          </div>
          <div
            id="carouselContainer"
            className={`${isFirstItem ? styles.carouselContainerRight : ""} ${
              isLastItem ? styles.carouselContainerLeft : ""
            } ${styles.carouselBlurContainer} ${styles.carouselContainer}`}
          >
            {userId === "Polaznik" ||
            userId === "Voditelj" ||
            userId === "Polaznik VT-a" ||
            userId === "Supervizor" ? (
              <>
                {facultative.map((item, index) => (
                  <CarouselCard
                    key={index}
                    title={item.name}
                    image={item.imageURL}
                    date={item.date}
                    userId={"Polaznik"}
                    onClick={() =>
                      navigate(
                        `/pojedina-prijavnica/fakultativne-radionice/${item.id}`
                      )
                    }
                  />
                ))}
              </>
            ) : (
              <>
                {programsData.map((item, index) => (
                  <CarouselCard
                    key={index}
                    title={item.title}
                    image={item.image}
                    studentsNumber={item.attendees}
                    onClick={() => navigate(`/pojediniprogram/${item.id}/one`)}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default LeftContainer;
