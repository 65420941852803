import axios from "axios";
import Help from "../../../assets/img/Help.png";
import Success from "../../../assets/img/Success.png";

const apiUrl = process.env.REACT_APP_API_URL;

export const duplicateGroup = async (groupId, name, accessToken) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/group/duplicate/${groupId}`,
      { name: name },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error in duplicate group:", error);
    throw error;
  }
};

export const handleDuplicateGroup = async (
  name,
  groupId,
  accessToken,
  resetGroupData,
  navigate,
  setModalContent,
  setModalOpen,
  setDuplicateGroupModal
) => {
  try {
    const response = await duplicateGroup(groupId, name, accessToken);
    if (response.status === 200) {
      const createdGroupId = response.data.id;
      setModalOpen(true);
      setModalContent({
        image: Success,
        title: "Grupa uspješno duplicirana.",
        description: "",
        buttons: [
          {
            label: "Ok",
            action: () => {
              navigate(`/grupe/${createdGroupId}`);

              resetGroupData();
              setModalOpen(false);
              setDuplicateGroupModal();
            },
            className: "modal-btn-success",
          },
        ],
      });
    }
  } catch (error) {
    console.error("Error creating group:", error);
    setModalOpen(true);
    setModalContent({
      image: Help,
      title: "Grupa neuspješno duplicirana.",
      description:
        "Kada arhivirate polaznike, više ih nećete moći uređivati, već samo pregledavati.",
      buttons: [
        {
          label: "Ok",
          action: () => {
            setModalOpen(false);
          },
          className: "modal-btn-success",
        },
      ],
    });
  }
};
