import { Box } from "@mui/material";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Sucess from "../../../../../assets/img/Success.png";
import Modal from "../../../../../components/Modal/Modal.jsx";
import { useGroupState } from "../../../../../context/CreateGroupContext.js";
import {
  fetchSearchData,
  handleSelectChange,
  MultiselectPlaceOption,
  MultiselectPlaceStyles,
  selectStyles,
  sviPolaznici,
} from "../../../../../scenes/attendees/utils";
import SearchStudents from "../../../../attendees/searchStudents/SearchStudents";
import { fetchData } from "../../../../attendees/utils.js";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import TableAttendees from "../../../../TableAttendees/index.jsx";
import styles from "../../../../TableAttendees/Table.module.css";
import {
  Label,
  Title,
  TopBox,
  Wrapper,
} from "./addPolaznikToTheGroup.styles.js";
import { fetchCities, handleDodajPolaznika } from "./API.js";
import { handleProgramsChange } from "./utils";

const AddPolaznikToTheGroup = () => {
  const location = useLocation();
  const groupId = location.state?.groupId;
  const isCreateNewGroup = location.state?.isCreateNewGroup;
  const isCreateEvent = location.state?.isCreateEvent;
  const selectedAttendeesData = location.state?.selectedAttendeesData;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { authToken } = useAuthentication();
  const [allPolaznici, setAllPolaznici] = useState([]);
  const [mjestoUpisa, setMjestoUpisa] = useState([]);
  const [selectedPolazniciIds, setSelectedPolazniciIds] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const { groupData, updateGroupData } = useGroupState();
  const [isNoGroupsClicked, setIsNoGroupsClicked] = useState(false);
  const [polazniciCount, setPolazniciCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState({
    value: "all",
    label: "Svi polaznici",
  });

  useEffect(() => {
    if (authToken) fetchCities(setMjestoUpisa, authToken);
  }, [authToken]);

  useEffect(() => {
    const extractedIds = selectedAttendeesData
      ? selectedAttendeesData.map((polaznik) => polaznik.id) || []
      : [];
    setSelectedPolazniciIds(extractedIds);
  }, [selectedAttendeesData]);

  const debouncedFetchSearchData = useMemo(() => {
    return _.debounce(
      (
        currentPage,
        authToken,
        setAllPolaznici,
        setPolazniciCount,
        searchResults,
        isNoGroupsClicked
      ) => {
        fetchSearchData(
          currentPage,
          authToken,
          setAllPolaznici,
          setPolazniciCount,
          searchResults,
          false,
          isNoGroupsClicked
        );
      },
      400
    );
  }, [authToken]);

  useEffect(() => {
    const city = selectedOption.map((option) => option.label);
    if (searchResults.length > 0) {
      if (authToken)
        debouncedFetchSearchData(
          currentPage,
          authToken,
          setAllPolaznici,
          setPolazniciCount,
          searchResults,
          isNoGroupsClicked
        );
    } else {
      if (authToken)
        fetchData(
          currentPage,
          authToken,
          setAllPolaznici,
          setPolazniciCount,
          false,
          city,
          selectedProgram.value,
          null,
          null,
          null,
          isNoGroupsClicked
        );
    }
  }, [
    currentPage,
    authToken,
    selectedOption,
    selectedProgram,
    polazniciCount,
    searchResults,
    isNoGroupsClicked,
  ]);

  useEffect(() => {
    if (searchResults.length > 0) setCurrentPage(1);
  }, [searchResults]);

  useEffect(() => {
    const newData = {
      ...groupData,
      noviPolaznici: selectedPolazniciIds,
    };
    if (groupData.noviPolaznici !== selectedPolazniciIds) {
      updateGroupData(newData);
    }
  }, [selectedPolazniciIds, groupData, updateGroupData]);

  const modalButtons = [
    {
      label: "U redu",
      action: () => {
        setIsModalOpen(false);
        navigate(-1);
      },
      className: "modal-btn-success",
    },
  ];

  return (
    <div>
      <SearchStudents setSearchResults={setSearchResults} />
      <Box display="flex">
        <Title>Dodaj polaznika</Title>
      </Box>
      <div style={{ marginTop: 30, marginBottom: -10 }}>
        <input
          type="checkbox"
          checked={isNoGroupsClicked}
          onChange={() => setIsNoGroupsClicked(!isNoGroupsClicked)}
          className={styles.customCheckbox}
        />
        <span
          style={{
            fontFamily: "Inter",
            fontSize: 16,
            color: "#4D4E4E",
            fontWeight: 600,
          }}
        >
          Prikaži samo one koji ne pripadaju ni jednoj grupi
        </span>
      </div>
      <Wrapper style={{ marginBottom: 50 }}>
        <TopBox>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Label>Mjesto upisa</Label>
            <Select
              options={mjestoUpisa}
              value={selectedOption.label}
              isMulti
              onChange={(selectedOption) =>
                handleSelectChange(
                  selectedOption,
                  setSelectedOption,
                  setCurrentPage
                )
              }
              styles={MultiselectPlaceStyles}
              isSearchable={false}
              components={{
                Option: MultiselectPlaceOption,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <Select
            defaultValue={selectedProgram}
            options={sviPolaznici}
            styles={selectStyles}
            isSearchable={false}
            components={{ IndicatorSeparator: () => null }}
            onChange={(selectedOption) =>
              handleProgramsChange(
                selectedOption,
                setSelectedProgram,
                setCurrentPage
              )
            }
          />
        </TopBox>
      </Wrapper>
      <TableAttendees
        data={allPolaznici}
        rowsPerPage={10}
        showCheckbox={true}
        showAvatars={true}
        userIdsIMentor={selectedPolazniciIds}
        showButtonRow={true}
        polazniciCount={polazniciCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setStudents={setAllPolaznici}
        isCreateNewGroup={isCreateNewGroup}
        isCreateEvent={isCreateEvent}
        handleDodajPolaznika={handleDodajPolaznika}
        setIsModalOpen={setIsModalOpen}
        idGroup={groupId}
        handleSelectionChange={setSelectedPolazniciIds}
        onClickOdustani={() => navigate(-1)}
      />
      {isModalOpen && (
        <Modal
          image={Sucess}
          title={"Polaznici su uspješno dodani!"}
          buttons={modalButtons}
        />
      )}
    </div>
  );
};

export default AddPolaznikToTheGroup;
