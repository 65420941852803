import React, { useEffect, useState } from "react";
import OsobniRadKlijentiGrupeAccordion from "./Accordions/OsobniRadKlijentiGrupeAccordion";
import styles from "./OsobniRadKlijentiGrupe.module.css";

function OsobniRadKlijentiGrupe({
  userId,
  polaznikLevel,
  data,
  setDeleteFlag,
  deleteFlag,
  evidentionUserID,
}) {
  const minBrojSatiPrakticar = 20;
  const minBrojSatiTerapeut = 400;
  const [osobniRadKlijentiGrupeArray, setOsobniRadKlijentiGrupeArray] =
    useState([]);
  const [obracunInfo, setObracunInfo] = useState([]);
  useEffect(() => {
    if (data) {
      const array = Object.values(data);
      setOsobniRadKlijentiGrupeArray([
        { individualWork: array[0] },
        { groupWork: array[1] },
      ]);
    }
  }, [data]);
  useEffect(() => {
    if (osobniRadKlijentiGrupeArray.length > 0) {
      const individualWork =
        osobniRadKlijentiGrupeArray[0]?.individualWork || {};
      const groupWork = osobniRadKlijentiGrupeArray[1]?.groupWork || {};
      const individualWorkTopicsArray = [
        {
          name: "Moj rad s klijentima (Školski kalendar)",
          hours: individualWork.automatic || 0,
          isNameClickable: true,
          eventType: "therapist_therapy_automatic",
        },
        {
          name: "Moj naknadno upisani rad s klijentima",
          hours: individualWork.manual || 0,
          isNameClickable: true,
          eventType: "manual_work_with_client",
        },
      ];
      const groupWorkTopicsArray = [
        {
          name: "Rad s grupom (Školski kalendar)",
          hours: groupWork.automatic || 0,
          isNameClickable: true,
          eventType: "therapist_therapy_automatic",
        },
        {
          name: "Moj upisani rad s grupom",
          hours: groupWork.manual || 0,
          isNameClickable: true,
          eventType: "therapist_therapy_manual",
        },
      ];
      setObracunInfo([
        {
          title: "Moj rad s klijentima - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: individualWorkTopicsArray,
              maxTopObracun:
                osobniRadKlijentiGrupeArray?.[0]?.individualWork.minAttendance,
            },
          ],
        },
        {
          title: "Osobni rad s grupom - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: groupWorkTopicsArray,
              maxBottomObracun: 0,
            },
          ],
        },
      ]);
    }
  }, [osobniRadKlijentiGrupeArray]);
  const minOsobniRadKlijentiGrupeSati =
    osobniRadKlijentiGrupeArray[0]?.individualWork.minAttendance;

  const currentIndividualniSati =
    osobniRadKlijentiGrupeArray[0]?.individualWork.manual +
    osobniRadKlijentiGrupeArray[0]?.individualWork.automatic;
  const currentGrupneSati =
    osobniRadKlijentiGrupeArray[1]?.groupWork.manual +
    osobniRadKlijentiGrupeArray[1]?.groupWork.automatic;
  return (
    <div className={styles.raniRazvojPrvaSekcija2}>
      <div>
        <h1 className={styles.raniRazvojHeader}>
          Osobni rad s klijentima i grupama
        </h1>
        <p className={styles.raniRazvojDescription}>
          {polaznikLevel === "praktičar"
            ? "Za postizanje statusa Praktičara ITP-a potrebno je ostvariti minimalno " +
              minBrojSatiPrakticar +
              " sati individualnog besplatnog psihoterapijskog rada s klijentima. Sati asistiranja se evidentiraju kao Osobni rad s grupom."
            : "Za postizanje statusa Psihoterapeuta ITP-a potrebno je ostvariti minimalno ukupno " +
              minBrojSatiTerapeut +
              " sati individualnog psihoterapijskog rada s klijentima ili sa grupama od čega je minimalno 250 sati rada s individualnim klijentima, a rad s grupama nije obavezan."}
        </p>
        <p className={styles.raniRazvojDescription2}>
          Moje trenutno stanje sati: <br /> Moj rad s klijentima -{" "}
          {currentIndividualniSati}h (Nedostaje{" "}
          {minOsobniRadKlijentiGrupeSati - currentIndividualniSati <= 0
            ? 0
            : minOsobniRadKlijentiGrupeSati - currentIndividualniSati}
          h){/*check jel treba conditional logika*/}
          <br />
          Osobni rad s grupom - {currentGrupneSati}h <br />
          Ukupno sati rada s klijentima i grupama:{" "}
          {currentGrupneSati + currentIndividualniSati}{" "}
          {polaznikLevel === "praktičar"
            ? ""
            : `(Nedostaje: ${
                minBrojSatiTerapeut -
                  currentIndividualniSati -
                  currentGrupneSati <=
                0
                  ? 0
                  : minBrojSatiTerapeut -
                    currentIndividualniSati -
                    currentGrupneSati
              })`}
        </p>
      </div>
      <div>
        <OsobniRadKlijentiGrupeAccordion
          data={obracunInfo}
          userId={userId}
          setDeleteFlag={setDeleteFlag}
          deleteFlag={deleteFlag}
          evidentionUserID={evidentionUserID}
        />
      </div>
    </div>
  );
}

export default OsobniRadKlijentiGrupe;
