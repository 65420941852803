// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DatePicker_dateRow__vLfLK {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 12px;\n  padding-top: 9px;\n}\n.DatePicker_dateInput__gUoG0 {\n  padding: 8px;\n  border: 1px solid #d8dbdf;\n  border-radius: 8px;\n  text-align: left;\n  height: 46px;\n  color: #5f6d7e;\n  box-shadow: 4px 4px 64px 0px #5f6d7e1a;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 1.6;\n  letter-spacing: -0.001em;\n  width: 100%;\n  box-sizing: border-box;\n}\n", "",{"version":3,"sources":["webpack://./src/scenes/obracunSoba/components/SingleInputDatePicker/DatePicker.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,sCAAsC;EACtC,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,wBAAwB;EACxB,WAAW;EACX,sBAAsB;AACxB","sourcesContent":[".dateRow {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 12px;\n  padding-top: 9px;\n}\n.dateInput {\n  padding: 8px;\n  border: 1px solid #d8dbdf;\n  border-radius: 8px;\n  text-align: left;\n  height: 46px;\n  color: #5f6d7e;\n  box-shadow: 4px 4px 64px 0px #5f6d7e1a;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 1.6;\n  letter-spacing: -0.001em;\n  width: 100%;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateRow": "DatePicker_dateRow__vLfLK",
	"dateInput": "DatePicker_dateInput__gUoG0"
};
export default ___CSS_LOADER_EXPORT___;
