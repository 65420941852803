import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "../../assets/svg/ChevronRightIcon";
import DocumentIcon from "../../assets/svg/DocumentIcon.js";
import { useUserState } from "../../context/UserContext";
import styles from "./Documents.module.css";

const Documents = () => {
  const navigate = useNavigate();
  const { role } = useUserState();
  return (
    <div>
      <div className={styles.cards}>
        <div
          className={styles.cardContainer}
          onClick={() => navigate("/dokumenti/pravilnici")}
        >
          <DocumentIcon className={styles.cardIcon} />
          <div className={styles.cardDivider} />
          <div className={styles.cardTextContainer}>
            <p className={styles.cardText}>pravilnici</p>
            <ChevronRightIcon className={styles.cardIcon} />
          </div>
        </div>
        {(role === "Referada" || role === "Polaznik") && (
          <div
            className={styles.cardContainer}
            onClick={() =>
              role === "Referada"
                ? navigate("/dokumenti/aplikacije-za-upis")
                : navigate("/dokumenti/aplikacije-za-upis/anketa")
            }
          >
            <DocumentIcon className={styles.cardIcon} />
            <div className={styles.cardDivider} />
            <div className={styles.cardTextContainer}>
              <p className={styles.cardText}>aplikacije za upis</p>
              <ChevronRightIcon className={styles.cardIcon} />
            </div>
          </div>
        )}
        {(role === "Voditelj" ||
          role === "Polaznik VT-a" ||
          role === "Supervizor") && (
          <div
            className={styles.cardContainer}
            onClick={() => navigate("/odobrenje-aplikacija/one")}
          >
            <DocumentIcon className={styles.cardIcon} />
            <div className={styles.cardDivider} />
            <div className={styles.cardTextContainer}>
              <p className={styles.cardText}>odobrenje aplikacija</p>
              <ChevronRightIcon className={styles.cardIcon} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Documents;
