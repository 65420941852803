import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const exportPregledPotrosnjePoGradovima = async (
  accessToken,
  cityId,
  startDate,
  endDate
) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Both startDate and endDate are required.");
    }
    const response = await axios.get(
      `${apiUrl}/api/exportAppointmentsStatisticsByCity/${cityId}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching pregled potrošnje po gradovima:", error);
    throw error;
  }
};

export const handlePregledPotrosnjePoGradovima = async (
  accessToken,
  cityId,
  startDate,
  endDate,
  city
) => {
  try {
    const response = await exportPregledPotrosnjePoGradovima(
      accessToken,
      cityId,
      startDate,
      endDate
    );
    const blob = new Blob([response], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `exported_pregled_potrosnje_${city}_${startDate}-${endDate}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Greška prilikom exporta potrošnje po gradovima:", error);
  }
};
