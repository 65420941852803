import * as yup from "yup";

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
export const options = [
  { label: "Uživo", value: "uzivo" },
  { label: "Online", value: "online" },
];

export const nastupnoPredavanjeModalSchema = yup.object().shape({
  tema: yup.string().required("Trebate unijeti temu predavanja"),
  tipPredavanja: yup.string().required("Trebate odabrati tip prisustvovanja"),
});
export const validateForm = async (setErrors, formData) => {
  try {
    await nastupnoPredavanjeModalSchema.validate(formData, {
      abortEarly: false,
    });
    setErrors({});

    return true;
  } catch (error) {
    const newErrors = {};
    error.inner.forEach((err) => {
      newErrors[err.path] = err.message;
    });
    setErrors(newErrors);
    return false;
  }
};
export const handleInputChange = (name, value, setFormData, formData) => {
  setFormData({
    ...formData,
    [name]: value,
  });
};
