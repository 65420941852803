import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const updateSupervision = async (id, data, accessToken) => {
  try {
    const response = await axios.put(`${apiUrl}/api/supervision/${id}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error updating supervision", error);
    throw error;
  }
};
