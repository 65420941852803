import axios from "axios";
import ProgramCard from "../../../components/ProgramCard/Card";
import { fetchEModuliData } from "../../EModuliEditCreate/util";
import { fetchRadionicaData } from "../../FakultativnaRadionicaEditCreate/utils";
import { fetchIntenziviData } from "../../IntenziviEditCreate/utils";
import { fetchSupervizijaData } from "../../SupervizijeEditCreate/utils";

const apiUrl = process.env.REACT_APP_API_URL;

export const handleCreateNewRadionica = async (navigate) => {
  navigate("/fakultativna-radionica-edit-create/five", {
    state: { isCreateMode: true },
  });
};
export const handleEditButton = async (
  radionicaID,
  navigate,
  accessToken,
  resetCreateEventData
) => {
  resetCreateEventData && resetCreateEventData();
  const data = await fetchRadionicaData(radionicaID, accessToken);
  if (data) {
    navigate("/fakultativna-radionica-edit-create/five", {
      state: { radionicaData: data, isEditMode: true },
    });
  }
};
export const handleCreateNewIntenziv = async (navigate) => {
  navigate("/intenzivi-edit-create/six", {
    state: { isCreateMode: true },
  });
};
export const handleEditIntenzivButton = async (
  intenzivID,
  isWeekend,
  navigate,
  accessToken,
  resetCreateEventData
) => {
  resetCreateEventData && resetCreateEventData();
  const data = await fetchIntenziviData(intenzivID, isWeekend, accessToken);
  if (data) {
    navigate("/intenzivi-edit-create/six", {
      state: { intenzivData: data, isEditMode: true },
    });
  }
};
export const handleCreateNewSupervizija = async (navigate) => {
  navigate("/grupna-supervizija-edit-create/seven", {
    state: { isCreateMode: true },
  });
};
export const handleEditSupervizijaButton = async (
  supervizijaID,
  navigate,
  accessToken,
  resetCreateEventData
) => {
  resetCreateEventData && resetCreateEventData();
  const data = await fetchSupervizijaData(supervizijaID, accessToken);
  if (data) {
    navigate("/grupna-supervizija-edit-create/seven", {
      state: { supervizijaData: data, isEditMode: true },
    });
  }
};

export const handleCreateNewEModul = async (navigate) => {
  navigate("/e-moduli-edit-create/nine", {
    state: { isCreateMode: true },
  });
};
export const handleEditEModulButton = async (
  emodulID,
  navigate,
  accessToken,
  resetCreateEventData
) => {
  resetCreateEventData && resetCreateEventData();
  const data = await fetchEModuliData(emodulID, accessToken);
  if (data) {
    navigate("/e-moduli-edit-create/nine", {
      state: { eModulData: data, isEditMode: true },
    });
  }
};
export const ProgramCardList = ({
  data,
  cardType,
  tabIndex,
  userId,
  navigate,
}) => (
  <div className="subpage-wrapper">
    {data
      ?.sort((a, b) => a.id - b.id)
      ?.map((item, index) => (
        <ProgramCard
          key={index}
          variant={cardType}
          title={item.title}
          userId={userId}
          opis={item.description}
          image={item.image}
          polaznici={item.polaznici}
          voditelji={item.voditelji}
          grupe={item.groups}
          level={item.degree}
          onClick={() => navigate(`/pojediniprogram/${item.id}/${tabIndex}`)}
        />
      ))}
  </div>
);
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "1px solid #D1D9E2",
    borderRadius: "4px",
    height: "42px",
    width: "168px",
    fontSize: "16px",
    fontWeight: 600,
    color: "red",
    fontFamily: "Inter",
    boxShadow: state.isFocused ? "0px 4px 17px 0px #5F6D7E29" : null,
    "&:hover": {
      borderColor: "#D1D9E2",
      cursor: "pointer",
    },
    "@media screen and (max-width:470px)": {
      width: "100%",
      paddingLeft: "31%",
      paddingRight: "33%",
    },
    "@media screen and (max-width:415px)": {
      width: "100%",
      paddingLeft: "27%",
      paddingRight: "33%",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    backgroundColor:
      state.isFocused || state.menuIsOpen
        ? "#FFFFFF"
        : state.isSelected
        ? "#FFFFFF"
        : null,
    color:
      state.isFocused || state.isSelected || state.menuIsOpen
        ? "#9FA7B2"
        : "#5F6D7E",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#5F6D7E",
  }),
  menuList: (provided) => ({
    ...provided,
    fontFamily: "Inter",
    fontWeight: 500,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};
export const options = [
  { value: "aktivno", label: "Aktivno" },
  { value: "odradjeno", label: "Odrađeno" },
];

export const supervisionTypes = [
  { value: "all", label: "Sve vrste" },
  { value: "trijada", label: "Trijade" },
  { value: "ags", label: "AGS" },
  { value: "eper", label: "EPeR" },
];

export const supervisionMonths = [
  { value: "all", label: "Cijela godina" },
  { value: "1", label: "Siječanj" },
  { value: "2", label: "Veljača" },
  { value: "3", label: "Ožujak" },
  { value: "4", label: "Travanj" },
  { value: "5", label: "Svibanj" },
  { value: "6", label: "Lipanj" },
  { value: "7", label: "Srpanj" },
  { value: "8", label: "Kolovoz" },
  { value: "9", label: "Rujan" },
  { value: "10", label: "Listopad" },
  { value: "11", label: "Studeni" },
  { value: "12", label: "Prosinac" },
];

export const getSupervisions = async (type, supervisor, month, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/supervisions?type=${
        type === "all" ? null : type
      }&supervisor=${supervisor === "all" ? null : supervisor}&month=${
        month === "all" ? null : month
      }`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.error("Error updating supervision", e);
    throw e;
  }
};
