import React from "react";
import Arrow from "../../../../assets/img/ArrowRight.png";
import styles from "./Card.module.css";

function PolaznikCard({
  icon,
  title,
  backgroundImage,
  onClick,
  customWidth,
  customHeight,
  customRight,
  customTop,
}) {
  return (
    <div className={styles.Wrapper} onClick={onClick}>
      <div className={styles.leftSide}>
        <div className={styles.icon}>
          <img src={icon} alt="" />
        </div>
        <div className={styles.divider} />
        <div className={styles.title}>
          {title}
          <img alt="" src={Arrow} />
        </div>
      </div>
      <div
        className={styles.rightSide}
        style={{ right: customRight, top: customTop }}
      >
        <img
          alt=""
          src={backgroundImage}
          className={styles.backgroundImage}
          style={{ width: customWidth, height: customHeight }}
        />
      </div>
    </div>
  );
}

export default PolaznikCard;
