export const PolaznikCardData = [
  {
    icon: require("../../../assets/img/Programs.png"),
    title: "AKTIVNI PROGRAM",
    backgroundImage: require("../../../assets/img/PolaznikProgrami.png"),
    route: "/programi/one",
    number: 10,
  },
  // {
  //   icon: require("../../../assets/img/Prijavnice.png"),
  //   title: "PRIJAVLJENE AKTIVNOSTI",
  //   backgroundImage: require("../../../assets/img/PolaznikPrijavnice.png"),
  //   route: "/prijavnice/six",
  // },
];
export const generateReferadaCardData = (
  numberOfAttendees,
  numberOfEmployees,
  numberOfGroups
) => {
  return [
    {
      icon: require("../../../assets/img/StudentIcon.png"),
      title: "POLAZNICI",
      route: "/polaznici",
      number: numberOfAttendees,
    },
    {
      icon: require("../../../assets/img/IPDTimIcon.png"),
      title: "ŠKOLSKI TIM",
      route: "/djelatnici",
      number: numberOfEmployees,
    },
    {
      icon: require("../../../assets/img/GrupeIcon.png"),
      title: "RAZREDI",
      route: "/grupe",
      number: numberOfGroups,
    },
  ];
};

export const CarouselPolaznikMockData = [
  {
    title: "DPO",
    image: require("../../../assets/img/DPOImage.png"),
    date: "23.9.2023.",
    route: "/pojedina-prijavnica", //ovdje će trebati slati na pojedina-prijavnica/:prijavnicaID
  },
  {
    title: "Preporađanje",
    image: require("../../../assets/img/PreporadanjeImage.png"),
    date: "15.9.2023.",
    route: "/pojedina-prijavnica", //ovdje će trebati slati na pojedina-prijavnica/:prijavnicaID
  },
];
export const CarouselReferadaMockData = [
  {
    title: "RAR - Rani razvoj",
    image: require("../../../assets/img/RaniRazvojPhoto.png"),
    polaznici: 43,
    route: "/programi/one",
  },
  {
    title: "OR - Osobni razvoj",
    image: require("../../../assets/img/OsobniRazvojPhoto.png"),
    polaznici: 200,
    route: "/programi/two",
  },
  {
    title: "TP - Terapeutski praktikum",
    image: require("../../../assets/img/TerapeutskiPractisingPhoto.png"),
    polaznici: 56,
    route: "/programi/three",
  },
  {
    title: "TT - Napredni terapeutski trening",
    image: require("../../../assets/img/NapredniTerapeutskiTreningPhoto.png"),
    polaznici: 34,
    route: "/programi/four",
  },
];
export const UpcomingEventsData = [
  {
    title: "Psihopatologije 1",
    date: "03-07-2023",
    time1: "17:00",
    time2: "20:00",
    color: "#009CBE",
    avatar: require("../../../assets/img/Avatar.png"),
    name: "Tomislav Kuljiš",
  },
  {
    title: "Psihopatologije 1",
    date: "13-07-2023",
    time1: "17:00",
    time2: "20:00",
    color: "#53ECD9",
    avatar: require("../../../assets/img/Avatar.png"),
    name: "Tomislav Kuljiš",
  },
  {
    title: "Psihopatologije 1",
    date: "14-07-2023",
    time1: "17:00",
    time2: "20:00",
    color: "#9747FF",
    avatar: require("../../../assets/img/Avatar.png"),
    name: "Tomislav Kuljiš",
  },
];
