export const handleDownload = async (
  makePDFCertificate,
  inputPdfPath,
  userName,
  country
) => {
  try {
    const pdfBlob = await makePDFCertificate(inputPdfPath, userName, country);
    const url = URL.createObjectURL(pdfBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `IPD ${country}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};
