export const organizeNotificationsByMonth = (notifications) => {
  const organizedNotifications = {};

  notifications.forEach((notification) => {
    const timePosted = new Date(notification.timePosted);
    const monthName = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(timePosted);
    const monthYearKey = `${monthName}-${timePosted.getFullYear()}`;

    if (!organizedNotifications[monthYearKey]) {
      organizedNotifications[monthYearKey] = [];
    }

    organizedNotifications[monthYearKey].push(notification);
  });

  return organizedNotifications;
};
