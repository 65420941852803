import React from "react";
import { useUserState } from "../../../../context/UserContext";
import "./IntrospekcijeTable.css";
import RenderTableData from "./RenderTableData";
import RenderTableHeader from "./RenderTableHeader";

function IntrospekcijeTable({ data }) {
  const { role } = useUserState();
  const userId = role;
  const numIntrospections = data?.reduce(
    (maxIntrospections, item) =>
      Math.max(
        maxIntrospections,
        item?.polaznici?.reduce(
          (maxPolaznikIntrospections, polaznik) =>
            Math.max(maxPolaznikIntrospections, polaznik.introspections.length),
          0
        )
      ),
    0
  );
  const baseColumnNames = Array.from(
    { length: numIntrospections },
    (_, i) => `Introspekcija ${i + 1}`
  );
  const columnNames = ["Mentor/Polaznik", ...baseColumnNames];
  const columnNamesVoditelj = ["Polaznici", ...baseColumnNames];
  return (
    <div className="scrollContainer">
      <table className="table-introspekcije">
        <thead>
          <RenderTableHeader
            columnNames={
              userId === "Voditelj" ? columnNamesVoditelj : columnNames
            }
          />
        </thead>
        <tbody>
          <RenderTableData data={data} emptyTDNumber={columnNames.length} />
        </tbody>
      </table>
    </div>
  );
}

export default IntrospekcijeTable;
