import React, { useState } from "react";
import Down from "../../../../../assets/img/ArrowDown.png";
import {
  ColumnWrapper,
  Content,
  ContentRow,
  DropdownArrow,
  Input,
  InputContainer,
  SectionDropdown,
  SectionHeader,
  SectionTitle,
  SectionTitleRow,
} from "../../ProgramContainer.styles";

function IskustveniDio({ iskustveniDio, isEditMode, setIskustveniDioData }) {
  const [iskustveniExpandedSections, setIskustveniExpandedSections] = useState(
    []
  );
  const toggleIskustveniSection = (index) => {
    const newExpandedSections = [...iskustveniExpandedSections];
    newExpandedSections[index] = !newExpandedSections[index];
    setIskustveniExpandedSections(newExpandedSections);
  };
  const handleInputChange = (event, moduleId, workshopId) => {
    const { name, value } = event.target;
    const updatedData = [...iskustveniDio];
    const moduleIndex = updatedData.findIndex(
      (module) => module.id === moduleId
    );
    if (moduleIndex !== -1) {
      if (workshopId !== undefined) {
        const workshopIndex = updatedData[
          moduleIndex
        ].regularWorkshops.findIndex((workshop) => workshop.id === workshopId);
        if (workshopIndex !== -1) {
          updatedData[moduleIndex].regularWorkshops[workshopIndex].name = value;
        } else {
          console.error("Workshop with id:", workshopId, "not found.");
        }
      } else {
        updatedData[moduleIndex].moduleName = value;
      }
      setIskustveniDioData(updatedData);
    } else {
      console.error("Module with id:", moduleId, "not found.");
    }
  };
  return (
    <ColumnWrapper>
      <SectionHeader>Iskustveni dio</SectionHeader>
      {iskustveniDio.map((module, index) => (
        <SectionDropdown key={index} width={"40vw"}>
          <SectionTitleRow onClick={() => toggleIskustveniSection(index)}>
            {isEditMode ? (
              <InputContainer>
                <SectionTitle>{`${index + 1}. Modul`}</SectionTitle>
                <Input
                  type="text"
                  placeholder="Upiši"
                  value={module?.moduleName}
                  onChange={(event) => handleInputChange(event, module.id)}
                />
              </InputContainer>
            ) : (
              <SectionTitle>{`${index + 1}. Modul - ${
                module?.moduleName
              }`}</SectionTitle>
            )}
            <DropdownArrow
              alt=""
              src={Down}
              rotated={iskustveniExpandedSections[index]}
            />
          </SectionTitleRow>
          {iskustveniExpandedSections[index] && module.regularWorkshops && (
            <div>
              {module.regularWorkshops.map((item, resourceIndex) => (
                <ContentRow
                  key={resourceIndex}
                  style={{ textDecoration: "none" }}
                >
                  {isEditMode ? (
                    <InputContainer style={{ marginBottom: 16, marginTop: 12 }}>
                      <SectionTitle>{`${index + 1}.${
                        resourceIndex + 1
                      }`}</SectionTitle>
                      <Input
                        type="text"
                        placeholder="Upiši"
                        value={item?.name}
                        onChange={(event) =>
                          handleInputChange(event, module.id, item.id)
                        }
                      />
                    </InputContainer>
                  ) : (
                    <Content>{`${index + 1}.${resourceIndex + 1} ${
                      item.name
                    }`}</Content>
                  )}
                </ContentRow>
              ))}
            </div>
          )}
        </SectionDropdown>
      ))}
    </ColumnWrapper>
  );
}

export default IskustveniDio;
