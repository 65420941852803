import React, { useState } from "react";
import CustomInput from "../myProfile/components/customInput/CustomInput";
import SelectDropdown from "../singleGroup/components/selectDropdown/SelectDropdown";
import styles from "./Payment.module.css";

/*
Customer :

id
fbId
stripeId
email
fullName
lastActivity
totalOrders
totalConsumption
averageOrders
country
region
zipCode

Registration :

couponCode
imageUrl
name
surname
email
dateOfBirth
gender
address
city
country
attendingCity
qualifications
title
pin
cellphone
isFbMember
consentsToDataUse
isCompanyPayment
companyName
companyOib
companyAddress
noNotifications
message
acceptsAgreement
acceptsRules
readAndAgreesToPrivacyPolicy
*/

const initialState = {
  name: "",
  surname: "",
  address: "",
  country: "",
  city: "",
  phoneNumber: "",
  brojKartice: "",
  CVV: "",
  expires: "",
  pin: "",
  gender: "",
  oib: "",
};

function Payment() {
  const [formData, setFormData] = useState(initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    console.log(formData);
    setFormData(initialState);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Prijava</div>
      <div className={styles.formContainer}>
        <div className={styles.leftContainer}>
          <CustomInput
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            labelText="Ime"
          />
          <CustomInput
            name="surname"
            value={formData.surname}
            onChange={handleInputChange}
            labelText="Prezime"
          />
          <div style={{ margin: "9px 0" }}>
            <SelectDropdown
              name="gender"
              onChange={handleInputChange}
              title="Spol"
              value={formData.gender}
              defaultValue={""}
              options={[
                { value: "", label: "Odaberi" },
                { value: "Muško", label: "Muško" },
                { value: "Žensko", label: "Žensko" },
              ]}
            />
          </div>
          <CustomInput
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            labelText="Država"
          />
          <CustomInput
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            labelText="Grad"
          />
          <CustomInput
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            labelText="Adresa stanovanja"
          />
        </div>
        <div className={styles.rightContainer}>
          <CustomInput
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            labelText="Broj telefona"
          />
          <CustomInput
            name="oib"
            value={formData.oib}
            onChange={handleInputChange}
            labelText="OIB"
          />
          <CustomInput
            name="brojKartice"
            value={formData.brojKartice}
            onChange={handleInputChange}
            labelText="Broj kartice"
          />
          <CustomInput
            name="CVV"
            value={formData.CVV}
            onChange={handleInputChange}
            labelText="CVV"
          />
          <CustomInput
            name="expires"
            value={formData.expires}
            onChange={handleInputChange}
            labelText="Expires"
          />
          <CustomInput
            name="pin"
            value={formData.pin}
            onChange={handleInputChange}
            labelText="Pin"
          />
        </div>
      </div>
      <div className={styles.submitBtnContainer}>
        <div className={styles.submit} onClick={() => handleSubmit()}>
          Prijava
        </div>
      </div>
    </div>
  );
}

export default Payment;
