const LetterIcon = (props) => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.941 14H6.06c-1.765 0-2.941-.882-2.941-2.941V6.94C3.118 4.882 4.294 4 6.058 4h5.883c1.765 0 2.941.882 2.941 2.941v4.118c0 2.059-1.176 2.941-2.94 2.941z"
        stroke="current"
        strokeWidth={0.784314}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.94 7.235l-1.84 1.47c-.606.483-1.6.483-2.206 0l-1.835-1.47"
        stroke="current"
        strokeWidth={0.784314}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LetterIcon;
