import React from "react";
import { formatDate } from "../../../polazniciPregled/utils/utils";
import { formatTime } from "../../utils/utils";
import "./ObracunTable.css";

function RenderData({
  data = [],
  isPotrosnjaPoGradovima,
  isPotpuniObracun,
  showSum,
  isObracunPoSobama,
  cityRoomReservationsNumber,
}) {
  let dataToRender = [];
  if (!Array.isArray(data)) {
    return null;
  }
  if (isObracunPoSobama) {
    dataToRender = data;
  } else {
    dataToRender = data?.map((item) => item.obracun).flat();
  }
  const calculateSum = (data, key) => {
    return data?.reduce((acc, item) => {
      const value = item[key];
      return acc + (value !== undefined && value !== null ? Number(value) : 0);
    }, 0);
  };

  const sums = {
    ukupnoRezervacija: calculateSum(dataToRender, "numberOfReservations"),
    ukupnoEuri: calculateSum(dataToRender, "totalSpend"),
    brojOtkazanihRezervacija: calculateSum(dataToRender, "numberOfCancelled"),
    ukupnoKoristenje: calculateSum(dataToRender, "totalSpent"),
    postotakOtkazanihRezervacija: (
      (calculateSum(dataToRender, "numberOfCancelled") /
        calculateSum(dataToRender, "numberOfReservations")) *
      100
    ).toFixed(2),
  };
  return (
    <>
      {dataToRender?.map((item, index) => (
        <tr key={index}>
          <td style={{ textAlign: "left" }}>
            {item.roomName !== undefined
              ? item.roomName
              : item.firstName !== undefined && item.lastName !== undefined
              ? `${item.firstName} ${item.lastName}`
              : item.userFirstName !== undefined &&
                item.userLastName !== undefined
              ? `${item.userFirstName} ${item.userLastName}`
              : item.period !== undefined
              ? item.period
              : ""}
          </td>
          <td>
            {item.numberOfReservations !== undefined
              ? item.numberOfReservations
              : item.userType !== undefined
              ? item.userType
              : item.nazivSobe !== undefined
              ? item.nazivSobe
              : item.nazivGrada !== undefined
              ? item.nazivGrada
              : item.grad !== undefined
              ? item.grad
              : ""}
          </td>
          <td>
            {item.roomPrice !== undefined
              ? item.roomPrice
              : item.totalSpent !== undefined
              ? item.totalSpent
              : item.appointmentType !== undefined
              ? item.appointmentType
              : item.sumOfReservations !== undefined
              ? item.sumOfReservations
              : ""}
          </td>
          {isPotrosnjaPoGradovima && (
            <td>{item.roomName !== undefined && item.roomName}</td>
          )}
          <td>
            {item.totalSpend !== undefined
              ? item.totalSpend
              : item.otkazaneRezervacije !== undefined
              ? item.otkazaneRezervacije
              : item.date !== undefined
              ? formatDate(item.date)
              : item.cancelledReservations !== undefined
              ? item.cancelledReservations
              : ""}
          </td>
          <td>
            {item.numberOfCancelled !== undefined
              ? item.numberOfCancelled
              : item.postotakOtkazane !== undefined
              ? item.postotakOtkazane
              : item.timeFrom !== undefined
              ? formatTime(item.timeFrom)
              : item.percentCancelled !== undefined
              ? item.percentCancelled
              : item.cancelled !== undefined
              ? item.cancelled
              : ""}
          </td>
          <td>
            {item.percentageCancelled !== undefined
              ? item.percentageCancelled
              : item.timeTo !== undefined
              ? formatTime(item.timeTo)
              : item.percentageCancelled !== undefined
              ? item.percentageCancelled
              : ""}
          </td>
        </tr>
      ))}
      {showSum && (
        <tr style={{ backgroundColor: "#F4F4F4", fontWeight: 600 }}>
          <td style={{ textAlign: "left", fontWeight: 600 }}>
            {isPotpuniObracun ? "UKUPNO KORIŠTENJE" : "UKUPNO"}
          </td>
          <td>{!isPotpuniObracun && sums.ukupnoRezervacija}</td>
          <td>{isPotpuniObracun ? sums.ukupnoKoristenje : "/"}</td>
          <td>{!isPotpuniObracun && sums.ukupnoEuri}</td>
          <td>{!isPotpuniObracun && sums.brojOtkazanihRezervacija}</td>
          <td>{!isPotpuniObracun && sums.postotakOtkazanihRezervacija}</td>
          {isPotrosnjaPoGradovima && <td></td>}
        </tr>
      )}
    </>
  );
}

export default RenderData;
