import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useAuthentication } from "../hooks/useAuthentication";
import WeekTable from "./WeekTable";
import {
  createWeeks,
  handleBulkPublish,
  isWeekTableEmpty,
} from "./calendar-utils/calendar.utils";
import "./calendar.css";

const Calendar = ({
  selectedAcademicYear,
  room,
  name,
  setSelectedEmptyTimeSlot,
  setEventData,
  setActionType,
  groupList,
  headerColor,
  setOpenModal,
  tableHeadColor,
  masterObject,
  showToggleForFirstIndex,
  startYear,
  endYear,
  setCalendarData,
}) => {
  const { authToken } = useAuthentication();
  const [events, setEvents] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [meetingsWithMentors, setMeetingsWithMentors] = useState([]);
  const [interactiveLessons, setInteractiveLessons] = useState([]);
  const [workshopIds, setWorkshopIds] = useState([]);
  const [meetingWithMentorIds, setMeetingWithMentorIds] = useState([]);
  const [interactiveLessonIds, setInteractiveLessonIds] = useState([]);
  const [isAllPublished, setIsAllPublished] = useState(false);
  const weeks = createWeeks(selectedAcademicYear);
  useEffect(() => {
    if (masterObject && masterObject.workshops) {
      setWorkshops(
        masterObject.workshops.map((event) => ({
          ...event,
          sourceObjectName: "regular_workshop",
        }))
      );
      const extractedWorkshopdIds = masterObject.workshops.map(
        (item) => item.id
      );
      setWorkshopIds(extractedWorkshopdIds);
    } else {
      setWorkshops([]);
    }
    if (masterObject && masterObject.meetingsWithMentor) {
      setMeetingsWithMentors(
        masterObject.meetingsWithMentor.map((event) => ({
          ...event,
          sourceObjectName: "meeting_with_mentor",
        }))
      );
      const extractedMeetingsIds = masterObject.meetingsWithMentor.map(
        (item) => item.id
      );
      setMeetingWithMentorIds(extractedMeetingsIds);
    } else {
      setMeetingsWithMentors([]);
    }
    if (masterObject && masterObject.interactiveLessons) {
      setInteractiveLessons(
        masterObject.interactiveLessons.map((event) => ({
          ...event,
          sourceObjectName: "interactive_lesson",
        }))
      );
      const extractedInteractiveIds = masterObject.interactiveLessons.map(
        (item) => item.id
      );
      setInteractiveLessonIds(extractedInteractiveIds);
    } else {
      setInteractiveLessons([]);
    }
  }, [masterObject]);

  useEffect(() => {
    const allEvents = [
      ...workshops,
      ...meetingsWithMentors,
      ...interactiveLessons,
    ];
    setEvents(allEvents);
    const isPublished = allEvents.every((event) => event.isPublished === true);
    setIsAllPublished(isPublished);
  }, [workshops, meetingsWithMentors, interactiveLessons]);

  return (
    <div
      className="calendar"
      style={{ marginTop: !showToggleForFirstIndex ? 38 : 0 }}
    >
      {showToggleForFirstIndex && (
        <div className="switch-row">
          <span>
            {isAllPublished ? "Svi eventovi su objavljeni" : "Objavi sve"}
          </span>
          <Switch
            checked={isAllPublished}
            onChange={() =>
              handleBulkPublish(
                interactiveLessonIds,
                workshopIds,
                meetingWithMentorIds,
                authToken,
                startYear,
                endYear,
                setCalendarData
              )
            }
            inputProps={{ "aria-label": "Show events" }}
            disabled={events.length === 0}
          />
        </div>
      )}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 999,
          display: "flex",
        }}
      >
        <div
          style={{
            minWidth: 80,
            maxWidth: 80,
            height: 42,
            backgroundColor: tableHeadColor,
            border: "1px solid #ccc",
          }}
        ></div>
        <div
          style={{
            minWidth: 195.5,
            maxWidth: 195.5,
            height: 42,
            backgroundColor: "white",
            border: "1px solid #ccc",
          }}
        ></div>

        <div
          colSpan={7}
          style={{
            height: 42,
            display: "flex",
            alignItems: "center",
            backgroundColor: tableHeadColor,
            width: "100%",
            border: "1px solid #ccc",
          }}
        >
          <div
            style={{
              marginLeft: "100px",
              display: "flex",
              alignItems: "center",
              fontWeight: 800,
            }}
          >
            {name} &nbsp;&nbsp;&nbsp;
          </div>
        </div>
      </div>
      {weeks.map((week, weekIndex) => (
        <WeekTable
          key={weekIndex}
          weekIndex={weekIndex}
          headerColor={headerColor}
          tableHeadColor={tableHeadColor}
          events={events}
          setActionType={setActionType}
          setOpenModal={setOpenModal}
          eventsCell={[]}
          groupList={groupList}
          selectedAcademicYear={selectedAcademicYear}
          isWeekTableEmpty={isWeekTableEmpty}
          setSelectedEmptyTimeSlot={setSelectedEmptyTimeSlot}
          setEventData={setEventData}
          room={room}
        />
      ))}
    </div>
  );
};

export default Calendar;
