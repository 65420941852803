import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const updateSupervisionComment = async (
  supervisionId,
  comment,
  accessToken
) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/updateSupervisionComment`,
      {
        supervisionId: supervisionId,
        comment: comment,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating comment", error);
    throw error;
  }
};
