import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackComponent from "../../../../components/BackComponent/BackComponent";
import Modal from "../../../../components/Modal/Modal";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { GrupneSupervizijeValidationSchema } from "./Schema";
import styles from "./Styles.module.css";
import {
  grupneSupervizije,
  handleInputChange,
  handleUnesiSateClick,
  postData,
  returnUloga,
  satiPromatraca,
} from "./utils";

function GrupneSupervizije({ supervizori, userEvidentionID }) {
  const navigate = useNavigate();
  const fullName = localStorage.getItem("evidentionFullName");
  const { authToken } = useAuthentication();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [formData, setFormData] = useState({
    tipGrupneSupervizije: "",
    date: new Date().toISOString().split("T")[0],
    uloga: "",
    satiIndividualneSupervizije: "",
    satiGrupneSupervizije: "",
    fullName: "",
    vanjskiSupervizor: "",
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (
      formData.tipGrupneSupervizije === "trijada" &&
      formData.uloga === "terapeut"
    ) {
      setFormData({
        ...formData,
        satiIndividualneSupervizije: 1,
        satiGrupneSupervizije: 1.5,
      });
    } else if (
      formData.tipGrupneSupervizije === "trijada" &&
      formData.uloga === "sudionik"
    ) {
      setFormData({
        ...formData,
        satiGrupneSupervizije: 2.5,
        satiIndividualneSupervizije: 0,
      });
    } else if (
      formData.tipGrupneSupervizije === "ags" &&
      formData.uloga === "supervizant"
    ) {
      setFormData({
        ...formData,
        satiIndividualneSupervizije: 1,
        satiGrupneSupervizije: 2,
      });
    } else if (
      formData.tipGrupneSupervizije === "ags" &&
      formData.uloga === "sudionik"
    ) {
      setFormData({
        ...formData,
        satiIndividualneSupervizije: 0,
        satiGrupneSupervizije: 3,
      });
    } else if (formData.tipGrupneSupervizije === "eper") {
      setFormData({
        ...formData,
        uloga: "sudionik",
        satiIndividualneSupervizije: 0,
        satiGrupneSupervizije: 3,
      });
    }
  }, [formData.uloga, formData.tipGrupneSupervizije]);
  useEffect(() => {
    if (formData.fullName !== "Vanjski supervizor") {
      setFormData({
        ...formData,
        vanjskiSupervizor: "",
      });
    }
  }, [formData.fullName]);
  const otherInfo = JSON.stringify(
    postData(formData.tipGrupneSupervizije, formData)
  );
  return (
    <div className={styles.wrapper}>
      <BackComponent />
      <span className={styles.title}>
        Ručni unos sati za radionice grupne supervizije ili metodologije
      </span>
      <div className={styles.inputWrapper}>
        <div className={styles.inputColumn}>
          <span className={styles.label}>
            Tip grupne supervizije ili metodologije
          </span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="tipGrupneSupervizije"
            value={formData.tipGrupneSupervizije}
            onChange={(e) =>
              handleInputChange(
                "tipGrupneSupervizije",
                e.target.value,
                setFormData,
                formData
              )
            }
          >
            <option value="" disabled>
              Odaberi
            </option>
            {grupneSupervizije.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.tipGrupneSupervizije && (
            <div className={styles.error}>{errors.tipGrupneSupervizije}</div>
          )}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>
            Datum održavanja grupne supervizije ili metodologije
          </span>
          <input
            className={styles.rucniUnosInput}
            type="date"
            name="date"
            value={formData.date}
            onChange={(e) =>
              handleInputChange("date", e.target.value, setFormData, formData)
            }
          />
          {errors.rucniUnosInput && (
            <div className={styles.error}>{errors.rucniUnosInput}</div>
          )}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Uloga</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="uloga"
            value={formData.uloga}
            onChange={(e) =>
              handleInputChange("uloga", e.target.value, setFormData, formData)
            }
          >
            <option value="" disabled>
              Odaberi
            </option>
            {returnUloga(formData.tipGrupneSupervizije).map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.uloga && <div className={styles.error}>{errors.uloga}</div>}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>
            Sati grupne supervizije ili metodologije
          </span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="satiGrupneSupervizije"
            value={formData.satiGrupneSupervizije}
            disabled
          >
            {satiPromatraca.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>
            Sati individualne supervizije ili metodologije
          </span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="satiIndividualneSupervizije"
            value={formData.satiIndividualneSupervizije}
            disabled
          >
            {satiPromatraca.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Supervizor</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={(e) =>
              handleInputChange(
                "fullName",
                e.target.value,
                setFormData,
                formData
              )
            }
          >
            <option value="" disabled>
              Odaberi
            </option>
            {supervizori.map((item, index) => (
              <option key={index} value={item.label}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.fullName && (
            <div className={styles.error}>{errors.fullName}</div>
          )}
        </div>
        {formData.fullName === "Vanjski supervizor" && (
          <div className={styles.inputColumn}>
            <span className={styles.label}>
              Ime i prezime vanjskog supervizora
            </span>
            <input
              className={styles.rucniUnosInput}
              type="text"
              name="vanjskiSupervizor"
              value={formData.vanjskiSupervizor}
              onChange={(e) =>
                handleInputChange(
                  "vanjskiSupervizor",
                  e.target.value,
                  setFormData,
                  formData
                )
              }
            />
            {errors.vanjskiSupervizor && (
              <div className={styles.error}>{errors.vanjskiSupervizor}</div>
            )}
          </div>
        )}
      </div>
      <div className={styles.btnRow}>
        <button
          onClick={() => {
            localStorage.setItem("evidentionFullName", fullName);
            localStorage.setItem("evidentionUserID", userEvidentionID);
            navigate("/evidencija");
          }}
          className={styles.cancelBtn}
        >
          Odustani
        </button>
        <button
          className={styles.successBtn}
          onClick={() =>
            handleUnesiSateClick(
              setErrors,
              formData,
              GrupneSupervizijeValidationSchema,
              userEvidentionID,
              formData.tipGrupneSupervizije,
              otherInfo,
              authToken,
              setModalContent,
              navigate,
              setShowModal
            )
          }
        >
          Unesi sate
        </button>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
}

export default GrupneSupervizije;
