export const selectStyles = {
  control: (styles, state) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    color: "#828d9a",
    height: "46px",
    width: "340px",
    boxShadow: "4px 4px 64px 0px rgba(95, 109, 126, 0.1)",
    backgroundColor: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "8px",
    border: `1px solid ${state.isFocused ? "#cb9c2d" : "#D1D9E2"}`,
    cursor: "pointer",
    "&:focus": {
      border: "1px solid #cb9c2d !important",
    },
    "&:hover": {
      border: "1px solid #cb9c2d !important",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      ":hover": {
        backgroundColor: data.isDisabled ? "#FFFFFF" : "#F2F2F2",
      },
    };
  },
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#5F6D7E",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "22px",
    boxShadow: "4px 4px 64px 0px #5f6d7e1a",
  }),
  menu: (styles) => ({
    ...styles,
    width: "340px",
  }),
};

export const starLabels = [
  "Prisutnost tijekom radionica",
  "Pripremljenost za radionicu",
  "Koliko često voditelj koristi procesni rad",
  "Stručnost u procesnom radu",
  "Razinu stručnog teorijskog znanja",
  "Posvećenost grupi i zainteresiranost za kvalitetu rada i napredovanje polaznika (koliko mu/joj je stalo)",
];

export const tabsData = [
  { label: "Pisanje osvrta na voditelja", value: "one" },
  { label: "Osvrti i ocjene na moj rad", value: "two" },
  { label: "Pregled ostalih voditelja", value: "three" },
];

export const evaluations = [
  {
    title: "SIGURNOST",
    description:
      "Osigurava da se studenti osjećaju uključeno i sigurno u grupi i osjećaju pripadnost.",
  },
  {
    title: "GRUPNA DINAMIKA",
    description:
      "Detektira i konstruktivno rješava konflikte unutar grupne dinamike.",
  },
  {
    title: "FEEDBACK",
    description:
      "Doživljava, vidi i osjeća studente pružajući primjeren, iskren i konstruktivan feedback.",
  },
  {
    title: "POVJERENJE",
    description:
      "Svojom osobnošću, energijom i radom doprinosi osjećaju stabilnosti i povjerenja u grupu, voditelje i Školu.",
  },
  {
    title: "SAMOKRITIČNOST",
    description:
      "Prihvaća kritiku i demonstrira promjene kao rezultat povratnih informacija od studenata.",
  },
  {
    title: "GRANICE",
    description:
      "Prepoznaje realne kapacitete i čuva osobne granice polaznika te poštuje njihovu privatnost.",
  },
  {
    title: "KREATIVNOST",
    description:
      "Pokazuje fleksibilnost i kreativnost u prilagodbi programa prema potrebama grupe.",
  },
  {
    title: "INKLUZIVNOST",
    description:
      "Potiče inkluzivnost prema različitostima te podržava individualizam i raznolikost među studentima ",
  },
  {
    title: "PREPOZNAVANJE EMOCIONALNIH POTREBA",
    description:
      "Uspješno prepoznaje emocionalne potrebe studenata i primjereno odgovara na njih.",
  },
  {
    title: "PRISUTNOST",
    description:
      "Svojom energijom i pažnjom prisutan/na i fokusiran/na je za vrijeme radionica",
  },
  {
    title: "PRIPREMLJENOST",
    description:
      "Dolazi pripremljen/na na radionicu s jasnim planom i ciljevima radionice",
  },
  {
    title: "PROCESNI RAD",
    description:
      "Stručno podržava i potiče procesni rad u skladu s potrebama i kapacitetima grupe/pojedinca",
  },
  {
    title: "INTEGRACIJA",
    description:
      "Dopušta vrijeme za regulaciju i integraciju pojedinaca i grupe nakon procesnog rada",
  },
  {
    title: "POZNAVANJE TEORIJE",
    description:
      "Prikazuje potrebnu razinu stručnih teorijskih znanja tijekom radionica",
  },
  {
    title: "INTEGRACIJA TEORIJE S ISKUSTVOM",
    description:
      "Daje pojašnjenja spajajući teorijsko znanje s iskustvom doživljenim kroz grupni rad na radionicama",
  },
  {
    title: "POSVEĆENOST",
    description:
      "Pokazuje predanost grupi i interes za kvalitetu rada te napredak polaznika.",
  },
  {
    title: "MENTORSTVO",
    description:
      "(U slučaju da ti je mentor) Kao Mentor redovito komentira i daje kvalitetan feedback na moje introspekcije.",
  },
];
