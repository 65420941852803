import React, { useEffect, useState } from "react";
import Export from "../../assets/img/Export.png";
import { useAuthentication } from "../hooks/useAuthentication";
import { formatDate } from "../polazniciPregled/utils/utils";
import SelectDropdown from "../singleGroup/components/selectDropdown/SelectDropdown";
import { getObracunData } from "./API/fetchObracunData";
import { getZakupitelji } from "./API/fetchZakupitelji";
import styles from "./ObracunSoba.module.css";
import ObracunComponent from "./components/ObracunComponent/ObracunComponent";
import DatePicker from "./components/SingleInputDatePicker/DatePicker";
import { mojObracunKoristenjaSobeColumnNames } from "./utils/columnNames";
import { handleSelectZakupiteljChange } from "./utils/utils";

function PregledKoristenjaSoba() {
  const today = new Date();
  const initialDate = today.toISOString().split("T")[0];
  const [zakupiteljId, setZakupiteljId] = useState(1);
  const [zakupiteljLabel, setZakupiteljLabel] = useState("");
  const [pregledKoristenjaSoba, setPregledKoristenjaSoba] = useState({});
  const [zakupiteljOptions, setZakupiteljOptions] = useState([
    { value: "", label: "" },
  ]);
  const [startDate, setStartDate] = useState(initialDate);
  const [endDate, setEndDate] = useState(initialDate);
  const { authToken } = useAuthentication();
  const handleDateChange = (newDates) => {
    setStartDate(newDates[0]);
    setEndDate(newDates[1]);
  };
  useEffect(() => {
    if (authToken)
      getZakupitelji(authToken)
        .then((data) => {
          const mappedData = data.map((zakupitelj) => ({
            value: zakupitelj.id.toString(),
            label: `${zakupitelj.firstName} ${zakupitelj.lastName}`,
          }));

          setZakupiteljOptions(mappedData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
  }, [authToken, startDate, endDate]);
  useEffect(() => {
    if (authToken)
      getObracunData(
        authToken,
        zakupiteljId,
        startDate,
        endDate,
        setPregledKoristenjaSoba
      );
  }, [authToken, startDate, endDate, zakupiteljId]);
  return (
    <div className={styles.obracunWrapper}>
      <span className={styles.title}>Pregled korištenja soba</span>
      <div className={styles.inputRow}>
        <div className={styles.column}>
          <span className={styles.label}>Odaberi zakupitelja</span>
          <SelectDropdown
            options={zakupiteljOptions}
            value={
              zakupiteljId ||
              (zakupiteljOptions.length > 0 ? zakupiteljOptions[0].value : null)
            }
            onChange={(e) =>
              handleSelectZakupiteljChange(
                e,
                setZakupiteljId,
                setZakupiteljLabel,
                zakupiteljOptions
              )
            }
          />
        </div>
        <div className={styles.column}>
          <span className={styles.label}>Odaberi vremenski raspon</span>
          <DatePicker
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
          />
        </div>
      </div>
      <ObracunComponent
        title={pregledKoristenjaSoba?.statistics?.userType ?? "BASIC KORISNIK"}
        label={`Korisnik : ${zakupiteljLabel} (${formatDate(
          startDate
        )} - ${formatDate(endDate)})`}
        columnNames={mojObracunKoristenjaSobeColumnNames}
        data={
          pregledKoristenjaSoba.statistics
            ? pregledKoristenjaSoba?.statistics.obracun
            : []
        }
        backgroundColor={"#87AD77"}
        showSum
        isObracunPoSobama
      />
      <button className={styles.exportBtn}>
        Export tablice <img alt="" src={Export} />
      </button>
    </div>
  );
}

export default PregledKoristenjaSoba;
