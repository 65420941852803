import React from "react";
import "./ObracunTable.css";
function RenderTableHeader({ columnNames = [], backgroundColor }) {
  if (!Array.isArray(columnNames)) {
    console.error("columnNames is not an array:", columnNames);
    return null;
  }

  return (
    <>
      <tr>
        {columnNames.map((columnName, index) => (
          <th
            key={index}
            className="table"
            style={{
              textAlign: index === 0 ? "left" : "center",
              backgroundColor: backgroundColor,
            }}
          >
            {columnName}
          </th>
        ))}
      </tr>
    </>
  );
}

export default RenderTableHeader;
