import React from "react";

const EmptyCell = ({ content, intervalStart, intervalEnd, onClick }) => (
  <div
    style={{ minWidth: 110, height: 20 }}
    onClick={() => onClick(intervalStart, intervalEnd)}
    className="event"
  >
    {content}
  </div>
);

export default EmptyCell;
