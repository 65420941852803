import { useState } from "react";
import ChevronDownIcon from "../../../../../../assets/svg/ChevronDownIcon";
import ChevronUpIcon from "../../../../../../assets/svg/ChevronUpIcon";
import styles from "./Accordion.module.css";

const Accordion = ({ isOpen, children, title, disabled }) => {
  const [isExpanded, setIsExpanded] = useState(isOpen);
  return (
    <div className={disabled ? styles.disabled : styles.accordionContainer}>
      <div
        className={
          isExpanded ? styles.accordionHeaderActive : styles.accordionHeader
        }
        onClick={() =>
          disabled ? setIsExpanded(false) : setIsExpanded(!isExpanded)
        }
      >
        <p className={styles.accordionHeaderText}>{title}</p>
        {isExpanded ? (
          <ChevronUpIcon className={styles.accordionExpandIcon} />
        ) : (
          <ChevronDownIcon className={styles.accordionExpandIcon} />
        )}
      </div>
      {isExpanded && <div className={styles.accordionContent}>{children}</div>}
    </div>
  );
};
export default Accordion;
