import {
  addWeeks,
  isSameWeek as isSameWeekOriginal,
  parseISO,
  startOfYear,
} from "date-fns";
import { bulkPublishEvents, getCalendarData } from "./calendar.api";

export const startOfYearDate = startOfYear(new Date());
export const createWeeks = (selectedAcademicYear) => {
  const weeks = [];
  for (let i = 0; i < 52; i++) {
    const startDate = addWeeks(startOfYearDate, i);
    weeks.push({ startDate });
  }

  let startDate = selectedAcademicYear.start;
  while (startDate <= selectedAcademicYear.end) {
    weeks.push({ startDate });
    startDate = addWeeks(startDate, 1);
  }
  return weeks;
};
export const matchRoomNames = (masterObject, roomCalendars) => {
  const matchedRooms = {};
  roomCalendars.forEach((roomInfo) => {
    const { roomName } = roomInfo;
    if (masterObject && masterObject[roomName]) {
      matchedRooms[roomName] = masterObject[roomName];
    }
  });

  return matchedRooms;
};

export const isWeekTableEmpty = (weeks, events) => {
  if (!Array.isArray(events)) {
    return true;
  }
  const weekLen = weeks.every((week) => {
    const weekEvents = events.filter((event) =>
      isSameWeekOriginal(parseISO(event.date), parseISO(week.startDate))
    );
    return weekEvents.length === 0;
  });
  return weekLen;
};
export const programSelectData = (workshop) => {
  switch (workshop) {
    case "regular_workshop":
      return [
        { value: "1", label: "RAR" },
        { value: "2", label: "eRAR" },
        { value: "3", label: "OR1" },
        { value: "4", label: "OR2" },
        { value: "5", label: "OR3" },
        { value: "6", label: "OR4" },
        { value: "7", label: "eOR1" },
        { value: "8", label: "eOR2" },
        { value: "9", label: "eOR3" },
        { value: "10", label: "eOR4" },
        { value: "11", label: "TT5" },
        { value: "12", label: "TT6" },
        { value: "13", label: "TP1" },
        { value: "14", label: "TP2" },
      ];
    case "meeting_with_mentor":
      return [
        { value: "11", label: "TT5" },
        { value: "12", label: "TT6" },
      ];
    case "interactive_lesson":
      return [
        { value: "3", label: "OR1" },
        { value: "4", label: "OR2" },
        { value: "5", label: "OR3" },
        { value: "6", label: "OR4" },
        { value: "13", label: "TP1" },
        { value: "14", label: "TP2" },
      ];
    default:
      return [];
  }
};
export const eRAReORProgrami = [
  { value: "2", label: "eRAR" },
  { value: "7", label: "eOR1" },
  { value: "8", label: "eOR2" },
  { value: "9", label: "eOR3" },
  { value: "10", label: "eOR4" },
];
export const academicYears = [
  { start: new Date(2021, 8, 1), end: new Date(2022, 7, 31) }, // 2021/2022
  { start: new Date(2022, 8, 1), end: new Date(2023, 7, 31) }, // 2022/2023
  { start: new Date(2023, 8, 1), end: new Date(2024, 7, 31) }, // 2023/2024
  { start: new Date(2024, 8, 1), end: new Date(2025, 7, 31) }, // 2024/2025
  { start: new Date(2025, 8, 1), end: new Date(2026, 7, 31) }, // 2025/2026
  { start: new Date(2026, 8, 1), end: new Date(2027, 7, 31) }, // 2026/2027
  // Add more academic years as needed
];
export const handleBulkPublish = async (
  interactiveLessonIds,
  workshopIds,
  meetingWithMentorIds,
  accessToken,
  startYear,
  endYear,
  setCalendarData
) => {
  try {
    const response = await bulkPublishEvents(
      interactiveLessonIds,
      workshopIds,
      meetingWithMentorIds,
      accessToken
    );
    if (response.status === 200) {
      getCalendarData(accessToken, startYear, endYear, setCalendarData);
    }
  } catch (error) {
    console.error("Error while publishing all events");
    throw error;
  }
};
