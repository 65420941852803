import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { default as React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Bio from "../../assets/img/BiografijaWhite.png";
import ProfilePhoto from "../../assets/img/blank_profile_photo.jpeg";
import groups from "../../assets/img/GrupeIcon.png";
import Modal from "../../components/Modal/Modal";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import AddUserToModal from "../polazniciPregled/components/AddUserToModal/AddUserToModal";
import ZakupiteljButton from "../polazniciPregled/components/ZakupiteljButton/ZakupiteljButton";
import { handleSuccessRemoveZakupiteljClick } from "../polazniciPregled/utils/utils";
import backToArrow from "./../polazniciPregled/assets/BackToArrow.svg";
import { getUserById } from "./api/getUserById";
import { getUserGroups } from "./api/getUserGroups";
import ProfileDetails from "./components/profileDetails.jsx";
import styles from "./djelatnikProfil.module.css";

export const LargeTextBox = styled(Box)(() => ({
  width: "100%",
  marginTop: 5,
  fontSize: 18,
}));

const DjelatnikProfil = () => {
  const { role } = useUserState();
  const { authToken } = useAuthentication();
  const [djelatnikData, setDjelatnikData] = useState({});
  const [djelatnikGrupe, setDjelatnikGrupe] = useState();
  const [isModalZakupiteljOpen, setIsModalZakupiteljOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    title: "",
    description: "",
    buttons: [],
  });
  const [successModal, setSuccessModal] = useState(false);
  const [isZakupitelj, setIsZakupitelj] = useState();
  const navigate = useNavigate();
  const userId = role;
  const { djelatnikId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserById(djelatnikId, authToken);
        const responseGrupe = await getUserGroups(djelatnikId, authToken);
        setDjelatnikData(response);
        setIsZakupitelj(response.isZakupitelj);
        setDjelatnikGrupe(responseGrupe);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (authToken) fetchData();
  }, [authToken, successModal]);

  function handleGoBackClick() {
    if (userId === "Polaznik") {
      navigate(-1);
    } else {
      navigate("/djelatnici");
    }
  }

  return (
    <>
      {isModalZakupiteljOpen && (
        <AddUserToModal
          onClose={() => setIsModalZakupiteljOpen(false)}
          setIsModalZakupiteljOpen={setIsModalZakupiteljOpen}
          setModalContent={setModalContent}
          setSuccessModal={setSuccessModal}
          pojediniPolaznikID={djelatnikId}
          title={"Dodaj polaznika u zakupitelje"}
          addPolaznikToZakupitelj={true}
          data={djelatnikData}
          setDjelatnikData={setDjelatnikData}
          setIsZakupitelj={setIsZakupitelj}
        />
      )}
      {successModal && (
        <Modal
          fullWidth={true}
          image={modalContent.image}
          title={modalContent.title}
          description={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
      <div className={styles.backToContainer} onClick={handleGoBackClick}>
        <img src={backToArrow} alt="go back" className={styles.backToArrow} />
        <h1 className={styles.backToh1}>Natrag </h1>
      </div>
      <Box
        sx={{
          width: "100%",
          height: 180,
          borderRadius: "10px",
          backgroundColor: "#EBE6DD",
          marginTop: "20px",
        }}
      ></Box>
      <img
        style={{
          marginLeft: "60px",
          position: "relative",
          zIndex: 10,
          marginTop: "-180px",
        }}
        src={
          djelatnikData?.imageUrl === "" ? ProfilePhoto : djelatnikData.imageUrl
        }
        alt="profile image"
        className={styles.profilePic}
      />
      <div className={styles.profileRow}>
        <ProfileDetails userData={djelatnikData ? djelatnikData : {}} />
        <div className={styles.rightColumn}>
          {userId === "Referada" && (
            <ZakupiteljButton
              onClick={() => setIsModalZakupiteljOpen(true)}
              isZakupitelj={isZakupitelj}
              onClickRemoveZakupitelj={() =>
                handleSuccessRemoveZakupiteljClick(
                  setModalContent,
                  setSuccessModal,
                  djelatnikId,
                  authToken,
                  setDjelatnikData,
                  setDjelatnikGrupe,
                  null,
                  setIsZakupitelj
                )
              }
            />
          )}
          {userId !== "Polaznik" && (
            <button className={styles.biografijaBtn}>
              Biografija <img alt="" src={Bio} />
            </button>
          )}
          <div className={styles.groupContent}>
            <div className={styles.groupH1}> Član grupa </div>
            <img src={groups} alt="group icon" />
          </div>
          {djelatnikGrupe && djelatnikGrupe?.groups?.length === 0 ? (
            <p className={styles.groupText}>
              Djelatnik trenutno ne pripada nijednoj grupi.
            </p>
          ) : (
            <div className={styles.groupsBadges}>
              {djelatnikGrupe &&
                djelatnikGrupe?.groups?.map((item, index) => (
                  <div className={styles.groupBadge} key={index}>
                    <p className={styles.groupBadgeText}>
                      {item !== null ? item.name : "Null group"}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default DjelatnikProfil;
