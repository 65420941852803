import axios from "axios";
import { fetchNastupnaPredavanja, fetchPresentations } from "../util";
const apiUrl = process.env.REACT_APP_API_URL;

export const deleteNastupnoPredavanje = async (
  userId,
  theoreticalId,
  accessToken,
  setPresentations,
  setNastupnaPredavanja
) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/api/user/presentation/${userId}`,
      {
        data: {
          theoreticalId: theoreticalId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      fetchPresentations(accessToken, setPresentations);
      fetchNastupnaPredavanja(accessToken, setNastupnaPredavanja);
    }
  } catch (error) {
    console.error("Error deleting nastupno predavanje", error);
    throw error;
  }
};
