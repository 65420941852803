export const handlePrev = (setActiveIndex, data) => {
  setActiveIndex((prevIndex) =>
    prevIndex === 0 ? data.length - 1 : prevIndex - 1
  );
};

export const handleNext = (setActiveIndex, data) => {
  setActiveIndex((prevIndex) =>
    prevIndex === data.length - 1 ? 0 : prevIndex + 1
  );
};
