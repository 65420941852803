import React from "react";
import "./Table.css";

function RenderTableHeader({ columnNames = [] }) {
  if (!Array.isArray(columnNames)) {
    console.error("columnNames is not an array:", columnNames);
    return null;
  }

  return (
    <tr>
      {columnNames.map((columnName, index) => (
        <th key={index} className="table">
          {columnName}
        </th>
      ))}
    </tr>
  );
}

export default RenderTableHeader;
