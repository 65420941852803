export const mojObracunKoristenjaSobeColumnNames = [
  "SOBE",
  "UKUPNO REZERVACIJA",
  "CIJENA REZERVACIJE (€)",
  "UKUPNO (€)",
  "BROJ OTKAZANIH REZERVACIJA",
  "POSTOTAK OTKAZANIH REZERVACIJA %",
];

export const potpuniObracunKoristenjaSobaColumnNames = [
  "ZAKUPITELJ",
  "TIP KORISNIKA",
  "UKUPNO (€)",
  "BROJ OTKAZANIH REZERVACIJA",
  "POSTOTAK OTKAZANIH REZERVACIJA %",
  "",
];

export const potrosnjaPoGradovima = [
  "ZAKUPITELJ",
  "TIP KORISNIKA",
  "VRSTA DOGAĐAJA",
  "SOBA",
  "DATUM ODRŽAVANJA",
  "VRIJEME POČETKA",
  "VRIJEME ZAVRŠETKA",
];

export const periodPotrosnjeGradoviColumnNames = [
  "PERIOD",
  "GRAD",
  "UKUPNO REZERVACIJA",
  "UKUPNO (€)",
  "BROJ OTKAZANIH REZERVACIJA",
  "POSTOTAK OTKAZANIH REZERVACIJA %",
];

export const totalniObracunSobaColumnNames = [
  "ZAKUPITELJ",
  "TIP KORISNIKA",
  "VRSTA DOGAĐAJA",
  "DATUM ODRŽAVANJA",
  "VRIJEME POČETKA",
  "VRIJEME ZAVRŠETKA",
];

export const periodObracunaSobeColumnNames = [
  "PERIOD",
  "SOBA",
  "UKUPNO REZERVACIJA",
  "UKUPNO (€)",
  "BROJ OTKAZANIH REZERVACIJA",
  "POSTOTAK OTKAZANIH REZERVACIJA %",
];
