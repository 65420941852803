import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftIcon from "../../../../../../assets/svg/ArrowLeftIcon";
import Checkbox from "../../../../../../components/checkbox/Checkbox";
import CustomInput from "../../../../../../components/customInput/CustomInput";
import RadioChoices from "../../../../../../components/radioChoices/RadioChoices";
import { useUserState } from "../../../../../../context/UserContext";
import { useAuthentication } from "../../../../../hooks/useAuthentication";
import Accordion from "../accordion/Accordion";
import SelectDropdown from "../selectDropdown/SelectDropdown";
import { createApplication } from "./api/createApplication";
import { getApplicationById } from "./api/getApplicationById";
import { getUsersApplications } from "./api/getUsersApplications";
import styles from "./EnrollmentForm.module.css";

const EnrollmentForm = () => {
  const [visibleForm, setVisibleForm] = useState([]);
  const [application, setApplication] = useState({});
  const navigate = useNavigate();
  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const userId = role;

  const { programApplicationId } = useParams();

  useEffect(() => {
    const fetchApplication = async () => {
      const response = await getApplicationById(
        programApplicationId,
        authToken
      );
      setApplication(response);
      const visible = [response.course];
      setVisibleForm(visible);
      const parsedApplication = JSON.parse(response.application);

      if (response.course === "OR") {
        Object.entries(parsedApplication).forEach(([key, value]) => {
          formMethodsOne.setValue(key, value);
        });
      } else if (response.course === "eOR") {
        Object.entries(parsedApplication).forEach(([key, value]) => {
          formMethodsTwo.setValue(key, value);
        });
      } else if (response.course === "TP") {
        Object.entries(parsedApplication).forEach(([key, value]) => {
          formMethodsThree.setValue(key, value);
        });
      } else {
        Object.entries(parsedApplication).forEach(([key, value]) => {
          formMethodsFour.setValue(key, value);
        });
      }
    };
    if (authToken) fetchApplication();
  }, [authToken]);

  // OR form
  const formMethodsOne = useForm({
    defaultValues: {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
      question6: "",
      check1: false,
      check2: false,
    },
    mode: "all",
  });
  // eOR form
  const formMethodsTwo = useForm({
    defaultValues: {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      choice: "",
      question5: "",
      question6: "",
      check1: false,
      check2: false,
    },
    mode: "all",
  });
  // TP form
  const formMethodsThree = useForm({
    defaultValues: {
      question1: "",
      question2: "",
      choice1: "",
      question3: "",
      question4: "",
      question5: "",
      choice2: "",
      select: "",
      question6: "",
      choice3: "",
    },
    mode: "all",
  });
  // TT form
  const formMethodsFour = useForm({
    defaultValues: {
      question1: "",
      select1: "",
      select2: "",
      question2: "",
      check: false,
    },
    mode: "all",
  });

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const response = await getUsersApplications(userData.id, authToken);
        setVisibleForm(response);
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    if (userId !== "Referada" && programApplicationId == undefined)
      if (authToken) fetchApplication();
  }, [authToken]);

  const sendApplication = async (data, form) => {
    const requestBody = {
      application: data,
      userId: userData.id,
      programId: 1,
      course: form,
      visibleForReferada: false,
    };
    try {
      const response = await createApplication(requestBody, authToken);
      console.log(response);
    } catch (error) {
      console.error("Error creating application:", error);
    }
  };

  const handleResetForm = async (formMethods) => {
    const formData = formMethods.getValues();
    const jsonString = JSON.stringify(formData);
    let form;

    if (formMethods === formMethodsOne) {
      form = "OR";
    } else if (formMethods === formMethodsTwo) {
      form = "eOR";
    } else if (formMethods === formMethodsThree) {
      form = "TP";
    } else {
      form = "TT";
    }

    try {
      await sendApplication(jsonString, form);
      formMethods.reset();
    } catch (error) {
      console.error("Error sending application:", error);
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.backButton}
        onClick={() =>
          userId === "Referada"
            ? navigate("/dokumenti/aplikacije-za-upis")
            : navigate("/dokumenti")
        }
      >
        <ArrowLeftIcon className={styles.backButtonArrow} />
        <p className={styles.backButtonText}>natrag</p>
      </div>
      {userId === "Referada" && (
        <span className={styles.userName}>
          Polaznik: {application?.User?.firstName} {application?.User?.lastName}
        </span>
      )}{" "}
      <div className={styles.formContainer}>
        <p className={styles.formTitle}>
          Moje aplikacije za upis više godine ITP-a
        </p>
        <p className={styles.formDescription}>
          Ukoliko želiš iskazati interes za upis više godine u našoj školi
          Integralne tjelesne terapije, potrebno je ispuniti aplikaciju ispod i
          zadovoljiti sve uvjete na tekućoj godini.
        </p>
        <div className={styles.form}>
          {visibleForm.map((form, index) => (
            <Accordion
              key={index}
              isOpen={false}
              title={`Aplikacija za ${form}`}
              children={
                form === "OR" ? (
                  <div>
                    <div className={styles.formInputsContainer}>
                      <p className={styles.formSectionText}>
                        Ispunjavanjem ove aplikacije izražavaš svoj interes za
                        upis 4-godišnjeg programa Osobni razvoj, kao 2. stupnja
                        i temeljnog dijela edukacije u školi Integralne tjelesne
                        psihoterapije. Škola prima ograničen broj polaznika koji
                        trebaju imati određenu spremnost i kapacitet za ovaj vid
                        rada.Na odluku o tvom prijemu u program OR utječe
                        sadržaj tvojih introspekcija, procjena tvojih voditelja
                        RAR-a o spremnosti tvog sustava za ovakav rad kao i
                        informacije iz ove aplikacije. Naglašavamo da kao škola,
                        zbog zakonskih normi kao i kontraindiciranosti u
                        edukaciju za psihoterapeute ITP-a i program Osobni
                        razvoj ne možemo primiti:
                        <br />- osobe koje su ovisnici o narkoticima i alkoholu
                        <br />- osobe sa aktivnim psihijatrijskim dijagnozama
                        i/ili osobe koje kontinuirano koriste psihofarmake
                        <br />- osobe koje su emocionalno u teškim životnim
                        fazama
                        <br />- osobe s puno psiho-emocionalne nestabilnosti
                        <br />- osobe koje su pravomoćno osuđene za neko od
                        kaznenih dijela navedenih u Zakonu o djelatnosti
                        psihoterapije Članak 6. stavak 3.
                      </p>
                      <Controller
                        control={formMethodsOne.control}
                        name="question1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question1"
                            labelText="Opiši svoje trenutno radno mjesto"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="question2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question2"
                            labelText="Navedi ako imaš dosadašnje iskustvo u savjetodavnim, psihoterapijskim ili sličnim edukacijama za rad s ljudima kao i u neposrednom stručnom radu s ljudima:"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="question3"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question3"
                            labelText="Navedi ako imaš svoja osobna iskustva u radu na sebi i psihoterapiji."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="question4"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question4"
                            labelText="Opiši kratko što je tvoja motivacija za upis OR-a."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="question5"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question5"
                            labelText="Da li već sada znaš da namjeravaš upisati trening za psihoterapeute ITP-a koji počinje na 3. godini s programom TP i nastavlja se na petoj i šestoj godini s programom TT."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="question6"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question6"
                            labelText="Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="check1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            label="Ovime potvrđujem da nemam trenutno nikakvo fizičko ili psiho-emocionalno stanje koje bi moglo biti u kontraindikaciji s programom Osobni razvoj.U slučaju da se pitaš da li je neko tvoje fizičko ili psiho-emocionalno stanje ili neki lijekovi koje uzimaš u kontraindikaciji sa upisom OR-a javi nam se na referada@ipd-center.eu kako bi dogovorili evaluacijsku terapiju s voditeljem grupe OR-a."
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="check2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            label="Ovime potvrđujem da u ovoj fazi života (ukoliko ne dođe do nekih nepredviđenih okolnosti) sam u mogućnosti financirati pohađanje programa četverogodišnji Osobni razvoj i potrebne individualne terapije"
                          />
                        )}
                      />
                    </div>
                    {userId !== "Referada" &&
                      programApplicationId === undefined && (
                        <div className={styles.formButtonsContainer}>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.cancelButton}`
                                : styles.cancelButton
                            }
                            onClick={() => {
                              if (userId !== "Referada") {
                                formMethodsFour.reset();
                                navigate(-1);
                              }
                            }}
                            disabled={true}
                          >
                            odustani
                          </div>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.sendButton}`
                                : styles.sendButton
                            }
                            onClick={() => {
                              if (userId !== "Referada")
                                handleResetForm(formMethodsOne);
                            }}
                          >
                            Pošalji prijavu
                          </div>
                        </div>
                      )}
                  </div>
                ) : form === "eOR" ? (
                  <div>
                    <div className={styles.formInputsContainer}>
                      <p className={styles.formSectionText}>
                        Ispunjavanje ove aplikacije izražavaš svoj interes za
                        upis 4-godišnjeg online programa Osobni razvoj, kao 2.
                        stupnja i temeljnog dijela edukacije u školi Integralne
                        tjelesne psihoterapije. Škola prima ograničen broj
                        polaznika koji trebaju imati određenu spremnost i
                        kapacitet za ovaj vid rada.Na odluku o tvom prijemu u
                        program e-OR utječe sadržaj tvojih introspekcija,
                        procjena tvojih voditelja RAR-a o spremnosti tvog
                        sustava za ovakav rada kao i informacije iz ove
                        aplikacije. Naglašavamo da kao škola zbog zakonskih
                        normi kao i kontraidiciranosti u edukaciju za
                        psihoterapeute ITP-a i program Osobni razvoj ne možemo
                        primati:
                        <br />- osobe koje su ovisnici o narkoticima i alkoholu
                        <br />- osobe sa aktivnim psihijatrijskim dijagnozama
                        i/ili osobe koje kontinuirano koriste psihofarmake
                        <br />- osobe koje su emocionalno teškim životnim fazama
                        <br />- osobe s puno psiho-emocionalne nestabilnosti
                        <br />- osobe koje su pravomoćno osuđene za neko od
                        kaznenih dijela navedenih u Zakonu o djelatnosti
                        psihoterapije Članak 6. stavak 3.
                        <br />
                        <br />
                        Za one koji upisuju e-OR kao dio edukacije iz ITP-a,
                        obveza je proći modul uživo 1x godišnje u Zagrebu, a
                        naša preporuka je i dolazak u Zagreb na procesne
                        intenzive uživo * u slučaju da u tvom gradu postoji OR
                        uživo, dobro promisli o svojoj odluci oko upisa online
                        e-OR jer neće biti moguće prebacivanje tijekom
                        školovanja
                      </p>
                      <Controller
                        control={formMethodsTwo.control}
                        name="question1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question1"
                            labelText="Opiši svoje trenutno radno mjesto"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="question2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question2"
                            labelText="Navedi ako imaš dosadašnje iskustvo u savjetodavnim, psihoterapijskim ili sličnim edukacijama za rad s ljudima kao i u neposrednom stručnom radu s ljudima:"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="question3"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question3"
                            labelText="Navedi ako imaš svoja osobna iskustva u radu na sebi i psihoterapiji."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="question4"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question4"
                            labelText="Opiši kratko što je tvoja motivacija za upis eOR-a."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="choice"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <RadioChoices
                            name="choice"
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            title="Da li je tvoja namjera dolaziti 1x godišnje na modul uživo u Zagreb?"
                            choices={["Da", "Ne"]}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="question5"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question5"
                            labelText="Da li već sada znaš da namjeravaš upisati trening za psihoterapeute ITP-a koji počinje na 3. godini s programom TP i nastavlja se na petoj i šestoj godini s programom TT."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="question6"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question6"
                            labelText="Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="check1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            name="check1"
                            label="Ovime potvrđujem da nemam trenutno nikakvo fizičko ili psiho-emocionalno stanje koje bi moglo biti u kontraindikaciji s programom Osobni razvoj.U slučaju da se pitaš da li je neko tvoje fizičko ili psiho-emocionalno stanje ili neki lijekovi koje uzimaš u kontraindikaciji sa upisom OR-a javi nam se na referada@ipd-center.eu kako bi dogovorili evaluacijsku terapiju s voditeljem grupe OR-a."
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="check2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            name="check2"
                            label="Ovime potvrđujem da u ovoj fazi života (ukoliko ne dođe do nekih nepredviđenih okolnosti) sam u mogućnosti financirati pohađanje programa četverogodišnji Osobni razvoj i potrebne individualne terapije"
                          />
                        )}
                      />
                    </div>
                    {userId !== "Referada" &&
                      programApplicationId === undefined && (
                        <div className={styles.formButtonsContainer}>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.cancelButton}`
                                : styles.cancelButton
                            }
                            onClick={() => {
                              if (userId !== "Referada") {
                                formMethodsFour.reset();
                                navigate(-1);
                              }
                            }}
                            disabled={true}
                          >
                            odustani
                          </div>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.sendButton}`
                                : styles.sendButton
                            }
                            onClick={() => {
                              if (userId !== "Referada")
                                handleResetForm(formMethodsTwo);
                            }}
                          >
                            Pošalji prijavu
                          </div>
                        </div>
                      )}
                  </div>
                ) : form === "TT" ? (
                  <div>
                    <div className={styles.formInputsContainer}>
                      {userId !== "Referada" ||
                        (programApplicationId === undefined && (
                          <p className={styles.formSectionText}>
                            Pozdrav,u svrhu prijave za upis u Napretni
                            terapeutski trening &#40;TT5&#41; molimo te da
                            popuniš obrazac u nastavku.
                            <br />
                            <br />
                          </p>
                        ))}
                      <Controller
                        control={formMethodsFour.control}
                        name="question1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question1"
                            labelText="Trenutno zanimanje"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsFour.control}
                        name="select1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectDropdown
                            name="select1"
                            title="Navedi ime svog mentora:"
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            onBlur={onBlur}
                            value={value}
                            initialOption="Odaberi"
                            options={["Tomislav Kuljiš", "Marija Matić"]}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsFour.control}
                        name="select2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectDropdown
                            name="select2"
                            title="Navedi ime svog mentora:"
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            onBlur={onBlur}
                            value={value}
                            initialOption="Odaberi"
                            options={["Tomislav Kuljiš", "Marija Matić"]}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsFour.control}
                        name="question2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question2"
                            labelText="Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsFour.control}
                        name="check"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            name="check"
                            label="Potvrđujem da sam upoznat sa Zakonom o djelatnosti psihoterapije u Hrvatskoj koji kaže: Djelatnost psihoterapije obavlja ovlašteni psihoterapeut ili ovlašteni savjetodavni terapeut. Za status savjetodavnog terapeuta potrebno je imati minimalno status Sveučilišnog prvostupnika (Baccalaureus) stručnog prvostupnik ili VŠS (prije Bologne) i uz to imati završenu Propedeutiku psihoterapije. Za status psihoterapeuta neki od navedenih fakulteta (medicinske znanosti, kliničke medicinske znanosti, javnog zdravstva i zdravstvene zaštite psihologije, socijalne djelatnosti, edukacijsko-rehabilitacijske znanosti, pedagogije i logopedije). U Srbiji i Sloveniji djelatnost psihoterapije još nije regulirana zakonski, ali je za očekivati da će u narednim godinama zakoni izgledati slično kao u Hrvatskoj."
                          />
                        )}
                      />
                    </div>
                    {userId !== "Referada" &&
                      programApplicationId === undefined && (
                        <div className={styles.formButtonsContainer}>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.cancelButton}`
                                : styles.cancelButton
                            }
                            onClick={() => {
                              if (userId !== "Referada") {
                                formMethodsFour.reset();
                                navigate(-1);
                              }
                            }}
                            disabled={true}
                          >
                            odustani
                          </div>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.sendButton}`
                                : styles.sendButton
                            }
                            onClick={() => {
                              if (userId !== "Referada")
                                handleResetForm(formMethodsFour);
                            }}
                          >
                            Pošalji prijavu
                          </div>
                        </div>
                      )}
                  </div>
                ) : form === "TP" ? (
                  <div>
                    <div className={styles.formInputsContainer}>
                      <p className={styles.formSectionText}>
                        Pozdrav,u svrhu prijave za upis u Terapeutski praktikum
                        1 &#40;TP1&#41; molimo te da popuniš obrazac u nastavku.
                        <br />
                        <br />
                        Termine pohađanja TPa biraš u nastavku obrasca a nakon
                        što prikupimo sve prijave, javit ćemo ti se s potvrdom
                        tvog termina i upisa TP1.
                        <br />
                        <br />
                        Predavanja za sve grupe TP1 su:
                        <br />
                        15.02.2023.
                        <br />
                        14.03.2023.
                        <br />
                        Vrijeme održavanja: 18h-21:15h
                        <br />
                        <br />
                        Ukoliko ćeš dobiti suglasnost mentora nakon naknadno
                        ispunjenih introspekcija do 8.2., uredi svoje odgovore
                        unutar aplikacije.
                      </p>
                      <Controller
                        control={formMethodsThree.control}
                        name="question1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question1"
                            labelText="Navedi ako imaš dodane edukacije iz područja psihoterapije ili rada na sebi."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="question2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question2"
                            labelText="Imaš li riješene sve financijske obaveze prema školi od 1. i 2. godine (ili sve 4. godine ako si završio/la OR) što je preduvjet za upis TP-a?"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="choice1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <RadioChoices
                              name="choice1"
                              onBlur={onBlur}
                              onChange={
                                userId !== "Referada" &&
                                programApplicationId === undefined &&
                                onChange
                              }
                              value={value}
                              title="Imaš li ispunjene sve introspekcije do sada?"
                              choices={[
                                "Da",
                                "Ne",
                                "NE, ali potvrđujem da ću ih ispuniti do 08.02. i javiti svom mentoru i naknadno dobiti suglasnost",
                              ]}
                            />
                          </>
                        )}
                      />

                      <Controller
                        control={formMethodsThree.control}
                        name="question3"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question3"
                            labelText="Navedi broj programskih terapija koje imaš do najkasnije 08.02. 2023 (min je 25 terapija) i imena terapeuta"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="question4"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question4"
                            labelText="Opiši zašto smatraš da si spreman/na upisati TP kao novi stadij treninga zaterapeuta i kako sada vidiš svoje trenutne kapacitete da budeš na TP-u i što misliš sada na čemu trebaš raditi kod sebe kako bi poslije mogao/la raditi sljudima:"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="question5"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question5"
                            labelText="Opiši zašto želiš upisati TP, što je tvoja motivacija"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="choice2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <RadioChoices
                            name="choice2"
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            title="Označi u kojem terminu bi želio/željela pohađati radionice TPa (napomena: odabirom termina, ne garantiramo da ćeš u tom terminu pohađati TP. Nakon što završe prijave i oforme se grupe, potvrdit ćemo ti s kojom grupom ćete pohađati TP)"
                            choices={[
                              "1. GRUPA: radnim danom 18h-21:15h  (ZG uživo + BG zoom u prostoru) - 22.2./1.3./22.3./29.3./26.4./3.5.",
                              "2. GRUPA: vikendima (LJ+ZG - uživo u ZG subotama) - 18.02./26.03./29.04.",
                              "3. GRUPA: eOR subota i nedjelja (za eOR polaznike) - 11.-12.03./22.-23.04.",
                              "Ne odgovara mi niti jedna alternativna grupa",
                            ]}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="select"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectDropdown
                            name="select"
                            title="Navedi ime svog mentora:"
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            onBlur={onBlur}
                            value={value}
                            initialOption="Odaberi"
                            options={["Tomislav Kuljiš", "Marija Matić"]}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="question6"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question6"
                            labelText="Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="choice3"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <RadioChoices
                            name="choice3"
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            title="Plaćanje TP1"
                            choices={[
                              "U gotovini odjednom na blagajni centra  na 1. modulu/radionici",
                              "U gotovini na dvije rate: 2. rata za vikend grupe na 2. modulu, a tjedne grupe na 4. radionici",
                              "Ostalo",
                            ]}
                          />
                        )}
                      />
                    </div>
                    {userId !== "Referada" &&
                      programApplicationId === undefined && (
                        <div className={styles.formButtonsContainer}>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.cancelButton}`
                                : styles.cancelButton
                            }
                            onClick={() => {
                              if (
                                userId !== "Referada" &&
                                programApplicationId === undefined
                              ) {
                                formMethodsFour.reset();
                                navigate(-1);
                              }
                            }}
                            disabled={true}
                          >
                            odustani
                          </div>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.sendButton}`
                                : styles.sendButton
                            }
                            onClick={() => {
                              if (userId !== "Referada")
                                handleResetForm(formMethodsThree);
                            }}
                          >
                            Pošalji prijavu
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  ""
                )
              }
            />
          ))}
          {visibleForm.length === 0 && (
            <div
              style={{
                marginTop: 30,
                boxShadow: "4px 4px 64px 0px #5f6d7e1a",
                border: "1px solid #e2e2e2",
                borderRadius: 8,
              }}
            >
              <p
                className={styles.formDescription}
                style={{ fontSize: 16, textAlign: "center", width: "98%" }}
              >
                Trenutno nemate dostupnih aplikacija
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default EnrollmentForm;
