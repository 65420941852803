const ArrowLeftIcon = (props) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.762 25.093a.989.989 0 01-.707-.293l-8.093-8.093a1.006 1.006 0 010-1.414L12.055 7.2a1.006 1.006 0 011.414 0 1.006 1.006 0 010 1.413L6.082 16l7.387 7.387a1.006 1.006 0 010 1.413.967.967 0 01-.707.293z"
        fill="current"
      />
      <path
        d="M27.334 17H4.894c-.546 0-1-.453-1-1 0-.547.454-1 1-1h22.44c.547 0 1 .453 1 1 0 .547-.453 1-1 1z"
        fill="current"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
