import React from "react";
import { useNavigate } from "react-router-dom";
import CrossBtn from "../../../assets/img/crossBtn.png";
import RenderTableData from "./RenderTableData";
import RenderTableHeader from "./RenderTableHeader";
import "./TablePolaznici.css";
function TablePolaznici({ columnNames = [], data = [] }) {
  const navigate = useNavigate();

  return (
    <>
      <table className="table-polaznici">
        <thead>
          <RenderTableHeader columnNames={columnNames} />
        </thead>
        <tbody>
          <RenderTableData data={data} />
        </tbody>
      </table>
      <div className="table-polaznici-btn-container">
        <button
          className="table-polaznici-add-btn "
          onClick={() =>
            navigate("/dodaj-polaznika-u-grupu", {
              state: {
                isCreateEvent: true,
                selectedAttendeesData: data,
              },
            })
          }
        >
          Dodaj polaznika <img src={CrossBtn} className="cross-btn-icon" />
        </button>
      </div>
    </>
  );
}

export default TablePolaznici;
