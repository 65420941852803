import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getAllCities = async (accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/cities`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = response.data.map((city) => ({
      value: city.id,
      label: city.cityName,
    }));
    return data;
  } catch (error) {
    console.error("Error fetching cities:", error);
    return [];
  }
};
