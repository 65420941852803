export const handleChangeCheckbox = async (
  setLoadingInteractiveRows,
  setLoadingModulRows,
  addEvidentionEntry,
  deleteEvidentionEntry,
  authToken,
  refreshData,
  e,
  userId,
  eventId,
  eventType,
  moduleIndex
) => {
  const data = {
    userId,
    eventId,
    eventType: eventType,
  };
  if (eventType === "interactive_lesson") {
    setLoadingInteractiveRows((prev) => ({ ...prev, [userId]: true }));
  }
  if (eventType === "regular_workshop") {
    setLoadingModulRows((prev) => ({
      ...prev,
      [`${userId}-${moduleIndex}`]: true,
    }));
  }
  try {
    if (e.target.checked) {
      await addEvidentionEntry(data, authToken);
    } else {
      await deleteEvidentionEntry(data, authToken);
    }
    await refreshData();
  } catch (error) {
    console.error("Error updating attendance", error);
  } finally {
    if (eventType === "interactive_lesson") {
      setLoadingInteractiveRows((prev) => ({ ...prev, [userId]: false }));
    }
    if (eventType === "regular_workshop") {
      setLoadingModulRows((prev) => ({
        ...prev,
        [`${userId}-${moduleIndex}`]: false,
      }));
    }
  }
};
