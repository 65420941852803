import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const archiveGroup = async (groupId, accessToken) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/groups/archive`,
      {
        groupIds: [groupId],
        isArchived: true,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error archiving group:", error);
    throw error;
  }
};
