import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import ExportIcon from "../../../../../../assets/svg/ExportIcon";
import UserIcon from "../../../../../../assets/svg/UserIcon";
import styles from "./Table.module.css";

const Table = ({ dataLive, dataOnline, exportData, selectedProgram }) => {
  const navigate = useNavigate();

  const modifyData = (data) => {
    return data.map((item) => {
      const applicationData = JSON.parse(item.application);
      const userProperties = ["firstName", "lastName"];

      const user = userProperties.map((prop) => item.User[prop]).join(" ");
      const flattenedApplication = Object.values(applicationData).join(", ");

      const modifiedItem = {
        ...item,
        User: user,
        application: flattenedApplication,
      };

      return modifiedItem;
    });
  };

  const modifiedData = modifyData(dataLive);
  const modifiedDataOnline = modifyData(dataOnline);

  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeaderRow}>
              <th className={styles.nameSurnameHeaderCell}>
                <p className={styles.headerCellLabel}>Ime i prezime</p>
              </th>
              <th className={styles.linkHeaderCell}>
                <p className={styles.headerCellLabel}>
                  Aplikacije za{" "}
                  {selectedProgram === "3,4,5,6"
                    ? "OR"
                    : selectedProgram === "7,8,9,10"
                    ? "eOR"
                    : selectedProgram === "11,12"
                    ? "TP1"
                    : selectedProgram === "13,14"
                    ? "TT"
                    : "sve"}
                </p>
              </th>
              {selectedProgram === "13,14" && (
                <>
                  <th className={styles.descriptionHeaderCell}>
                    <p className={styles.headerCellLabel}>
                      Suglasnost Supervizora 1
                    </p>
                  </th>
                  <th className={styles.descriptionHeaderCell}>
                    <p className={styles.headerCellLabel}>
                      Suglasnost Supervizora 2
                    </p>
                  </th>
                </>
              )}
              {selectedProgram === "3,4,5,6" && (
                <th className={styles.descriptionHeaderCell}>
                  <p className={styles.headerCellLabel}>Evaluacija mentora</p>
                </th>
              )}
              {selectedProgram === "11,12" && (
                <th className={styles.descriptionHeaderCell}>
                  <p className={styles.headerCellLabel}>Preporuka mentora</p>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {dataLive?.map((item, index) => (
              <tr className={styles.tableDataRow} key={index}>
                <td
                  className={styles.nameSurnameCell}
                  onClick={() => navigate(`/pojedini-polaznik/${item.User.id}`)}
                >
                  {item.User.imageUrl === "" ? (
                    <div className={styles.emptyProfilePhoto}>
                      <UserIcon className={styles.profilePhotoIcon} />
                    </div>
                  ) : (
                    <img
                      alt="user"
                      src={item.User.imageUrl}
                      className={styles.userPhoto}
                    />
                  )}
                  <p
                    className={styles.nameSurnameText}
                    onClick={() =>
                      navigate(`/pojedini-polaznik/${item.User.id}`)
                    }
                  >
                    {item.User.firstName} {item.User.lastName}
                  </p>
                </td>
                <td className={styles.tableLinkCell}>
                  <p
                    onClick={() =>
                      navigate(
                        `/dokumenti/aplikacije-za-upis/anketa/${item.id}`
                      )
                    }
                    className={styles.tableLinkText}
                  >
                    Link na anketu
                  </p>
                </td>
                {selectedProgram === "13,14" && (
                  <>
                    <td className={styles.tableSupervisorCell}>
                      <p className={styles.tableSupervisorText}>
                        {!item.supervisor1 ? "------" : item.supervisor1}
                      </p>
                    </td>
                    <td className={styles.tableSupervisorCell}>
                      <p className={styles.tableSupervisorText}>
                        {!item.supervisor2 ? "------" : item.supervisor2}
                      </p>
                    </td>
                  </>
                )}
                {selectedProgram === "3,4,5,6" && (
                  <td className={styles.tableSupervisorCell}>
                    <p className={styles.tableSupervisorText}>
                      {!item.mentorReview || item.mentorReview === null
                        ? "------"
                        : item.mentorReview}
                    </p>
                  </td>
                )}
                {selectedProgram === "11,12" && (
                  <td className={styles.tableSupervisorCell}>
                    <p className={styles.tableSupervisorText}>
                      {!item.mentorReview || item.mentorReview === null
                        ? "------"
                        : item.mentorReview}
                    </p>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CSVLink
        data={modifiedData}
        target="_blank"
        filename={"AplikacijeZaUpis.csv"}
        className={styles.exportButton}
      >
        <p className={styles.exportButtonText}>Export tablice s odgovorima</p>
        <ExportIcon className={styles.exportButtonIcon} />
      </CSVLink>
      <div
        className={styles.tableContainer}
        style={{
          marginTop: "50px",
          display: selectedProgram === "3,4,5,6" ? "block" : "none",
        }}
      >
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeaderRow}>
              <th className={styles.nameSurnameHeaderCell}>
                <p className={styles.headerCellLabel}>Ime i prezime</p>
              </th>
              <th className={styles.linkHeaderCell}>
                <p className={styles.headerCellLabel}>Aplikacije za eOR</p>
              </th>
              {selectedProgram === "3,4,5,6" && (
                <th className={styles.descriptionHeaderCell}>
                  <p className={styles.headerCellLabel}>Evaluacija mentora</p>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {dataOnline?.map((item, index) => (
              <tr className={styles.tableDataRow} key={index}>
                <td
                  className={styles.nameSurnameCell}
                  onClick={() => navigate(`/pojedini-polaznik/${item.User.id}`)}
                >
                  {item.User.imageUrl === "" ? (
                    <div className={styles.emptyProfilePhoto}>
                      <UserIcon className={styles.profilePhotoIcon} />
                    </div>
                  ) : (
                    <img
                      alt="user"
                      src={item.User.imageUrl}
                      className={styles.userPhoto}
                    />
                  )}
                  <p
                    className={styles.nameSurnameText}
                    onClick={() =>
                      navigate(`/pojedini-polaznik/${item.User.id}`)
                    }
                  >
                    {item.User.firstName} {item.User.lastName}
                  </p>
                </td>
                <td className={styles.tableLinkCell}>
                  <p
                    onClick={() =>
                      navigate(
                        `/dokumenti/aplikacije-za-upis/anketa/${item.id}`
                      )
                    }
                    className={styles.tableLinkText}
                  >
                    Link na anketu
                  </p>
                </td>
                <td className={styles.tableSupervisorCell}>
                  <p className={styles.tableSupervisorText}>
                    {!item.mentorReview || item.mentorReview === null
                      ? "------"
                      : item.mentorReview}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedProgram === "3,4,5,6" && (
        <CSVLink
          data={modifiedDataOnline}
          target="_blank"
          filename={"AplikacijeZaUpis.csv"}
          className={styles.exportButton}
        >
          <p className={styles.exportButtonText}>Export tablice s odgovorima</p>
          <ExportIcon className={styles.exportButtonIcon} />
        </CSVLink>
      )}
    </div>
  );
};
export default Table;
