import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const handleDodajDjelatnika = async (
  userId,
  selectedOption,
  selectedTerapeutiOption,
  accessToken,
  navigate
) => {
  try {
    if (selectedOption && selectedTerapeutiOption) {
      const response = await axios.put(
        `${apiUrl}/api/user/${userId}`,
        {
          roleId: selectedOption,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const therapistUser = await axios.get(
        `${apiUrl}/api/therapistUser/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      let therapistResponse;
      if (therapistUser.data) {
        therapistResponse = await axios.put(
          `${apiUrl}/api/therapistUser?userId=${userId}`,
          {
            therapistRole: selectedTerapeutiOption,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      } else {
        therapistResponse = await axios.post(
          `${apiUrl}/api/therapistUser`,
          {
            userId,
            therapistRole: selectedTerapeutiOption,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      }
      if (therapistResponse.status !== 200) {
        throw new Error(`HTTP error! Status: ${therapistResponse.status}`);
      }
      if (therapistResponse.status === 200) {
        navigate("/djelatnici");
      }
    } else if (selectedOption && selectedTerapeutiOption === null) {
      const response = await axios.put(
        `${apiUrl}/api/user/${userId}`,
        {
          roleId: selectedOption,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        navigate("/djelatnici");
      }
    } else if (selectedOption === null && selectedTerapeutiOption) {
      const therapistUser = await axios.get(
        `${apiUrl}/api/therapistUser/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      let therapistResponse;
      if (therapistUser.data) {
        therapistResponse = await axios.put(
          `${apiUrl}/api/therapistUser?userId=${userId}`,
          {
            therapistRole: selectedTerapeutiOption,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      } else {
        therapistResponse = await axios.post(
          `${apiUrl}/api/therapistUser`,
          {
            userId,
            therapistRole: selectedTerapeutiOption,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      }
      if (therapistResponse.status !== 200) {
        throw new Error(`HTTP error! Status: ${therapistResponse.status}`);
      } else {
        navigate("/djelatnici");
      }
    } else {
      console.log("Something went wrong!");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
