// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dodajDjelatnika_radioContainer__9cCsq,\n.dodajDjelatnika_radioLabel__gLHHW {\n  display: flex;\n  align-items: center;\n}\n.dodajDjelatnika_radioLabel__gLHHW {\n  margin-right: 20px;\n}\n.dodajDjelatnika_label__Vrk37 {\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #4d4d4d;\n  cursor: pointer;\n}\n.dodajDjelatnika_customRadio__fX3et {\n  color: #e2e2e2;\n}\n.dodajDjelatnika_customRadioChecked__IAwgD {\n  color: #cb9c2d !important;\n}\n", "",{"version":3,"sources":["webpack://./src/scenes/IPDTeam/DodajDjelatnika/dodajDjelatnika.module.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".radioContainer,\n.radioLabel {\n  display: flex;\n  align-items: center;\n}\n.radioLabel {\n  margin-right: 20px;\n}\n.label {\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #4d4d4d;\n  cursor: pointer;\n}\n.customRadio {\n  color: #e2e2e2;\n}\n.customRadioChecked {\n  color: #cb9c2d !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioContainer": "dodajDjelatnika_radioContainer__9cCsq",
	"radioLabel": "dodajDjelatnika_radioLabel__gLHHW",
	"label": "dodajDjelatnika_label__Vrk37",
	"customRadio": "dodajDjelatnika_customRadio__fX3et",
	"customRadioChecked": "dodajDjelatnika_customRadioChecked__IAwgD"
};
export default ___CSS_LOADER_EXPORT___;
