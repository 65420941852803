import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getPregledPotrošnjePoGradovima = async (
  accessToken,
  cityId,
  startDate,
  endDate,
  setPregledPotrošnjePoGradovima
) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Both startDate and endDate are required.");
    }
    const response = await axios.get(
      `${apiUrl}/api/UserStatisticsByCity/${cityId}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const resData = response.data;
    setPregledPotrošnjePoGradovima(resData);
  } catch (error) {
    console.error("Error fetching pregled potrošnje po gradovima:", error);
    throw error;
  }
};
