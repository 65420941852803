import Help from "../../assets/img/Help.png";
import Success from "../../assets/img/Success.png";
import { arhivirajDjelatnika } from "./API";

export const handleArhivirajDjelatnike = async (
  selectedUserUuid,
  accessToken,
  setSelectedUserUuid,
  navigate
) => {
  try {
    const response = await arhivirajDjelatnika(selectedUserUuid, accessToken);
    if (response.status === 200) {
      setSelectedUserUuid([]);
    }
  } catch (error) {
    console.error("Error in action:", error);
  }
};
export const handleOpenModal = (
  selectedUserUuid,
  setModalContent,
  accessToken,
  setShowModal,
  setSelectedUserUuid,
  navigate
) => {
  if (selectedUserUuid.length > 1) {
    setModalContent({
      image: Help,
      title: "Jeste li sigurni da želite arhivirati polaznike?",
      description:
        "Kada arhivirate polaznike, više ih nećete moći uređivati, već samo pregledavati.",
      buttons: [
        {
          label: "Odustani",
          action: () => setShowModal(false),
          className: "modal-btn-deletePage modal-btn-discontinue-deletePage",
        },
        {
          label: "Svejedno arhiviraj",
          action: async () =>
            await handleArhivirajDjelatnike(
              selectedUserUuid,
              accessToken,
              setSelectedUserUuid,
              navigate
            )
              .then(() => {
                setModalContent({
                  image: Success,
                  title: "Polaznik uspješno arhiviran.",
                  description:
                    "Kada arhivirate polaznike, više ih nećete moći uređivati, već samo pregledavati.",
                  buttons: [
                    {
                      label: "Ok",
                      action: () => {
                        setShowModal(false);
                        navigate("/polaznici");
                      },
                      className: "modal-btn-success",
                    },
                  ],
                });
              })
              .catch(() => {
                setModalContent({
                  image: Help,
                  title: "Polaznik nije uspješno arhiviran.",
                  description: "Dogodila se greška pri arhiviranju korisnika",
                  buttons: [
                    {
                      label: "Ok",
                      action: () => {
                        setShowModal(false);
                      },
                      className: "modal-btn-success",
                    },
                  ],
                });
              }),
          className: "modal-btn-deletePage modal-btn-delete-deletePage",
        },
      ],
    });
    setShowModal(true);
  } else {
    setModalContent({
      image: Help,
      title: "Jeste li sigurni da želite arhivirati polaznika?",
      description:
        "Kada arhivirate polaznika, više ga nećete moći uređivati, već samo pregledavati.",
      buttons: [
        {
          label: "Odustani",
          action: () => setShowModal(false),
          className: "modal-btn-deletePage modal-btn-discontinue-deletePage",
        },
        {
          label: "Svejedno arhiviraj",
          action: async () => {
            await handleArhivirajDjelatnike(
              selectedUserUuid,
              accessToken,
              setSelectedUserUuid,
              navigate
            );
            navigate("/polaznici");
          },
          className: "modal-btn-deletePage modal-btn-delete-deletePage",
        },
      ],
    });
  }
  setShowModal(true);
};
