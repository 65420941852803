import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../../firebase";
export function useAuthentication() {
  const [user, setUser] = useState();
  const [authToken, setAuthToken] = useState("");
  useEffect(() => {
    const unsubscribeFromAuthStateChanged = onAuthStateChanged(
      auth,
      async (user) => {
        if (user) {
          // User is signed in.
          setUser(user);
          const time = localStorage.getItem("sessionTime");
          // Refresh the user token and update the auth token state
          const refreshedToken = await refreshUserToken(user);
          localStorage.setItem("authToken", refreshedToken);
          const date = new Date();
          if (!time) {
            localStorage.setItem(
              "sessionTime",
              `${date.setHours(date.getHours() + 3)}`
            );
          }
          setAuthToken(refreshedToken);
        } else {
          // User is signed out
          setUser(undefined);
          setAuthToken("");
          localStorage.removeItem("authToken");
        }
      }
    );
    return unsubscribeFromAuthStateChanged;
  }, []);
  // Function to refresh the user token
  const refreshUserToken = async (user) => {
    try {
      const refreshToken = user ? await user.getIdToken(true) : "";
      localStorage.setItem("authToken", refreshToken);
      setAuthToken(refreshToken);
      return refreshToken;
    } catch (error) {
      // Handle the error if token refresh fails
      console.log("Failed to refresh user token:", error);
      return "";
    }
  };
  return {
    user,
    setUser,
    authToken,
    refreshUserToken,
  };
}
