const HelpIcon = (props) => {
  return (
    <svg
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={13} cy={13} r={12} stroke="current" strokeWidth={1.5} />
      <circle cx={13} cy={19} r={1} fill="current" />
      <circle cx={13} cy={19} r={1} stroke="current" />
      <path
        d="M13 17v-.857c0-.714.357-1.381.951-1.777l.599-.4a3.257 3.257 0 001.45-2.71V11a3 3 0 00-3-3v0a3 3 0 00-3 3v0"
        stroke="current"
        strokeWidth={1.5}
      />
    </svg>
  );
};

export default HelpIcon;
