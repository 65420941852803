import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getAllPolaznici = async (
  page,
  limit,
  accessToken,
  allRoles,
  city,
  programId,
  groupId,
  fwId,
  intId,
  activityId,
  isNoGroupsClicked
) => {
  try {
    let urlWithoutParams = `${apiUrl}/api/usersByRole/${
      allRoles ? "99" : "2"
    }?page=${page}&limit=${limit}&noGroups=${isNoGroupsClicked}`;
    const paramArray = [];
    if (city !== null) {
      paramArray.push(`city=${city}`);
    }
    if (programId !== null) {
      paramArray.push(`programId=${programId}`);
    }
    if (groupId !== null) {
      paramArray.push(`groupId=${groupId}`);
    }
    if (fwId !== null) {
      paramArray.push(`fwId=${fwId}`);
    }
    if (intId !== null) {
      paramArray.push(`intId=${intId}`);
    }
    if (activityId !== null) {
      paramArray.push(`activityId=${activityId}`);
    }
    if (paramArray.length > 0) {
      urlWithoutParams += `&${paramArray.join("&")}`;
    }
    const response = await axios.get(urlWithoutParams, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
