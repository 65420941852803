export const polaznikRARDiplomePotvrde = [
  {
    programi: [
      {
        isRARPolozen: true,
      },
    ],
  },
];

export const polaznikOR1DiplomePotvrde = [
  {
    programi: [
      {
        isRARPolozen: true,
        isORPolozen: true,
        isTPPolozen: false,
      },
    ],
    isPrakticarDiplomaReady: false,
  },
];
export const polaznikTT5DiplomePotvrde = [
  {
    programi: [
      {
        isRARPolozen: true,
        isORPolozen: false,
        isTPPolozen: false,
        isTTPolozen: false,
      },
    ],
    isPrakticarDiplomaReady: true,
    isTerapeutDiplomaReady: false,
  },
];
