import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Success from "../../assets/img/Success.png";
import Question from "../../assets/svg/ModalQuestionIcon.svg";
import Modal from "../../components/Modal/Modal.jsx";
import { useAuthentication } from "../hooks/useAuthentication";
import { createDbUser } from "./api/createDbUser";
import styles from "./DodajPolaznika.module.css";
import { Roles } from "./mockData";

const Form = () => {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExistModalOpen, setIsExistModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    roleId: 0,
    firstName: "",
    lastName: "",
  });
  const successButtons = [
    {
      label: "Zatvori",
      action: () => {
        setIsModalOpen(false);
        navigate("/polaznici");
      },
      className: "modal-btn-success",
    },
  ];

  const existsButtons = [
    {
      label: "Zatvori",
      action: () => {
        setIsExistModalOpen(false);
        setFormData({
          email: "",
          roleId: 0,
          firstName: "",
          lastName: "",
        });
      },
      className: "modal-btn-success",
    },
  ];

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
      color: "#828d9a",
      height: "46px",
      width: "270px",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      borderRadius: "8px",
      border: "1px solid #D1D9E2 !important",
      boxShadow: "4px 4px 64px 0px #5f6d7e1a",
      cursor: "pointer",
      "&:focus": {
        border: "0 !important",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#5F6D7EC7",
        fontWeight: "500",
        fontSize: "16px",
        fontFamily: "Inter",
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
        ":hover": {
          backgroundColor: data.isDisabled ? "#FFFFFF" : "#F2F2F2",
        },
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      color: "#5F6D7E",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "22px",
      boxShadow: "4px 4px 64px 0px #5f6d7e1a",
    }),
  };

  const isFormValid = () => {
    return (
      formData.email !== "" &&
      formData.firstName !== "" &&
      formData.roleId !== 0 &&
      formData.lastName !== ""
    );
  };

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      const postData = {
        bio: "",
        therapyType: "",
        firstName: formData.firstName,
        lastName: formData.lastName,
        dateOfBirth: "2024-07-07",
        gender: "",
        city: "",
        address: "",
        country: "",
        programCity: "",
        qualification: "",
        title: "",
        OIB: "",
        phoneNumber: "",
        emailAddress: formData.email,
        isZakupitelj: false,
        firebaseId: "",
        roleId: formData.roleId,
      };

      const response = await createDbUser(postData, authToken);
      if (response?.response?.status === 409) {
        setIsExistModalOpen(true);
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formContainer}>
        <div className={styles.formFields}>
          <div className={styles.formColumn}>
            <div className={styles.formGroup}>
              <label className={styles.Label} htmlFor="firstName">
                Ime
              </label>
              <input
                type="firstName"
                id="firstName"
                className={styles.inputField}
                placeholder="Upiši"
                value={formData.firstName}
                onChange={(e) => handleInputChange("firstName", e.target.value)}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.Label} htmlFor="email">
                Email adresa
              </label>
              <input
                type="email"
                id="email"
                className={styles.inputField}
                placeholder="Upiši"
                value={formData.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formGroup}>
              <label className={styles.Label} htmlFor="lastName">
                Prezime
              </label>
              <input
                type="lastName"
                id="lastName"
                className={styles.inputField}
                placeholder="Upiši"
                value={formData.lastName}
                onChange={(e) => handleInputChange("lastName", e.target.value)}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.Label} htmlFor="roleId">
                Uloga (rola) korisnika
              </label>
              <Select
                placeholder="Odaberi"
                options={Roles}
                styles={selectStyles}
                isSearchable={false}
                components={{ IndicatorSeparator: () => null }}
                value={Roles.find((option) => option.value === formData.roleId)}
                onChange={(selectedOption) =>
                  handleInputChange("roleId", selectedOption.value)
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.formButtons}>
          <button
            type="button"
            className={styles.cancelButton}
            onClick={() => navigate("/polaznici")}
          >
            Odustani
          </button>
          <button
            type="submit"
            className={`${styles.submitButton} ${
              !isFormValid() ? styles.disabledButton : ""
            }`}
            disabled={!isFormValid()}
          >
            Dodaj polaznika
          </button>
        </div>
        {isModalOpen && (
          <Modal
            image={Success}
            description="Korisnik uspješno dodan!"
            buttons={successButtons}
          />
        )}
        {isExistModalOpen && (
          <Modal
            image={Question}
            description="Korisnik kojeg ste pokušali dodati već postoji."
            buttons={existsButtons}
          />
        )}
      </div>
    </form>
  );
};

export default Form;
