import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const deleteTherapist = async (userId, accessToken) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/api/therapistUser?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
    }
  } catch (error) {
    console.error("Error updating therapist profile data", error);
    throw error;
  }
};
