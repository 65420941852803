import { styled } from "@mui/system";
import ReactDatePicker from "react-datepicker";
import ReactSelect from "react-select";
export const MultiSelect = styled(ReactSelect)(() => ({
  borderRadius: 8,
  textAlign: "left",
  color: "#5F6D7E",
  boxShadow: "4px 4px 64px 0px #5F6D7E1A",
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.6,
  cursor: "pointer",
  letterSpacing: "-0.001em",
  width: "100%",
  backgroundColor: "white",
}));
export const ImageButton = styled(`button`)(({ selectedImage }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 155,
  width: 213,
  position: "absolute",
  left: 56,
  bottom: "-26px",
  border: "8px solid white",
  borderRadius: 16,
  backgroundColor: "#F3F2F2",
  "&:hover": {
    border: "8px solid #F3F2F2",
    cursor: "pointer",
  },
  backgroundImage: selectedImage ? `url(${selectedImage})` : "none",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));
export const DatePicker = styled(ReactDatePicker)(() => ({
  borderRadius: 8,
  textAlign: "left",
  color: "#5F6D7E",
  boxShadow: "4px 4px 64px 0px #5F6D7E1A",
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.6,
  cursor: "pointer",
  letterSpacing: "-0.001em",
  width: "104%",
  backgroundColor: "white",
}));
