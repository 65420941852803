import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Plus from "../../assets/img/AddIcon.png";
import Logo from "../../assets/img/EksterniLogo.png";
import Placeholder from "../../assets/img/ProgramBackground.png";
import styles from "./EksterniLink.module.css";
import Form from "./Form/Form";

function EksterniLink() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const [userData, setUserData] = useState({
    imageUrl: "",
    firstName: "",
    lastName: "",
    gender: "",
    city: "",
    programCity: "",
    title: "",
    phoneNumber: "",
    dateOfBirth: "",
    address: "",
    country: "",
    qualification: "",
    OIB: "",
    emailAddress: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageUpload = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setUserData((prevUserData) => ({
        ...prevUserData,
        imageUrl: imageUrl,
      }));
    }
  };
  const clearSelectedImage = () => {
    setSelectedImage(null);
  };
  const [numericPhoneNumber, setNumericPhoneNumber] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const Upload = () => {
    document?.getElementById("imageInput")?.click();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const maxLength = 20;
    const truncatedValue = value.slice(0, maxLength);
    if (name === "programCity") {
      setUserData({
        ...userData,
        [name]: value,
      });
    } else if (name === "phoneNumber") {
      setNumericPhoneNumber(value);
      setUserData({
        ...userData,
        [name]: selectedValue + value,
      });
    } else if (name === "OIB") {
      setUserData({
        ...userData,
        [name]: truncatedValue,
      });
    } else {
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };
  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption.value);
  };
  const areAllFieldsFilled = () => {
    for (const key in userData) {
      if (!userData[key]) {
        return false;
      }
    }
    return !!userData.imageUrl;
  };
  return (
    <div className={styles.container}>
      <img alt="" src={Logo} className={styles.logo} />
      <span className={styles.title}>
        Ispunite svoje podatke kako bi nastavili u softver
      </span>
      <img alt="" src={Placeholder} style={{ width: "100%", height: 155 }} />
      <div className={styles.wrapper}>
        <div className={styles.imageContainer} onClick={Upload}>
          <input
            type="file"
            accept="image/*"
            id="imageInput"
            style={{ display: "none", cursor: "pointer" }}
            onChange={handleImageUpload}
          />
          <button
            style={{
              backgroundImage: selectedImage ? `url(${selectedImage})` : "none",
            }}
            onClick={clearSelectedImage}
            className={styles.imageUploadBtn}
          >
            <label htmlFor="imageInput">
              {!selectedImage && (
                <div className={styles.column}>
                  <img alt="" src={Plus} />
                  Dodaj sliku profila
                </div>
              )}
            </label>
          </button>
        </div>
        <Form
          userData={userData}
          handleInputChange={handleInputChange}
          numericPhoneNumber={numericPhoneNumber}
          selectedValue={selectedValue}
          handleSelectChange={handleSelectChange}
        />
        <div className={styles.profileFormButtons}>
          <button className={styles.closeButton}>
            <p className={styles.closeButtonText} onClick={handleGoBack}>
              Odustani
            </p>
          </button>
          <button
            type="button"
            className={
              areAllFieldsFilled() ? styles.saveButton : styles.disabledBtn
            }
            onClick={() => {
              if (areAllFieldsFilled()) {
                console.log(userData);
              }
            }}
            disabled={!areAllFieldsFilled()}
          >
            <p className={styles.saveButtonText}>Nastavi u softver</p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default EksterniLink;
