import axios from "axios";
import { validateForm } from "../components/NastupnoPredavanjeTable/Modal/utils";
import { fetchNastupnaPredavanja, fetchPresentations } from "../util";
const apiUrl = process.env.REACT_APP_API_URL;

export const editNastupnoPredavanje = async (
  setErrors,
  formData,
  userId,
  theoreticalId,
  topic,
  attendance,
  accessToken,
  setPresentations,
  setNastupnaPredavanja,
  setShowModal
) => {
  try {
    const validate = await validateForm(setErrors, formData);
    if (validate) {
      const response = await axios.put(
        `${apiUrl}/api/user/presentation/${userId}`,
        {
          theoreticalId: theoreticalId,
          topic: topic,
          attendance: attendance,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        fetchPresentations(accessToken, setPresentations);
        fetchNastupnaPredavanja(accessToken, setNastupnaPredavanja);
        setShowModal(false);
      }
    }
  } catch (error) {
    console.error("Error editing nastupno predavanje", error);
    throw error;
  }
};
