import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const assignUsersToMentor = async (
  groupId,
  userIds,
  mentorId,
  accessToken,
  navigate
) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/group/mentorToUserInGroup`,
      {
        groupId: groupId,
        userIds: userIds,
        mentorId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      navigate(`/grupe/${groupId}`);
    }
  } catch (error) {
    console.error("Error assigning users to mentor", error);
    throw error;
  }
};
