import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getGroup = async (groupId, accessToken, setIsAllowed) => {
  try {
    const response = await axios.get(`${apiUrl}/api/group?id=${groupId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status !== 200) {
      setIsAllowed(false);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setIsAllowed(true);
    return response.data;
  } catch (error) {
    setIsAllowed(false);
    console.error("Error fetching single group data:", error);
    throw error;
  }
};
