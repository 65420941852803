import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../hooks/useAuthentication";
import SelectDropdown from "../../../singleGroup/components/selectDropdown/SelectDropdown";
import { changeIsFirstLogin } from "../../api/changeIsFirstLogin";
import { strucnaSpremaOptions } from "../../utils";
import PozivniDropdown from "../PozivniDropdown/PozivniDropdown";
import CustomInput from "../customInput/CustomInput";
import styles from "./EditForm.module.css";

function EditForm({
  handleInputChange,
  myProfileData,
  mjestoUpisaOptions,
  selectedValue,
  handleSelectChange,
  editMode,
  setEditMode,
  handleSubmit,
  userData,
  apiUrl,
  uploadedFile,
  numericPhoneNumber,
  setShowModal,
  setUserData,
  setModalContent,
  setLoading,
  isFirstLogin,
}) {
  const { authToken } = useAuthentication();
  const navigate = useNavigate();
  const [emptyFields, setEmptyFields] = useState([]);

  const handleSave = async () => {
    const newEmptyFields = [];
    if (!selectedValue) newEmptyFields.push("pozivni broj");
    Object.entries(myProfileData).forEach(([key, value]) => {
      if (value === "" || value === null || value.length === 0) {
        newEmptyFields.push(key);
      }
    });
    setEmptyFields(newEmptyFields);
    if (newEmptyFields.length !== 0) {
      const updatedEmptyFields = newEmptyFields.map((key) => {
        switch (key) {
          case "firstName":
            return "ime";
          case "lastName":
            return "prezime";
          case "dateOfBirth":
            return "datum rođenja";
          case "gender":
            return "spol";
          case "city":
            return "mjesto stanovanja";
          case "address":
            return "adresa stanovanja";
          case "country":
            return "država stanovanja";
          case "programCity":
            return "grad pohađanja programa";
          case "qualification":
            return "stručna sprema";
          case "title":
            return "zvanje";
          case "phoneNumber":
            return "broj mobitela";
          case "imageUrl":
            return "slika";
          default:
            return key;
        }
      });
      alert(
        "Molimo vas da ispunite sva polja (" +
          updatedEmptyFields.join(", ") +
          ")"
      );
    } else {
      await handleSubmit(
        userData,
        myProfileData,
        authToken,
        setModalContent,
        setShowModal,
        setUserData,
        setLoading,
        selectedValue,
        numericPhoneNumber,
        uploadedFile,
        apiUrl,
        navigate,
        changeIsFirstLogin,
        setEditMode
      );
    }
  };
  return (
    <div className={styles.profileForm}>
      <div className={styles.profileFormRow}>
        <div className={styles.profileFormColumn}>
          <CustomInput
            name="firstName"
            onChange={handleInputChange}
            value={myProfileData.firstName}
            labelText="Ime"
            isRed={emptyFields.includes("firstName")}
          />
          <CustomInput
            name="lastName"
            onChange={handleInputChange}
            value={myProfileData.lastName}
            labelText="Prezime"
            isRed={emptyFields.includes("lastName")}
          />

          <SelectDropdown
            name="gender"
            onChange={handleInputChange}
            title="Spol"
            value={myProfileData.gender}
            defaultValue={myProfileData.gender || ""}
            isRed={emptyFields.includes("gender")}
            options={[
              { value: "", label: "Odaberi" },
              { value: "Muško", label: "Muško" },
              { value: "Žensko", label: "Žensko" },
              { value: "Ostalo", label: "Ostalo" },
            ]}
          />
          <CustomInput
            name="address"
            value={myProfileData.address}
            onChange={handleInputChange}
            labelText="Adresa stanovanja"
            isRed={emptyFields.includes("address")}
          />

          <SelectDropdown
            name="programCity"
            onChange={handleInputChange}
            title="Grad u kojem pohađa program"
            value={myProfileData.programCity}
            options={mjestoUpisaOptions}
            isRed={emptyFields.includes("programCity")}
          />
          <CustomInput
            name="title"
            value={myProfileData.title}
            onChange={handleInputChange}
            labelText="Zvanje - Titula"
            isRed={emptyFields.includes("title")}
          />

          <div className={styles.mobileColumn}>
            {isFirstLogin && (
              <div
                style={{
                  fontSize: 15,
                  fontWeight: 600,
                  opacity: 0.8,
                  marginBottom: 12,
                }}
              >
                <b>
                  NAPOMENA: Pozivni broj odabrati u izborniku lijevo od polja za
                  upis broja telefona, pozivni broj se ne upisuje ručno u
                  polje!!!
                </b>
              </div>
            )}
            <span className={styles.label}>Broj mobitela</span>
            <div className={styles.mobileRow}>
              <PozivniDropdown
                selectedValue={selectedValue}
                handleSelectChange={handleSelectChange}
                isRed={emptyFields.includes("pozivniBroj")}
              />
              <div style={{ marginLeft: "-6px", width: "100%", paddingTop: 7 }}>
                <CustomInput
                  type="tel"
                  name="phoneNumber"
                  value={myProfileData?.phoneNumber || numericPhoneNumber || ""}
                  onChange={handleInputChange}
                  pattern="[1-9][0-9]*"
                  isRed={emptyFields.includes("phoneNumber")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.profileFormColumn}>
          <CustomInput
            name="dateOfBirth"
            value={myProfileData.dateOfBirth}
            onChange={handleInputChange}
            labelText="Datum rođenja"
            placeholder={"mm/dd/yyyy"}
            type="date"
            isRed={emptyFields.includes("dateOfBirth")}
            isBirthDate={true}
          />
          <CustomInput
            name="city"
            onChange={handleInputChange}
            value={myProfileData.city}
            labelText="Mjesto stanovanja"
            type="text"
            isRed={emptyFields.includes("city")}
          />
          <CustomInput
            name="country"
            onChange={handleInputChange}
            value={myProfileData.country}
            labelText="Država stanovanja"
            type="text"
            isRed={emptyFields.includes("country")}
          />
          <SelectDropdown
            name="qualification"
            onChange={handleInputChange}
            title="Stručna sprema"
            value={myProfileData.qualification}
            options={strucnaSpremaOptions}
            isRed={emptyFields.includes("qualification")}
          />
          <CustomInput
            name="OIB"
            onChange={handleInputChange}
            value={myProfileData.OIB}
            labelText="Identifikacijski broj (OIB/EMŠO/JMBG i sl.)"
            type="number"
            isRed={emptyFields.includes("OIB")}
          />
          <CustomInput
            name="emailAddress"
            onChange={handleInputChange}
            value={myProfileData.emailAddress}
            labelText="Email adresa"
            type="email"
            isRed={emptyFields.includes("emailAddress")}
            disabled={true}
          />
        </div>
      </div>
      <div className={styles.profileFormButtons}>
        {!isFirstLogin && (
          <div
            onClick={() => {
              if (!isFirstLogin) {
                setEditMode(false);
              }
            }}
            className={styles.closeButton}
          >
            <p className={styles.closeButtonText}>Odustani</p>
          </div>
        )}
        <div className={styles.saveButton} onClick={handleSave}>
          <p className={styles.saveButtonText}>Spremi promjene</p>
        </div>
      </div>
    </div>
  );
}

export default EditForm;
