import * as yup from "yup";

export const IndividualneProgramskeTerapijeValidationSchema = yup
  .object()
  .shape({
    date: yup
      .date()
      .typeError("Trebate unijeti datum terapije")
      .required("Trebate unijeti datum terapije"),
    brojSati: yup.string().required("Trebate unijeti broj terapija"),
    fullName: yup.string().required("Trebate unijeti terapeuta"),
    vanjskiTerapeut: yup.string().when("fullName", {
      is: "Vanjski terapeut",
      then: yup
        .string()
        .required("Trebate unijeti ime i prezime vanjskog terapeuta"),
    }),
  });

export const FakultativneRadioniceValidationSchema = yup.object().shape({
  fullName: yup.string().required("Trebate unijeti ime i prezime voditelja"),
  nazivRadionice: yup.string().required("Trebate unijeti naziv radionice"),
  date: yup
    .string()
    .typeError("Trebate unijeti datum radionice")
    .required("Trebate unijeti datum radionice"),
  gprSati: yup.string().required("Trebate unijeti broj sati GPR-a"),
});

export const GrupneSupervizijeValidationSchema = yup.object().shape({
  tipGrupneSupervizije: yup
    .string()
    .required("Trebate odabrati tip grupne supervizije"),
  date: yup
    .date()
    .typeError("Trebate unijeti datum grupne supervizije")
    .required("Datum supervizije is required"),
  uloga: yup.string().required("Trebate odabrati ulogu"),
  satiIndividualneSupervizije: yup
    .string()
    .required("Trebate odabrati broj sati individualne supervizije"),
  satiGrupneSupervizije: yup
    .string()
    .required("Trebate odabrati broj sati pasivnog promatrača"),
  fullName: yup.string().required("Trebate odabrati supervizora"),
  vanjskiSupervizor: yup.string().when("fullName", {
    is: "Vanjski supervizor",
    then: yup
      .string()
      .required("Trebate upisate ime i prezime vanjskog supervizora"),
  }),
});

export const RetroaktivniRadKlijentiValidationSchema = yup
  .object()
  .shape({
    fullName: yup.string(),
    outsideClient: yup.string(),
    year: yup
      .string()
      .typeError("Trebate unijeti datum održavanja")
      .required("Datum održavanja is required"),
    brojSati: yup
      .number()
      .typeError("Trebate unijeti broj terapija")
      .required("Trebate unijeti broj terapija"),
  })
  .test(
    "fullName-or-outsideClient",
    "Trebate unijeti ime i prezime klijenta ili naziv vanjskog klijenta",
    function (value) {
      return !!(value.fullName || value.outsideClient);
    }
  );

export const IntenziviRetroaktivnoValidationSchema = yup.object().shape({
  vrstaIntenziva: yup.string().required("Trebate odabrati vrstu intenziva"),
  name: yup.string().required("Trebate odabrati naziv intenziva"),
  year: yup.number().required("Trebate unijeti godinu održavanja"),
});

export const IndividualneSupervizijeValidationSchema = yup.object().shape({
  date: yup
    .date()
    .typeError("Trebate unijeti datum individualne supervizije")
    .required("Datum terapije is required"),
  brojSati: yup.string().required("Trebate odabrati broj supervizija"),
  fullName: yup.string().required("Trebate odabrati supervizora"),
  vanjskiSupervizor: yup.string().when("fullName", {
    is: "Vanjski supervizor",
    then: yup
      .string()
      .required("Trebate unijeti ime i prezime vanjskog supervizora"),
  }),
});

// export const AktualniRadKlijentiValidationSchema = yup.object().shape({
//   fullName: yup.string().required("Trebate unijeti ime i prezime klijenta"),
//   date: yup
//     .string()
//     .typeError("Trebate unijeti datum održavanja")
//     .required("Datum održavanja is required"),
// });

export const AktualniRadKlijentiValidationSchema = yup
  .object()
  .shape({
    fullName: yup.string(),
    outsideClient: yup.string(),
    date: yup
      .string()
      .typeError("Trebate unijeti datum održavanja")
      .required("Datum održavanja is required"),
  })
  .test(
    "fullName-or-outsideClient",
    "Trebate unijeti ime i prezime klijenta ili naziv vanjskog klijenta",
    function (value) {
      return !!(value.fullName || value.outsideClient);
    }
  );

export const OsobniRadGrupaValidationSchema = yup.object().shape({
  vrstaRadionicePrograma: yup
    .string()
    .required("Trebate odabrati vrstu radionice/programa"),
  uloga: yup.string().required("Trebate odabrati ulogu"),
  dateGrupa: yup
    .string()
    .typeError("Trebate odabrati datum početka radionice/programa")
    .required("Trebate odabrati datum početka radionice/programa"),
  brojSati: yup
    .number()
    .typeError("Trebate unijeti broj sati radionice/programa")
    .required("Trebate unijeti broj sati radionice/programa"),
});
