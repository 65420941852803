import { useEffect, useState } from "react";
import InputColor from "react-input-color";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CheckIcon from "../../../../assets/svg/CheckIcon";
import CrossIcon from "../../../../assets/svg/CrossIcon";
import Modal from "../../../../components/Modal/Modal";
import { useGroupState } from "../../../../context/CreateGroupContext";
import { useUserState } from "../../../../context/UserContext";
import { useAuthentication } from "../../../hooks/useAuthentication";
import AttendeesTable from "../../../singleGroup/components/attendeesTable/AttendeesTable";
import CustomInput from "../../../singleGroup/components/customInput/CustomInput";
import TeacherCard from "../../../singleGroup/components/teacherCard/TeacherCard";
import { getAllCities } from "../../api/getAllCities";
import { getAllProgramsReferada } from "../../api/getAllProgramsReferada";
import { getUserById } from "../../api/getUserById";
import styles from "./GroupCreate.module.css";
import { handleCreateGroup, handleInputChange, selectStyles } from "./utils";

const GroupCreate = () => {
  const navigate = useNavigate();
  const { role } = useUserState();
  const { authToken } = useAuthentication();
  const { groupData, updateGroupData, resetGroupData } = useGroupState();
  const [boxColorPicker, setBoxColorPicker] = useState(false);
  const [textColorPicker, setTextColorPicker] = useState(false);
  const userId = role;
  const [allPrograms, setAllPrograms] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [voditeljiData, setVoditeljiData] = useState([]);
  const [polazniciData, setPolazniciData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    title: "",
    description: "",
    buttons: [],
  });

  const [groupValues, setGroupValues] = useState({
    name: groupData.name,
    programId: groupData.programId,
    cityId: groupData.cityId,
    calendarFieldColour: groupData.calendarFieldColour,
    calendarTextColour: groupData.calendarTextColour,
    noviVoditelji: groupData.noviVoditelji,
    noviPolaznici: groupData.noviPolaznici,
  });

  useEffect(() => {
    const fetchData = async () => {
      const formattedOptions = await getAllProgramsReferada(authToken);
      setAllPrograms(formattedOptions);
      const formattedCityOptions = await getAllCities(authToken);
      setAllCities(formattedCityOptions);
      if (groupData.noviVoditelji && groupData.noviVoditelji.length > 0) {
        const voditeljiPromises = groupData.noviVoditelji.map((voditeljId) =>
          getUserById(authToken, voditeljId)
        );
        const voditeljiDataArray = await Promise.all(voditeljiPromises);
        setVoditeljiData(voditeljiDataArray);
      }
      if (groupData.noviPolaznici && groupData.noviPolaznici.length > 0) {
        const polazniciPromises = groupData.noviPolaznici.map((polaznikId) =>
          getUserById(authToken, polaznikId)
        );
        const polazniciDataArray = await Promise.all(polazniciPromises);
        setPolazniciData(polazniciDataArray);
      }
    };
    if (authToken) fetchData();
  }, [groupData, authToken]);

  return (
    <div>
      <div className={styles.editContainer}>
        <div className={styles.editContainerBox}>
          <CustomInput
            type="text"
            name="name"
            value={groupValues.name}
            onChange={(e) =>
              handleInputChange(
                e.target.name,
                e.target.value,
                setGroupValues,
                updateGroupData
              )
            }
            labelText="Naziv grupe"
            placeholder="Upiši"
          />
          <div className={styles.programContainerBox}>
            <p className={styles.colorLabel}>Grad upisa</p>
            <Select
              placeholder="Odaberi"
              options={allCities}
              name="cityId"
              value={allCities.find(
                (city) => city.value === groupValues.cityId
              )}
              onChange={(selectedOption) =>
                handleInputChange(
                  "cityId",
                  selectedOption ? selectedOption.value : null,
                  setGroupValues,
                  updateGroupData
                )
              }
              isSearchable={false}
              styles={selectStyles}
              components={{ IndicatorSeparator: () => null }}
            />
          </div>
        </div>

        <div className={styles.editContainerBox}>
          <div className={styles.programContainerBox}>
            <p className={styles.colorLabel}>Grupa programa</p>
            <Select
              placeholder="Odaberi"
              options={allPrograms}
              name="programId"
              value={allPrograms.find(
                (program) => program.value === groupValues.programId
              )}
              onChange={(selectedOption) =>
                handleInputChange(
                  "programId",
                  selectedOption ? selectedOption.value : null,
                  setGroupValues,
                  updateGroupData
                )
              }
              isSearchable={false}
              styles={selectStyles}
              components={{ IndicatorSeparator: () => null }}
            />
          </div>
          <div className={styles.colorsContainer}>
            <div className={styles.colorContainer}>
              <p className={styles.colorLabel}>Kalendar - boja polja</p>
              {boxColorPicker ? (
                <div className={styles.colorPickInput}>
                  <InputColor
                    initialValue="#ffffff"
                    onChange={(color) =>
                      handleInputChange(
                        "calendarFieldColour",
                        color.hex,
                        setGroupValues,
                        updateGroupData
                      )
                    }
                    placement="right"
                  />
                  <div
                    onClick={() => {
                      setBoxColorPicker(false);
                    }}
                    className={styles.colorPickIconBox}
                  >
                    <CheckIcon className={styles.colorPickIcon} />
                  </div>
                </div>
              ) : (
                <div
                  className={styles.colorBox}
                  onClick={() => {
                    setBoxColorPicker(true);
                  }}
                >
                  <div
                    style={{ backgroundColor: groupValues.calendarFieldColour }}
                    className={styles.color}
                  />
                </div>
              )}
            </div>
            <div className={styles.colorContainer}>
              <div className={styles.colorContainer}>
                <p className={styles.colorLabel}>Kalendar - boja teksta</p>
                {textColorPicker ? (
                  <div className={styles.colorPickInput}>
                    <InputColor
                      initialValue="#ffffff"
                      onChange={(color) =>
                        handleInputChange(
                          "calendarTextColour",
                          color.hex,
                          setGroupValues,
                          updateGroupData
                        )
                      }
                    />
                    <div
                      onClick={() => {
                        setTextColorPicker(false);
                      }}
                      className={styles.colorPickIconBox}
                    >
                      <CheckIcon className={styles.colorPickIcon} />
                    </div>
                  </div>
                ) : (
                  <div
                    className={styles.colorBox}
                    onClick={() => {
                      setTextColorPicker(true);
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: groupValues.calendarTextColour,
                      }}
                      className={styles.color}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.leadersContainer}>
        <div className={styles.leadersTitleContainer}>
          <h3 className={styles.leadersTitle}>Voditelji grupe</h3>
          <p className={styles.leadersNumber}>
            {groupData.noviVoditelji ? groupData.noviVoditelji.length : 0}
          </p>
        </div>
        <div className={styles.cardsContainer}>
          {voditeljiData &&
            voditeljiData.map((item, index) => {
              return (
                <div key={index}>
                  <TeacherCard
                    photo={item.imageUrl}
                    firstName={item.firstName}
                    lastName={item.lastName}
                    email={item.emailAddress}
                    biography={item.bio}
                  />
                </div>
              );
            })}
          <div
            onClick={() => {
              navigate("/djelatnici", {
                state: {
                  addVoditeljiNewGroup: true,
                  createNewGroupSelectedVoditelji: groupData.noviVoditelji,
                },
              });
            }}
            className={styles.addCard}
          >
            <CrossIcon className={styles.addCardIcon} />
            <p className={styles.addCardText}>Dodaj voditelja</p>
          </div>
        </div>
      </div>
      <div className={styles.attendeesContainer}>
        <div className={styles.attendeesTitleContainer}>
          <h3 className={styles.attendeesTitle}>Polaznici</h3>
          <p className={styles.attendeesNumber}>
            {groupData.noviPolaznici ? groupData.noviPolaznici.length : 0}
          </p>
        </div>
        <AttendeesTable
          editMode={true}
          isCreateNewGroup={true}
          data={polazniciData && polazniciData}
          userId={userId}
        />
        <div className={styles.editActionsButtons}>
          <div
            onClick={() => {
              navigate("/grupe");
            }}
            className={styles.cancelButton}
          >
            <div className={styles.cancelButtonText}>odustani</div>
          </div>
          <div
            className={styles.saveButton}
            onClick={() =>
              handleCreateGroup(
                groupValues,
                authToken,
                resetGroupData,
                navigate,
                setModalContent,
                setShowModal
              )
            }
          >
            <div className={styles.saveButtonText}>Kreiraj grupu</div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.title}
          description={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
};
export default GroupCreate;
