import axios from "axios";
import { formatDate } from "../../polazniciPregled/utils/utils";
const apiUrl = process.env.REACT_APP_API_URL;

export const exportObracunSoba = async (accessToken, startDate, endDate) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Both startDate and endDate are required.");
    }
    const response = await axios.get(
      `${apiUrl}/api/exportUserStats?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching obracun sobe:", error);
    throw error;
  }
};

export const exportObracunSobaByUser = async (
  accessToken,
  startDate,
  endDate,
  userId
) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Both startDate and endDate are required.");
    }
    const response = await axios.get(
      `${apiUrl}/api/exportUserStatsByUserId?userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching obracun sobe:", error);
    throw error;
  }
};

export const handleExportObracunSoba = async (
  accessToken,
  userId,
  startDate,
  endDate,
  firstName,
  lastName
) => {
  try {
    const response = await exportObracunSoba(accessToken, startDate, endDate);
    const blob = new Blob([response], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `Moj_obracun:${firstName}_${lastName}_${formatDate(
      startDate
    )}-${formatDate(endDate)}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Greška prilikom exporta obračuna sobe:", error);
  }
};

export const handleExportObracunSobaByUser = async (
  accessToken,
  userId,
  startDate,
  endDate,
  name,
  setLoading
) => {
  try {
    const response = await exportObracunSobaByUser(
      accessToken,
      startDate,
      endDate,
      userId
    );
    const blob = new Blob([response], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `Moj_obracun:${name}_${formatDate(startDate)}-${formatDate(
      endDate
    )}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    setLoading(false);
  } catch (error) {
    setLoading(false);

    console.error("Greška prilikom exporta obračuna sobe:", error);
  }
};
