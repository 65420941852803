import React from "react";
import Anonymous from "../../../../assets/img/Anonymous.png";
import styles from "./OsvrtiOcjeneNaMojRad.module.css";

function CommentContainer({ avatar, name, date, comment }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
      "siječnja",
      "veljače",
      "ožujka",
      "travnja",
      "svibnja",
      "lipnja",
      "srpnja",
      "kolovoza",
      "rujna",
      "listopada",
      "studenoga",
      "prosinca",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}. ${month} ${year}`;
  };

  return (
    <div className={styles.commentWrapper}>
      <div className={styles.row}>
        <img
          style={{ width: 64, height: 64 }}
          className={styles.avatar}
          src={avatar === "" ? Anonymous : avatar}
        />
        <div className={styles.wrapper}>
          <span className={styles.avatarName} style={{ fontSize: 20 }}>
            {name}
          </span>
          <span
            className={styles.avatarRole}
            style={{ color: "#7E818C", fontSize: 14 }}
          >
            {formatDate(date)}
          </span>
        </div>
      </div>
      <div
        style={{ marginTop: 24, marginBottom: 24, textAlign: "left" }}
        className={styles.title}
      >
        {comment}
      </div>
    </div>
  );
}

export default CommentContainer;
