import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "../../assets/svg/ArrowLeftIcon";
import styles from "./BackComponent.module.css";

function BackComponent() {
  const navigate = useNavigate();
  return (
    <div className={styles.backButton} onClick={() => navigate("/evidencija")}>
      <ArrowLeftIcon className={styles.backButtonArrow} />
      <p className={styles.backButtonText}>Natrag na evidenciju</p>
    </div>
  );
}

export default BackComponent;
