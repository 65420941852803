export const handleInputChange = (
  name,
  value,
  type,
  checked,
  setFormData,
  formData
) => {
  let inputValue;
  if (type === "checkbox") {
    inputValue = checked;
  } else if (type === "text") {
    if (value === "") {
      inputValue = "";
    } else {
      inputValue = value.split(" ").map((item) => item.trim());
    }
  } else {
    inputValue = value;
  }

  setFormData({
    ...formData,
    [name]: inputValue,
  });
};
