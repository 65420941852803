// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchStudents_searchStudentsInput__3j4yB {\n  border: 1px solid #d9dce0;\n  width: 438px;\n  height: 46px;\n  padding: 8px 16px 8px 46px;\n  border-radius: 8px;\n  cursor: pointer;\n}\n.SearchStudents_searchStudentsInput__3j4yB::placeholder {\n  color: rgba(95, 109, 126, 0.6);\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n}\n.SearchStudents_searchStudentsInput__3j4yB:focus {\n  border: 1px solid rgba(203, 156, 45, 1);\n  outline: none;\n}\n.SearchStudents_searchStudentsIcon__jF7PH {\n  position: absolute;\n  height: 24px;\n  width: 24px;\n  margin: 10px 16px;\n  position: absolute;\n}\n", "",{"version":3,"sources":["webpack://./src/scenes/attendees/searchStudents/SearchStudents.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,uCAAuC;EACvC,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".searchStudentsInput {\n  border: 1px solid #d9dce0;\n  width: 438px;\n  height: 46px;\n  padding: 8px 16px 8px 46px;\n  border-radius: 8px;\n  cursor: pointer;\n}\n.searchStudentsInput::placeholder {\n  color: rgba(95, 109, 126, 0.6);\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n}\n.searchStudentsInput:focus {\n  border: 1px solid rgba(203, 156, 45, 1);\n  outline: none;\n}\n.searchStudentsIcon {\n  position: absolute;\n  height: 24px;\n  width: 24px;\n  margin: 10px 16px;\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchStudentsInput": "SearchStudents_searchStudentsInput__3j4yB",
	"searchStudentsIcon": "SearchStudents_searchStudentsIcon__jF7PH"
};
export default ___CSS_LOADER_EXPORT___;
