import React, { useEffect, useState } from "react";
import Export from "../../assets/img/Export.png";
import { useAuthentication } from "../hooks/useAuthentication";
import { formatDate } from "../polazniciPregled/utils/utils";
import SelectDropdown from "../singleGroup/components/selectDropdown/SelectDropdown";
import { handlePregledPotrosnjePoGradovima } from "./API/exportPregledPotrosnjePoGradovima";
import { getPregledPotrošnjePoGradovima } from "./API/fetchPregledPotrošnjePoGradovima";
import styles from "./ObracunSoba.module.css";
import ObracunComponent from "./components/ObracunComponent/ObracunComponent";
import DatePicker from "./components/SingleInputDatePicker/DatePicker";
import {
  periodPotrosnjeGradoviColumnNames,
  potrosnjaPoGradovima,
} from "./utils/columnNames";
import { Gradovi, handleSelectCityChange } from "./utils/utils";

function PregledPotrosnjePoGradovima() {
  const today = new Date();
  const initialDate = today.toISOString().split("T")[0];
  const [pregledPotrosnjePoGradovima, setPregledPotrosnjePoGradovima] =
    useState({});
  const [cityStats, setCityStats] = useState([]);
  const [startDate, setStartDate] = useState(initialDate);
  const [endDate, setEndDate] = useState(initialDate);
  const { authToken } = useAuthentication();
  const [cityId, setCityId] = useState(1);
  const [cityLabel, setCityLabel] = useState(
    Gradovi.length > 0 ? Gradovi[0].label : ""
  );
  const handleDateChange = (newDates) => {
    setStartDate(newDates[0]);
    setEndDate(newDates[1]);
  };
  useEffect(() => {
    if (authToken)
      getPregledPotrošnjePoGradovima(
        authToken,
        cityId,
        startDate,
        endDate,
        setPregledPotrosnjePoGradovima
      );
  }, [authToken, startDate, endDate, cityId]);
  const obracun = Array.isArray(
    pregledPotrosnjePoGradovima?.statistics?.appointments
  )
    ? [...pregledPotrosnjePoGradovima?.statistics?.appointments]
    : [];
  useEffect(() => {
    if (
      pregledPotrosnjePoGradovima.statistics &&
      pregledPotrosnjePoGradovima.statistics.cityStats &&
      Array.isArray(pregledPotrosnjePoGradovima.statistics.cityStats)
    ) {
      setCityStats((prevObracun) => {
        const newArray = pregledPotrosnjePoGradovima.statistics.cityStats.map(
          (stats) => {
            return {
              period: `${formatDate(startDate)} - ${formatDate(endDate)}`,
              grad: cityLabel,
              sumOfReservations: obracun.length || 0,
              totalSpend: stats.totalSpent || "0",
              cancelled: stats.cancelled || "0",
              percentageCancelled: stats.percentageCancelled || "",
            };
          }
        );

        return newArray.filter(Boolean);
      });
    }
  }, [pregledPotrosnjePoGradovima, startDate, endDate, cityLabel]);
  return (
    <div className={styles.obracunWrapper}>
      <span className={styles.title}>Pregled potrošnje po gradovima</span>
      <div className={styles.inputRow}>
        <div className={styles.column}>
          <span className={styles.label}>Grad</span>
          <SelectDropdown
            options={Gradovi}
            value={cityId || (Gradovi.length > 0 ? Gradovi[0].value : null)}
            onChange={(e) => handleSelectCityChange(e, setCityId, setCityLabel)}
          />
        </div>
        <div className={styles.column}>
          <span className={styles.label}>Vremenski period</span>
          <DatePicker
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
          />
        </div>
      </div>
      <ObracunComponent
        title={`Pregled potrošnje za grad: ${cityLabel}`}
        label={`(${formatDate(startDate)} - ${formatDate(endDate)})`}
        columnNames={potrosnjaPoGradovima}
        data={obracun}
        backgroundColor={"#87AD77"}
        isObracunPoSobama
        isPotrosnjaPoGradovima
      />
      <ObracunComponent
        columnNames={periodPotrosnjeGradoviColumnNames}
        data={cityStats}
        backgroundColor={"#87AD77"}
        isObracunPoSobama
      />
      <button
        className={styles.exportBtn}
        onClick={() =>
          handlePregledPotrosnjePoGradovima(
            authToken,
            cityId,
            startDate,
            endDate,
            cityLabel
          )
        }
      >
        Export tablica <img alt="" src={Export} />
      </button>
    </div>
  );
}

export default PregledPotrosnjePoGradovima;
