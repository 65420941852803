import React, { useEffect, useState } from "react";
import Export from "../../assets/img/Export.png";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import { formatDate } from "../polazniciPregled/utils/utils";
import { handleExportObracunSoba } from "./API/exportObracunSoba";
import { getMyObracun } from "./API/fetchMyObracun";
import styles from "./ObracunSoba.module.css";
import ObracunComponent from "./components/ObracunComponent/ObracunComponent";
import DatePicker from "./components/SingleInputDatePicker/DatePicker";
import { mojObracunKoristenjaSobeColumnNames } from "./utils/columnNames";

function MojObracunKoristenjaSoba() {
  const today = new Date();

  const formatDateForPicker = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [obracunData, setObracunData] = useState([]);
  const [startDate, setStartDate] = useState(
    formatDateForPicker(firstDayOfMonth)
  );
  const [endDate, setEndDate] = useState(formatDateForPicker(lastDayOfMonth));
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const userId = userData.id;
  const handleDateChange = (newDates) => {
    setStartDate(newDates[0]);
    setEndDate(newDates[1]);
  };
  useEffect(() => {
    if (authToken) getMyObracun(authToken, startDate, endDate, setObracunData);
  }, [authToken, startDate, endDate]);
  return (
    <div className={styles.obracunWrapper}>
      <span className={styles.title}>Moj obračun korištenje soba</span>
      <span className={styles.label}>Odaberi vremenski raspon</span>
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        onDateChange={handleDateChange}
      />
      <ObracunComponent
        title={obracunData?.statistics?.userType ?? "BASIC KORISNIK"}
        label={`Korisnik : ${userData.firstName} ${
          userData.lastName
        } (${formatDate(startDate)} - ${formatDate(endDate)})`}
        columnNames={mojObracunKoristenjaSobeColumnNames}
        data={obracunData?.statistics}
        backgroundColor={"#cb9c2d"}
        showSum
        isObracunPoSobama
      />
      <button
        className={styles.exportBtn}
        onClick={() =>
          handleExportObracunSoba(
            authToken,
            userId,
            startDate,
            endDate,
            userData.firstName,
            userData.lastName
          )
        }
      >
        Export tablice <img alt="" src={Export} />
      </button>
    </div>
  );
}

export default MojObracunKoristenjaSoba;
