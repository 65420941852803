import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import SearchIcon from "../../assets/svg/SearchIcon.svg";
import { useUserState } from "../../context/UserContext";
import NastupnoPredavanje from "../evidencija/components/NastupnoPredavanje/NastupnoPredavanje";
import OsobniPsihoterapeutskiRad from "../evidencija/components/OsobniPsihoterapeutskiRad/OsobniPsihoterapeutskiRad";
import OsobniRadKlijentiGrupe from "../evidencija/components/OsobniRadKlijentiGrupe/OsobniRadKlijentiGrupe";
import SudjelovanjeNaPredavanjima from "../evidencija/components/SudjelovanjeNaPredavanjima/SudjelovanjeNaPredavanjima";
import Supervizije from "../evidencija/components/Supervizije/Supervizije";
import ZavrsniRadovi from "../evidencija/components/ZavrsniRadovi/ZavrsniRadovi";
import { useAuthentication } from "../hooks/useAuthentication";
import { getTtAndTp } from "./api/getTtAndT";
import { getUsersEvidention } from "./api/getUsersEvidention";
import { getUsersILead } from "./api/getUsersILead";
import styles from "./EvidencijaPolaznika.module.css";

function EvidencijaPolaznika() {
  const navigate = useNavigate();
  const { role } = useUserState();
  const { authToken } = useAuthentication();
  const [searchText, setSearchText] = useState("");
  const [polaznik, setPolaznik] = useState("");
  const [allPolaznici, setAllPolaznici] = useState([]);
  const [evidentionData, setEvidentionData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [change, setChange] = useState(false);

  useEffect(() => {
    if (
      role !== "Voditelj" &&
      role !== "Polaznik VT-a" &&
      role !== "Supervizor"
    ) {
      navigate("/dashboard");
    }
  }, [role, authToken]);

  useEffect(() => {
    const fetchPolaznici = async () => {
      if (role === "Voditelj" || role === "Polaznik VT-a") {
        const polaznici = await getUsersILead(authToken);
        setAllPolaznici(
          polaznici.userInGroups.map((user) => ({
            name: `${user.firstName} ${user.lastName}`,
            id: user.id,
          }))
        );
      } else if (role === "Supervizor") {
        const polaznici = await getTtAndTp(authToken);
        setAllPolaznici(
          polaznici.userInGroups.map((user) => ({
            name: `${user.firstName} ${user.lastName}`,
            id: user.id,
          }))
        );
      } else {
        setAllPolaznici({});
      }
    };

    if (authToken) fetchPolaznici();
  }, [authToken]);

  useEffect(() => {
    const getPolaznikEvidention = async () => {
      const eData = await getUsersEvidention(polaznik.id, authToken);
      setEvidentionData(eData);
    };
    if (authToken && polaznik) getPolaznikEvidention();
  }, [polaznik, authToken, change]);

  const filteredValues = allPolaznici.filter((value) =>
    value.name?.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSearchChange = (event) => {
    const searchTextValue = event.target.value;
    setSearchText(searchTextValue);
    setShowDropdown(searchTextValue.length > 0);
  };

  const handlePolaznikClick = (value) => {
    setSearchText(value.name);
    setPolaznik(value);
  };

  const userId = role;
  const polaznikLevel = "polaznikTT5";
  return (
    <div className={styles.wrapper} onClick={() => setShowDropdown(false)}>
      <div className={styles.search}>
        <p className={styles.searchLabel}>Polaznik:</p>
        <div className={styles.searchContainer}>
          <label htmlFor="" className={styles.label}>
            <input
              type="search"
              className={styles.input}
              placeholder="Upiši"
              value={searchText}
              onChange={(event) => handleSearchChange(event)}
            />
            <img src={SearchIcon} alt="" />
          </label>
          {filteredValues.length > 0 && showDropdown && (
            <div className={styles.dropdown}>
              {filteredValues.slice(0, 5).map((value) => (
                <div
                  key={value.id}
                  className={styles.dropdownItem}
                  onClick={() => handlePolaznikClick(value)}
                >
                  <p className={styles.p}>{value.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {polaznik === "" ? (
        <div className={styles.info}>
          <img src={InfoIcon} alt="" />
          <p className={styles.infoP}>
            Trenutno nije odabran nijedan polaznik. Za prikaz evidencije, moraš
            odabrati polaznika.
          </p>
        </div>
      ) : (
        <div>
          <SudjelovanjeNaPredavanjima
            userId={userId}
            polaznikLevel={polaznikLevel}
            evidentionUserID={polaznik.id}
            data={evidentionData?.lessonsAndWorkshops}
          />
          <OsobniPsihoterapeutskiRad
            userId={userId}
            polaznikLevel={polaznikLevel}
            data={evidentionData?.personalPsychotherapyWork}
            evidentionUserID={polaznik.id}
          />
          {polaznikLevel !== "polaznikRAR" && (
            <Supervizije
              userId={userId}
              polaznikLevel={polaznikLevel}
              data={evidentionData?.supervisionObject}
              evidentionUserID={polaznik.id}
              change={change}
              setChange={setChange}
            />
          )}
          {polaznikLevel !== "polaznikRAR" && (
            <OsobniRadKlijentiGrupe
              userId={userId}
              polaznikLevel={polaznikLevel}
              data={evidentionData?.workWithClients}
              evidentionUserID={polaznik.id}
            />
          )}
          {polaznikLevel === "polaznikTT5" && (
            <NastupnoPredavanje
              userId={userId}
              polaznikLevel={polaznikLevel}
              data={evidentionData?.presentations}
              evidentionUserID={polaznik.id}
            />
          )}
          {polaznikLevel !== "polaznikRAR" && (
            <ZavrsniRadovi
              userId={userId}
              polaznikLevel={polaznikLevel}
              data={evidentionData?.zavrsniRadovi}
              evidentionUserID={polaznik.id}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default EvidencijaPolaznika;
