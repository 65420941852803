import React from "react";
import styles from "./UrediUloge.module.css";

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    color: "#828d9a",
    padding: "6px",
    width: "340px",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "8px",
    border: "1px solid #D1D9E2 !important",
    boxShadow: "none",
    cursor: "pointer",
    "&:focus": {
      border: "0 !important",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      display: "flex",
      alignItems: "center",
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      ":hover": {
        backgroundColor: data.isDisabled ? "#FFFFFF" : "#F2F2F2",
      },
    };
  },
  singleValue: (provided) => ({
    ...provided,
    color: "#5F6D7E",
  }),
  menuList: (provided) => ({
    ...provided,
    fontFamily: "Inter",
    fontWeight: 500,
    padding: 12,
    color: "#5F6D7EC7",
    fontSize: 16,
    cursor: "pointer",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    fontFamily: "Inter",
    fontSize: "16px",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#5F6D7E",
    backgroundColor: "transparent",
    fontWeight: 500,
    fontFamily: "Inter",
    fontSize: "16px",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#5F6D7E",
    display: "none",
    ":hover": {
      backgroundColor: "transparent",
      color: "#5F6D9F",
    },
  }),
};
export const options = [
  { label: "Edukator", value: "Edukator" },
  { label: "Edukant", value: "Edukant" },
];
export const CustomOption = ({ innerProps, label, data }) => (
  <div
    {...innerProps}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    }}
  >
    <label className={styles.label}>
      <input
        type="checkbox"
        checked={data.isSelected}
        className={styles.checkboxInput}
      />
      <span className={styles.checkboxCustom} />
    </label>
    {label}
  </div>
);
export const MultiValueContainer = ({ children, innerProps }) => (
  <div {...innerProps} style={{ display: "flex", flexWrap: "wrap" }}>
    {children.map((child, index) => (
      <React.Fragment key={index}>
        {child}
        {index > 0 && ", "}
      </React.Fragment>
    ))}
  </div>
);
