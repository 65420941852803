import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Background from "../../../assets/img/ProgramBackground.png";

export const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  justifyContent: "center",
  gap: 20,
  paddingBottom: 40,
}));

export const BackRow = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 20,
  transition: "textDecoration 300ms ease-in-out",
  "&:hover": {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export const ColumnWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  justifyContent: "center",
  gap: 16,
  width: "50%",
  "@media screen and (max-width: 1244px)": {
    width: "100%",
  },
}));
export const Back = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#5F6D7E",
}));
export const Header = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  height: 200,
}));

export const BackgroundImage = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 155,
  width: "80vw",
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));
export const ProgramImage = styled(Box)(({ backgroundImage }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 155,
  width: 213,
  backgroundColor: "white",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "absolute",
  left: 56,
  bottom: "-26px",
}));
export const Title = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "90%",
  fontFamily: "Inter",
  fontSize: 28,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#4E4E4E",
  paddingTop: 60,
}));

export const UrediButton = styled(Box)(({ disabled }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 40,
  paddingLeft: 100,
  paddingRight: 100,
  border: "1px solid #D8DBDF",
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "center",
  color: "#5F6D7E",
  borderRadius: 4,
  transition: "backgroundColor 300ms ease-in-out",
  "&:hover": {
    backgroundColor: !disabled && "#D8DBDF",
    color: !disabled && "#4E4E4E",
    cursor: !disabled ? "pointer" : "not-allowed",
  },
  backgroundColor: disabled ? "#D8DBDF" : "transparent",
  "@media screen and (max-width: 450px)": {
    paddingLeft: 60,
    paddingRight: 60,
  },
}));
export const OpisWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  justifyContent: "center",
  width: "45%",
  gap: 16,
  "@media screen and (max-width: 1024px)": {
    width: "90%",
  },
}));

export const Opis = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: " -0.001em",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
  textAlign: "left",
  color: "#5F6D7E",
}));

export const DropdownButton = styled(Box)(() => ({
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#4E4E4E",
  textDecoration: "underline",
  transition: "color 300ms ease-in-out",
  "&:hover": {
    color: "#5F6D7E",
    cursor: "pointer",
  },
}));

export const SectionTitleRow = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #D1D9E2",
  width: "100%",
  paddingTop: 10,
  paddingBottom: 10,
  cursor: "pointer",
}));

export const SectionTitle = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontSize: 15,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#5F6D7E",
  cursor: "pointer",
}));

export const SectionDropdown = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  width: "100%",
  cursor: "pointer",
}));

export const DropdownArrow = styled(`img`)(({ rotated }) => ({
  transition: "transform 0.3s ease-in-out",
  transform: rotated ? "rotate(180deg)" : "rotate(0deg)",
  cursor: "pointer",
}));

export const Content = styled(Box)((isEditMode) => ({
  fontFamily: "Inter",
  fontSize: 15,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  paddingTop: isEditMode ? 25 : 16,
  paddingBottom: isEditMode ? 25 : 16,
  color: "#5F6D7E",
  transition: "color 300ms ease-in-out",
  cursor: "pointer",
  "&:hover": {
    color: "#4E4E4E",
    textDecoration: !isEditMode ? "underline" : "none",
  },
}));

export const ContentRow = styled(Box)((isEditMode) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 16,
  paddingLeft: 36,
  transition: "textDecoration 300ms ease-in-out",
  cursor: "pointer",
  "&:hover": {
    textDecoration: !isEditMode ? "underline" : "none",
  },
}));

export const SectionHeader = styled(Typography)(() => ({
  paddingTop: 40,
  paddingLeft: 6,
  marginBottom: "-10px",
  fontFamily: "Inter",
  fontSize: 20,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#4E4E4E",
  cursor: "pointer",
}));

export const ListItem = styled(`li`)(({}) => ({
  cursor: "pointer",
  paddingTop: 10,
  paddingBottom: 10,
  width: "100%",
  display: "flex",
  alignItems: "cneter",
}));
export const UpisRow = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: 48,
  width: "100%",
  "@media screen and (max-width: 800px)": {
    flexDirection: "column",
    gap: 5,
    alignItems: "flex-start",
    marginTop: 40,
  },
  "@media screen and (max-width: 600px)": {
    marginTop: 70,
  },
}));
export const UpisStyled = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#9FA7B2",
}));
export const InputContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  boxSizing: "border-box",
  gap: 6,
}));
export const Input = styled(`input`)(({ disabled }) => ({
  padding: 8,
  border: "1px solid #D8DBDF",
  borderRadius: 8,
  textAlign: "left",
  color: "#5F6D7E",
  boxShadow: "4px 4px 64px 0px #5F6D7E1A",
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.6,
  letterSpacing: " -0.001em",
  width: "85%",
  cursor: disabled ? "not-allowed" : "pointer",
  backgroundColor: disabled && "#F4F4F4",
  boxSizing: "border-box",
}));
