import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Blank from "../../assets/img/blank_profile_photo.jpeg";
import StudentsIcon from "../../assets/svg/StudentsIcon";
import useWindowResize from "../../components/Hooks/ResizeHook";
import Modal from "../../components/Modal/Modal";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import { Crop } from "./components/Crop";
import EditForm from "./components/EditForm/EditForm";
import ViewForm from "./components/ViewForm/ViewForm";
import styles from "./MyProfile.module.css";
import {
  fetchData,
  getActivePrograms,
  handleSubmit,
  programsByIds,
} from "./utils";

const apiUrl = process.env.REACT_APP_API_URL;

const backgroundColorMap = {
  RAR: "#87AD76",
  OR: "#CBA855",
  TT: "#A35254",
  TP: "#D64D51",
  Roditeljstvo: "#7BBA9F",
  VT: "#B8834F",
  RPO: "#3A54A1",
};

const MyProfile = () => {
  const location = useLocation();
  const isFirstLogin = location?.state?.isFirstLogin;
  const { userData, setUserData, role } = useUserState();
  const { authToken } = useAuthentication();
  const [selectedValue, setSelectedValue] = useState(
    userData?.phoneNumber &&
      userData?.phoneNumber?.length > 4 &&
      userData?.phoneNumber?.substring(0, 1) === "+"
      ? userData?.phoneNumber?.substring(0, 4)
      : ""
  );
  const [numericPhoneNumber, setNumericPhoneNumber] = useState("");
  const [activePrograms, setActivePrograms] = useState("");

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption.value);
  };
  const ref = useRef();

  const reset = () => {
    ref.current.value = "";
  };
  const [myProfileData, setMyProfileData] = useState({
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    dateOfBirth: userData?.dateOfBirth,
    gender: userData?.gender || "",
    city: userData?.city || "",
    address: userData?.address || "",
    country: userData?.country || "",
    programCity: userData?.programCity || "",
    qualification: userData?.qualification || "",
    title: userData?.title || "",
    OIB: userData?.OIB || "",
    phoneNumber: userData?.phoneNumber?.substring(4) || "",
    emailAddress: userData?.emailAddress || "",
    imageUrl: userData?.imageUrl || "",
  });

  useEffect(() => {
    if (userData) {
      setMyProfileData({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        dateOfBirth: userData.dateOfBirth,
        gender: userData.gender || "",
        city: userData.city || "",
        address: userData.address || "",
        country: userData.country || "",
        programCity: userData.programCity || "",
        qualification: userData.qualification || "",
        title: userData.title || "",
        OIB: userData.OIB || "",
        phoneNumber: userData?.phoneNumber?.substring(4) || "",
        emailAddress: userData.emailAddress || "",
        imageUrl: userData.imageUrl || "",
      });
    }
  }, [userData]);

  const [editMode, setEditMode] = useState(isFirstLogin);
  const [mjestoUpisaOptions, setMjestoUpisaOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [groupData, setGroupData] = useState();
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setOpenCrop(true);

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      const formData = new FormData();
      formData.append("image", file);
      const imageFile = formData.get("image");
      setUploadedFile(imageFile);
      setSelectedImage(imageUrl);
      setMyProfileData({ ...myProfileData, imageUrl: imageUrl });
      reset();
    }
  };
  const clearSelectedImage = () => {
    setSelectedImage(null);
    setUploadedFile(null);
    setMyProfileData({ ...myProfileData, imageUrl: null });
  };

  useEffect(() => {
    const getActive = async () => {
      await getActivePrograms(apiUrl, authToken, setActivePrograms);
    };
    if (authToken) getActive();
  }, [authToken]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const maxLength = 20;
    const truncatedValue = value.slice(0, maxLength);
    if (name === "emailAddress") {
      return;
    }
    if (name === "programCity") {
      setMyProfileData({
        ...myProfileData,
        [name]: value,
      });
    } else if (name === "phoneNumber") {
      setNumericPhoneNumber(value);
      if (value.charAt(0) === "0") {
        setMyProfileData({
          ...myProfileData,
          [name]: value.substring(1),
        });
      } else {
        setMyProfileData({
          ...myProfileData,
          [name]: value,
        });
      }
    } else if (name === "OIB") {
      setMyProfileData({
        ...myProfileData,
        [name]: truncatedValue,
      });
    } else {
      setMyProfileData({
        ...myProfileData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    if (authToken)
      fetchData(
        apiUrl,
        authToken,
        setGroupData,
        setMjestoUpisaOptions,
        userData
      );
  }, [authToken, userData]);
  const breakpoint = 900;
  const width = useWindowResize();
  const getBackgroundColor = (name) => {
    return backgroundColorMap[name] || "";
  };
  function filterDuplicates(array) {
    const uniqueNames = new Set();
    return array.filter((obj) => {
      if (!uniqueNames.has(obj.name)) {
        uniqueNames.add(obj.name);
        return true;
      }
      return false;
    });
  }

  return userData ? (
    <div>
      <div className={styles.profileContainer}>
        {openCrop && (
          <Crop
            src={selectedImage}
            setSelected={setSelectedImage}
            setOpen={setOpenCrop}
            setUploadedFile={setUploadedFile}
            setMyProfileData={setMyProfileData}
            myProfileData={myProfileData}
          />
        )}
        {isFirstLogin && <div className={styles.step}>1. korak</div>}
        <div
          style={{ display: "flex", gap: 20 }}
          className={styles.imageContainer}
        >
          <div className={styles.imageContainer}>
            {editMode ? (
              <>
                <input
                  type="file"
                  accept="image/*"
                  id="imageInput"
                  ref={ref}
                  style={{ display: "none", cursor: "pointer" }}
                  onChange={handleImageUpload}
                />
                <button
                  style={{
                    backgroundImage: selectedImage
                      ? `url(${selectedImage})`
                      : "none",
                    margin:
                      width > breakpoint
                        ? "32px 0px 0px 32px"
                        : "64px 0px 0px 24px",
                  }}
                  onClick={clearSelectedImage}
                  className={styles.imageUploadBtn}
                >
                  <label htmlFor="imageInput" className={styles.imageLabel}>
                    {!selectedImage ? (
                      <img
                        alt=""
                        src={
                          myProfileData.imageUrl === null ||
                          myProfileData.imageUrl === ""
                            ? Blank
                            : myProfileData.imageUrl
                        }
                        className={styles.image}
                        style={{ cursor: "pointer" }}
                      />
                    ) : null}
                  </label>
                </button>
              </>
            ) : (
              <img
                alt="profile_photo"
                src={myProfileData.imageUrl}
                className={styles.image}
                style={{
                  margin:
                    width > breakpoint
                      ? "32px 0px 0px 32px"
                      : "64px 0px 0px 16px",
                }}
              />
            )}
          </div>
          {isFirstLogin && (
            <div
              style={{
                width: 200,
                fontSize: 15,
                fontWeight: 600,
                opacity: 0.8,
                marginTop: 50,
              }}
              className={styles.groupsMemberTitle}
            >
              NAPOMENA: Veličinu svoje slike možeš prilagoditi uz pomoć ove
              aplikacije{" "}
              <a href="https://imageresizer.com/" target="_blank">
                Image resizer
              </a>
              . Preporučena širina (width) je 450px i visina (height) je 537px.
            </div>
          )}
        </div>
        <div>
          <div className={styles.fullNameRow}>
            <div
              className={styles.leftBox}
              style={{ marginTop: editMode && "-24px" }}
            >
              <h3
                className={styles.nameText}
              >{`${userData.firstName} ${userData.lastName}`}</h3>
              {role === "Polaznik" &&
                activePrograms &&
                activePrograms.length > 0 &&
                filterDuplicates(
                  activePrograms.map((item) => {
                    const programId = item.id;
                    const prefix = programsByIds.find((program) =>
                      program.value.includes(programId)
                    );
                    return {
                      color: getBackgroundColor(prefix.label),
                      name: `Polaznik ${
                        prefix === undefined ? "" : prefix.label + "-a"
                      }`,
                    };
                  })
                ).map((item, index) => (
                  <div
                    className={styles.badge}
                    key={index}
                    style={{ backgroundColor: item.color }}
                  >
                    <p className={styles.badgeText}>{item.name}</p>
                  </div>
                ))}
              {role === "Voditelj" &&
                activePrograms &&
                activePrograms.length > 0 &&
                filterDuplicates(
                  activePrograms.map((item) => {
                    const programId = item.id;
                    const prefix = programsByIds.find((program) =>
                      program.value.includes(programId)
                    );
                    return {
                      color: getBackgroundColor(prefix.label),
                      name: `Voditelj ${
                        prefix === undefined ? "" : prefix.label + "-a"
                      }`,
                    };
                  })
                ).map((item, index) => (
                  <div
                    className={styles.badge}
                    key={index}
                    style={{ backgroundColor: item.color }}
                  >
                    <p className={styles.badgeText}>{item.name}</p>
                  </div>
                ))}
            </div>
            {!editMode && (
              <div
                className={
                  width > breakpoint ? styles.rightBox : styles.mobileRightBox
                }
              >
                <div
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                  className={styles.editButton}
                >
                  <p className={styles.editButtonText}>Uredi profil</p>
                </div>
                {/* {userId === "Polaznik" && (
                  <div
                    onClick={() => {
                      navigate("#");
                      // navigate("/evidencija");
                    }}
                    className={styles.evidentationButton}
                  >
                    <p className={styles.evidentationButtonText}>
                      Pogledaj evidenciju
                    </p>
                    <ChevronRightIcon className={styles.buttonIcon} />
                  </div>
                )} */}
              </div>
            )}
          </div>
          <div
            className={
              width > breakpoint
                ? styles.profileInfoContainer
                : styles.profileInfoContainerMobile
            }
          >
            {editMode ? (
              <EditForm
                handleInputChange={handleInputChange}
                myProfileData={myProfileData}
                mjestoUpisaOptions={mjestoUpisaOptions}
                selectedValue={
                  selectedValue || userData?.phoneNumber?.substring(0, 4)
                }
                handleSelectChange={handleSelectChange}
                editMode={editMode}
                setEditMode={setEditMode}
                handleSubmit={handleSubmit}
                userData={userData}
                authToken={authToken}
                apiUrl={apiUrl}
                uploadedFile={uploadedFile}
                numericPhoneNumber={numericPhoneNumber}
                setShowModal={setShowModal}
                setUserData={setUserData}
                setModalContent={setModalContent}
                setLoading={setLoading}
                isFirstLogin={isFirstLogin}
              />
            ) : (
              <ViewForm userData={userData} />
            )}
            <div
              className={
                editMode
                  ? styles.rightProfileBoxDisabled
                  : styles.rightProfileBox
              }
            >
              <div className={styles.groupsMemberHeader}>
                <h3 className={styles.groupsMemberTitle}>Član grupa</h3>
                <StudentsIcon className={styles.groupsIcon} />
              </div>
              {groupData && groupData.groups.length === 0 ? (
                <>
                  <p className={styles.groupText}>
                    {userData.firstName} {userData.lastName} trenutno ne pripada
                    nijednoj grupi.
                  </p>
                </>
              ) : (
                <div className={styles.groupsBadges}>
                  {groupData &&
                    groupData.groups &&
                    groupData.groups.length > 0 &&
                    groupData.groups.map(
                      (item, index) =>
                        item !== null && (
                          <div className={styles.groupBadge} key={index}>
                            <p className={styles.groupBadgeText}>{item.name}</p>
                          </div>
                        )
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          description={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  ) : (
    <></>
  );
};
export default MyProfile;
