import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const addUsersToFw = async (data, accessToken) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/userInFacultativeWorkshop`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error adding user to Facultative workshop: ", error);
    throw error;
  }
};
