import React, { useState } from "react";
import Pagination from "../../../../../components/Pagination/Pagination";
import styles from "../OdobrenjeAplikacija.module.css";
import "./OdobrenjeTable.css";
import RenderTableData from "./RenderTableData";
import RenderTableHeader from "./RenderTableHeader";

function OdobrenjeTable({ data = [], columnNames }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const safeData = Array.isArray(data) ? data : [];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = safeData.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tablescroll}>
        <table className="table-odobrenje-aplikacija">
          <thead>
            <RenderTableHeader columnNames={columnNames} />
          </thead>
          <tbody>
            <RenderTableData data={currentUsers} />
          </tbody>
        </table>
      </div>
      <Pagination
        itemsToDisplay={data}
        setCurrent={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default OdobrenjeTable;
