import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getProgramById = async (accessToken, programID) => {
  try {
    const response = await axios.get(`${apiUrl}/api/program?id=${programID}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching pojedini program:", error);
    return [];
  }
};
export const getActivePrograms = async (accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/active-programs`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching pojedini program:", error);
    return [];
  }
};
