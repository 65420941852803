import React, { useState } from "react";
import SearchIcon from "../../../../assets/svg/SearchIcon.svg";
import styles from "./SearchGroups.module.css";

const SearchGroups = ({ groups, setSearchResults }) => {
  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);

    const searchName = input.trim().toLowerCase();
    if (searchName === "") {
      setSearchResults([]);
    } else {
      // const filteredGroups = groups.filter((group) => {
      //   const groupName = group.name.toLowerCase();
      //   return groupName.includes(searchName);
      // });
      setSearchResults(searchName);
    }
  };

  return (
    <label className={styles.searchGroupsInputContainer}>
      <img alt="search" src={SearchIcon} className={styles.searchGroupsIcon} />
      <input
        type="search"
        placeholder="Pretraži"
        className={styles.searchGroupsInput}
        value={searchInput}
        onChange={handleSearchInputChange}
      />
    </label>
  );
};

export default SearchGroups;
