import * as React from "react";
import { useUserState } from "../../context/UserContext";
import PolaznikVoditeljProgrami from "./PolaznikVoditelj/PolaznikVoditeljProgrami";
import styles from "./Programi.module.css";
import ReferadaProgrami from "./Referada/ReferadaProgrami";
import "./programi.css";

const Programi = () => {
  const { role } = useUserState();
  const userId = role;
  return (
    <div className={styles.wrapper}>
      {(userId === "Referada" || userId === "Supervizor") && (
        <ReferadaProgrami userId={userId} />
      )}
      {userId !== "Referada" && userId !== "Supervizor" && (
        <PolaznikVoditeljProgrami userId={userId} />
      )}
    </div>
  );
};

export default Programi;
