import styles from "./ToggleSwitch.module.css";

const ToggleSwitch = ({ name, label, value, onChange, onBlur, checked }) => {
  const handleToggle = () => {
    const newValue = !value;
    const syntheticEvent = {
      target: {
        name,
        value: newValue,
        checked: newValue,
      },
    };
    onChange(syntheticEvent);
  };
  return (
    <div className={styles.container}>
      <div
        onClick={handleToggle}
        className={value ? styles.toggleSwitchOn : styles.toggleSwitchOff}
      >
        <input
          className={styles.input}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
        />
        <div className={styles.slider} />
      </div>
      <p className={styles.label}>{label}</p>
    </div>
  );
};
export default ToggleSwitch;
