import styles from "./DodajPolaznika.module.css";
import DodajPolaznika from "./DodajPolaznikaForm.jsx";
import { useUserState } from "../../context/UserContext";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
const Polaznici = () => {
  const { userData } = useUserState();
  const isReferada = userData.roleId === 6;
  return (
    <>
      {isReferada ? (
        <div>
          <div className={styles.pageTitle}>Upis novog polaznika</div>
          <div className={styles.contentContainer}>
            <p className={styles.contentContainerText}>
              S obzirom da se radi o novom korisniku, potrebno je unijeti
              njegovu email adresu na koju će dobiti link na registraciju u
              sustav.
            </p>
            <p className={styles.contentContainerInfo}>
              Napomena: Email adresa korisnika mora biti s Google ili Apple
              account-a!
            </p>
          </div>
          <DodajPolaznika />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            gap: 10,
            backgroundColor: "#ffebeb",
            width: "100%",
            height: 40,
            borderRadius: 6,
            fontSize: 15,
            fontFamily: "Inter",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "500",
            color: "#5f6d7e",
          }}
        >
          <img alt="" src={InfoIcon} height={18} width={18} />
          Trenutno vam nije omogućen pristup ovoj stranici!
        </div>
      )}
    </>
  );
};

export default Polaznici;
