import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const createResource = async (data, accessToken) => {
  try {
    if (data.hasOwnProperty("resourceTempId")) {
      delete data.resourceTempId;
    }
    const response = await axios.post(`${apiUrl}/api/resource`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating resource", error);
    throw error;
  }
};
