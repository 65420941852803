import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const vrijemeOdrzavanja = [
  { value: "08:00", label: "08:00" },
  { value: "08:30", label: "08:30" },
  { value: "09:00", label: "09:00" },
  { value: "09:30", label: "09:30" },
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },
  { value: "20:00", label: "20:00" },
  { value: "20:30", label: "20:30" },
  { value: "21:00", label: "21:00" },
];
export const fetchRadionicaData = async (id, accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/facultativeWorkshop`, {
      params: {
        id,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log(response.data);
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const handleInputChange = (e, formData, setFormData) => {
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value,
  });
};

export const areAllFieldsFilled = (formData) => {
  for (const key in formData) {
    if (!formData[key]) {
      return false;
    }
  }
  return true;
};
export const columnNames = [
  "Ime i prezime",
  "Aktivni program/Grupa",
  "Email",
  "Broj mobitela",
];
