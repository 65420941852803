import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import styles from "./Dashboard.module.css";
import { fetchData } from "./api/getDashboardData";
import LeftContainer from "./components/LeftContainer/LeftContainer";
import { generateReferadaCardData } from "./components/MockData";
import RightContainer from "./components/RightContainer/RightContainer";

const Dashboard = () => {
  const { role } = useUserState();
  const { authToken } = useAuthentication();
  const userId = role;
  const [dashboardData, setDashboardData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (authToken) fetchData(authToken, setDashboardData);
  }, [authToken]);
  const ReferadaCardData = generateReferadaCardData(
    dashboardData.polaznici,
    dashboardData.zaposlenici,
    dashboardData.group
  );
  return (
    <>
      <Layout isDashboard>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <LeftContainer
              userId={userId}
              ReferadaCardData={ReferadaCardData}
            />
            {userId === "Referada" && (
              <RightContainer
                onClick={() => navigate("/sobe")}
                userId={userId}
              />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Dashboard;
