import styles from "./SelectDropdown.module.css";

const SelectDropdown = ({
  onChange,
  onBlur,
  value,
  options,
  name,
  title,
  initialOption,
  row,
  isDisabled,
}) => {
  return (
    <div className={row ? styles.containerRow : styles.container}>
      <h2
        className={`${styles.label} ${isDisabled ? styles.labelDisabled : ""}`}
      >
        {title}
      </h2>{" "}
      <select
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`${styles.select} ${isDisabled ? styles.disabled : ""}`}
        disabled={isDisabled}
      >
        {initialOption && <option value="">{initialOption}</option>}
        {options?.map((option, index) => (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectDropdown;
