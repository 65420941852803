import React, { useEffect, useState } from "react";
import Anonymous from "../../../../../../assets/img/Anonymous.png";
import Plus from "../../../../../../assets/img/WhitePlus.png";
import { useUserState } from "../../../../../../context/UserContext";
import { getUserById } from "../../../../../groups/api/getUserById";
import { useAuthentication } from "../../../../../hooks/useAuthentication";
import { formatDate } from "../../../../../myProfile/utils";
import KomentarModal from "../KomentarModal/KomentarModal";

function RenderTableData({ data = [], evidentionUserID, change, setChange }) {
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const [showModal, setShowModal] = useState(false);
  const [showFullOpis, setShowFullOpis] = useState([]);
  const [supervizori, setSupervizori] = useState([]);
  const [supId, setSupId] = useState();

  const toggleOpis = (index) => {
    const newShowFullOpis = [...showFullOpis];
    newShowFullOpis[index] = !newShowFullOpis[index];
    setShowFullOpis(newShowFullOpis);
  };

  const supervizorIds = data.map((item) => item.supervisorId);
  const uniqueSupervisorIds = Array.from(new Set(supervizorIds));

  useEffect(() => {
    const fetchSupervizori = async () => {
      try {
        const responses = await Promise.all(
          uniqueSupervisorIds.map((id) => getUserById(authToken, id))
        );
        setSupervizori(responses);
      } catch (error) {
        console.error("Error fetching supervisors:", error);
      }
    };
    if (authToken && uniqueSupervisorIds.length > 0) {
      fetchSupervizori();
    }
  }, [authToken]);

  const getSupervisorById = (id) => {
    return supervizori.find((supervisor) => supervisor.id === id);
  };

  const getSupervisionType = (type) => {
    switch (type) {
      case "individual_supervision":
        return "Individualna supervizija";
      case "ags":
        return "AGS";
      case "trijada":
        return "Trijada";
      default:
        return "Nema podataka";
    }
  };

  return (
    <>
      {data.map((item, index) => {
        const supervisor = getSupervisorById(item.supervisorId);

        return (
          <tr key={index}>
            <td>{item?.date ? formatDate(item?.date) : "Nema podataka"}</td>
            <td>
              {item?.supervisionType
                ? getSupervisionType(item?.supervisionType)
                : "Nema podataka"}
            </td>
            <td>
              <div className="table-evaluacije-row">
                <img
                  alt=""
                  className="table-evaluacije-avatar"
                  src={supervisor?.imageUrl ? supervisor.imageUrl : Anonymous}
                />
                {supervisor?.firstName && supervisor?.lastName
                  ? `${supervisor.firstName} ${supervisor.lastName}`
                  : "Nema podataka"}
              </div>
            </td>
            <td>
              {item?.comment ? (
                <>
                  <span
                    className="table-nastupna-predavanja-comment-span"
                    style={{
                      WebkitLineClamp: showFullOpis[index] ? "unset" : 2,
                      whiteSpace: showFullOpis[index] ? "normal" : "",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "48ch",
                    }}
                  >
                    {item?.comment}
                  </span>
                  {item?.comment.length > 32 && (
                    <div
                      onClick={() => toggleOpis(index)}
                      className="table-nastupna-predavanja-toggle"
                    >
                      {showFullOpis[index] ? "Prikaži manje" : "Prikaži više"}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {userData.id === item?.supervisorId ? (
                    <button
                      className="table-nastupna-predavanja-btn"
                      onClick={() => {
                        setSupId(item?.id);
                        setShowModal(true);
                      }}
                    >
                      Dodaj komentar <img alt="" src={Plus} />
                    </button>
                  ) : (
                    <span className="table-evaluacije-comment">
                      Komentar trenutno nije dodan.
                    </span>
                  )}
                </>
              )}
            </td>
          </tr>
        );
      })}
      {showModal && (
        <KomentarModal
          onClose={() => setShowModal(false)}
          evidentionUserID={evidentionUserID}
          authToken={authToken}
          supId={supId}
          change={change}
          setChange={setChange}
        />
      )}
    </>
  );
}

export default RenderTableData;
