import Error from "../../../../assets/img/IntrospekcijeError.png";
import Success from "../../../../assets/img/IntrospekcijeSuccess.png";

export const renderIntrospekcija = (introspection) => {
  return (
    <div className="introspekcije-header-separator" style={{ gap: 16 }}>
      <img
        src={introspection.isWritten ? Success : Error}
        alt={
          introspection.isWritten ? "Success - Napisana" : "Error - Napisana"
        }
      />
      <div className="introspekcije-table-divider" />
      <img
        src={introspection.isRead ? Success : Error}
        alt={introspection.isRead ? "Success - Procitana" : "Error - Procitana"}
      />
    </div>
  );
};
