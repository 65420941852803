import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const arhivirajDjelatnika = async (selectedIds, accessToken) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/users/archive`,
      {
        userIds: selectedIds,
        isArchived: true,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    console.log(response);
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error archiving user:", error);
    throw error;
  }
};
