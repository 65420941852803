import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "../../../../assets/svg/ArrowLeftIcon";
import MobileHeader from "../../../../components/MobileHeader/MobileHeader";
import UserMenu from "../../../../components/userMenu/UserMenu";
import { useUserState } from "../../../../context/UserContext";
import RegulationSection from "./components/RegulationSection";
import styles from "./Regulations.module.css";

const Regulations = () => {
  const { role } = useUserState();
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.MobileNav}>
        <MobileHeader />
      </div>
      <div className={styles.backButton} onClick={() => navigate("/dokumenti")}>
        <ArrowLeftIcon className={styles.backButtonArrow} />
        <p className={styles.backButtonText}>natrag</p>
      </div>
      <div className={styles.mobileTitle}>
        <h1 className={styles.heading}>Pravilnici</h1>
      </div>
      <div className={styles.container}>
        <div className={styles.headerRow}>
          <div className={styles.headerBox}>
            <div
              className={styles.backButton}
              onClick={() => navigate("/dokumenti")}
            >
              <ArrowLeftIcon className={styles.backButtonArrow} />
              <p className={styles.backButtonText}>natrag</p>
            </div>
            <h1 className={styles.heading}>Pravilnici</h1>
          </div>
          <UserMenu />
        </div>
        <div className={styles.content}>
          {role === "Referada" ? (
            <>
              <RegulationSection title="Supervizori" id={"Supervizor"} />
              <RegulationSection
                title="Voditelji grupa RAR i OR"
                id={"Voditelj"}
              />
              <RegulationSection title="Polaznik VT-a" id={"Polaznik VT-a"} />
              <RegulationSection
                title="Polaznici svih programa"
                id={"Polaznik"}
              />
              <RegulationSection title="Zakupitelj soba" id={"Zakupitelj"} />
            </>
          ) : (
            <RegulationSection title="Pravilnici IPD" id={role} />
          )}
        </div>
      </div>
    </>
  );
};
export default Regulations;
