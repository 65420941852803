import React from "react";
import { useUserState } from "../../context/UserContext";
import PrijavnicePolaznikVoditelj from "./PolaznikVoditelj/PrijavnicePolaznikVoditelj";

function PrijavnicePage() {
  const { role } = useUserState();
  const userId = role;

  return (
    <div>
      <PrijavnicePolaznikVoditelj userId={userId} />
    </div>
  );
}

export default PrijavnicePage;
