import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgramCard, {
  cardTypes,
} from "../../../../components/ProgramCard/Card";
import { useUserState } from "../../../../context/UserContext";
import { useAuthentication } from "../../../hooks/useAuthentication";
import styles from "./Završeni.module.css";
import { getFinishedPrograms, getProgramsByIds } from "./api";

function Završeni({ userId }) {
  const navigate = useNavigate();
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const [programData, setProgramData] = useState([]);
  const [showErr, setShowErr] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowErr(true);
    }, 1000);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const idProgramData = await getFinishedPrograms(userData.id, authToken);
        const programIds = idProgramData.map((program) => program.programId);
        const programDataArray = await getProgramsByIds(authToken, programIds);
        setProgramData(programDataArray);
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };
    if (authToken) fetchData();
  }, [authToken, userData]);
  if (programData.length === 0) {
    return showErr ? (
      <div className={styles.emptyContainer}>
        <span className={styles.emptySpan}>
          Trenutno nema završenih programa.
        </span>
      </div>
    ) : (
      <></>
    );
  }
  return (
    <div className={styles.wrapper}>
      {programData
        ?.sort((a, b) => a.id - b.id)
        ?.map((item, index) => (
          <ProgramCard
            key={index}
            variant={cardTypes.defaultCard}
            title={item.title}
            userId={userId}
            opis={item.description}
            brojPredavanja={item.brojPredavanja}
            trajanje={item?.title.includes("RAR") ? "2 mjeseca" : "12 mjeseci"}
            image={item.image}
            level={item.degree}
            polaznikProgrami
            onClick={() => navigate(`/pojediniprogram/${item.id}/one`)}
          />
        ))}
    </div>
  );
}

export default Završeni;
