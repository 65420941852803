import React, { useEffect, useState } from "react";
import ProgramCard, {
  cardTypes,
} from "../../../../components/ProgramCard/Card";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { getAllSupervisions } from "./api/getAllSupervisions";

function GrupneSupervizije({ userId, onClick }) {
  const { authToken } = useAuthentication();
  const [supervisions, setSupervisions] = useState([]);
  useEffect(() => {
    const fetchSupervisions = async () => {
      try {
        const response = await getAllSupervisions(authToken);
        setSupervisions(response);
      } catch (error) {
        console.error("Error fetching supervisions:", error);
      }
    };

    if (authToken) fetchSupervisions();
  }, []);

  return (
    <div className="subpage-wrapper">
      {supervisions.map((item, index) => (
        <ProgramCard
          key={index}
          variant={
            userId === "Polaznik" ? cardTypes.defaultCard : cardTypes.editCard
          }
          title={item.name}
          datum={item.date}
          userId={userId}
          image={item.imageURL}
          opis={item.description}
          voditelji={item.voditeljiCount}
          polaznici={item.polaznikCount}
          onClick={() => onClick(item.id)}
        />
      ))}
    </div>
  );
}

export default GrupneSupervizije;
