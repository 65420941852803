import React from "react";
import "./PredavanjeTable.css";

function RenderTableHeader({ columnNames = [], backgroundColor }) {
  if (!Array.isArray(columnNames)) {
    console.error("columnNames is not an array:", columnNames);
    return null;
  }

  return (
    <tr>
      {columnNames.map((columnName, index) => (
        <th
          key={index}
          className="table"
          style={{
            fontWeight: index === 0 ? 600 : "normal",
            fontSize: index === 0 ? 20 : "inherit",
            backgroundColor: backgroundColor || "initial",
          }}
        >
          {columnName}
        </th>
      ))}
    </tr>
  );
}

export default RenderTableHeader;
