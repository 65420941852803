export const eventOptions = [
  { value: "therapy", label: "Terapija", type: "therapy" },
  {
    value: "individual_supervision",
    label: "Supervizija 1 na 1",
    type: "individual_supervision",
  },
  { value: "other", label: "Ostalo", type: "other" },
];

export const vrstaSupervizije = [
  { value: "trijada", label: "Trijada", type: "trijada" },
];

export const infoLabels = ["Soba", "Početak", "Kraj"];
