import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "../../../../assets/svg/ArrowLeftIcon";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { formData } from "./__mocks__/formMock";
import { getAllApplications } from "./components/enrollmentForm/api/getAllApplications";
import Table from "./components/table/Table";
import styles from "./EnrollmentApplications.module.css";
import { programOptions } from "./utils";

const EnrollmentApplications = () => {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();

  const [applications, setApplications] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState("3,4,5,6");
  const [dataOnline, setDataOnline] = useState([]);
  const [updatedApplications, setUpdatedApplications] = useState([]);
  const handleSelectChange = (event) => {
    setSelectedProgram(event.target.value);
  };

  useEffect(() => {
    const eORIds = [7, 8, 9, 10];
    const defaultIds = [3, 4, 5, 6];
    const fetchApplications = async () => {
      const response = await getAllApplications(authToken);
      setApplications(response);
      const defaultData = response.filter((application) =>
        defaultIds.includes(application.programId)
      );
      setUpdatedApplications(defaultData);
      const online = response.filter((application) =>
        eORIds.includes(application.programId)
      );
      setDataOnline(online);
    };

    if (authToken) fetchApplications();
  }, [authToken]);

  const filterApplicationsByProgram = () => {
    if (selectedProgram === null || selectedProgram === "0") {
      return applications;
    } else {
      const selectedProgramArray = selectedProgram?.split(",")?.map(Number);
      const filteredApplications = applications.filter((application) =>
        selectedProgramArray.includes(application.programId)
      );

      return filteredApplications;
    }
  };

  useEffect(() => {
    const filteredApplications = filterApplicationsByProgram();

    setUpdatedApplications(filteredApplications);
  }, [selectedProgram]);

  return (
    <div className={styles.container}>
      <div className={styles.backButton} onClick={() => navigate("/dokumenti")}>
        <ArrowLeftIcon className={styles.backButtonArrow} />
        <p className={styles.backButtonText}>natrag</p>
      </div>
      <div className={styles.selectContainer}>
        <select className={styles.select} onChange={handleSelectChange}>
          {programOptions.map((program, index) => (
            <option value={program.value} key={index}>
              {program.label}
            </option>
          ))}
        </select>
      </div>
      <Table
        dataLive={updatedApplications}
        dataOnline={dataOnline}
        exportData={formData}
        selectedProgram={selectedProgram}
      />
    </div>
  );
};
export default EnrollmentApplications;
