import React from "react";
import { useNavigate } from "react-router-dom";
import Add from "../../../../assets/img/Add.png";
import Success from "../../../../assets/img/CheckRing.png";
import { formatDate } from "../../../myProfile/utils";
import styles from "./NastupnoPredavanje.module.css";

function NastupnoPredavanje({ userId, data }) {
  const navigate = useNavigate();
  const handleNavigateClick = async () => {
    navigate("/dodaj-nastupno-predavanje", {
      state: {
        disabledPredavanjaButton: data?.length === 2,
      },
    });
  };
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.raniRazvojHeader}>Nastupno predavanje</h1>
      <p className={styles.raniRazvojDescription}>
        Za postizanje zvanja Psihoterapeuta ITP-a potrebno je održati nastupno
        predavanje u okviru predavanja programa Osobni razvoj.
      </p>
      <div className={styles.predavanjaWrapper}>
        {data?.length === 0 && userId === "Polaznik" ? (
          <>
            <span className={styles.label}>
              Nastupno predavanje još nije održano.
            </span>
            <button
              className={styles.addPredavanjeBtn}
              onClick={() => navigate("/dodaj-nastupno-predavanje")}
            >
              Dodaj svoje nastupno predavanje
              <img alt="" src={Add} />
            </button>
          </>
        ) : (
          <>
            {(data?.[0]?.done || data?.[1]?.done) && (
              <img alt="" src={Success} className={styles.success} />
            )}
            <span className={styles.label}>
              {data?.[0]?.done || data?.[1]?.done
                ? "Nastupno predavanje održano."
                : "Nastupno predavanje još nije održano."}
            </span>
            {data?.map((item, index) => {
              if (data?.length === 1 && userId === "Polaznik") {
                return (
                  <>
                    <span
                      key={index}
                      className={styles.predavanjaLabel}
                    >{`(${formatDate(item?.date)} - ${item?.topic})`}</span>
                  </>
                );
              }
              if (data?.length === 2 && userId === "Polaznik") {
                return (
                  <>
                    <span
                      key={index}
                      className={styles.predavanjaLabel}
                    >{`(${formatDate(item?.date)} - ${item?.topic})`}</span>
                  </>
                );
              }
            })}
            {(data?.[1]?.done === false ||
              (data?.[0]?.done === false && !data?.length === 1)) &&
              userId === "Polaznik" && (
                <button
                  className={styles.addPredavanjeBtn}
                  onClick={() => handleNavigateClick()}
                >
                  Uredi predavanje
                  <img alt="" src={Add} />
                </button>
              )}
            {/* {data?.[1]?.done === !data?.[0]?.done && (
              <button
                className={styles.addPredavanjeBtn}
                onClick={() => handleNavigateClick()}
              >
                Uredi predavanje
                <img alt="" src={Add} />
              </button>
            )} */}
            {data?.length === 1 && (
              <button
                className={styles.addPredavanjeBtn}
                onClick={() => handleNavigateClick()}
              >
                Dodaj predavanje
                <img alt="" src={Add} />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default NastupnoPredavanje;
