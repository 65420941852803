export const tabsData = [
  { value: "one", label: "1.Stupanj" },
  { value: "two", label: "2.Stupanj" },
  { value: "three", label: "3.Stupanj" },
  { value: "four", label: "4.stupanj" },
  { value: "five", label: "Fakultativne radionice" },
  { value: "six", label: "Intenzivi" },
  { value: "seven", label: "Supervizije" },
  { value: "nine", label: "E-moduli uživo" },
  { value: "ten", label: "Voditeljski trening" },
  { value: "eleven", label: "Roditeljstvo 0-6" },
  { value: "twelve", label: "RPO" },
  // { value: "thirteen", label: "Ostalo" },
];
export const polaznikTabsData = [
  { value: "one", label: "Aktivni programi" },
  { value: "two", label: "Završeni programi" },
];
