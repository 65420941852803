import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getProgramById = async (accessToken, programID, setIsAllowed) => {
  try {
    const response = await axios.get(`${apiUrl}/api/program?id=${programID}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status !== 200) {
      setIsAllowed(false);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    setIsAllowed(true);
    return data;
  } catch (error) {
    setIsAllowed(false);
    console.error("Error fetching pojedini program:", error);
    return [];
  }
};

export const updateProgramById = async (update, accessToken, programID) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/program?programId=${programID}`,
      {
        description: update.description,
        iskustveniDio: update.iskustveniDio,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.status;
  } catch (error) {
    console.error("Error updating pojedini program:", error);
    return [];
  }
};

export const getAllInteraktivna = async (id, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/getInteractiveLessonsForProgram?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const updatedResponse = response.data.map((item) => ({
      ...item,
      resourceType: "video/mp4",
      url: "",
    }));
    return updatedResponse;
  } catch (error) {
    console.error("Error getting interactive lessons:", error);
    return [];
  }
};

export const getProgramsIntrospections = async (id, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/introspectionByProgramId?programId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting introspections:", error);
    return [];
  }
};

export const getIntrospectionData = async (id, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/userInIntrospection/byUser/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting introspections data:", error);
    return [];
  }
};
