import React, { useEffect, useRef, useState } from "react";
import Dots from "../../../../assets/img/Dots.png";
import styles from "./ArhiviraniButton.module.css";

function ArhiviraniButton({ onClick }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const dropdownRefs = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDropdown &&
        dropdownRefs.current &&
        !dropdownRefs.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);
  return (
    <div>
      <div className={styles.arhiviraniPolaznikButton} onClick={handleDropdown}>
        <span>Arhivirani polaznik</span>
        <img alt="" src={Dots} />
      </div>
      {showDropdown && (
        <div
          className={styles.dropdownWrapper}
          ref={dropdownRefs}
          onClick={onClick}
        >
          <span>Upiši na program</span>
        </div>
      )}
    </div>
  );
}

export default ArhiviraniButton;
