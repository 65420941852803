import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCities = async (accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/cities`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.data.map((city) => ({
      label: city.cityName,
      value: city.id,
    }));
  } catch (error) {
    console.error("Error fetching cities:", error);
    throw error;
  }
};

export const fetchUsersByRole = async (role, accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/usersByRole/${role}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.data.map((user) => ({
      ...user,
      id: user.id,
    }));
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const deleteUserApiRequest = async (data, accessToken) => {
  try {
    const response = await axios.delete(`${apiUrl}/api/users`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data,
    });

    console.log("Delete User Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
