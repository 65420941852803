import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useAuthentication } from "../../../hooks/useAuthentication";
import CustomInput from "../../../myProfile/components/customInput/CustomInput";
import {
  getGroupByProgramId,
  getInteractiveLessonsForProgram,
  getModulesForProgram,
  getRegularWorkshopsForModule,
} from "../calendar.api";
import {
  izaberiDogadjaj,
  izaberiObjava,
  rooms,
  timeSlots,
} from "../calendar.mocks";
import { eRAReORProgrami, programSelectData } from "../calendar.utils";
import styles from "./AddEventDialog.module.css";
import {
  formatDate,
  formatTime,
  handleCreateEvent,
  handleDeleteEvent,
  handleInputChange,
  handleUpdateEvent,
  selectStyles,
} from "./utils";

export const LargeTextBox = styled(Box)(() => ({
  width: "100%",
  marginTop: 5,
  fontSize: 18,
}));
//type edit, add-local,add-global
const AddEventDialog = ({
  open,
  onClose,
  type,
  clickedEvent,
  selectedEmptyTimeSlot,
  startYear,
  endYear,
  setCalendarData,
  setOpenModal,
}) => {
  const { authToken } = useAuthentication();
  const [regularWorkshops, setRegularWorkshops] = useState();
  const [modulesForPrograms, setModulesForPrograms] = useState();
  const [interactiveLessonsForProgram, setInteractiveLessonsForProgram] =
    useState();
  const [groupsByProgramId, setGroupsByProgramId] = useState();
  const [openDialogNew, setOpenDialogNew] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [formValues, setFormValues] = useState({
    eventType: "",
    id: "",
    programId: "",
    regularWorkshop: "",
    interactiveLessons: "",
    masterAbbreviation: "",
    date: "",
    timeFrom: "",
    timeTo: "",
    modulId: "",
    groupId: "",
    roomId: "",
    isPublished: false,
  });
  useEffect(() => {
    if (formValues.programId !== "") {
      getModulesForProgram(
        formValues.programId,
        authToken,
        setModulesForPrograms
      );
      getInteractiveLessonsForProgram(
        formValues.programId,
        authToken,
        setInteractiveLessonsForProgram
      );
      getGroupByProgramId(
        formValues.programId,
        authToken,
        setGroupsByProgramId
      );
      if (
        formValues.modulId !== "" &&
        formValues.eventType === "regular_workshop"
      ) {
        getRegularWorkshopsForModule(
          formValues.modulId,
          authToken,
          setRegularWorkshops
        );
      }
    }
  }, [authToken, formValues.programId, formValues.modulId]);
  useEffect(() => {
    if (type === "add-local" || type === "edit") {
      setFormValues({
        eventType: type === "edit" ? clickedEvent.sourceObjectName : "",
        id: type === "edit" ? clickedEvent.id : "",
        programId: type === "edit" ? clickedEvent.programId : "",
        masterAbbreviation:
          type === "edit" ? clickedEvent.masterAbbreviation : "",
        date:
          type === "edit"
            ? formatDate(clickedEvent?.date)
            : type === "add-local"
            ? formatDate(selectedEmptyTimeSlot?.start)
            : "",
        timeFrom:
          type === "edit"
            ? clickedEvent?.timeFrom
            : type === "add-local"
            ? formatTime(selectedEmptyTimeSlot?.start)
            : "",
        timeTo:
          type === "edit"
            ? clickedEvent?.timeTo
            : type === "add-local"
            ? formatTime(selectedEmptyTimeSlot?.end)
            : "",
        modulId: type === "edit" ? clickedEvent.modulId : "",
        groupId: type === "edit" ? clickedEvent.groupId : "",
        roomId:
          type === "edit"
            ? clickedEvent.roomId
            : type === "add-local"
            ? selectedEmptyTimeSlot?.room
            : "",
        isPublished: type === "edit" ? clickedEvent.isPublished : false,
      });
    }
  }, [type]);
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className={styles.dialogTitle}>
          {type === "edit" ? "Uredi događaj" : "Dodaj događaj"}
        </DialogTitle>
        <DialogContent>
          <Typography className={styles.label}>Vrsta događaja</Typography>
          <select
            value={formValues.eventType}
            name="eventType"
            onChange={(event) => {
              handleInputChange("eventType", event.target.value, setFormValues);
            }}
            style={selectStyles}
          >
            <option hidden selected>
              Odaberi
            </option>
            {izaberiDogadjaj.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <Typography className={styles.label}>Program</Typography>
          <select
            value={formValues.programId}
            name="programId"
            onChange={(event) => {
              handleInputChange("programId", event.target.value, setFormValues);
            }}
            style={selectStyles}
          >
            <option hidden selected>
              Odaberi
            </option>
            {(clickedEvent?.roomId === 8 || selectedEmptyTimeSlot?.room === 8
              ? eRAReORProgrami
              : programSelectData(formValues.eventType)
            ).map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {formValues?.eventType === "interactive_lesson" && (
            <div>
              <Typography className={styles.label}>Predavanje</Typography>
              <select
                value={formValues.interactiveLessons}
                onChange={(event) => {
                  handleInputChange(
                    "interactiveLessons",
                    event.target.value,
                    setFormValues
                  );
                }}
                className={styles.select}
              >
                <option hidden selected>
                  Odaberi
                </option>
                {interactiveLessonsForProgram?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {formValues?.eventType === "regular_workshop" && (
            <>
              <div>
                <Typography className={styles.label}>Modul</Typography>
                <select
                  value={formValues.modulId}
                  onChange={(event) => {
                    handleInputChange(
                      "modulId",
                      event.target.value,
                      setFormValues
                    );
                  }}
                  className={styles.select}
                >
                  <option hidden selected>
                    Odaberi
                  </option>
                  {modulesForPrograms?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.moduleName}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <Typography className={styles.label}>Radionica</Typography>
                <select
                  value={formValues.regularWorkshop}
                  onChange={(event) => {
                    handleInputChange(
                      "regularWorkshop",
                      event.target.value,
                      setFormValues
                    );
                  }}
                  className={styles.select}
                >
                  <option hidden selected>
                    Odaberi
                  </option>
                  {regularWorkshops?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
          <Typography className={styles.label}>Kratica</Typography>
          <CustomInput
            name="masterAbbreviation"
            onChange={(event) => {
              handleInputChange(
                "masterAbbreviation",
                event.target.value,
                setFormValues
              );
            }}
            value={formValues.masterAbbreviation}
            placeholder="Upiši"
          />
          <div>
            <Typography className={styles.label}>Datum održavanja</Typography>
            <input
              type="date"
              name="startDate"
              value={formValues.date}
              placeholder="Odaberi"
              className={styles.dateInput}
              onChange={(event) => {
                handleInputChange("date", event.target.value, setFormValues);
              }}
            />
            <Typography className={styles.label}>Vrijeme održavanja</Typography>
            <div>
              <select
                name="timeFrom"
                value={formValues.timeFrom}
                onChange={(event) => {
                  handleInputChange(
                    "timeFrom",
                    event.target.value,
                    setFormValues
                  );
                }}
                style={{
                  ...selectStyles,
                  width: "255px",
                  marginRight: "8px",
                }}
              >
                {timeSlots.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <select
                name="timeTo"
                value={formValues.timeTo}
                onChange={(event) => {
                  handleInputChange(
                    "timeTo",
                    event.target.value,
                    setFormValues
                  );
                }}
                style={{
                  ...selectStyles,
                  width: "255px",
                  marginRight: "8px",
                }}
              >
                {timeSlots.map((option, index) => (
                  <option
                    disabled={option.value.split(":")[0] < formValues.timeFrom}
                    key={index}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {formValues?.eventType !== "interactive_lesson" && (
            <div>
              <Typography className={styles.label}>Grupa</Typography>
              <select
                value={formValues.groupId}
                onChange={(event) => {
                  handleInputChange(
                    "groupId",
                    event.target.value,
                    setFormValues
                  );
                }}
                style={selectStyles}
              >
                <option hidden selected>
                  Odaberi
                </option>
                {groupsByProgramId?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div>
            <Typography className={styles.label}>Soba</Typography>
            <select
              value={formValues.roomId}
              name="roomId"
              style={selectStyles}
              onChange={(event) => {
                handleInputChange("roomId", event.target.value, setFormValues);
              }}
            >
              <option hidden selected>
                Odaberi
              </option>
              {rooms.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <Typography className={styles.label}>Objavi event</Typography>
          <select
            value={formValues.isPublished}
            name="isPublished"
            onChange={(event) => {
              handleInputChange(
                "isPublished",
                event.target.value,
                setFormValues
              );
            }}
            style={selectStyles}
          >
            {izaberiObjava.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </DialogContent>
        <DialogActions
          className={styles.dialogActions}
          style={{
            justifyContent: type === "edit" ? "space-between" : "flex-end",
          }}
        >
          {type === "edit" && (
            <IconButton
              className={styles.iconButton}
              onClick={() =>
                handleDeleteEvent(
                  formValues,
                  authToken,
                  startYear,
                  endYear,
                  setCalendarData,
                  setOpenModal(false)
                )
              }
              aria-label="Delete"
            >
              <DeleteIcon />
            </IconButton>
          )}
          <div>
            <Button
              onClick={onClose}
              className={styles.cancelButton}
              variant="contained"
            >
              Odustani
            </Button>
            <Button
              onClick={() => {
                console.log(formValues);
                type === "edit"
                  ? handleUpdateEvent(
                      formValues,
                      authToken,
                      startYear,
                      endYear,
                      setCalendarData,
                      setOpenModal(false)
                    )
                  : handleCreateEvent(
                      formValues,
                      authToken,
                      startYear,
                      endYear,
                      setCalendarData,
                      setOpenModal(false)
                    );
              }}
              sx={{ backgroundColor: "#CB9C2D" }}
              variant="contained"
            >
              {type === "edit" ? "Uredi događaj" : "Kreiraj događaj"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogNew}
        onClose={() => setOpenDialogNew(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialogNew(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEventDialog;
