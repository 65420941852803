import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const deleteEvidentionEntry = async (data, accessToken) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/api/deleteEvidentionEntry?eventId=${data.eventId}&userId=${data.userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting evidention entry:", error);
    throw error;
  }
};
