import { getProgramById } from "./API";

export const getIconByType = (type) => {
  const lowercaseType = type.toLowerCase();
  if (lowercaseType.includes("uvod")) {
    return (
      <img alt="Uvod Icon" src={require("../../assets/img/UvodIcon.png")} />
    );
  } else if (lowercaseType.includes("pdf")) {
    return <img alt="PDF Icon" src={require("../../assets/img/PDFIcon.png")} />;
  } else if (lowercaseType.includes("text")) {
    return (
      <img
        alt="Introspekcije Icon"
        src={require("../../assets/img/IntrospekcijeIcon.png")}
      />
    );
  } else if (lowercaseType.includes("audio")) {
    return (
      <img alt="Audio Icon" src={require("../../assets/img/AudioIcon.png")} />
    );
  } else if (lowercaseType.includes("introspection")) {
    return (
      <img
        alt="Introspekcije"
        src={require("../../assets/img/IntrospekcijeIcon.png")}
      />
    );
  } else {
    return (
      <img alt="Video Icon" src={require("../../assets/img/Videoicon.png")} />
    );
  }
};
export const fetchProgramData = async (
  accessToken,
  program_id,
  setProgramData,
  setIsAllowed
  // isPolaznik,
  // userId
) => {
  try {
    const allProgramData = await getProgramById(
      accessToken,
      program_id,
      setIsAllowed
      // isPolaznik,
      // userId
    );
    setProgramData(allProgramData);
  } catch (error) {
    console.error("Error in fetchProgramData:", error);
  }
};
