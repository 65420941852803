import { Radio, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import ArrowLeft from "../../../assets/img/BackArrow.png";
import Success from "../../../assets/img/Success.png";
import Modal from "../../../components/Modal/Modal";
import { useUserState } from "../../../context/UserContext";
import { useAuthentication } from "../../hooks/useAuthentication";
import { createEvaluation } from "../api/createEvaluation";
import { createOsvrt } from "../api/createOsvrt";
import { getRemainingVoditeljiEvaluations } from "../api/getRemainingVoditeljiEvaluations";
import { getRemainingVoditeljiOsvrti } from "../api/getRemainingVoditeljiOsvrti";
import { getSchoolYear } from "../api/getSchoolYear";
import { getVoditelji } from "../api/getVoditelji";
import styles from "../OsvrtiNaVoditelja.module.css";
import { evaluations, selectStyles } from "../utils";
import EvaluacijaItem from "./EvaluacijaItem";

function PisanjeOsvrtaNaVoditelje() {
  const { authToken } = useAuthentication();
  const { userData } = useUserState();
  const evaluationItemsContainerRef = useRef(null);
  const [voditeljiDataOsvrti, setVoditeljiDataOsvrti] = useState([]);
  const [voditeljiDataEvaluacije, setVoditeljiDataEvaluacije] = useState([]);
  const [allVoditeljiData, setAllVoditeljiData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledEvaluations, setIsDisabledEvaluations] = useState(true);
  const [selectedVoditeljOsvrt, setSelectedVoditeljOsvrt] = useState(null);
  const [selectedVoditeljEvaluacija, setSelectedVoditeljEvaluacija] =
    useState(null);
  const [selectedAnonymityOption, setSelectedAnonymityOption] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEvaluationModalOpen, setIsEvaluationModalOpen] = useState(false);
  const [activeEvaluation, setActiveEvaluation] = useState(1);
  const [schoolYear, setSchoolYear] = useState("");
  const [text, setText] = useState("");
  const [evaluationValues, setEvaluationValues] = useState(
    Array(evaluations.length).fill(null)
  );
  const [reFetch, setReFetch] = useState(false);

  const schoolYears = [
    { start: "2023-09-01", end: "2024-08-31", index: 1 },
    { start: "2024-09-01", end: "2025-08-31", index: 2 },
    { start: "2025-09-01", end: "2026-08-31", index: 3 },
    { start: "2026-09-01", end: "2027-08-31", index: 4 },
  ];

  function getSchoolYearIndex(currentDate) {
    const current = new Date(currentDate);

    for (const year of schoolYears) {
      const start = new Date(year.start);
      const end = new Date(year.end);

      if (current >= start && current <= end) {
        return year.index;
      }
    }
    return null;
  }

  useEffect(() => {
    const fetchSchoolYear = async () => {
      try {
        const response = await getSchoolYear(authToken);
        setSchoolYear(response[0].date);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (authToken) fetchSchoolYear();
  }, [authToken]);

  const isBeforeHalfDate = (date) => {
    const currentSchoolYear = new Date(schoolYear);
    const halfDate = new Date(
      currentSchoolYear.getFullYear(),
      currentSchoolYear.getMonth(),
      currentSchoolYear.getDay()
    );
    return date < halfDate;
  };

  const handleRadioChange = () => {
    setSelectedOption(!selectedOption);
  };

  const handleAnonymityRadioChange = () => {
    setSelectedAnonymityOption(!selectedAnonymityOption);
  };

  const handleReset = () => {
    setSelectedAnonymityOption(false);
    setSelectedOption(false);
    setSelectedVoditeljOsvrt(null);
    setText("");
  };

  const handleSubmitOsvrt = async () => {
    if (selectedVoditeljOsvrt && text.length > 20) {
      const submitData = {
        allowPosting: selectedOption,
        isAnonymous: selectedAnonymityOption,
        voditeljId: selectedVoditeljOsvrt.value,
        polaznikId: userData.id,
        osvrtText: text,
      };
      await createOsvrt(submitData, authToken);
      setIsModalOpen(true);
      setReFetch(!reFetch);
      handleReset();
    }
  };

  const successDeleteButtons = [
    {
      label: "Zatvori",
      action: () => {
        setIsModalOpen(false);
        setIsEvaluationModalOpen(false);
      },
      className: "modal-btn-deletePage modal-btn-discontinue-deletePage",
    },
  ];

  useEffect(() => {
    if (selectedVoditeljOsvrt && text.length > 20) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedVoditeljOsvrt, text]);

  useEffect(() => {
    if (
      selectedVoditeljEvaluacija &&
      !evaluationValues.some((evaluation) => evaluation === null)
    ) {
      setIsDisabledEvaluations(false);
    } else {
      setIsDisabledEvaluations(true);
    }
  }, [selectedVoditeljEvaluacija, evaluationValues]);

  const handleEvaluationReset = () => {
    setSelectedVoditeljEvaluacija(null);
    setEvaluationValues(Array(evaluations.length).fill(null));
  };

  const date = new Date();

  const handleSubmitEvaluacije = async () => {
    if (
      selectedVoditeljEvaluacija &&
      !evaluationValues.some((evaluation) => evaluation === null)
    ) {
      const submitData = {
        polaznikId: userData.id,
        voditeljId: selectedVoditeljEvaluacija.value,
        yearIndex: getSchoolYearIndex(date),
        evaluationIndex: isBeforeHalfDate(date) ? 1 : 2,
        grades: [
          {
            gradeId: 1,
            grade: evaluationValues[0],
          },
          {
            gradeId: 2,
            grade: evaluationValues[1],
          },
          {
            gradeId: 3,
            grade: evaluationValues[2],
          },
          {
            gradeId: 4,
            grade: evaluationValues[3],
          },
          {
            gradeId: 5,
            grade: evaluationValues[4],
          },
          {
            gradeId: 6,
            grade: evaluationValues[5],
          },
          {
            gradeId: 7,
            grade: evaluationValues[6],
          },
          {
            gradeId: 8,
            grade: evaluationValues[7],
          },
          {
            gradeId: 9,
            grade: evaluationValues[8],
          },
          {
            gradeId: 10,
            grade: evaluationValues[9],
          },
          {
            gradeId: 11,
            grade: evaluationValues[10],
          },
          {
            gradeId: 12,
            grade: evaluationValues[11],
          },
          {
            gradeId: 13,
            grade: evaluationValues[12],
          },
          {
            gradeId: 14,
            grade: evaluationValues[13],
          },
          {
            gradeId: 15,
            grade: evaluationValues[14],
          },
          {
            gradeId: 16,
            grade: evaluationValues[15],
          },
          {
            gradeId: 17,
            grade: evaluationValues[16],
          },
        ],
      };
      await createEvaluation(submitData, authToken);
      setIsEvaluationModalOpen(true);
      setReFetch(!reFetch);
      handleEvaluationReset();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getVoditelji(authToken);
        setAllVoditeljiData(response);
        const remainingVoditeljiOsvrtiIds = await getRemainingVoditeljiOsvrti(
          authToken
        );
        const remainingVoditeljiOsvrti = response.filter((voditelj) =>
          remainingVoditeljiOsvrtiIds.includes(voditelj.id)
        );
        setVoditeljiDataOsvrti(remainingVoditeljiOsvrti);
        const remainingVoditeljiEvaluacije =
          await getRemainingVoditeljiEvaluations(authToken);

        setVoditeljiDataEvaluacije(remainingVoditeljiEvaluacije);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (authToken) fetchData();
  }, [authToken, reFetch]);

  const selectOptionsEvaluacije = voditeljiDataEvaluacije.map((voditelj) => ({
    value: voditelj.id,
    label: `${voditelj.firstName} ${voditelj.lastName}`,
  }));

  const selectOptions = voditeljiDataOsvrti.map((voditelj) => ({
    value: voditelj.id,
    label: `${voditelj.firstName} ${voditelj.lastName}`,
  }));

  const handlePreviousClick = () => {
    setActiveEvaluation(activeEvaluation > 1 ? activeEvaluation - 1 : 1);
    if (evaluationItemsContainerRef.current && activeEvaluation === 17) {
      evaluationItemsContainerRef.current.scrollLeft -= 580;
      setActiveEvaluation(15);
    } else if (evaluationItemsContainerRef.current) {
      evaluationItemsContainerRef.current.scrollLeft -= 640;
    } else {
      console.log("Error");
    }
  };

  const handleNextClick = () => {
    setActiveEvaluation(activeEvaluation < 17 ? activeEvaluation + 1 : 17);
    if (evaluationItemsContainerRef.current) {
      evaluationItemsContainerRef.current.scrollLeft += 640;
    }
  };

  return (
    <div style={{ padding: 5 }}>
      {isModalOpen && (
        <Modal
          image={Success}
          description="Osvrt uspješno poslan!"
          buttons={successDeleteButtons}
        />
      )}
      {isEvaluationModalOpen && (
        <Modal
          image={Success}
          description="Evaluacija uspješno poslana!"
          buttons={successDeleteButtons}
        />
      )}
      <div className={styles.text}>
        Obavezno prije pisanja prvo odaberi voditelja o kojem želiš napisati
        komentar, a na dnu se klikom na DA ili NE odredi oko anonimnosti i
        eventualne objave tvog komentara.
      </div>
      <div className={styles.containerVoditelj}>
        <h1 className={styles.bioTitle}>Voditelj</h1>
        <Select
          placeholder="Odaberi"
          styles={selectStyles}
          isSearchable={false}
          components={{ IndicatorSeparator: () => null }}
          options={selectOptions}
          onChange={setSelectedVoditeljOsvrt}
          value={selectedVoditeljOsvrt}
        />
      </div>
      <div className={styles.containerBio}>
        <h1 className={styles.bioTitle}>Moj osvrt</h1>
        <textarea
          className={styles.textarea}
          placeholder="Dodaj osvrt"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div className={styles.radioContainer}>
        <h1 className={styles.radioTitle}>
          Označi ukoliko se slažeš ako bismo eventualno htjeli objaviti tvoj
          osvrt kao svjedočanstvo na našim društvenim mrežama.
        </h1>
        <div className={styles.radioBox}>
          <Radio
            value={true}
            checked={selectedOption}
            onChange={handleRadioChange}
            className={`${styles.customRadio} ${
              selectedOption ? styles.customRadioChecked : ""
            }`}
          />
          <Typography className={styles.radioLabel}>Da</Typography>
          <Radio
            value={false}
            checked={!selectedOption}
            onChange={handleRadioChange}
            className={`${styles.customRadio} ${
              !selectedOption ? styles.customRadioChecked : ""
            }`}
          />
          <Typography className={styles.radioLabel}>Ne</Typography>
        </div>
        <h1 className={styles.radioTitle}>
          Označi ukoliko želiš da tvoj osvrt bude anoniman.
        </h1>
        <div className={styles.radioBox}>
          <Radio
            value={true}
            checked={selectedAnonymityOption}
            onChange={handleAnonymityRadioChange}
            className={`${styles.customRadio} ${
              selectedAnonymityOption ? styles.customRadioChecked : ""
            }`}
          />
          <Typography className={styles.radioLabel}>Da</Typography>
          <Radio
            value={false}
            checked={!selectedAnonymityOption}
            onChange={handleAnonymityRadioChange}
            className={`${styles.customRadio} ${
              !selectedAnonymityOption ? styles.customRadioChecked : ""
            }`}
          />
          <Typography className={styles.radioLabel}>Ne</Typography>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.cancelButton} onClick={handleReset}>
          Resetiraj
        </button>
        <button
          className={styles.nextButton}
          onClick={handleSubmitOsvrt}
          style={isDisabled ? { opacity: 0.6 } : {}}
        >
          Pošalji osvrt
        </button>
      </div>
      <div className={styles.evaluationContainer}>
        <div className={styles.title}>Evaluacija voditelja</div>
        <div className={styles.text}>
          Ocjeni svog voditelja (odabranog u polju iznad) tako da klikneš na
          opis koji smatraš da odgovara tome koliko se navedena karakteristika
          odnosi na tvog određenog voditelja:
        </div>
        <div className={styles.containerVoditelj}>
          <h1 className={styles.bioTitle}>Voditelj</h1>
          <Select
            placeholder="Odaberi"
            styles={selectStyles}
            isSearchable={false}
            components={{ IndicatorSeparator: () => null }}
            options={selectOptionsEvaluacije}
            onChange={setSelectedVoditeljEvaluacija}
            value={selectedVoditeljEvaluacija}
          />
        </div>
        <div className={styles.evaluationNumber}>
          {activeEvaluation}/{evaluations.length}
        </div>
        <div
          className={styles.evaluationItemsContainer}
          ref={evaluationItemsContainerRef}
        >
          {evaluations.map((evaluation, index) => (
            <EvaluacijaItem
              key={index}
              index={index}
              title={evaluation.title}
              description={evaluation.description}
              evaluationValues={evaluationValues}
              setEvaluationValues={setEvaluationValues}
            />
          ))}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            gap: 30,
            marginTop: 20,
            userSelect: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              gap: 7,
              color: "#5F6D7E",
              fontFamily: "Inter",
              fontSize: 16,
              fontWeight: 600,
              textAlign: "left",
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={handlePreviousClick}
          >
            <img src={ArrowLeft} alt="" /> Prev
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              gap: 7,
              color: "#5F6D7E",
              fontFamily: "Inter",
              fontSize: 16,
              fontWeight: 600,
              textAlign: "left",
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={handleNextClick}
          >
            Next
            <img src={ArrowLeft} alt="" style={{ rotate: "180deg" }} />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <button
            className={styles.cancelButton}
            onClick={handleEvaluationReset}
          >
            Resetiraj
          </button>
          <button
            className={styles.nextButton}
            onClick={handleSubmitEvaluacije}
            style={isDisabledEvaluations ? { opacity: 0.6 } : {}}
          >
            Pošalji evaluaciju
          </button>
        </div>
      </div>
    </div>
  );
}

export default PisanjeOsvrtaNaVoditelje;
