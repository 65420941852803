import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProfilePhoto from "../../assets/img/blank_profile_photo.jpeg";
import Pagination from "../../components/Pagination/Pagination.jsx";
import { useAuthentication } from "../hooks/useAuthentication.js";
import styles from "./Terapeuti.module.css";
import { getAllTherapistsSupervisors } from "./api/getAllTherapists";
import TerapeutCard from "./components/TerapeutCard.jsx";
import TerapeutCardSearch from "./components/TerapeutSearch.jsx";

const Terapeuti = () => {
  const [supervisors, setSupervisors] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const { authToken, userData } = useAuthentication();
  const [therapists, setTherapists] = useState([]);
  const [isPolaznikTP, setIsPolaznikTP] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [currentTherapistPage, setCurrentTherapistPage] = useState(1);
  const [currentSupervisorPage, setCurrentSupervisorPage] = useState(1);
  const itemsPerPage = 12;
  const indexOfLastTherapistItem = currentTherapistPage * itemsPerPage;
  const indexOfFirstTherapistItem = indexOfLastTherapistItem - itemsPerPage;
  const indexOfLastSupervisorItem = currentSupervisorPage * itemsPerPage;
  const indexOfFirstSupervisorItem = indexOfLastSupervisorItem - itemsPerPage;
  useEffect(() => {
    if (userData?.programs) {
      userData?.programs.map((item) => {
        if (item >= 13) {
          setIsPolaznikTP(true);
        }
      });
    }
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllTherapistsSupervisors(authToken);
        const therapists = response?.filter(
          (item) => item.therapistRole === 1 || item.therapistRole === 2
        );
        const sortedTherapists = therapists.sort((a, b) =>
          a.lastName.localeCompare(b.lastName)
        );
        const sortedByActivity = sortedTherapists.sort(
          (a, b) => b.availableLive - a.availableLive
        );
        const sortedByActivityOnline = sortedByActivity.sort(
          (a, b) => b.availableOnline - a.availableOnline
        );

        setTherapists(sortedByActivityOnline);
        const supervisors = response?.filter(
          (item) => item.therapistRole === 3
        );
        const sortedSupervisors = supervisors.sort((a, b) =>
          a.lastName.localeCompare(b.lastName)
        );
        const sortedByActivitySupervisors = sortedSupervisors.sort(
          (a, b) => b.availableLive - a.availableLive
        );
        const sortedByActivityOnlineSupervisors =
          sortedByActivitySupervisors.sort(
            (a, b) => b.availableOnline - a.availableOnline
          );
        setSupervisors(sortedByActivityOnlineSupervisors);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (authToken) fetchData();
  }, [authToken]);

  const currentTherapists = therapists?.slice(
    indexOfFirstTherapistItem,
    indexOfLastTherapistItem
  );
  const currentSupervisors = supervisors?.slice(
    indexOfFirstSupervisorItem,
    indexOfLastSupervisorItem
  );

  return (
    <div>
      <div className={styles.text}>
        Na ovoj stranici pronaći ćeš popis praktičara i terapeuta Integralne
        tjelesne psihoterapije. Terapeuti su diplomirani terapeuti ITP-a koji
        imaju završeno cjelokupno školovanje od 6 godina. Praktičari su
        terapeuti u edukaciji odobreni od strane supervizora za rad s
        polaznicima programa, trenutno na 5. ili 6. god edukacije, a njihove
        terapije priznat će se retrogradno tek kada diplomiraju. Kod svakog
        terapeuta naznačen je njegov status, te da li u ovom trenutku prima
        klijente i za koji vid rada (uživo ili online). Cijene programskih
        terapija određene su važećim cjenikom koji možete pronaći u školskom
        pravilniku.
      </div>
      <div className={styles.textSecond}>
        Za sve informacije i rezervaciju termina, javite se direktno terapeutima
        na njihove kontakte.
      </div>

      <h1 className={styles.h1}>Popis praktičara i terapeuta ITP</h1>
      <div className={styles.searchContainer}>
        <TerapeutCardSearch
          data={therapists?.concat(supervisors)}
          setSearchResults={setFilteredUsers}
          setIsSearch={setIsSearch}
        />
      </div>
      <div className={styles.cardGrid}>
        {filteredUsers
          ? filteredUsers?.map(
              (user, index) =>
                (user.therapistRole === 1 || user.therapistRole === 2) && (
                  <Link
                    to={`/profile/therapist/${user.id}`}
                    state={{ user: user }}
                    key={index}
                    className={styles.link}
                  >
                    <TerapeutCard
                      firstName={user.firstName}
                      lastName={user.lastName}
                      city={user.city === "" ? "Nema podatka" : user.city}
                      email={
                        user.emailAddress === ""
                          ? "Nema podatka"
                          : user.emailAddress
                      }
                      profilePic={user.imageUrl || ProfilePhoto}
                      role={user.therapistRole === 1 ? "Terapeut" : "Praktičar"}
                      live={user?.availableLive || false}
                      online={user?.availableOnline || false}
                    />
                  </Link>
                )
            )
          : currentTherapists?.map((terapeut, index) => (
              <Link
                to={`/profile/therapist/${terapeut.id}`}
                state={{ user: terapeut }}
                key={index}
                className={styles.link}
              >
                <TerapeutCard
                  firstName={terapeut.firstName}
                  lastName={terapeut.lastName}
                  city={terapeut.city === "" ? "Nema podatka" : terapeut.city}
                  email={
                    terapeut.emailAddress === ""
                      ? "Nema podatka"
                      : terapeut.emailAddress
                  }
                  profilePic={terapeut.imageUrl || ProfilePhoto}
                  role={terapeut.therapistRole === 1 ? "Terapeut" : "Praktičar"}
                  live={terapeut?.availableLive || false}
                  online={terapeut?.availableOnline || false}
                />
              </Link>
            ))}
      </div>
      {therapists?.length >= 12 && !isSearch && (
        <div className={styles.paginationContainer}>
          <Pagination
            itemsToDisplay={therapists}
            setCurrent={setCurrentTherapistPage}
            currentPage={currentTherapistPage}
            itemsPerPage={itemsPerPage}
          />
        </div>
      )}
      {(isPolaznikTP || userData?.roleId !== 2) && (
        <>
          <h1 className={styles.h1}>Popis supervizora ITP-a</h1>
          <div className={styles.cardGrid}>
            {filteredUsers
              ? filteredUsers?.map(
                  (user, index) =>
                    user?.therapistRole == 3 && (
                      <Link
                        to={`/profile/supervisor/${user?.id}`}
                        state={{ user: user }}
                        key={index}
                        className={styles.link}
                      >
                        <TerapeutCard
                          firstName={user?.firstName}
                          lastName={user?.lastName}
                          city={user.city === "" ? "Nema podatka" : user.city}
                          email={user?.emailAddress}
                          profilePic={user?.imageUrl || ProfilePhoto}
                          role="Supervizor"
                          live={user?.availableLive || false}
                          online={user?.availableOnline || false}
                        />
                      </Link>
                    )
                )
              : currentSupervisors?.map((supervisor, index) => (
                  <Link
                    to={`/profile/supervisor/${supervisor?.id}`}
                    state={{ user: supervisor }}
                    key={index}
                    className={styles.link}
                  >
                    <TerapeutCard
                      firstName={supervisor?.firstName}
                      lastName={supervisor?.lastName}
                      city={
                        supervisor.city === ""
                          ? "Nema podatka"
                          : supervisor.city
                      }
                      email={supervisor?.emailAddress}
                      profilePic={supervisor?.imageUrl || ProfilePhoto}
                      role="Supervizor"
                      live={supervisor?.availableLive || false}
                      online={supervisor?.availableOnline || false}
                    />
                  </Link>
                ))}
          </div>
          {supervisors?.length >= 12 && !isSearch && (
            <div className={styles.paginationContainer}>
              <Pagination
                itemsToDisplay={supervisors}
                setCurrent={setCurrentSupervisorPage}
                currentPage={currentSupervisorPage}
                itemsPerPage={itemsPerPage}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Terapeuti;
