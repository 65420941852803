import styles from "./SelectDropdown.module.css";

const SelectDropdown = ({
  onChange,
  onBlur,
  value,
  options,
  name,
  title,
  initialOption,
  row,
  disabled,
}) => {
  return (
    <div className={row ? styles.containerRow : styles.container}>
      <h2 className={styles.label}>{title}</h2>
      <select
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={styles.select}
        disabled={disabled}
      >
        {initialOption && <option value="">{initialOption}</option>}
        {options.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
export default SelectDropdown;
