import React from "react";
import RenderTableData from "./RenderTableData";
import RenderTableHeader from "./RenderTableHeader";

function PredavanjeTable({
  data = [],
  columnNames,
  backgroundColor,
  onClick,
  setPresentations,
  setNastupnaPredavanja,
}) {
  return (
    <div className="table-wrapper">
      <table className="table-nastupna-predavanja">
        <thead>
          <RenderTableHeader
            columnNames={columnNames}
            backgroundColor={backgroundColor}
          />
        </thead>
        <tbody>
          <RenderTableData
            data={data}
            onClick={onClick}
            setPresentations={setPresentations}
            setNastupnaPredavanja={setNastupnaPredavanja}
          />
        </tbody>
      </table>
    </div>
  );
}

export default PredavanjeTable;
