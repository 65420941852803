import axios from "axios";
import { postData } from "../utils";
import { getEvents } from "./getEvents";
const apiUrl = process.env.REACT_APP_API_URL;

export const createManyEvents = async (
  eventType,
  formValues,
  accessToken,
  startDate,
  endDate,
  selectedRooms,
  setEvents,
  setIsCreateOpen,
  reRender,
  setReRender,
  selectedPonavljanja,
  setIsBusyOpen,
  setBusyAppointmentInfo
) => {
  try {
    const newData = postData(eventType, formValues);
    const response = await axios.post(
      `${apiUrl}/api/createManyEventsIPD`,
      { ...newData, selectedPonavljanja },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      setIsCreateOpen(false);
      getEvents(
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0],
        selectedRooms,
        accessToken,
        setEvents
      );
      setReRender(!reRender);
    }
    if (response.data.unavailableTherapies.length > 0) {
      setBusyAppointmentInfo(response.data.unavailableTherapies);
      setIsBusyOpen(true);
    }
  } catch (error) {
    console.error("Error creating many events:", error);
    throw error;
  }
};
