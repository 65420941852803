import React from "react";
import Logo1 from "../../assets/img/LogoCard.png";
import Roditeljstvo from "../../assets/img/RoditeljstvoIcon.png";
import RPO from "../../assets/img/RPOIcon.png";
import Logo2 from "../../assets/TPIcon.png";
import useWindowResize from "../Hooks/ResizeHook";
import {
  BrojOsoba,
  ButtonUredi,
  Datum,
  Divider,
  IconWrapper,
  ImageContainer,
  InfoContainer,
  OpisContainer,
  OsobeRow,
  Title,
  TitleRow,
} from "./Card.styles";
import styles from "./CardProgrami.module.css";

export const cardTypes = {
  defaultCard: "defaultCard",
  editCard: "editCard",
};

function ProgramCard({
  variant,
  image,
  title,
  datum,
  opis,
  polaznici,
  voditelji,
  grupe,
  userId,
  brojPredavanja,
  trajanje,
  polaznikProgrami,
  preostaloMjesta,
  onClick,
  editClick,
  level,
}) {
  const width = useWindowResize();
  const breakpoint = 900;
  const formattedDate = new Date(datum)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .join(".");

  const handleUrediClick = (e) => {
    e.stopPropagation();
    editClick();
  };

  return (
    <div className={`container-card-one ${styles.wrapper}`} onClick={onClick}>
      <ImageContainer>
        {/* <ImageContainer backgroundImage={image} /> */}
        <img src={image} alt="" className={styles.progImage} />
      </ImageContainer>
      <InfoContainer>
        {variant === cardTypes.defaultCard && (
          <TitleRow justifyContent="flex-start">
            {level && (
              <IconWrapper
                backgroundImage={
                  ((level === "1.stupanj" || level === "2.stupanj") && Logo1) ||
                  ((level === "3.stupanj" || level === "4.stupanj") && Logo2) ||
                  (level === "roditeljstvo" && Roditeljstvo) ||
                  (level === "rpo" && RPO)
                }
              />
            )}
            <Title>{title}</Title>
          </TitleRow>
        )}
        {variant === "editCard" && (
          <TitleRow justifyContent="space-between">
            <Title>{title}</Title>
            <ButtonUredi onClick={handleUrediClick}>
              {userId === "Voditelj" ? "Saznaj više" : "Uredi"}
            </ButtonUredi>
          </TitleRow>
        )}
        {(userId === "Polaznik" || userId === "Voditelj") &&
          polaznikProgrami && (
            <OsobeRow
              flexDirection={width > breakpoint ? "row" : "column"}
              alignItems={width > breakpoint ? "center" : "flex-start"}
            >
              <BrojOsoba>Ukupan broj predavanja: {brojPredavanja}</BrojOsoba>
              {width > breakpoint && <Divider />}
              <BrojOsoba>Trajanje: {trajanje}</BrojOsoba>
            </OsobeRow>
          )}
        {userId === "Voditelj" && datum ? (
          <OsobeRow flexDirection={width > breakpoint ? "row" : "column"}>
            <BrojOsoba>
              {datum && <Datum>Datum održavanja: {datum}</Datum>}
            </BrojOsoba>
            {preostaloMjesta && (
              <>
                {width > breakpoint && <Divider />}
                <BrojOsoba>
                  <Datum>Preostalo mjesta: {preostaloMjesta}</Datum>
                </BrojOsoba>
              </>
            )}
          </OsobeRow>
        ) : (
          <> {datum && <Datum>Datum održavanja: {formattedDate}</Datum>}</>
        )}
        <OpisContainer>{opis}</OpisContainer>
        {(userId === "Referada" || userId === "Polaznik") && (
          <OsobeRow>
            {polaznici !== undefined && (
              <>
                <BrojOsoba>{polaznici} Polaznika</BrojOsoba>
              </>
            )}
            {voditelji !== undefined && (
              <>
                <Divider />
                <BrojOsoba>{voditelji} Voditelja</BrojOsoba>
              </>
            )}
            {grupe !== undefined && (
              <>
                <Divider />
                <BrojOsoba>{grupe} Grupa</BrojOsoba>
              </>
            )}
          </OsobeRow>
        )}
      </InfoContainer>
    </div>
  );
}

export default ProgramCard;
