import React from "react";
import Back from "../../assets/img/BackArrow.png";
import styles from "./Pagination.module.css";

function Pagination({
  currentPage,
  itemsToDisplay = [],
  itemsPerPage,
  setCurrent,
  customWidth,
}) {
  const currentItems = itemsToDisplay ?? [];
  const handleClick = (event) => {
    setCurrent(Number(event.target.id));
  };
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(currentItems.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const calculateDisplayedNumbers = (
    currentPage,
    totalPageCount,
    maxDisplayed
  ) => {
    let startPage = Math.max(1, currentPage - Math.floor(maxDisplayed / 2));
    let endPage = Math.min(totalPageCount, startPage + maxDisplayed - 1);

    if (endPage - startPage < maxDisplayed - 1) {
      startPage = Math.max(1, endPage - maxDisplayed + 1);
    }

    return Array.from(
      { length: Math.min(maxDisplayed, totalPageCount) },
      (_, i) => startPage + i
    );
  };

  const displayedPageNumbers = calculateDisplayedNumbers(
    currentPage,
    Math.ceil(currentItems.length / itemsPerPage),
    5
  );
  return (
    <div style={{ width: customWidth ? customWidth : "90%" }}>
      <div className={styles.btnRow}>
        <div
          className={
            itemsToDisplay.length <= itemsPerPage || currentPage === 1
              ? styles.disabledBtn
              : styles.navigationRow
          }
          onClick={() => {
            setCurrent((prev) => (prev > 1 ? prev - 1 : prev));
          }}
        >
          <img alt="" src={Back} />
          <span>Prev</span>
        </div>
        <div className={styles.numberContainer}>
          {displayedPageNumbers.map((number) => (
            <div
              key={number}
              id={number.toString()}
              onClick={handleClick}
              className={
                currentPage === number ? styles.activeNumber : styles.pageNumber
              }
            >
              {number}
            </div>
          ))}
        </div>
        <div
          className={
            itemsToDisplay.length <= itemsPerPage ||
            currentPage === Math.ceil(currentItems.length / itemsPerPage)
              ? styles.disabledBtn
              : styles.navigationRow
          }
          onClick={() => {
            setCurrent((prev) => (prev < pageNumbers.length ? prev + 1 : prev));
          }}
        >
          <span>Next</span>
          <img alt="" src={Back} style={{ transform: "rotate(180deg)" }} />
        </div>
      </div>
    </div>
  );
}

export default Pagination;
