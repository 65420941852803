const DocumentIcon = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M14.738 2.762H8.085A3.82 3.82 0 004.25 6.49v10.713a3.828 3.828 0 003.742 3.91c.03 0 .06.002.092 0h7.989a3.887 3.887 0 003.729-3.91V8.038l-5.065-5.276z"
        stroke="current"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.475 2.75v2.909a2.574 2.574 0 002.568 2.575h2.755M14.288 15.358h-5.4M12.243 11.605H8.887"
        stroke="current"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentIcon;
