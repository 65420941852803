import React, { useEffect, useMemo, useState } from "react";
import Export from "../../assets/img/Export.png";
import LoaderModal from "../../components/LoaderModal/LoaderModal";
import { handleKlijentChange } from "../calendarIpd/utils";
import { useAuthentication } from "../hooks/useAuthentication";
import { formatDate } from "../polazniciPregled/utils/utils";
import { handleExportObracunSobaByUser } from "./API/exportObracunSoba";
import { getObracunData } from "./API/fetchObracunData";
import { getZakupitelji } from "./API/fetchZakupitelji";
import styles from "./ObracunSoba.module.css";
import ObracunComponent from "./components/ObracunComponent/ObracunComponent";
import DatePicker from "./components/SingleInputDatePicker/DatePicker";
import { mojObracunKoristenjaSobeColumnNames } from "./utils/columnNames";

function PregledKoristenjaSoba() {
  const today = new Date();

  const formatDateForPicker = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [zakupiteljId, setZakupiteljId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [zakupiteljLabel, setZakupiteljLabel] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [pregledKoristenjaSoba, setPregledKoristenjaSoba] = useState({});
  const [allZakupitelji, setAllZakupitelji] = useState([]);

  const [startDate, setStartDate] = useState(
    formatDateForPicker(firstDayOfMonth)
  );
  const [endDate, setEndDate] = useState(formatDateForPicker(lastDayOfMonth));
  const { authToken } = useAuthentication();

  const handleDateChange = (newDates) => {
    setStartDate(newDates[0]);
    setEndDate(newDates[1]);
  };

  useEffect(() => {
    if (authToken)
      getZakupitelji(authToken)
        .then((data) => {
          setAllZakupitelji(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
  }, [authToken]);

  const filteredUsers = useMemo(() => {
    const normalizedSearchValue = searchValue?.toLowerCase();

    if (!normalizedSearchValue) return [];

    const startsWithSearch = [];
    const includesSearch = [];

    allZakupitelji?.forEach((user) => {
      const fullName = `${user.firstName?.toLowerCase()} ${user.lastName?.toLowerCase()}`;

      if (
        user.firstName?.toLowerCase().startsWith(normalizedSearchValue) ||
        user.lastName?.toLowerCase().startsWith(normalizedSearchValue)
      ) {
        startsWithSearch.push(user);
      } else if (fullName.includes(normalizedSearchValue)) {
        includesSearch.push(user);
      }
    });

    return [...startsWithSearch, ...includesSearch];
  }, [allZakupitelji, searchValue]);

  const handleKlijentClick = (
    value,
    name,
    surname,
    setSearchValue,
    setIsSearchVisible,
    setZakupiteljLabel
  ) => {
    setSearchValue(name + " " + surname);
    setZakupiteljLabel(name + " " + surname);
    setZakupiteljId(value);
    setIsSearchVisible(false);
  };

  return (
    <div className={styles.obracunWrapper}>
      <span className={styles.title}>Pregled korištenja soba</span>
      <div className={styles.inputRow}>
        <div className={styles.search}>
          <span className={styles.labelZakupitelj}>Odaberi zakupitelja</span>
          <div className={styles.searchInput}>
            <input
              type="search"
              className={styles.input}
              placeholder="Upiši"
              value={searchValue}
              onChange={(event) =>
                handleKlijentChange(event, setSearchValue, setIsSearchVisible)
              }
            />
          </div>
          <div
            className={
              searchValue === "" || !isSearchVisible
                ? styles.none
                : styles.searchDropdown
            }
          >
            {filteredUsers?.slice(0, 5).map((user, index) => (
              <div
                key={index}
                className={styles.dropdownItems}
                onClick={() =>
                  handleKlijentClick(
                    user.id,
                    user.firstName,
                    user.lastName,
                    setSearchValue,
                    setIsSearchVisible,
                    setZakupiteljLabel
                  )
                }
              >
                {user.firstName} {user.lastName}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.column}>
          <span className={styles.label}>Odaberi vremenski raspon</span>
          <DatePicker
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
          />
        </div>
        <button
          className={styles.button}
          onClick={() => {
            setLoading(true);
            if (authToken && zakupiteljId)
              getObracunData(
                authToken,
                zakupiteljId,
                startDate,
                endDate,
                setPregledKoristenjaSoba,
                setLoading
              );
          }}
        >
          prikaži podatke
        </button>
      </div>
      {loading && <LoaderModal />}
      <ObracunComponent
        title={pregledKoristenjaSoba?.statistics?.userType ?? "BASIC KORISNIK"}
        label={`Korisnik : ${zakupiteljLabel}  (${formatDate(
          startDate
        )} - ${formatDate(endDate)})`}
        columnNames={mojObracunKoristenjaSobeColumnNames}
        data={pregledKoristenjaSoba?.statistics}
        backgroundColor={"#87AD77"}
        showSum
        isObracunPoSobama
      />
      <button
        className={styles.exportBtn}
        style={zakupiteljId === null ? { backgroundColor: "#c9c7c7" } : {}}
        disabled={zakupiteljId === null}
        onClick={() => {
          setLoading(true);
          handleExportObracunSobaByUser(
            authToken,
            zakupiteljId,
            startDate,
            endDate,
            zakupiteljLabel,
            setLoading
          );
        }}
      >
        Export tablice <img alt="" src={Export} />
      </button>
    </div>
  );
}

export default PregledKoristenjaSoba;
