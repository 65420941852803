import React from "react";
import { useNavigate } from "react-router-dom";
function RenderTableData({ data }) {
  const navigate = useNavigate();
  return (
    <>
      {data.map((polaznik, index) => (
        <tr key={index}>
          <td style={{ color: "#2E3646" }}>
            <div
              className="table-polaznici-row"
              onClick={() => navigate(`/djelatnik/2/${polaznik.id}`)}
            >
              <div className="table-polaznici-avatar">
                <img alt="" src={polaznik.imageUrl} />
              </div>
              {polaznik.firstName} {polaznik.lastName}
            </div>
          </td>
          <td style={{ color: "#5F6D7E" }}>
            <div className="table-polaznici-column">
              {polaznik.aktivniProgramResponse[0] && (
                <>
                  <p style={{ marginBlockEnd: 5, marginBlockStart: 5 }}>
                    {polaznik.aktivniProgramResponse[0].title}
                  </p>
                  <p style={{ marginBlockEnd: 5, marginBlockStart: 5 }}>
                    {polaznik.aktivniProgramResponse[0].name}
                  </p>
                </>
              )}
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}

export default RenderTableData;
