import React from "react";
import Biografija from "../../../assets/img/BiografijaIcon.png";
import Email from "../../../assets/img/EmailIcon.png";
import {
  CardWrapper,
  DeleteButton,
  ImageContainer,
  InfoContainer,
  Name,
  Row,
  SmallTypography,
} from "./VoditeljiCard.styles";

function VoditeljiCard({
  voditeljAvatar,
  voditeljIme,
  voditeljPrezime,
  voditeljEmail,
  userId,
  onClick,
  deleteVoditelj,
}) {
  return (
    <CardWrapper onClick={onClick}>
      {userId !== "Polaznik" && (
        <DeleteButton onClick={() => deleteVoditelj()} />
      )}
      <InfoContainer>
        <ImageContainer backgroundImage={voditeljAvatar} />
        <Name>{voditeljIme}</Name>
        <Name>{voditeljPrezime}</Name>
        <Row style={{ marginTop: 6, marginBottom: 6 }}>
          <img alt="" src={Biografija} />
          <SmallTypography>Biografija</SmallTypography>
        </Row>
        <Row>
          <img alt="" src={Email} />
          <SmallTypography>{voditeljEmail}</SmallTypography>
        </Row>
      </InfoContainer>
    </CardWrapper>
  );
}

export default VoditeljiCard;
