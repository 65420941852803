import { getAllFacultativeWorkshops } from "../Prijavnice/PolaznikVoditelj/FakultativneRadionice/api/getAllFacultativeWorkshops";
import { getAllIntensives } from "./api/getAllIntensives";
import { getAllPolaznici } from "./api/getAllPolaznici";
import { getGroupsByProgramId } from "./api/getGroupsByProgramId";
import { getSearchPolaznici } from "./api/getSearchPolaznici";
const apiUrl = process.env.REACT_APP_API_URL;

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    height: "40px",
    width: "100%",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "4px",
    border: "1px solid #D1D9E2 !important",
    boxShadow: "none",
    cursor: "pointer",
    "&:focus": {
      border: "0 !important",
    },
    "@media screen and (max-width: 950px)": {
      width: "100%",
      minWidth: "350px",
    },
  }),
  option: (styles) => {
    return {
      ...styles,
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#F2F2F2",
      },
    };
  },
};

export const selectStylesMogucnosti = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    height: "40px",
    width: "100%",
    minWidth: "200px",
    borderRadius: "4px",
    border: "1px solid #D1D9E2 !important",
    cursor: "pointer",
    boxShadow: "none",
    "&:focus": {
      border: "0 !important",
    },
    "@media screen and (max-width: 950px)": {
      width: "100%",
      minWidth: "350px",
    },
  }),
  option: (styles) => {
    return {
      ...styles,
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      ":hover": {
        backgroundColor: "#F2F2F2",
      },
    };
  },
};

export const MultiselectPlaceStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    width: "60%",
    minWidth: "200px",
    borderRadius: "8px",
    border: "1px solid #D1D9E2 !important",
    padding: "2px 0px",
    cursor: "pointer",
    boxShadow: "none",
    "&:focus": {
      border: "0 !important",
    },
    "@media screen and (max-width: 950px)": {
      width: "100%",
      minWidth: "350px",
    },
  }),
  option: (styles) => ({
    ...styles,
    padding: "5px",
    display: "flex",
    alignItems: "center",
    color: "#5F6D7EC7",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    ":hover": {
      backgroundColor: "#F2F2F2",
    },
  }),
};
export const MultiselectPlaceOption = ({ innerProps, label, isSelected }) => {
  return (
    <div
      {...innerProps}
      style={{
        display: "flex",
        gap: "12px",
        cursor: "pointer",
        padding: "12px 16px",
      }}
    >
      <input
        type="checkbox"
        checked={isSelected}
        readOnly
        style={{
          width: "20px",
          height: "20px",
          cursor: "pointer",
        }}
      />
      <span
        style={{
          color: "rgba(95, 109, 126, 0.78)",
          fontFamily: "Inter",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "22px",
        }}
      >
        {label}
      </span>
    </div>
  );
};

export const fetchData = async (
  currentPage,
  accessToken,
  setStudents,
  setPolazniciCount,
  allRoles,
  city,
  programId,
  groupId,
  fwId,
  intId,
  activityId,
  isNoGroupsClicked
) => {
  try {
    const response = await getAllPolaznici(
      currentPage,
      10,
      accessToken,
      allRoles,
      city,
      programId,
      groupId,
      fwId,
      intId,
      activityId,
      isNoGroupsClicked
    );
    setStudents(response?.users);
    setPolazniciCount(response?.count);
  } catch (error) {
    console.error("Error fetching attendees:", error);
  }
};

export const fetchSearchData = async (
  currentPage,
  accessToken,
  setStudents,
  setPolazniciCount,
  searchResults,
  allRoles,
  isNoGroupsClicked,
  activity
) => {
  try {
    const response = await getSearchPolaznici(
      currentPage,
      10,
      accessToken,
      searchResults,
      allRoles,
      isNoGroupsClicked,
      activity
    );
    setStudents(response?.users);
    setPolazniciCount(response?.count);
  } catch (error) {
    console.error("Error fetching attendees:", error);
  }
};

export const programsWithGroups = [
  "1",
  "2",
  "7",
  "8",
  "9",
  "10",
  "3",
  "4",
  "5",
  "6",
  "13",
  "14",
  "11",
  "12",
];

export const sviPolaznici = [
  { value: null, label: "Svi polaznici" },
  { value: "1", label: "Polaznici RAR" },
  { value: "2", label: "Polaznici eRAR" },
  { value: "3", label: "Polaznici OR1" },
  { value: "4", label: "Polaznici OR2" },
  { value: "5", label: "Polaznici OR3" },
  { value: "6", label: "Polaznici OR4" },
  { value: "7", label: "Polaznici eOR1" },
  { value: "8", label: "Polaznici eOR2" },
  { value: "9", label: "Polaznici eOR3" },
  { value: "10", label: "Polaznici eOR4" },
  { value: "13", label: "Polaznici TP1" },
  { value: "14", label: "Polaznici TP2" },
  { value: "11", label: "Polaznici TT5" },
  { value: "12", label: "Polaznici TT6" },
  { value: "17", label: "Polaznici VT" },
  { value: "16", label: "RPO" },
  { value: "15", label: "Roditeljstvo 0-6" },
  { value: "22", label: "Polaznici radionica" },
  { value: "33", label: "Polaznici intenziva" },
];

export const activeArchive = [
  { value: "1", label: "Aktivni polaznici" },
  { value: "2", label: "Arhivirani polaznici" },
];

export const onlinePolaznici = [
  { value: null, label: "Svi polaznici" },
  { value: "2", label: "Polaznici eRAR" },
  { value: "7", label: "Polaznici eOR1" },
  { value: "8", label: "Polaznici eOR2" },
  { value: "9", label: "Polaznici eOR3" },
  { value: "10", label: "Polaznici eOR4" },
];

export const fetchGroups = async (selectedProgram, accessToken, setGroups) => {
  const responseGrupe = await getGroupsByProgramId(
    selectedProgram.value,
    accessToken
  );
  setGroups([
    { value: null, label: "Sve grupe" },
    ...responseGrupe.map((group) => ({
      value: group.id,
      label: group.name,
    })),
  ]);
};
export const handleSelectChange = (
  selectedOptions,
  setSelectedOption,
  setCurrentPage
) => {
  setCurrentPage(1);
  const selectedValues = selectedOptions.map((item) => item);
  setSelectedOption(selectedValues);
};
export const handleProgramChange = async (
  selectedOption,
  setSelectedProgram,
  setCurrentPage
) => {
  setCurrentPage(1);
  setSelectedProgram(selectedOption);
};
export const handleGrupaChange = async (
  selectedOption,
  setSelectedGroup,
  setCurrentPage
) => {
  setCurrentPage(1);
  setSelectedGroup(selectedOption);
};

export const handleActivityChange = async (
  selectedOption,
  setSelectedActivity,
  setCurrentPage
) => {
  setCurrentPage(1);
  setSelectedActivity(selectedOption);
};

export const handleIntensiveChange = async (
  selectedOption,
  setSelectedIntensive,
  setCurrentPage
) => {
  setCurrentPage(1);
  setSelectedIntensive(selectedOption);
};

export const handleWorkshopChange = async (
  selectedOption,
  setSelectedWorkshop,
  setCurrentPage
) => {
  setCurrentPage(1);
  setSelectedWorkshop(selectedOption);
};
export const handleRowClick = (userId, navigate) => {
  navigate(`/pojedini-polaznik/${userId}`);
};

export const fetchCities = async (accessToken, setMjestoUpisa) => {
  try {
    const response = await fetch(`${apiUrl}/api/cities`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    const updatedMjestoUpisa = data.map((city) => ({
      label: city.cityName,
      value: city.id,
    }));
    setMjestoUpisa(updatedMjestoUpisa);
  } catch (error) {
    console.error("Error fetching cities:", error);
  }
};
export const fetchWI = async (accessToken, setWorkshops, setIntensives) => {
  const response = await getAllFacultativeWorkshops(accessToken);
  const responseIntensives = await getAllIntensives(accessToken);
  setWorkshops([
    { value: null, label: "Sve radionice" },
    ...response?.active.map((workshop) => ({
      value: workshop.id,
      label: workshop.name,
    })),
  ]);
  setIntensives([
    { value: null, label: "Svi intenzivi" },
    ...responseIntensives.map((intensive) => ({
      value: intensive.id,
      label: intensive.name,
    })),
  ]);
};
