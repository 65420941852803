import React from "react";
import "./ObracunTable.css";
import RenderData from "./RenderData";
import RenderTableHeader from "./RenderHeader";

function ObracunTable({
  columnNames = [],
  data = [],
  isPotrosnjaPoGradovima,
  backgroundColor,
  isPotpuniObracun,
  showSum,
  isObracunPoSobama,
}) {
  return (
    <table className="table-obracun-soba">
      <thead>
        <RenderTableHeader
          columnNames={columnNames}
          backgroundColor={backgroundColor}
        />
      </thead>
      <tbody>
        <RenderData
          isPotpuniObracun={isPotpuniObracun}
          data={data}
          isPotrosnjaPoGradovima={isPotrosnjaPoGradovima}
          columnNames={columnNames}
          showSum={showSum}
          isObracunPoSobama={isObracunPoSobama}
        />
      </tbody>
    </table>
  );
}

export default ObracunTable;
