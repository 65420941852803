import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReusableTabs from "../../../components/TabMenu/Tabs";
import Aktivni from "./Aktivni/Aktivni";
import { polaznikTabsData } from "./utils";
import Završeni from "./Završeni/Završeni";

function PolaznikVoditeljProgrami({ userId }) {
  const { selectedTab } = useParams();
  const navigate = useNavigate();
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedTab || "one");
  useEffect(() => {
    if (selectedTab === undefined) {
      setCurrentTabIndex("one");
      navigate(`/programi/${currentTabIndex}`);
    }
  }, [selectedTab, navigate, currentTabIndex]);
  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };
  return (
    <div className="pregled-wrapper">
      <ReusableTabs
        currentTabIndex={currentTabIndex}
        handleTabChange={handleTabChange}
        tabsData={polaznikTabsData}
      />
      <div className="subpage-wrapper">
        {currentTabIndex === "one" && <Aktivni userId={userId} />}
        {currentTabIndex === "two" && <Završeni userId={userId} />}
      </div>
    </div>
  );
}

export default PolaznikVoditeljProgrami;
