import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import SingleNotification from "../../components/SingleNotification/SingleNotification";
import { Obavijesti } from "./MockData";
import styles from "./PrimljeneObavijesti.module.css";
import { organizeNotificationsByMonth } from "./utils";

function PrimljeneObavijesti() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const readNotifications = Obavijesti.filter((item) => !item.isUnread);
  const unreadNotifications = Obavijesti.filter((item) => item.isUnread);
  const itemsPerPage = 1;
  const readNotificationsByMonth =
    organizeNotificationsByMonth(readNotifications);
  const readNotificationsArray = Object.entries(readNotificationsByMonth).map(
    ([monthYearKey, notifications]) => {
      const [month, year] = monthYearKey.split("-");
      return { month, year, notifications };
    }
  );
  const currentOffers = readNotificationsArray.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  if (readNotifications.length === 0 && unreadNotifications.length === 0) {
    return (
      <div className={styles.emptyContainer}>
        <span className={styles.emptySpan}>Još uvijek nemaš obavijesti. </span>
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.topTitle}>
        <div className={styles.horizontal} />
        NEPROČITANO
        <div className={styles.horizontal} />
      </div>
      <div className={styles.notificationColumn}>
        {unreadNotifications.map((item, index) => (
          <SingleNotification
            onClick={() => navigate("/pojedina-obavijest")}
            key={index}
            isUnread={item.isUnread}
            timePosted={item.timePosted}
            user={item.user}
            notificationTitle={item.notificationTitle}
            showDate
            showBorder={index !== unreadNotifications.length - 1}
          />
        ))}
      </div>
      {currentOffers.map((item, index) => (
        <div className={styles.readColumn} key={index}>
          <div className={styles.topTitle} style={{ marginBottom: 24 }}>
            <div className={styles.horizontal} />
            <div className={styles.date}>
              {item.month} {item.year}
            </div>
            <div className={styles.horizontal} />
          </div>
          {item.notifications.map((notification, notificationIndex) => (
            <SingleNotification
              onClick={() => navigate("/pojedina-obavijest")}
              key={notificationIndex}
              isUnread={notification.isUnread}
              timePosted={notification.timePosted}
              user={notification.user}
              notificationTitle={notification.notificationTitle}
              showDate
              showBorder={notificationIndex !== item.notifications.length - 1}
            />
          ))}
        </div>
      ))}
      <Pagination
        itemsToDisplay={readNotificationsArray}
        setCurrent={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        customWidth={"100%"}
      />
    </div>
  );
}

export default PrimljeneObavijesti;
