import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import Exit from "../../../assets/img/ExitIcon.png";
import { getUserById } from "../../groups/api/getUserById";
import { useAuthentication } from "../../hooks/useAuthentication";
import styles from "./InfoModal.module.css";
import { infoLabels } from "./modalUtils";

const InfoModal = ({ onClick, data, rooms, userData, getUsersName }) => {
  const { authToken } = useAuthentication();
  const {
    room,
    timeFrom,
    timeTo,
    eventType,
    title,
    clientId,
    outsideClient,
    therapistName,
    therapistId,
  } = data;

  const roomName = rooms.find((item) => item.value === room);
  const [user, setUser] = useState("");

  const subtractOneHour = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const date = new Date();

    date.setHours(hours - 1, minutes);
    return date.toTimeString().slice(0, 5);
  };

  const adjustedTimeFrom = timeFrom; //subtractOneHour(timeFrom);
  const adjustedTimeTo = timeTo; //subtractOneHour(timeTo);

  const eventData = [roomName?.label, adjustedTimeFrom, adjustedTimeTo];

  useEffect(() => {
    const fetchUser = async () => {
      if (clientId) {
        try {
          const wholeUser = await getUserById(authToken, clientId);
          setUser(`${wholeUser.firstName} ${wholeUser.lastName}`);
        } catch (error) {
          console.error("Error fetching user:", error);
          setUser("Unknown User");
        }
      } else {
        setUser(outsideClient);
      }
    };

    fetchUser();
  }, [clientId, authToken, outsideClient]);

  return (
    <div className={styles.wrapper}>
      <img src={Exit} alt="" className={styles.exit} onClick={onClick} />
      <div className={styles.modalContainer}>
        <h1 className={styles.h1}>
          {eventType !== "other" &&
            eventType !== "therapy" &&
            eventType !== "individual_supervision" &&
            capitalize(title)}
          {eventType === "other" && "Ostalo"}
          {eventType === "individual_supervision" && "Individualna supervizija"}
          {eventType === "therapy" &&
            "Terapija" +
              `: ${
                userData.id !== clientId
                  ? userData.firstName
                  : getUsersName(therapistId)
              } ${userData.id !== clientId ? userData.lastName : ""}`}
        </h1>
        <div className={styles.content}>
          <div className={styles.labels}>
            {infoLabels?.map((label, index) => (
              <div className={styles.label} key={index}>
                {label}
              </div>
            ))}
          </div>
          <div className={styles.eventInfos}>
            {eventData.map((data, index) => (
              <div className={styles.data} key={index}>
                {data}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
