import { Popover } from "@mui/material";
import { useState } from "react";
import ExpandIcon from "../assets/expandIcon.svg";
import smsIcon from "./../assets/sms.svg";
import styles from "./djelatniciCard.module.css";

const DjelatniciCard = ({
  profilePhoto,
  firstName,
  lastName,
  email,
  showExpandIcon,
  customStyles,
  isSelected,
  onClick,
}) => {
  const cardStyle = isSelected ? { border: "2px solid #cb9c2d" } : {};
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div
      className={styles.profileCard}
      style={{ ...customStyles, ...cardStyle }}
      onClick={() => onClick()}
    >
      <img src={profilePhoto} alt="Profile" className={styles.profilePhoto} />
      <div className={styles.profileDetails}>
        <div className={styles.row}>
          <h2 className={styles.cardTitle}>
            {firstName} {lastName}
          </h2>
          {showExpandIcon && (
            <img src={ExpandIcon} alt="expand icon" className={styles.icon} />
          )}
        </div>
        <div className={styles.secondRow}>
          <img src={smsIcon} alt="mail icon" className={styles.icon} />
          <p
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            className={styles.contactText}
          >
            {email}
          </p>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <p style={{ padding: 8, borderRadius: 8 }}>{email}</p>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default DjelatniciCard;
