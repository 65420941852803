import { useState } from "react";
import BellIcon from "../../assets/svg/BellIcon.svg";
import BurgerMenu from "../../assets/svg/BurgerMenu.svg";
import MobileLogo from "../../assets/svg/MobileLogo.svg";
import MobileSideMenu from "../MobileSideMenu/MobileSideMenu";
import styles from "./MobileHeader.module.css";

export default function MobileHeader({ onMobileSearchOpen }) {
  const [mobileSideMenuVisible, setMobileSideMenuVisible] = useState(false);

  const toggleSidebar = () => {
    setMobileSideMenuVisible((prevState) => !prevState);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.firstCol}>
          <img
            src={BurgerMenu}
            alt=""
            className={styles.imgs}
            onClick={() => setMobileSideMenuVisible((prevState) => !prevState)}
          />
          <img src={MobileLogo} alt="" className={styles.imgs} />
        </div>
        <div className={styles.secondCol}>
          <img src={BellIcon} alt="" className={styles.imgs2} />
          <svg
            width="22"
            height="22"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.imgs2}
            onClick={onMobileSearchOpen}
          >
            <path
              d="M16.4697 17.5303C16.7626 17.8232 17.2374 17.8232 17.5303 17.5303C17.8232 17.2374 17.8232 16.7626 17.5303 16.4697L16.4697 17.5303ZM13.4265 7.58824C13.4265 10.8126 10.8126 13.4265 7.58824 13.4265V14.9265C11.641 14.9265 14.9265 11.641 14.9265 7.58824H13.4265ZM7.58824 13.4265C4.36387 13.4265 1.75 10.8126 1.75 7.58824H0.25C0.25 11.641 3.53544 14.9265 7.58824 14.9265V13.4265ZM1.75 7.58824C1.75 4.36387 4.36387 1.75 7.58824 1.75V0.25C3.53544 0.25 0.25 3.53544 0.25 7.58824H1.75ZM7.58824 1.75C10.8126 1.75 13.4265 4.36387 13.4265 7.58824H14.9265C14.9265 3.53544 11.641 0.25 7.58824 0.25V1.75ZM11.7638 12.8244L16.4697 17.5303L17.5303 16.4697L12.8244 11.7638L11.7638 12.8244Z"
              fill="#4e4e4f"
            />
          </svg>
        </div>
      </div>
      {mobileSideMenuVisible && (
        <MobileSideMenu
          visible={mobileSideMenuVisible}
          toggleSidebar={toggleSidebar}
        />
      )}
    </>
  );
}
