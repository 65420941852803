import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const vrstaSupervizije = [
  { value: "trijada", label: "Trijada" },
  { value: "ags", label: "AGS" },
  { value: "eper", label: "EPeR" },
];
export const vrijemeOdrzavanja = [
  { value: "08:00-11:00", label: "08:00-11:00" },
  { value: "09:00-12:00", label: "09:00-12:00" },
  { value: "10:00-13:00", label: "10:00-13:00" },
  { value: "11:00-14:00", label: "11:00-14:00" },
  { value: "12:00-15:00", label: "12:00-15:00" },
  { value: "13:00-16:00", label: "13:00-16:00" },
  { value: "14:00-17:00", label: "14:00-17:00" },
  { value: "15:00-18:00", label: "15:00-18:00" },
  { value: "16:00-19:00", label: "16:00-19:00" },
  { value: "17:00-20:00", label: "17:00-20:00" },
  { value: "18:00-21:00", label: "18:00-21:00" },
];

export const fetchSupervizijaData = async (id, accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/supervision/${id}`, {
      params: {
        id,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
