const PaperClipIcon = (props) => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.185 9.084l-1.389 1.39c-.77.77-.77 2.014 0 2.784s2.014.77 2.784 0l2.189-2.188a3.942 3.942 0 000-5.569 3.942 3.942 0 00-5.57 0L4.816 7.886a3.381 3.381 0 000 4.776"
        stroke="current"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaperClipIcon;
