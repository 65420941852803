import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getProgramsByUserId = async (accessToken, id) => {
  try {
    const response = await axios.get(`${apiUrl}/api/active-programs/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const resData = response.data;
    return resData;
  } catch (error) {
    console.error("Error getting active programs:", error);
  }
};
