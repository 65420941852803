import React from "react";
import locationIcon from ".././assets/locationIcon.svg";
import smsIcon from ".././assets/smsIcon.svg";
import CheckIcon from "../assets/CheckIcon.svg";
import ExitIcon from "../assets/ExitIcon.svg";
import styles from "./TerapeutCard.module.css";

const TerapeutCard = ({
  firstName,
  lastName,
  city,
  email,
  profilePic,
  role,
  live,
  online,
}) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.profilePicContainer}>
        <img
          className={styles.profilePic}
          src={profilePic}
          alt="Therapist Profile Pic"
        />
        <div className={styles.roleLabel}>{role}</div>
      </div>
      <div
        className={`${styles.textRow} ${styles.name}`}
      >{`${firstName} ${lastName}`}</div>
      <div className={styles.rowContainer}>
        <img className={styles.icon} src={locationIcon} alt="Icon" />
        <div className={styles.textRow}>{city}</div>
      </div>
      <div className={styles.rowContainer}>
        <img className={styles.icon} src={smsIcon} alt="Icon" />
        <div className={styles.textRow}>{email}</div>
      </div>
      <div className={styles.rowContainer}>
        {live ? (
          <img className={styles.icon} src={CheckIcon} alt="Icon" />
        ) : (
          <img className={styles.icon} src={ExitIcon} alt="Icon" />
        )}
        <div className={styles.textRow}>Uživo terapije</div>
      </div>
      <div className={styles.rowContainer}>
        {online ? (
          <img className={styles.icon} src={CheckIcon} alt="Icon" />
        ) : (
          <img className={styles.icon} src={ExitIcon} alt="Icon" />
        )}
        <div className={styles.textRow}>Online terapije</div>
      </div>
    </div>
  );
};

export default TerapeutCard;
