import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const exportPotpuniObracunSoba = async (
  accessToken,
  startDate,
  endDate
) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Both startDate and endDate are required.");
    }
    const response = await axios.get(
      `${apiUrl}/api/exportUserStats?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching potpuni obracun sobe:", error);
    throw error;
  }
};

export const handlePotpuniObracunSoba = async (
  accessToken,
  startDate,
  endDate
) => {
  try {
    const response = await exportPotpuniObracunSoba(
      accessToken,
      startDate,
      endDate
    );
    const blob = new Blob([response], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `exported_potpuni_obracun_${startDate}-${endDate}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Greška prilikom exporta potpunog obračuna sobe:", error);
  }
};
