import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReusableTabs from "../../../components/TabMenu/Tabs";
import { useUserState } from "../../../context/UserContext";
import FakultativneRadionice from "./FakultativneRadionice/FakultativneRadionice";
import GrupneSupervizije from "./GrupneSupervizije/GrupneSupervizije";
import LjetniIntenzivi from "./LjetniIntenzivi/LjetniIntenzivi";
import ModulUživo from "./ModulUživo/ModulUživo";
import PrijavljeneAktivnosti from "./PrijavljeneAktivnosti/PrijavljeneAktivnosti";
import { polaznikOR3PrijavniceTabs, polaznikPrijavniceTabs } from "./utils";
import VikendIntenzivi from "./VikendIntenzivi/VikendIntenzivi";

function PrijavnicePolaznikVoditelj({ userId }) {
  const navigate = useNavigate();
  const { userData } = useUserState();
  const { selectedTab } = useParams();
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedTab || "one");
  const polaznikActivePrograms = userData.programs;
  const [isPolaznikOR3, setIsPolaznikOR3] = useState(false);
  useEffect(() => {
    if (polaznikActivePrograms) {
      polaznikActivePrograms.map((item) => {
        if (item >= 5) {
          setIsPolaznikOR3(true);
        }
      });
    }
  }, [polaznikActivePrograms]);
  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
    navigate(`/prijavnice/${newValue}`);
  };
  const handleNavigate = (type) => {
    navigate(`/pojedina-prijavnica/${type}`);
  };
  const handleNavigateActivity = (type, id) => {
    let newType;
    if (type === "facultativeWorkshops") {
      newType = "fakultativne-radionice";
    } else if (type === "weekendIntensives") {
      newType = "vikend-intenzivi";
    } else if (type === "summerIntensives") {
      newType = "ljetni-intenzivi";
    } else if (type === "superivisions") {
      newType = "grupne-supervizije";
    } else {
      newType = "moduli-uživo";
    }

    navigate(`/pojedina-prijavnica/${newType}/${id}`);
  };

  return (
    <div>
      <ReusableTabs
        currentTabIndex={currentTabIndex}
        handleTabChange={handleTabChange}
        tabsData={
          isPolaznikOR3 ? polaznikOR3PrijavniceTabs : polaznikPrijavniceTabs
        }
      />
      {currentTabIndex === "one" && (
        <FakultativneRadionice
          userId={userId}
          onClick={(id) => handleNavigate(`fakultativne-radionice/${id}`)}
        />
      )}
      {currentTabIndex === "two" && (
        <LjetniIntenzivi
          userId={userId}
          onClick={(id) => handleNavigate(`ljetni-intenzivi/${id}`)}
        />
      )}
      {currentTabIndex === "three" && (
        <VikendIntenzivi
          userId={userId}
          onClick={(id) => handleNavigate(`vikend-intenzivi/${id}`)}
        />
      )}
      {currentTabIndex === "four" && (
        <GrupneSupervizije
          userId={userId}
          onClick={(id) => handleNavigate(`grupne-supervizije/${id}`)}
        />
      )}
      {currentTabIndex === "five" && (
        <ModulUživo
          userId={userId}
          onClick={(id) => handleNavigate(`moduli-uživo/${id}`)}
        />
      )}
      {currentTabIndex === "six" && (
        <PrijavljeneAktivnosti
          userId={userId}
          onClick={(type, id) => handleNavigateActivity(type, id)}
        />
      )}
    </div>
  );
}

export default PrijavnicePolaznikVoditelj;
