import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const handleSelectChange = (
  selectedOptions,
  formData,
  setFormData,
  createEventData,
  updateCreateEventData
) => {
  const selectedValues = selectedOptions.map((option) => option.value);
  setFormData({
    ...formData,
    grupe: selectedValues,
  });

  updateCreateEventData({
    ...createEventData,
    grupe: selectedValues,
  });
};

export const fetchEModuliData = async (id, accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/eModuleLive/${id}`, {
      params: {
        id,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
