import React, { useState } from "react";
import SearchIcon from "../../../assets/svg/SearchIcon.svg";
import styles from "../../IPDTeam/components/SearchUserCards/UserCardSearch.module.css";

const TerapeutCardSearch = ({ data, setSearchResults, setIsSearch }) => {
  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
    if (input !== "") {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }

    const searchName = input.trim().toLowerCase();
    if (searchName === "") {
      setSearchResults(null);
    } else {
      const filteredData = data.filter((user) => {
        const firstName = user.firstName ? user.firstName.toLowerCase() : "";
        const lastName = user.lastName ? user.lastName.toLowerCase() : "";
        return (
          (firstName.includes(searchName) || lastName.includes(searchName)) &&
          user.therapistRole
        );
      });
      setSearchResults(filteredData);
    }
  };

  return (
    <label className={styles.searchUsersInputContainer}>
      <img alt="search" src={SearchIcon} className={styles.searchUsersIcon} />
      <input
        type="search"
        placeholder="Search"
        className={styles.searchUsersInput}
        value={searchInput}
        onChange={handleSearchInputChange}
      />
    </label>
  );
};

export default TerapeutCardSearch;
