import React from "react";
import styles from "./TeacherCard.module.css";

function BiographyModal({ user, biography, setBiographyOpen }) {
  return (
    <div className={styles.overlay}>
      <div className={styles.modalContainer}>
        <h2 className={styles.modalTitle}>Biografija: {user}</h2>
        <p className={styles.modalText}>
          {biography.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
        <button
          className={styles.closeButton}
          onClick={() => setBiographyOpen(false)}
        >
          Zatvori
        </button>
      </div>
    </div>
  );
}

export default BiographyModal;
