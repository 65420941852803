import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Exit from "../../../../assets/img/ExitIcon.png";
import Modal from "../../../../components/Modal/Modal";
import { useGroupState } from "../../../../context/CreateGroupContext";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { handleDuplicateGroup } from "../../api/duplicateGroup";
import styles from "./DuplicateModal.module.css";

function DuplicateModal({ title, groupId, onClose, onSuccess }) {
  const { authToken } = useAuthentication();
  const navigate = useNavigate();
  const { resetGroupData } = useGroupState();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState(title || "");
  const [modalContent, setModalContent] = useState({
    image: "",
    title: "",
    description: "",
    buttons: [],
  });

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  return (
    <div className={styles.modalOverlay}>
      {showModal && <Modal {...modalContent} />}
      <img
        alt=""
        src={Exit}
        className={styles.exitBtn}
        onClick={() => onClose()}
      />
      <div className={styles.modalWrapper}>
        <div className={styles.infoModal}>
          <p className={styles.title}>Dupliciraj grupu</p>
          <div className={styles.inputColumn}>
            <span className={styles.label}>Naziv grupe</span>
            <input
              type="text"
              className={styles.modalInput}
              value={name}
              onChange={handleNameChange}
            />
          </div>
          <div className={styles.btnRow}>
            <button onClick={() => onClose()} className={styles.cancelBtn}>
              Odustani
            </button>
            <button
              className={styles.successBtn}
              onClick={() =>
                handleDuplicateGroup(
                  name,
                  groupId,
                  authToken,
                  resetGroupData,
                  navigate,
                  setModalContent,
                  setShowModal,
                  onClose
                )
              }
            >
              Dupliciraj grupu
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DuplicateModal;
