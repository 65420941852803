import React, { useEffect, useState } from "react";
import Exit from "../../../../../../assets/img/ExitIcon.png";
import Success from "../../../../../../assets/img/Success.png";
import Modal from "../../../../../../components/Modal/Modal";
import { getUserById } from "../../../../../groups/api/getUserById";
import { updateSupervisionComment } from "../../../../api/updateSupervisionComment";
import styles from "./KomentarModal.module.css";
function KomentarModal({
  onClose,
  evidentionUserID,
  authToken,
  supId,
  change,
  setChange,
}) {
  const [user, setUser] = useState();
  const [comment, setComment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  // const [formData, setFormData] = useState({
  //   datumSupervizije: "",
  //   komentar: "",
  // });

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responses = await getUserById(authToken, evidentionUserID);
        setUser(responses);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    if (authToken) {
      fetchUser();
    }
  }, [authToken]);

  const handleSubmit = async () => {
    const response = await updateSupervisionComment(supId, comment, authToken);
    if (response.data.success === 1) {
      setModalContent({
        image: Success,
        description: "Tvoj komentar je uspješno dodan",
        buttons: [
          {
            label: "Uredu",
            action: () => {
              handleSubmit();
              onClose();
            },
            className: "modal-btn-success",
          },
        ],
      });
      setChange(!change);
      setShowModal(true);
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <img
        alt=""
        src={Exit}
        className={styles.exitBtn}
        onClick={() => onClose()}
      />
      <div className={styles.modalWrapper}>
        <div className={styles.infoModal}>
          <p className={styles.title}>Komentar na superviziju</p>
          <p className={styles.subtitle} style={{ fontSize: 20 }}>
            Polaznik: {user?.firstName + " " + user?.lastName}
          </p>
          <div className={styles.inputWrapper}>
            {/* <div className={styles.inputColumn}>
              <span className={styles.label}>Datum održavanja supervizije</span>
              <input
                name="datumSupervizije"
                value={formData.datumSupervizije}
                type="date"
                className={styles.modalInput}
                onChange={handleInputChange}
              />
            </div> */}
            <div className={styles.inputColumn}>
              <span className={styles.label}>Komentar</span>
              <textarea
                className={styles.textarea}
                placeholder="Upiši"
                name="komentar"
                value={comment}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className={styles.btnRow}>
          <button onClick={() => onClose()} className={styles.cancelBtn}>
            Odustani
          </button>
          <button className={styles.successBtn} onClick={() => handleSubmit()}>
            Objavi komentar
          </button>
        </div>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          description={modalContent.description}
          buttons={modalContent.buttons}
          fullWidth
        />
      )}
    </div>
  );
}

export default KomentarModal;
