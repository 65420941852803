import { signOut } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Exit from "../../assets/svg/Exit.svg";
import HelpIcon from "../../assets/svg/HelpIcon";
import LogoutIcon from "../../assets/svg/LogoutIcon";
import SettingsIcon from "../../assets/svg/SettingsIcon";
import SlanjeIcon from "../../assets/svg/SlanjeIcon.svg";
import { useUserState } from "../../context/UserContext";
import { auth } from "../../firebase";
import { OstaleRole, Polaznik, Referada, Voditelj } from "../sideMenu/Data";
import styles from "./MobileSideMenu.module.css";

const OrIds = [3, 4, 5, 6, 7, 8, 9, 10];

export default function MobileSideMenu({ visible, toggleSidebar }) {
  const { role, userData } = useUserState();
  const userId = role;
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();

  let dataToRender = [];
  let secondDataToRender = [];
  if (userId === "Referada") {
    dataToRender = Referada;
  } else if (userId === "Polaznik") {
    dataToRender = Polaznik;

    if (!OrIds.some((id) => userData.programs.includes(id))) {
      dataToRender = dataToRender.filter(
        (data) => data.title !== "Osvrti na voditelje"
      );
    }
  } else if (userId === "Voditelj") {
    dataToRender = Polaznik;
    secondDataToRender = Voditelj;
  } else {
    dataToRender = OstaleRole;
  }
  return (
    <div className={visible ? styles.wrapper : styles.hidden}>
      <div className={styles.sidebar}>
        <img
          src={Exit}
          alt=""
          className={styles.exit}
          onClick={toggleSidebar}
        />
        <div className={styles.user} onClick={() => navigate("/moj-profil")}>
          <img
            alt="user"
            src={userData.imageUrl}
            className={styles.userPhoto}
          />
          <div className={styles.userInfoContainer}>
            <p className={styles.userNameText}>
              {`${userData.firstName} ${userData.lastName}`}
            </p>
            <p className={styles.userRoleText}>{role}</p>
          </div>
        </div>
        <div className={styles.items}>
          {dataToRender.map((item, index) => (
            <div
              key={index}
              className={
                location.pathname === `${item.pathname}`
                  ? styles.itemActive
                  : styles.item
              }
              onClick={() => {
                navigate(`${item.pathname}`);
                toggleSidebar();
              }}
            >
              <img alt="" src={item.icon} className={styles.itemIcon} />
              <p className={styles.itemText}>{item.title}</p>
            </div>
          ))}
          {userId === "Referada" && (
            <div
              className={
                location.pathname === `/slanje-obavijesti`
                  ? styles.itemActive
                  : styles.item
              }
              onClick={() => {
                navigate(`/slanje-obavijesti`);
                toggleSidebar();
              }}
            >
              <img alt="" src={SlanjeIcon} className={styles.itemIcon} />
              <p className={styles.itemText}>Slanje obavijesti</p>
            </div>
          )}
        </div>
        {(userId === "Voditelj" || userId === "Supervizor") && (
          <>
            <div className={styles.separator}>
              <span className={styles.spanSeparator}>VODITELJI</span>
              <div className={styles.divider} />
            </div>
            <div className={styles.items} style={{ marginTop: 0 }}>
              {secondDataToRender.map((item, index) => (
                <div
                  key={index}
                  className={
                    location.pathname === `${item.pathname}`
                      ? styles.itemActive
                      : styles.item
                  }
                  onClick={() => {
                    navigate(`${item.pathname}`);
                  }}
                >
                  <img alt="" src={item.icon} className={styles.itemIcon} />
                  <p className={styles.itemText}>{item.title}</p>
                </div>
              ))}
            </div>
          </>
        )}
        <div className={styles.helpItemContainer}>
          {userId !== "Polaznik" && userId !== "Voditelj" && (
            <>
              <div
                className={
                  location.pathname === "/pomoc"
                    ? styles.itemActive
                    : styles.item
                }
                onClick={() => {
                  navigate("/pomoc");
                  toggleSidebar();
                }}
              >
                <HelpIcon className={styles.itemIcon} />
                <p className={styles.itemText}>Pomoć</p>
              </div>
              <div
                className={
                  location.pathname === "/postavke"
                    ? styles.itemActive
                    : styles.item
                }
                onClick={() => {
                  navigate("/postavke");
                  toggleSidebar();
                }}
              >
                <SettingsIcon className={styles.itemIcon} />
                <p className={styles.itemText}>Postavke</p>
              </div>
            </>
          )}
          <div
            className={styles.item}
            onClick={() => {
              cookies.remove("loggedUser");
              localStorage.removeItem("authToken");
              localStorage.removeItem("sessionTime");
              signOut(auth);
              navigate("/");
            }}
          >
            <LogoutIcon className={styles.itemIcon} />
            <p className={styles.itemText}>Odjava</p>
          </div>
        </div>
      </div>
    </div>
  );
}
