import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const createSupervision = async (data, accessToken) => {
  try {
    const response = await axios.post(`${apiUrl}/api/supervision`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error creating supervision: ", error);
    throw error;
  }
};
