import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import styles from "./ModalEdit.module.css";

import DatePicker from "react-datepicker";
import {
  deleteEvent,
  postAddEvent,
  putEditEvent,
} from "../calendar-utils/calendar.api";
import {
  izaberiDogadjaj,
  rooms,
  timeSlots,
} from "../calendar-utils/calendar.mocks";
import { programSelectData } from "../calendar-utils/calendar.utils";

export const LargeTextBox = styled(Box)(() => ({
  width: "100%",
  marginTop: 5,
  fontSize: 18,
}));
//type edit, add-local,add-global
const ModalEdit = ({
  open,
  onClose,
  type,
  clickedEvent,
  selectedEmptyTimeSlot,
  workshopList,
  groupList,
}) => {
  const [radionica, setRadionica] = useState(
    clickedEvent && clickedEvent.workshop ? clickedEvent.workshop : ""
  );
  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedStartTime, setSelectedStartTime] = useState(
    clickedEvent && clickedEvent.start
      ? new Date(clickedEvent.start)
      : new Date("1970-01-01T09:00:00")
  );
  const [selectedEndTime, setSelectedEndTime] = useState(
    clickedEvent && clickedEvent.end
      ? new Date(clickedEvent.end)
      : new Date("1970-01-01T12:15:00")
  );
  const [grupa, setGrupa] = useState(clickedEvent?.class_name);
  const [dogadjaj, setDogadjaj] = useState(clickedEvent?.event_type);
  const [soba, setSoba] = useState(clickedEvent?.soba_name);
  const [program, setProgram] = useState(clickedEvent?.program_name);
  const [mjesto, setMjesto] = useState(clickedEvent?.short_name);
  const [room, setRoom] = useState(
    selectedEmptyTimeSlot ? selectedEmptyTimeSlot?.room : ""
  );
  const [brojLekcije, setBrojLekcije] = useState(
    selectedEmptyTimeSlot ? selectedEmptyTimeSlot?.room : ""
  );
  const [objava, setObjava] = useState(
    clickedEvent && clickedEvent.published !== undefined
      ? clickedEvent.published
      : false
  );
  const handleOnDelete = () => {
    deleteEvent(clickedEvent.id).then(function () {
      onClose();

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };
  const [openDialogNew, setOpenDialogNew] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const handleSave = async () => {
    const date = `${selectedDate?.getFullYear()}-${
      selectedDate?.getMonth() + 1 < 10 ? "0" : ""
    }${selectedDate?.getMonth() + 1}-${
      selectedDate?.getDate() < 10 ? "0" : ""
    }${selectedDate?.getDate()}`;

    const start = `${date} ${selectedStartTime?.getHours() < 10 ? "0" : ""}${
      selectedStartTime?.getHours() || ""
    }:${selectedStartTime?.getMinutes() < 10 ? "0" : ""}${
      selectedStartTime?.getMinutes() || ""
    }:00`;
    const end = `${date} ${selectedEndTime.getHours() || ""}:${
      selectedEndTime?.getMinutes() || ""
    }:00`;

    if (!dogadjaj) {
      setDialogMessage("All fields must be filled out.");
      setOpenDialogNew(true);
      return;
    }

    const data = {
      title: `${grupa} ${radionica}`,
      start: clickedEvent
        ? clickedEvent.start
        : selectedEmptyTimeSlot
        ? selectedEmptyTimeSlot.start
        : start,
      program_name: program,
      start_time: clickedEvent
        ? clickedEvent.start_time
        : selectedEmptyTimeSlot
        ? selectedEmptyTimeSlot.start
        : start,
      end_time: clickedEvent
        ? clickedEvent.end_time
        : selectedEmptyTimeSlot
        ? selectedEmptyTimeSlot.end
        : end,
      event_type: dogadjaj,
      short_name: mjesto,
      class_name: grupa,
      workshop: radionica,
      resource_id: null,
      room: room,
      place: room,
      description: "",
      published: objava,
      lesson_number: brojLekcije,
    };

    const editData = {
      program_name: program,
      event_type: dogadjaj,
      short_name: mjesto,
      class_name: grupa,
      workshop: radionica,
      published: objava,
      lesson_number: brojLekcije,
    };

    if (type === "add-local" || type === "add-global") {
      console.log(data, "im fcking here");
      await postAddEvent(data);
    } else await putEditEvent(clickedEvent.id, editData);

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: theme.spacing(3),
      minWidth: "40%",
    },
  }));

  return (
    <>
      <StyledDialog
        open={open}
        onClose={onClose}
        sx={{
          borderRadius: "16px",
        }}
      >
        <DialogTitle
          sx={{
            borderRadius: "16px",
            color: "#4D4D4D",
            fontWeight: "600",
            fontSize: "28px",
            width: "calc(100%-50px)",
            textAlign: "center",
            fontFamily: "Inter",
            margin: "30px 0px",
          }}
        >
          Uredi događaj
        </DialogTitle>
        <DialogContent>
          <div className={styles.dialogContent}>
            <div className={styles.leftColumn}>
              <div className={styles.item}>
                <h1 className={styles.h1}>Vrsta događaja</h1>
                <select
                  value={dogadjaj}
                  onChange={(e) => setDogadjaj(e.target.value)}
                  className={styles.select}
                >
                  <option hidden selected>
                    Odaberi
                  </option>
                  {izaberiDogadjaj.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.item}>
                <h1 className={styles.h1}>Datum održavanja</h1>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <div className={styles.customDatePicker}>
                      <input
                        className={styles.date}
                        value={
                          selectedDate ? selectedDate.toLocaleDateString() : ""
                        }
                        readOnly
                      />
                      {/* <CalendarIcon className={styles.calendarIcon} /> */}
                    </div>
                  }
                />
              </div>
              <div className={styles.item}>
                <h1 className={styles.h1}>Vrijeme održavanja</h1>
                <div className={styles.times}>
                  <select
                    value={
                      selectedStartTime
                        ? selectedStartTime.toTimeString().substr(0, 5)
                        : ""
                    }
                    onChange={(e) => {
                      setSelectedStartTime(
                        new Date(`1970-01-01T${e.target.value}:00`)
                      );
                    }}
                    className={styles.select}
                  >
                    {timeSlots.map((option) => (
                      <option
                        disabled={
                          option.value.split(":")[0] >
                          selectedEndTime.getHours()
                        }
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div className={styles.divider}>-</div>
                  <select
                    value={
                      selectedEndTime
                        ? selectedEndTime.toTimeString().substr(0, 5)
                        : ""
                    }
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      // Check for "12:15" without leading/trailing whitespace
                      if (selectedValue === "12:15") {
                        console.log("Handling 12:15 case");
                        setSelectedEndTime(new Date(`1970-01-01T12:15:00`));
                      } else {
                        console.log("Handling other time cases");
                        setSelectedEndTime(
                          new Date(`1970-01-01T${selectedValue}:00`)
                        );
                      }
                    }}
                    className={styles.select}
                  >
                    {timeSlots.map((option) => (
                      <option
                        disabled={
                          option.value.split(":")[0] <
                          selectedStartTime.getHours()
                        }
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.item}>
                <h1 className={styles.h1}>Mjesto održavanja</h1>
                <select
                  value={soba}
                  onChange={(e) => setSoba(e.target.value)}
                  className={styles.select}
                >
                  <option hidden selected>
                    Odaberi
                  </option>
                  {/* Ovdje će pretpostavljam trebat novi podatci ove sam uzeo iz mocka za sobe */}
                  {rooms.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.item}>
                <h1 className={styles.h1}>Program</h1>
                <select
                  value={program}
                  onChange={(e) => setProgram(e.target.value)}
                  className={styles.select}
                >
                  <option hidden selected>
                    Odaberi
                  </option>
                  {programSelectData(dogadjaj).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              {dogadjaj === "Interaktivna predavanja" && (
                <div className={styles.item}>
                  <h1 className={styles.h1}>Predavanje</h1>
                  <select
                    value={radionica}
                    onChange={(e) => setBrojLekcije(e.target.value)}
                    className={styles.select}
                  >
                    <option hidden selected>
                      Odaberi
                    </option>
                  </select>
                </div>
              )}
              <div className={styles.item}>
                <h1 className={styles.h1}>Grupa</h1>
                <select
                  value={grupa}
                  onChange={(e) => setGrupa(e.target.value)}
                  className={styles.select}
                >
                  <option hidden selected>
                    Odaberi
                  </option>
                  {groupList.map((option) => (
                    <option key={option.class_name} value={option.class_name}>
                      {option.class_name || "Bez imena"}
                    </option>
                  ))}
                </select>
              </div>
              {dogadjaj === "Radionica" && workshopList && (
                <div className={styles.item}>
                  <h1 className={styles.h1}>Radionica</h1>
                  <select
                    value={radionica}
                    onChange={(e) => setRadionica(e.target.value)}
                    className={styles.select}
                  >
                    <option hidden selected>
                      Odaberi
                    </option>
                    {workshopList &&
                      workshopList.map((option) => (
                        <option
                          key={option.workshop_name}
                          value={option.workshop_name}
                        >
                          {option.workshop_name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              <div className={styles.item}>
                <h1 className={styles.h1}>Objavi event</h1>
                <select
                  value={objava}
                  onChange={(e) => setObjava(e.target.value)}
                  className={styles.select}
                >
                  <option hidden selected>
                    Odaberi
                  </option>
                  <option value={"Da"}>Da</option>
                  <option value={"Ne"}>Ne</option>
                </select>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 24,
            paddingRight: 24,
            marginBottom: 16,
          }}
        >
          <IconButton
            style={{
              backgroundColor: "#CB2D2D",
              color: "white",
              borderRadius: "4px",
              padding: "8px",
            }}
            onClick={handleOnDelete}
            aria-label="Delete"
          >
            <DeleteIcon />
          </IconButton>

          <div className={styles.btns}>
            <button
              onClick={onClose}
              className={styles.btnOdustani}
              variant="contained"
            >
              Odustani
            </button>
            <button
              onClick={handleSave}
              className={styles.btnUredi}
              variant="contained"
            >
              Uredi događaj
            </button>
          </div>
        </DialogActions>
      </StyledDialog>
      <StyledDialog
        open={openDialogNew}
        onClose={() => setOpenDialogNew(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialogNew(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default ModalEdit;
