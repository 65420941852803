import * as React from "react";
import styles from "./DatePicker.module.css";

export default function DatePicker({ startDate, endDate, onDateChange }) {
  const handleStartDateChange = (newDate) => {
    onDateChange([newDate, endDate]);
  };

  const handleEndDateChange = (newDate) => {
    onDateChange([startDate, newDate]);
  };
  return (
    <div className={styles.dateRow}>
      <input
        type="date"
        id="startDate"
        name="startDate"
        value={startDate}
        placeholder="Odaberi"
        className={styles.dateInput}
        onChange={(e) => handleStartDateChange(e.target.value)}
      />
      -
      <input
        type="date"
        id="endDate"
        name="endDate"
        value={endDate}
        placeholder="Odaberi"
        className={styles.dateInput}
        onChange={(e) => handleEndDateChange(e.target.value)}
      />
    </div>
  );
}
