import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getAllSummer = async (accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/intensive?isWeekend=false`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
