import React from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "../../assets/img/BackArrow.png";
import { pojedinaObavijest } from "./MockData";
import styles from "./PojedinaObavijest.module.css";
import { formatDate } from "./utils";

function PojedinaObavijest() {
  const navigate = useNavigate();
  const data = pojedinaObavijest;
  const newDate = formatDate(data[0].dateTimePosted);
  const paragraph = data.map((item) => item.paragraph).flat();
  return (
    <div className={styles.container}>
      <div
        className={styles.backRow}
        onClick={() => navigate("/primljene-obavijesti")}
      >
        <img alt="" src={Arrow} />
        <span className={styles.backSpan}>Natrag na pregled obavijesti</span>
      </div>
      <span className={styles.title}>{data?.[0].user}</span>
      <span className={styles.date}>
        {newDate.formattedTime}, {newDate.formattedDate}
      </span>
      <div className={styles.paragraph}>
        {paragraph.map((item, index) => (
          <span
            key={index}
            style={{
              display: "flex",
              marginBottom: index === 0 ? "6px" : "0",
            }}
          >
            <br />
            {item}
          </span>
        ))}
      </div>
      <a
        href={data?.[0].link}
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {data?.[0].link}
      </a>
    </div>
  );
}

export default PojedinaObavijest;
