import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getGroupUsers = async (groupId, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/groups/usersByRole?groupId=${groupId}&role=polaznik`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching group users data:", error);
    throw error;
  }
};
