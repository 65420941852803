import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const deleteModalEvidentionEntry = async (eventId, accessToken) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/api/deleteModalEvidentionEntry?id=${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error deleting modal evidention entry data", error);
    throw error;
  }
};
