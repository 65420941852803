export const programOptions = [
  { value: [3, 4, 5, 6, 7, 8, 9, 10], label: "OR/eOR" },
  { value: [13, 14], label: "TP" },
  { value: [11, 12], label: "TT" },
];

export const applicationProgramOptions = [
  { value: [3, 4, 5, 6], label: "OR" },
  { value: [7, 8, 9, 10], label: "eOR" },
  { value: [13, 14], label: "TP" },
  { value: [11, 12], label: "TT" },
];
