import { useEffect, useMemo, useState } from "react";
import Exit from "../../../assets/img/ExitIcon.png";
import TrashIcon from "../../../assets/svg/TrashIcon";
import { useUserState } from "../../../context/UserContext";
import { useAuthentication } from "../../hooks/useAuthentication";
import { createEvent } from "../api/createEvent";
import { createManyEvents } from "../api/createManyEvents";
import { deleteEvent } from "../api/deleteEvent";
import { deleteManyEvents } from "../api/deleteManyEvents";
import { getRoomAvalability } from "../api/getRoomAvailability";
import { getRoomAvalabilityLive } from "../api/getRoomAvailabilityLive";
import { isUserSupervisor } from "../api/isUserSupervisor";
import { updateEvent } from "../api/updateEvent";
import {
  timeSlotsEnd,
  timeSlotsStart,
  timeSlotsStartLive,
} from "../calendaripd-utils/calendarIpd.mocks";
import {
  handleEndTimeChange,
  handleInputChange,
  handleKlijentChange,
  handleKlijentClick,
  handleStartTimeChange,
} from "../utils";
import styles from "./CreateModal.module.css";
import { eventOptions } from "./modalUtils";

const CreateModal = ({
  onClick,
  date,
  rooms,
  type,
  users,
  startDate,
  endDate,
  selectedRooms,
  setEvents,
  reRender,
  setReRender,
  setIsCreateOpen,
  editEventValues,
  secondSelectedRoomId,
  zakupiteljType,
  setIsBusyOpen,
  setBusyAppointmentInfo,
  getUsersName,
}) => {
  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const [selectedTimeStart, setSelectedTimeStart] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [selectedTimeStartLive, setSelectedTimeStartLive] = useState(
    type === "edit" ? editEventValues.timeFrom : "0"
  );

  const [selectedTimeEndLive, setSelectedTimeEndLive] = useState(() => {
    let timeToLabel = "Odaberi";

    if (type === "edit" && editEventValues.timeTo) {
      const [hours, minutes] = editEventValues.timeTo.split(":").map(Number);

      const newTime = new Date();
      newTime.setHours(hours + 1);
      newTime.setMinutes(minutes);

      timeToLabel = `${String(newTime.getHours()).padStart(2, "0")}:${String(
        newTime.getMinutes()
      ).padStart(2, "0")}`;
    }

    return { label: timeToLabel };
  });
  const [timeStart, setTimeStart] = useState(timeSlotsStart);
  const [timeEnd, setTimeEnd] = useState(timeSlotsEnd);
  const [filteredTimeEnd, setFilteredTimeEnd] = useState(timeEnd);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isCurrentUserSupervisor, setIsCurrentUserSupervisor] = useState(false);
  const [busyAppointments, setBusyAppointments] = useState([]);
  const [busyAppointmentsLive, setBusyAppointmentsLive] = useState([]);
  const [freeAppointmentsLive, setFreeAppointmentsLive] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedPonavljanja, setSelectedPonavljanja] = useState(null);
  const [modalPonavljanja, setModalPonavljanja] = useState(false);

  const [formValues, setFormValues] = useState({
    eventType:
      eventOptions.length > 0 && role !== "Supervizor"
        ? eventOptions[0].value
        : eventOptions.length > 0 && role === "Supervizor"
        ? eventOptions[1].value
        : "",
    eventId: "",
    supervisionType: "individual",
    clientId: null,
    outsideClient: "",
    name: "",
    date: date,
    timeFrom: "",
    timeTo: "",
    description: "",
    roomId: secondSelectedRoomId
      ? secondSelectedRoomId
      : rooms.length > 0
      ? rooms[0].value
      : "",
  });

  const [selectedNumber, setSelectedNumber] = useState("1");
  const [intervalType, setIntervalType] = useState("day");
  const [selectedDays, setSelectedDays] = useState([]);
  const [ponavljanjaEndDate, setPonavljanjaEndDate] = useState("");

  const handleNumberChange = (e) => {
    setSelectedNumber(e.target.value);
  };

  const handleIntervalChange = (e) => {
    setIntervalType(e.target.value);
    setSelectedDays([]);
  };

  const handleDayClick = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
  };

  const handlePonavljanjaEndDateChange = (e) => {
    setPonavljanjaEndDate(e.target.value);
  };

  const handleOdustaniModal = () => {
    setModalPonavljanja(false);
    setSelectedPonavljanja(null);
    setSelectedDays([]);
    setSelectedNumber("1");
    setIntervalType("day");
    setPonavljanjaEndDate("");
  };

  const handlePonavljanjaSave = () => {
    const data = {
      selectedNumber,
      intervalType,
      selectedDays,
      ponavljanjaEndDate,
    };
    setSelectedPonavljanja(data);
    setModalPonavljanja(false);
  };

  const isOdaberiDisabled =
    !ponavljanjaEndDate ||
    (intervalType === "week" && selectedDays.length === 0);

  function timeStringToDate(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }

  function isTimeInRange(time, rangeStart, rangeEnd) {
    return time >= rangeStart && time < rangeEnd;
  }

  function isWithinOneHourOfBusySlot(slotTime, busyStartTime) {
    const oneHourBeforeBusyStart = new Date(busyStartTime);
    oneHourBeforeBusyStart.setHours(busyStartTime.getHours() - 1);

    return slotTime >= oneHourBeforeBusyStart && slotTime < busyStartTime;
  }

  function getEarliestBusyStart(busyAppointments) {
    const busyStartTimes = busyAppointments.map((appointment) => {
      const [start] = appointment.split(" - ");
      return timeStringToDate(start);
    });

    return new Date(Math.min(...busyStartTimes));
  }

  function filterTimeSlots(
    timeSlots,
    busyAppointments,
    selectedStartTime = null
  ) {
    const busyRanges = busyAppointments.map((appointment) => {
      const [start, end] = appointment.split(" - ");
      return {
        start: timeStringToDate(start),
        end: timeStringToDate(end),
      };
    });

    const earliestBusyStart = getEarliestBusyStart(busyAppointments);

    return timeSlots.filter((slot) => {
      const slotTime = timeStringToDate(slot.value);

      if (selectedStartTime) {
        return slotTime > selectedStartTime && slotTime < earliestBusyStart;
      } else {
        return !busyRanges.some(
          (range) =>
            isTimeInRange(slotTime, range.start, range.end) ||
            isWithinOneHourOfBusySlot(slotTime, range.start)
        );
      }
    });
  }

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      timeFrom: timeStart.length > 0 ? timeStart[0].value - 1 : "",
      timeTo: timeEnd.length > 0 ? timeEnd[0].value - 1 : "",
    }));
  }, [timeStart, timeEnd]);

  const filteredUsers = useMemo(() => {
    const normalizedSearchValue = searchValue.toLowerCase();

    if (!normalizedSearchValue) return [];

    const startsWithSearch = [];
    const includesSearch = [];

    users.forEach((user) => {
      const fullName = `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`;

      if (
        user.firstName.toLowerCase().startsWith(normalizedSearchValue) ||
        user.lastName.toLowerCase().startsWith(normalizedSearchValue)
      ) {
        startsWithSearch.push(user);
      } else if (fullName.includes(normalizedSearchValue)) {
        includesSearch.push(user);
      }
    });

    return [...startsWithSearch, ...includesSearch];
  }, [users, searchValue]);

  useEffect(() => {
    if (type === "edit") {
      const { timeFrom, timeTo, roomId, clientId } = editEventValues;
      setSelectedTimeStart(timeFrom);
      setSelectedTimeEnd(timeTo);
      const selectedClient = users.find((user) => user.id === clientId);
      if (selectedClient) {
        setSearchValue(
          `${selectedClient.firstName} ${selectedClient.lastName}`
        );
      }
      setFormValues({
        eventType: editEventValues.eventType,
        eventId: editEventValues.eventId,
        supervisionType: editEventValues.supervisionType,
        clientId: editEventValues.clientId || null,
        outsideClient: editEventValues.outsideClient,
        name: editEventValues.name,
        date: editEventValues.date,
        timeFrom: editEventValues.timeFrom,
        timeTo: editEventValues.timeTo,
        description: editEventValues.description,
        roomId: roomId,
      });
    }
  }, [type, editEventValues]);

  useEffect(() => {
    const fetchTime = async () => {
      const response = await getRoomAvalability(
        authToken,
        type === "edit" ? editEventValues.date : date?.split("T")[0],
        formValues.roomId
      );
      setBusyAppointments(response);
    };
    const fetchTimeLive = async () => {
      const response = await getRoomAvalabilityLive(
        authToken,
        type === "edit" ? editEventValues.date : date?.split("T")[0],
        formValues.roomId
      );
      setBusyAppointmentsLive(response);
      setFreeAppointmentsLive(
        response.filter((item) => item.availability === true)
      );
    };

    if (authToken && formValues.roomId === 7) fetchTime();
    if (authToken && formValues.roomId !== 7) fetchTimeLive();
  }, [formValues.roomId, date, editEventValues, type, authToken]);

  useEffect(() => {
    const fetchIsSupervisor = async () => {
      const response = await isUserSupervisor(
        editEventValues.eventId,
        userData.id,
        authToken
      );
      setIsCurrentUserSupervisor(response);
    };

    if (
      authToken &&
      type === "edit" &&
      editEventValues.eventType === "individual_supervision"
    )
      fetchIsSupervisor();
  }, [userData, editEventValues, authToken]);

  let availableTimeSlots;

  if (type === "create") {
    availableTimeSlots = filterTimeSlots(timeSlotsStart, busyAppointments);
  } else {
    availableTimeSlots = timeSlotsStart;
  }

  useEffect(() => {
    if (selectedTimeStart && type !== "edit") {
      const selectedStartDate = timeStringToDate(selectedTimeStart);

      const closestBusyStartTime = busyAppointments
        .map((appointment) => {
          const [start] = appointment.split(" - ");
          return timeStringToDate(start);
        })
        .filter((busyStartDate) => busyStartDate > selectedStartDate)
        .sort((a, b) => a - b)[0];

      const filtered = timeEnd.filter((item) => {
        const endTime = timeStringToDate(item.value);
        return (
          endTime > selectedStartDate &&
          (!closestBusyStartTime || endTime < closestBusyStartTime)
        );
      });

      setFilteredTimeEnd(filtered);
    } else {
      setFilteredTimeEnd(timeEnd);
    }
  }, [selectedTimeStart, timeEnd, busyAppointments]);

  const handleStartLiveChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "06:45" || selectedValue === "07:45") {
      setSelectedTimeStartLive("07:45");
      setSelectedTimeEndLive({
        value: "07:45",
        label: "08:45",
      });
      setFormValues((preValues) => ({
        ...preValues,
        timeTo: "07:45",
        timeFrom: selectedValue,
      }));
    } else if (selectedValue === "08:00" || selectedValue === "09:00") {
      setSelectedTimeStartLive("09:00");
      setSelectedTimeEndLive({
        value: "09:00",
        label: "10:00",
      });
      setFormValues((preValues) => ({
        ...preValues,
        timeTo: "09:00",
        timeFrom: selectedValue,
      }));
    } else if (selectedValue === "09:15" || selectedValue === "10:15") {
      setSelectedTimeStartLive("10:15");
      setSelectedTimeEndLive({
        value: "10:15",
        label: "11:15",
      });
      setFormValues((preValues) => ({
        ...preValues,
        timeTo: "10:15",
        timeFrom: selectedValue,
      }));
    } else if (selectedValue === "10:30" || selectedValue === "11:30") {
      setSelectedTimeStartLive("11:30");
      setSelectedTimeEndLive({
        value: "11:30",
        label: "12:30",
      });
      setFormValues((preValues) => ({
        ...preValues,
        timeTo: "11:30",
        timeFrom: selectedValue,
      }));
    } else if (selectedValue === "11:45" || selectedValue === "12:45") {
      setSelectedTimeStartLive("12:45");
      setSelectedTimeEndLive({
        value: "12:45",
        label: "13:45",
      });
      setFormValues((preValues) => ({
        ...preValues,
        timeTo: "12:45",
        timeFrom: selectedValue,
      }));
    } else if (selectedValue === "13:00" || selectedValue === "14:00") {
      setSelectedTimeStartLive("14:00");
      setSelectedTimeEndLive({
        value: "14:00",
        label: "15:00",
      });
      setFormValues((preValues) => ({
        ...preValues,
        timeTo: "14:00",
        timeFrom: selectedValue,
      }));
    } else if (selectedValue === "14:15" || selectedValue === "15:15") {
      setSelectedTimeStartLive("15:15");
      setSelectedTimeEndLive({
        value: "15:15",
        label: "16:15",
      });
      setFormValues((preValues) => ({
        ...preValues,
        timeTo: "15:15",
        timeFrom: selectedValue,
      }));
    } else if (selectedValue === "15:30" || selectedValue === "16:30") {
      setSelectedTimeStartLive("16:30");
      setSelectedTimeEndLive({
        value: "16:30",
        label: "17:30",
      });
      setFormValues((preValues) => ({
        ...preValues,
        timeTo: "16:30",
        timeFrom: selectedValue,
      }));
    } else if (selectedValue === "17:00" || selectedValue === "18:00") {
      setSelectedTimeStartLive("18:00");
      setSelectedTimeEndLive({
        value: "18:00",
        label: "19:00",
      });
      setFormValues((preValues) => ({
        ...preValues,
        timeTo: "18:00",
        timeFrom: selectedValue,
      }));
    } else if (selectedValue === "18:15" || selectedValue === "19:15") {
      setSelectedTimeStartLive("19:15");
      setSelectedTimeEndLive({
        value: "19:15",
        label: "20:15",
      });
      setFormValues((preValues) => ({
        ...preValues,
        timeTo: "19:15",
        timeFrom: selectedValue,
      }));
    } else if (selectedValue === "19:30" || selectedValue === "20:30") {
      setSelectedTimeStartLive("20:30");
      setSelectedTimeEndLive({
        value: "20:30",
        label: "21:30",
      });
      setFormValues((preValues) => ({
        ...preValues,
        timeTo: "20:30",
        timeFrom: selectedValue,
      }));
    } else {
      console.log("Wrong time selected!");
    }
  };

  const handleDeleteEvent = async () => {
    const eventDate = new Date(editEventValues.date);
    const currentDate = new Date();
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(currentDate.getDate() + 7);
    const twentyFourHoursFromNow = new Date();
    twentyFourHoursFromNow.setHours(currentDate.getHours() + 24);

    if (zakupiteljType === "Premium") {
      if (eventDate > currentDate && eventDate <= sevenDaysFromNow) {
        alert(
          "Ovaj termin ne može biti izbrisan jer će se održati za manje od 7 dana!"
        );
      } else {
        await deleteEvent(
          formValues,
          authToken,
          startDate,
          endDate,
          selectedRooms,
          setEvents,
          setIsCreateOpen,
          reRender,
          setReRender
        );
      }
    } else if (zakupiteljType === "Basic") {
      if (eventDate > currentDate && eventDate <= twentyFourHoursFromNow) {
        alert(
          "Ovaj termin ne može biti izbrisan jer će se održati za manje od 24 sata!"
        );
      } else {
        const delResponse = await deleteEvent(
          formValues,
          authToken,
          startDate,
          endDate,
          selectedRooms,
          setEvents,
          setIsCreateOpen,
          reRender,
          setReRender
        );
        if (delResponse === "Not allowed") {
          alert("Brisanje nije uspjelo, imate pravo brisati 15% termina.");
        }
      }
    }
  };

  const handleDeleteManyEvents = () => {
    const eventDate = new Date(editEventValues.date);
    const currentDate = new Date();
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(currentDate.getDate() + 7);

    if (zakupiteljType === "Premium") {
      if (eventDate > currentDate && eventDate <= sevenDaysFromNow) {
        alert(
          "Ovaj termin ne može biti izbrisan jer će se održati za manje od 7 dana!"
        );
      } else {
        deleteManyEvents(
          formValues,
          authToken,
          startDate,
          endDate,
          selectedRooms,
          setEvents,
          setIsCreateOpen,
          reRender,
          setReRender
        );
      }
    }
  };

  const isDeleteSupported =
    userData.roleId === 6 ||
    userData.id === editEventValues.supervisorId ||
    userData.id === editEventValues.creatorId ||
    userData.id === editEventValues.therapistId;

  return (
    <div className={styles.wrapper}>
      <img src={Exit} alt="" className={styles.exit} onClick={onClick} />
      <div className={styles.modalContainer}>
        <h1 className={styles.h1}>
          {type === "create"
            ? "Kreiranje događaja"
            : formValues.eventType === "therapy"
            ? formValues.eventType === "therapy" &&
              userData.id !== editEventValues.clientId
              ? "Terapija" + `: ${userData.firstName} ${userData.lastName}`
              : "Terapija" + `: ${getUsersName(editEventValues.therapistId)} `
            : ""}
        </h1>
        <div className={styles.formWrapper}>
          <div className={styles.form}>
            {type === "create" && (
              <div className={styles.item}>
                <label className={styles.label} htmlFor="vrsta">
                  Vrsta događaja
                </label>
                <select
                  className={styles.select}
                  value={formValues.eventType}
                  disabled={type === "edit" && role !== "Referada"}
                  name="eventType"
                  onChange={(event) =>
                    handleInputChange(
                      "eventType",
                      event.target.value,
                      setFormValues
                    )
                  }
                >
                  {eventOptions.map((event, index) => {
                    if (role === "Referada") {
                      return (
                        <option key={index} value={event.value}>
                          {event.label}
                        </option>
                      );
                    } else if (
                      role === "Voditelj" ||
                      role === "Polaznik VT-a" ||
                      role === "Terapeut" ||
                      role === "Učitelj"
                    ) {
                      if (index === 0) {
                        return (
                          <option key={index} value={event.value}>
                            {event.label}
                          </option>
                        );
                      } else {
                        return null;
                      }
                    } else if (role === "Supervizor") {
                      if (event.value === "individual_supervision") {
                        return (
                          <option key={index} value={event.value} selected>
                            {event.label}
                          </option>
                        );
                      }
                    } else {
                      return null;
                    }
                  })}
                </select>
              </div>
            )}
            {(formValues.eventType === "therapy" ||
              formValues.eventType === "individual_supervision") && (
              <>
                <div className={styles.item}>
                  <label className={styles.label} htmlFor="klijent">
                    Polaznik/student
                  </label>
                  <div className={styles.search}>
                    <div className={styles.searchInput}>
                      <input
                        type="search"
                        className={styles.input}
                        placeholder="Upiši"
                        value={searchValue}
                        onChange={(event) =>
                          handleKlijentChange(
                            event,
                            setSearchValue,
                            setIsSearchVisible
                          )
                        }
                        disabled={formValues.outsideClient !== ""}
                      />
                    </div>
                    <div
                      className={
                        searchValue === "" || !isSearchVisible
                          ? styles.none
                          : styles.searchDropdown
                      }
                    >
                      {filteredUsers?.slice(0, 5).map((user, index) => (
                        <div
                          key={index}
                          className={styles.dropdownItems}
                          onClick={() =>
                            handleKlijentClick(
                              user.id,
                              user.firstName,
                              user.lastName,
                              setSearchValue,
                              setFormValues,
                              setIsSearchVisible
                            )
                          }
                        >
                          {user.firstName} {user.lastName}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={styles.item}>
                  <label className={styles.label} htmlFor="klijentvan">
                    Klijent van škole
                  </label>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Upiši"
                    value={formValues.outsideClient}
                    disabled={searchValue !== ""}
                    name="outsideClient"
                    onChange={(event) =>
                      handleInputChange(
                        "outsideClient",
                        event.target.value,
                        setFormValues
                      )
                    }
                  />
                </div>
              </>
            )}
            {formValues.eventType === "other" && (
              <div className={styles.item}>
                <label className={styles.label} htmlFor="Ostalo">
                  Ostalo
                </label>
                <input
                  type="text"
                  className={styles.input}
                  placeholder="Upiši"
                  value={formValues.name}
                  name="name"
                  onChange={(event) =>
                    handleInputChange("name", event.target.value, setFormValues)
                  }
                />
              </div>
            )}
            <div className={styles.item}>
              <label className={styles.label} htmlFor="mjesto">
                Grad i soba
              </label>
              <select
                className={styles.select}
                name="roomId"
                value={secondSelectedRoomId || formValues.roomId}
                onChange={(event) =>
                  handleInputChange("roomId", event.target.value, setFormValues)
                }
                disabled={type === "edit" && role !== "Referada"}
              >
                {rooms.map((room, index) => (
                  <option value={room.value} key={index}>
                    {room.label}
                  </option>
                ))}
              </select>
            </div>
            {zakupiteljType === "Basic" ? (
              <div className={styles.item}>
                <label className={styles.label} htmlFor="datum">
                  Datum održavanja
                </label>
                <input
                  type="date"
                  className={styles.input}
                  value={formValues.date}
                  name="date"
                  onChange={(event) =>
                    handleInputChange("date", event.target.value, setFormValues)
                  }
                  disabled={type === "edit" && role !== "Referada"}
                />
              </div>
            ) : zakupiteljType === "Premium" || userData.roleId === 6 ? (
              <div className={styles.premiumItem}>
                <div className={styles.item}>
                  <label className={styles.label} htmlFor="datum">
                    Datum održavanja
                  </label>
                  <input
                    type="date"
                    className={styles.input}
                    value={formValues.date}
                    name="date"
                    onChange={(event) =>
                      handleInputChange(
                        "date",
                        event.target.value,
                        setFormValues
                      )
                    }
                    disabled={type === "edit" && role !== "Referada"}
                  />
                </div>
                {type === "create" && (
                  <div className={styles.item}>
                    <label className={styles.label} htmlFor="datum">
                      Broj ponavljanja
                    </label>
                    <button
                      onClick={() => setModalPonavljanja(true)}
                      className={styles.ponavljanjaBtn}
                    >
                      {selectedPonavljanja
                        ? "Odabrano"
                        : "Odaberite broj ponavljanja"}
                    </button>
                    {modalPonavljanja && (
                      <div className={styles.ponavljanjaWrapper}>
                        <div className={styles.ponavljanjaContainer}>
                          <div className={styles.modalContent}>
                            <h1 className={styles.h1}>Odabir ponavljanja</h1>
                            <div className={styles.selectColumn}>
                              <div className={styles.ponavljanjaOne}>
                                <label className={styles.label}>
                                  Ponovi svako:{" "}
                                </label>
                                <select
                                  className={styles.selectPonavljanja}
                                  value={selectedNumber}
                                  onChange={handleNumberChange}
                                >
                                  {Array.from({ length: 15 }, (_, i) => (
                                    <option key={i + 1} value={i + 1}>
                                      {i + 1}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  className={styles.selectPonavljanja}
                                  value={intervalType}
                                  onChange={handleIntervalChange}
                                >
                                  <option value="day">
                                    {selectedNumber === "1" ? "dan" : "dana"}
                                  </option>
                                  <option value="week">
                                    {selectedNumber == 1
                                      ? "tjedan"
                                      : selectedNumber < 5
                                      ? "tjedna"
                                      : "tjedana"}
                                  </option>
                                </select>
                              </div>
                              {intervalType === "week" && (
                                <div className={styles.daysContainer}>
                                  {[
                                    "Po",
                                    "Ut",
                                    "Sr",
                                    "Če",
                                    "Pe",
                                    "Su",
                                    "Ne",
                                  ].map((day) => (
                                    <button
                                      key={day}
                                      className={`${styles.dayBtn} ${
                                        selectedDays.includes(day)
                                          ? styles.selectedDay
                                          : ""
                                      }`}
                                      onClick={() => handleDayClick(day)}
                                    >
                                      {day}
                                    </button>
                                  ))}
                                </div>
                              )}
                              <div className={styles.ponavljanjaTwo}>
                                <label className={styles.label}>
                                  Do datuma:
                                </label>
                                <input
                                  type="date"
                                  className={styles.inputPonavljanja}
                                  value={ponavljanjaEndDate}
                                  onChange={handlePonavljanjaEndDateChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className={styles.createBtnsModal}>
                            <button
                              className={styles.btnCancel}
                              onClick={() => {
                                handleOdustaniModal();
                              }}
                            >
                              Odustani
                            </button>
                            <button
                              className={styles.btnCreate}
                              onClick={() => handlePonavljanjaSave()}
                              disabled={isOdaberiDisabled}
                            >
                              Odaberi
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className={styles.item}>
              <label className={styles.label} htmlFor="vrijeme">
                Vrijeme održavanja
              </label>
              {rooms[0]?.cityId !== 7 ? (
                <div className={styles.timeSelects}>
                  <select
                    className={styles.selectSmall}
                    value={selectedTimeStartLive ? selectedTimeStartLive : "0"}
                    name="timeFrom"
                    onChange={(event) => {
                      handleStartLiveChange(event);
                    }}
                    disabled={type === "edit" && role !== "Referada"}
                  >
                    <option value="0" disabled>
                      Odaberi
                    </option>
                    {freeAppointmentsLive && type === "create"
                      ? freeAppointmentsLive.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))
                      : timeSlotsStartLive &&
                        type === "edit" &&
                        timeSlotsStartLive.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                  </select>
                  <span className={styles.divider}>-</span>
                  <input
                    className={styles.selectSmall}
                    value={
                      selectedTimeEndLive
                        ? selectedTimeEndLive?.label
                        : "Odaberi"
                    }
                    disabled={true}
                  ></input>
                </div>
              ) : (
                <div className={styles.timeSelects}>
                  <select
                    className={styles.selectSmall}
                    value={selectedTimeStart ? selectedTimeStart : "0"}
                    name="timeFrom"
                    onChange={(event) =>
                      handleStartTimeChange(
                        event,
                        setSelectedTimeStart,
                        setFormValues
                      )
                    }
                    disabled={type === "edit" && role !== "Referada"}
                  >
                    <option value="0" selected disabled>
                      Odaberi
                    </option>
                    {availableTimeSlots &&
                      availableTimeSlots.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                  </select>
                  <span className={styles.divider}>-</span>
                  <select
                    className={styles.selectSmall}
                    value={selectedTimeEnd ? selectedTimeEnd : "0"}
                    onChange={(event) =>
                      handleEndTimeChange(
                        event,
                        setSelectedTimeEnd,
                        setFormValues
                      )
                    }
                    disabled={type === "edit" && role !== "Referada"}
                  >
                    <option value="0" selected disabled>
                      Odaberi
                    </option>
                    {filteredTimeEnd &&
                      filteredTimeEnd.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                  </select>
                </div>
              )}
            </div>

            {(type === "create" ||
              (type === "edit" &&
                (userData.id === editEventValues.therapistId ||
                  isCurrentUserSupervisor))) && (
              <div className={styles.item}>
                <label className={styles.label} htmlFor="napomena">
                  Napomena
                </label>
                <textarea
                  className={styles.textarea}
                  placeholder="Dodaj napomenu"
                  value={formValues.description}
                  name="description"
                  onChange={(event) =>
                    handleInputChange(
                      "description",
                      event.target.value,
                      setFormValues
                    )
                  }
                />
              </div>
            )}
            {formValues.eventType === "other" && (
              <div className={styles.item}>
                <label className={styles.label} htmlFor="opis">
                  Opis
                </label>
                <textarea
                  className={styles.opis}
                  value={formValues.description}
                  name="description"
                  onChange={(event) =>
                    handleInputChange(
                      "description",
                      event.target.value,
                      setFormValues
                    )
                  }
                  placeholder="Dodaj opis"
                />
              </div>
            )}
          </div>
          {role !== "Polaznik" && role !== "Zakupitelj" && (
            <div className={styles.btnsContainer}>
              {type === "edit" && isDeleteSupported && (
                <div
                  className={styles.delete}
                  onClick={() => handleDeleteEvent()}
                >
                  <TrashIcon alt="" />
                </div>
              )}

              <div className={styles.createBtns}>
                {type === "edit" &&
                  isDeleteSupported &&
                  (userData.roleId === 6 || zakupiteljType === "Premium") && (
                    <div
                      className={styles.btnDeleteAll}
                      onClick={() => handleDeleteManyEvents()}
                    >
                      Obriši niz
                    </div>
                  )}
                <button className={styles.btnCancel} onClick={onClick}>
                  Odustani
                </button>
                <button
                  className={styles.btnCreate}
                  onClick={() => {
                    type === "edit"
                      ? updateEvent(
                          formValues.eventType,
                          formValues,
                          authToken,
                          startDate,
                          endDate,
                          selectedRooms,
                          setEvents,
                          setIsCreateOpen,
                          reRender,
                          setReRender
                        )
                      : zakupiteljType === "Basic" ||
                        selectedPonavljanja === null
                      ? createEvent(
                          formValues.eventType,
                          formValues,
                          authToken,
                          startDate,
                          endDate,
                          selectedRooms,
                          setEvents,
                          setIsCreateOpen,
                          reRender,
                          setReRender
                        )
                      : createManyEvents(
                          formValues.eventType,
                          formValues,
                          authToken,
                          startDate,
                          endDate,
                          selectedRooms,
                          setEvents,
                          setIsCreateOpen,
                          reRender,
                          setReRender,
                          selectedPonavljanja,
                          setIsBusyOpen,
                          setBusyAppointmentInfo
                        );
                  }}
                >
                  {type === "edit" ? "Spremi promjene" : "Kreiraj događaj"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
