import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const updateZavrsniRad = async (
  userId,
  zavrsniOR,
  caseStudy1,
  caseStudy2,
  accessToken
) => {
  try {
    const usersZavrsni = await axios.get(
      `${apiUrl}/api/user/zavrsni/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    let response;
    if (usersZavrsni.data === "") {
      response = await axios.post(
        `${apiUrl}/api/user/zavrsni/${userId}`,
        {
          zavrsniOR: zavrsniOR,
          caseStudy1: caseStudy1,
          caseStudy2: caseStudy2,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } else {
      response = await axios.put(
        `${apiUrl}/api/user/zavrsni/${userId}`,
        {
          zavrsniOR: zavrsniOR,
          caseStudy1: caseStudy1,
          caseStudy2: caseStudy2,
          zavrsniId: usersZavrsni?.data?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    }
    return response;
  } catch (error) {
    console.error("Error posting evidention entry data", error);
    throw error;
  }
};
