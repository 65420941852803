export default function CalendarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        stroke="#5F6D7E"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width={1.5}
        d="M3.093 9.404h17.824M16.043 2v3.29M7.965 2v3.29"
      />
      <path
        stroke="#5F6D7E"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width={1.5}
        d="M16.238 3.578H7.771C4.834 3.578 3 5.214 3 8.221v9.05c0 3.054 1.834 4.728 4.771 4.728h8.458c2.946 0 4.771-1.645 4.771-4.653V8.221c.01-3.007-1.816-4.643-4.762-4.643Z"
        clip-rule="evenodd"
      />
    </svg>
  );
}
