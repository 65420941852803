import { initializeApp } from "firebase/app";
import { OAuthProvider, getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBPvxepyruJq7CAxOkFRtE84DLUqFbpE_U",
  authDomain: "ipd-international-dev.firebaseapp.com",
  projectId: "ipd-international-dev",
  storageBucket: "ipd-international-dev.appspot.com",
  messagingSenderId: "716093249978",
  appId: "1:716093249978:web:1dbc79a616fd0bb293a583",
  measurementId: "G-9ZEQ6NC1Z2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Apple Auth provider
export const appleProvider = new OAuthProvider("apple.com");
