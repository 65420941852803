import React, { useState } from "react";
import styles from "./OcjenaTooltip.module.css";

const OcjeneTooltip = ({ data, children }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div
      className={styles.tooltipContainer}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && (
        <div className={styles.tooltip}>
          <div className={styles.firstRow}>
            <span className={styles.averageOcjena}>{data?.ocjena}</span>
            <span className={styles.dot}>·</span>
            <span className={styles.ukupnoOcjene}>
              {data?.allCount || 0} ocjena
            </span>
          </div>
          <div className={styles.ocjena}>
            <span className={styles.ocjenaName}>Odlično</span>
            <div className={styles.progress}>
              <div className={styles.progressBar}>
                <div
                  className={styles.orangeBar}
                  style={{
                    width: `calc(${data.count5} / (${data.allCount}) * 100%)`,
                  }}
                ></div>
              </div>
              <span className={styles.ocjenaCount}>{data.count5}</span>
            </div>
          </div>
          <div className={styles.ocjena}>
            <span className={styles.ocjenaName}>Vrlo dobro</span>
            <div className={styles.progress}>
              <div className={styles.progressBar}>
                <div
                  className={styles.orangeBar}
                  style={{
                    width: `calc(${data.count4} / (${data.allCount}) * 100%)`,
                  }}
                ></div>
              </div>
              <span className={styles.ocjenaCount}>{data.count4}</span>
            </div>
          </div>
          <div className={styles.ocjena}>
            <span className={styles.ocjenaName}>Osrednje dobro</span>
            <div className={styles.progress}>
              <div className={styles.progressBar}>
                <div
                  className={styles.orangeBar}
                  style={{
                    width: `calc(${data.count3} / (${data.allCount}) * 100%)`,
                  }}
                ></div>
              </div>
              <span className={styles.ocjenaCount}>{data.count3}</span>
            </div>
          </div>
          <div className={styles.ocjena}>
            <span className={styles.ocjenaName}>Jedva dovoljno</span>
            <div className={styles.progress}>
              <div className={styles.progressBar}>
                <div
                  className={styles.orangeBar}
                  style={{
                    width: `calc(${data.count2} / (${data.allCount}) * 100%)`,
                  }}
                ></div>
              </div>
              <span className={styles.ocjenaCount}>{data.count2}</span>
            </div>
          </div>
          <div className={styles.ocjena}>
            <span className={styles.ocjenaName}>Apsolutno nedovoljno</span>
            <div className={styles.progress}>
              <div className={styles.progressBar}>
                <div
                  className={styles.orangeBar}
                  style={{
                    width: `calc(${data.count1} / (${data.allCount}) * 100%)`,
                  }}
                ></div>
              </div>
              <span className={styles.ocjenaCount}>{data.count1}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OcjeneTooltip;
