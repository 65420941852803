import React, { useEffect, useState } from "react";
import ProfilePhoto from "../../../assets/img/blank_profile_photo.jpeg";
import Question from "../../../assets/img/Help.png";
import LoadingIcon from "../../../assets/svg/LoadingIcon.svg";
import Modal from "../../../components/Modal/Modal.jsx";
import { useAuthentication } from "../../hooks/useAuthentication";
import { fetchData } from "../api.js";
import DjelatniciCard from "../components/djelatniciCard";
import { deleteDjelatnikRequest } from "./API.js";
import styles from "./ObrisiDjelatnika.module.css";

const ObrisiDjelatnika = ({
  searchResults,
  employeesData,
  teacherData,
  userData,
  currentTabIndex,
  deleteDjelatnik,
  handleOdustaniClick,
  setVoditeljiData,
  setTeacherData,
  setUserData,
  fetchedGroupTeachers,
  setSelectedVoditelji,
  setSelectedDropdownOption,
}) => {
  let usersToDisplay = [];
  const { authToken } = useAuthentication();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersIDs, setSelectedUsersIDs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isNextButtonDisabled = selectedUsers.length === 0;
  const [showDeleteDjelatnikButtons, setShowDeleteDjelatnikButtons] =
    useState(deleteDjelatnik);
  if (searchResults) {
    usersToDisplay = searchResults;
  } else if (currentTabIndex === "one") {
    usersToDisplay = teacherData;
  } else if (currentTabIndex === "two") {
    usersToDisplay = userData;
  } else if (currentTabIndex === "three") {
    usersToDisplay = employeesData;
  }
  const handleCardSelection = (selectedData) => {
    const isUserSelected = selectedUsers.some(
      (user) => user.id === selectedData.id
    );
    setSelectedUsers((prevSelectedUsers) => {
      if (isUserSelected) {
        return prevSelectedUsers.filter((user) => user.id !== selectedData.id);
      } else {
        return [...prevSelectedUsers, selectedData];
      }
    });
    setSelectedUsersIDs((prevSelectedUsersIDs) => {
      if (isUserSelected) {
        return prevSelectedUsersIDs.filter((id) => id !== selectedData.id);
      } else {
        return [...prevSelectedUsersIDs, selectedData.id];
      }
    });
  };

  useEffect(() => {
    setModalButtons((prevButtons) => [
      prevButtons[0],
      {
        ...prevButtons[1],
        action: async () => {
          try {
            const response = await deleteDjelatnikRequest(
              selectedUsersIDs,
              authToken,
              setIsModalOpen,
              setSelectedDropdownOption
            );
            if (response.status === 200) {
              setSelectedUsersIDs([]);
              await fetchData(
                authToken,
                setVoditeljiData,
                setTeacherData,
                setUserData,
                fetchedGroupTeachers,
                setSelectedVoditelji
              );
            }
          } catch (error) {
            console.error("Error in action:", error);
          }
        },
      },
    ]);
  }, [selectedUsersIDs]);
  const [modalButtons, setModalButtons] = useState([
    {
      label: "Odustani",
      action: () => setIsModalOpen(false),
      className: "modal-btn-deletePage modal-btn-discontinue-deletePage",
    },
    {
      label: "Svejedno obriši",
      action: () => {},
      className: "modal-btn-deletePage modal-btn-delete-deletePage",
    },
  ]);

  const nextButtonClass = `${styles.nextButton} ${
    isNextButtonDisabled ? styles.disabledBtn : ""
  }`;

  return (
    <>
      {usersToDisplay.map((data, index) => (
        <div className={styles.cardContainer} key={index}>
          <DjelatniciCard
            profilePhoto={data?.imageUrl ? data?.imageUrl : ProfilePhoto}
            showExpandIcon={false}
            firstName={data.firstName}
            lastName={data.lastName}
            email={data.emailAddress ? data.emailAddress : "Nema podatka"}
            isSelected={selectedUsers.includes(data)}
            onClick={() => handleCardSelection(data)}
          />
          {showDeleteDjelatnikButtons && (
            <div className={styles.buttonContainer}>
              <button
                className={styles.cancelButton}
                onClick={() => handleOdustaniClick()}
              >
                Odustani
              </button>
              <button
                className={nextButtonClass}
                disabled={isNextButtonDisabled}
                onClick={() => setIsModalOpen(true)}
              >
                Izbriši djelatnika
              </button>
            </div>
          )}
          {isLoading && (
            <Modal image={LoadingIcon} description="Učitavanje..." />
          )}
          {isModalOpen && (
            <Modal
              image={Question}
              title={"Jeste li sigurni da želite obrisati djelatnike?"}
              description="Time ćete ih trajno izbrisati iz baze i podaci više neće biti vidljivi."
              buttons={modalButtons}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default ObrisiDjelatnika;
