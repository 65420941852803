import { styled } from "@mui/system";
export const ImageButton = styled(`button`)(({ selectedImage }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 155,
  width: 213,
  position: "absolute",
  left: 56,
  bottom: "-26px",
  border: "8px solid white",
  borderRadius: 16,
  backgroundColor: "#F3F2F2",
  "&:hover": {
    border: "8px solid #F3F2F2",
    cursor: "pointer",
  },
  backgroundImage: selectedImage ? `url(${selectedImage})` : "none",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));
