import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Arrow from "../../assets/img/BackArrow.png";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import { getNotificationById } from "./api/getNotificationById";
import { updateIsRead } from "./api/updateIsRead";
import styles from "./PojedinaObavijest.module.css";
import { formatDate } from "./utils";

function PojedinaObavijest() {
  const navigate = useNavigate();
  const params = useParams();
  const { authToken } = useAuthentication();
  const { userData, setUserData } = useUserState();
  const [notification, setNotification] = useState({});
  const [notAllowed, setNotAllowed] = useState(false);

  useEffect(() => {
    const fetchNotificationAndMarkAsRead = async () => {
      try {
        const response = await getNotificationById(params?.id, authToken);

        if (response?.response?.status === 403) {
          setNotAllowed(true);
        } else {
          setNotification(response.notification);
          if (!response?.notification?.readBy?.includes(userData.id)) {
            await updateIsRead(params.id, authToken);
            setUserData((prevUserData) => ({
              ...prevUserData,
              notificationsCount: prevUserData.notificationsCount - 1,
            }));
          }
        }
      } catch (error) {
        console.error("Error in fetchNotificationAndMarkAsRead:", error);
      }
    };

    if (authToken) fetchNotificationAndMarkAsRead();
  }, [authToken, params.id]);

  const newDate = formatDate(notification?.createdAt);
  return (
    <div className={styles.container}>
      <div
        className={styles.backRow}
        onClick={() => navigate("/primljene-obavijesti")}
      >
        <img alt="" src={Arrow} />
        <span className={styles.backSpan}>Natrag na pregled obavijesti</span>
      </div>
      {notAllowed ? (
        <div className={styles.notAllowed}>
          <img alt="" src={InfoIcon} height={18} width={18} />
          Nije vam omogućen pristup ovoj obavijesti!
        </div>
      ) : (
        <>
          <span className={styles.title}>{notification?.fullName}</span>
          <span className={styles.date}>
            {newDate?.formattedTime}, {newDate?.formattedDate}
          </span>
          <div className={styles.paragraph}>
            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{ __html: notification?.description }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default PojedinaObavijest;
