import emailjs from "emailjs-com";
import React, { useState } from "react";
import styles from "./KontaktirajModal.module.css";

export default function KontaktirajModal({
  setIsKontaktirajOpen,
  nameTo,
  nameFrom,
  emailTo,
  emailFrom,
}) {
  const [toSend, setToSend] = useState({
    user_name: nameFrom,
    user_email: emailFrom,
    email_from: emailFrom,
    email_to: "josip.gajsak@netscale.agency",
    subject: "",
    message: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send("service_u53b80c", "template_ghj748e", toSend, "FHavrKXL8jA7KouvH")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setIsKontaktirajOpen(false);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalContainer}>
        <div className={styles.modalTitle}>Kontaktiraj - {nameTo}</div>
        <input
          type="text"
          name="subject"
          placeholder="Upišite temu"
          value={toSend.subject}
          onChange={handleChange}
          className={styles.modalInput}
        />
        <textarea
          className={styles.modalText}
          name="message"
          placeholder="Upišite poruku"
          value={toSend.message}
          onChange={handleChange}
        ></textarea>
        <div className={styles.btnsContainer}>
          <button
            className={styles.cancelButton}
            onClick={() => setIsKontaktirajOpen(false)}
          >
            Odustani
          </button>
          <button className={styles.nextButton} onClick={onSubmit}>
            Pošalji
          </button>
        </div>
      </div>
    </div>
  );
}
