import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const deleteDjelatnikRequest = async (
  selectedIds,
  accessToken,
  setIsModalOpen,
  setSelectedDropdownOption
) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/users-role`,
      {
        userIds: selectedIds,
        role: 2,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    if (response.status === 200) {
      setIsModalOpen(false);
      setSelectedDropdownOption("");
    }
    return response;
  } catch (error) {
    console.error("Error deleting group:", error);
    throw error;
  }
};
