import axios from "axios";
import { getTherapistProfile } from "./getTherapistProfile";
const apiUrl = process.env.REACT_APP_API_URL;

export const editMyTherapistProfile = async (
  userId,
  newData,
  accessToken,
  setTherapistProfile
) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/therapist-profiles/${userId}`,
      newData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.data === null) {
      await axios.post(
        `${apiUrl}/api/therapist-profiles`,
        { ...newData, userId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    }
    if (response.status === 200) {
      getTherapistProfile(userId, accessToken, setTherapistProfile);
    }
    return response;
  } catch (error) {
    console.error("Error updating therapist profile data", error);
    throw error;
  }
};
