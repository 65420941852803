const EventCell = ({ events, setActionType, setOpenModal, setEventData }) => {
  const handleEventClick = (event) => {
    setEventData && setEventData(event);
    setActionType("edit");
    setOpenModal();
  };

  return (
    <div>
      {events.map((event, index) => (
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "500",
            minHeight: 16,
            minWidth: 110,
          }}
          key={index}
          onClick={() => handleEventClick(event)}
        >
          <p style={{ margin: 0 }}>{event.masterAbbreviation}</p>
        </div>
      ))}
    </div>
  );
};

export default EventCell;
