import React from "react";
import styles from "./InfoContainer.module.css";
function InfoContainer({
  datumStart,
  datumEnd,
  location,
  capacity,
  gprSati,
  price,
  prijavnicaType,
  datum,
  timeFrom,
  timeTo,
}) {
  const formattedDate = new Date(datum)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .join(".");

  const formattedDateStart = new Date(datumStart)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .join(".");

  const formattedDateEnd = new Date(datumEnd)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .join(".");
  const date = new Date(timeFrom);
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const formattedTimeFrom = `${hours}:${minutes}:${seconds}`;

  const dateTo = new Date(timeTo);
  const hoursTo = String(dateTo.getUTCHours()).padStart(2, "0");
  const minutesTo = String(dateTo.getUTCMinutes()).padStart(2, "0");
  const secondsTo = String(dateTo.getUTCSeconds()).padStart(2, "0");
  const formattedTimeTo = `${hoursTo}:${minutesTo}:${secondsTo}`;

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.column}>
          {prijavnicaType === "fakultativne-radionice" ||
          prijavnicaType === "grupne-supervizije" ? (
            <div className={styles.label}>Datum održavanja</div>
          ) : (
            <>
              <div className={styles.label}>Od datuma</div>
              <div className={styles.label}>Do datuma</div>
            </>
          )}
          <div className={styles.label}>Mjesto održavanja</div>
          {prijavnicaType !== "grupne-supervizije" && (
            <>
              <div className={styles.label}>Broj dostupnih mjesta</div>
              <div className={styles.label}>Broj sati GPR-a</div>
              <div className={styles.label}>Cijena radionice</div>
            </>
          )}
          {prijavnicaType === "grupne-supervizije" && (
            <>
              <div className={styles.label}>Vrijeme održavanja</div>
            </>
          )}
        </div>
        <div className={styles.column}>
          {prijavnicaType === "fakultativne-radionice" ||
          prijavnicaType === "grupne-supervizije" ? (
            <div className={styles.info}>
              {formattedDate || "12.listopad 2022."}
            </div>
          ) : (
            <>
              <div className={styles.info}>
                {formattedDateStart || "12.listopad 2022."}
              </div>
              <div className={styles.info}>
                {formattedDateEnd || "16.listopad 2022."}
              </div>
            </>
          )}

          <div className={styles.info}>{location || "ZG1"}</div>
          {prijavnicaType !== "grupne-supervizije" && (
            <>
              <div className={styles.info}>{capacity || 55}</div>
              <div className={styles.info}>{gprSati || 55}</div>
              <div className={styles.info}>{price || "150.00 €"}</div>
            </>
          )}
          {prijavnicaType === "grupne-supervizije" && (
            <>
              <div className={styles.info}>
                {formattedTimeFrom || "12:00"} - {formattedTimeTo || "13:00"}h
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default InfoContainer;
