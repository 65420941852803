import Help from "../../../../assets/img/Help.png";
import Success from "../../../../assets/img/Success.png";
import { createGroup } from "../../api/createGroup";

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    alignContent: "center",
    backgroundColor: "#FFFFFF",
    color: "#5F6D7E",
    height: "46px",
    padding: "8px 16px",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "8px",
    border: "1px solid #D1D9E2;",
    boxShadow: "4px 4px 64px 0px rgba(95, 109, 126, 0.10);",
    cursor: "pointer",
    "&:focus": {
      border: "0 !important",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#F2F2F2",
      },
    };
  },
};

export const handleInputChange = (
  name,
  value,
  setGroupValues,
  updateGroupData
) => {
  setGroupValues((prevValues) => ({
    ...prevValues,
    [name]: value,
  }));
  updateGroupData((prevGroupData) => ({
    ...prevGroupData,
    [name]: value,
  }));
};

export const handleCreateGroup = async (
  groupValues,
  accessToken,
  resetGroupData,
  navigate,
  setModalContent,
  setModalOpen
) => {
  try {
    const newGroupData = {
      group: {
        name: groupValues.name,
        programId: groupValues.programId,
        cityId: groupValues.cityId,
        calendarFieldColour: groupValues.calendarFieldColour,
        calendarTextColour: groupValues.calendarTextColour,
      },
      userInGroup: {
        voditeljUserIds: groupValues.noviVoditelji || [],
        polaznikUserIds: groupValues.noviPolaznici || [],
      },
    };
    const response = await createGroup(newGroupData, accessToken);
    if (response.status === 200) {
      const createdGroupId = response.data.id;
      setModalOpen(true);
      setModalContent({
        image: Success,
        title: "Grupa uspješno kreirana.",
        description: "",
        buttons: [
          {
            label: "Ok",
            action: () => {
              resetGroupData();
              navigate(`/grupe/${createdGroupId}`);

              setModalOpen(false);
            },
            className: "modal-btn-success",
          },
        ],
      });
    }
  } catch (error) {
    console.error("Error creating group:", error);
    setModalOpen(true);
    setModalContent({
      image: Help,
      title: "Greška nije moguće kreirati grupu.",
      description: "Pokušajte ponovno kasnije",
      buttons: [
        {
          label: "Ok",
          action: () => {
            setModalOpen(false);
          },
          className: "modal-btn-success",
        },
      ],
    });
  }
};
