import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../hooks/useAuthentication";
import "./App.css";
import Calendar from "./Calendar";
import ModalEdit from "./EditModal/ModalEdit";
import AddEventButton from "./calendar-utils/AddEventDialog/AddEventButton";
import AddEventDialog from "./calendar-utils/AddEventDialog/AddEventDialog";
import { getCalendarData } from "./calendar-utils/calendar.api";
import { roomCalendars } from "./calendar-utils/calendar.mocks";
import { academicYears } from "./calendar-utils/calendar.utils";

const Master = () => {
  const { authToken } = useAuthentication();
  const navigate = useNavigate();
  const [calendarData, setCalendarData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [workshops, setWorkshops] = useState([]);
  const [groups, setGroups] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmptyTimeSlot, setSelectedEmptyTimeSlot] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [eventData, setEventData] = useState();
  const [actionType, setActionType] = useState("");
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(
    academicYears[3]
  );
  const startYear = selectedAcademicYear.start.getFullYear();
  const endYear = selectedAcademicYear.end.getFullYear();
  useEffect(() => {
    if (authToken)
      getCalendarData(authToken, startYear, endYear, setCalendarData);
  }, [authToken, startYear, endYear]);
  return (
    <div>
      {openModal && (
        <AddEventDialog
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            setSelectedEmptyTimeSlot(undefined);
            setEventData(undefined);
            setActionType(undefined);
          }}
          type={actionType}
          clickedEvent={eventData}
          selectedEmptyTimeSlot={selectedEmptyTimeSlot}
          startYear={startYear}
          endYear={endYear}
          setCalendarData={setCalendarData}
          setOpenModal={setOpenModal}
        />
      )}
      <div style={{ width: "400%" }}>
        <div
          style={{
            position: "sticky",
            left: 24,
            display: "flex",
            flexDirection: "column",
            zIndex: 800,
            width: 250,
          }}
        >
          <Typography
            onClick={() => navigate("/kalendar")}
            sx={{ color: "#7E818C", fontSize: "20px", fontWeight: "600" }}
          >
            {"<- Natrag na početnu"}
          </Typography>{" "}
          <h1>Master kalendar</h1>
          {/* Edit modal se otvara i zatvara klikom na button ispod */}
          <button onClick={() => setShowEdit(!showEdit)}>EDIT</button>
          <ModalEdit
            open={showEdit}
            groupList={groups}
            onClose={() => {
              setShowEdit(false);
              setSelectedEmptyTimeSlot(undefined);
              setEventData(undefined);
              setActionType(undefined);
            }}
            type={"edit"}
            workshopList={workshops}
            clickedEvent={eventData}
            selectedEmptyTimeSlot={selectedEmptyTimeSlot}
          />
          <Box display="flex" mb={3}>
            <div>Akademska godina</div>
            &nbsp;&nbsp;&nbsp;
            {/* Select dropdown for choosing academic year */}
            <select
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(Number(e.target.value));
                setSelectedAcademicYear(
                  academicYears.find(
                    (year) =>
                      year.start.getFullYear() === Number(e.target.value)
                  )
                );
              }}
            >
              {academicYears.map((year) => (
                <option
                  key={year.start.getFullYear()}
                  value={year.start.getFullYear()}
                >
                  {`${year.start.getFullYear()}/${
                    year.end.getFullYear() - 2000
                  }`}{" "}
                  {/* Display in the format "20XX/20XX" */}
                </option>
              ))}
            </select>
          </Box>
          <AddEventButton
            onAddEvent={() => {
              setOpenModal(true);
              setActionType("add-global");
            }}
          />
        </div>
      </div>
      <Box m="20px" width={"100%"} sx={{ overflow: "scroll" }}>
        <div className="App">
          {/* First Calendar */}
          {roomCalendars.map((item, index) => (
            <div className="calendar-container" key={index}>
              <Calendar
                key={item.room}
                name={item.name}
                room={item.roomId}
                setOpenModal={() => {
                  setOpenModal(true);
                }}
                setActionType={setActionType}
                headerColor={item.headerColor}
                groupList={groups}
                tableHeadColor={item.tableHeadColor}
                setSelectedEmptyTimeSlot={setSelectedEmptyTimeSlot}
                setEventData={setEventData}
                selectedYear={selectedYear}
                selectedAcademicYear={selectedAcademicYear}
                masterObject={calendarData.masterObject?.[item.roomName]}
                showToggleForFirstIndex={index === 0}
                startYear={startYear}
                endYear={endYear}
                setCalendarData={setCalendarData}
              />
            </div>
          ))}
        </div>
      </Box>
    </div>
  );
};

export default Master;
