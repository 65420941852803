export const Obavijesti = [
  {
    user: "Mislav Berić",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-12-10T15:30:00",
    isUnread: true,
  },
  {
    user: "Antonia Mihanović",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-12-10T10:30:00",
    isUnread: true,
  },
  {
    user: "Matea Kukulj",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-12-09T14:30:00",
    isUnread: true,
  },
  {
    user: "Referada IPD Centra",
    notificationTitle:
      "Poštovani, otvorene su prijave za fakultativnu radionicu Preporađanje je vrlo moćna i jednostavna tehnika rada na emocijama i traumama koje nosimo potisnute u sebi i koja se danas radi u cijelom svijetu te ne zahtijeva nikakvo predznanje niti pripremu. Rezultat preporađanja je postizanje većeg unutarnjeg mira, emocionalne protočnosti, stanja preporođenosti, … moglo bi se reći da djeluje na naše tijelo i emocionalno-psihološko stanje kao što djeluje restartanje na kompjuter.",
    timePosted: "2023-12-01T16:30:00",
    isUnread: true,
  },
  {
    user: "Mislav Berić",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-11-22T15:30:00",
    isUnread: false,
  },
  {
    user: "Antonia Mihanović",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-11-22T10:30:00",
    isUnread: false,
  },
  {
    user: "Matea Kukulj",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-12-09T14:30:00",
    isUnread: false,
  },
  {
    user: "Referada IPD Centra",
    notificationTitle:
      "Poštovani, otvorene su prijave za fakultativnu radionicu Preporađanje je vrlo moćna i jednostavna tehnika rada na emocijama i traumama koje nosimo potisnute u sebi i koja se danas radi u cijelom svijetu te ne zahtijeva nikakvo predznanje niti pripremu. Rezultat preporađanja je postizanje većeg unutarnjeg mira, emocionalne protočnosti, stanja preporođenosti, … moglo bi se reći da djeluje na naše tijelo i emocionalno-psihološko stanje kao što djeluje restartanje na kompjuter.",
    timePosted: "2023-12-01T16:30:00",
    isUnread: false,
  },
  {
    user: "Mislav Berić",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-11-22T15:30:00",
    isUnread: false,
  },
  {
    user: "Antonia Mihanović",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-11-22T10:30:00",
    isUnread: false,
  },
  {
    user: "Matea Kukulj",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-12-09T14:30:00",
    isUnread: false,
  },
  {
    user: "Referada IPD Centra",
    notificationTitle:
      "Poštovani, otvorene su prijave za fakultativnu radionicu Preporađanje je vrlo moćna i jednostavna tehnika rada na emocijama i traumama koje nosimo potisnute u sebi i koja se danas radi u cijelom svijetu te ne zahtijeva nikakvo predznanje niti pripremu. Rezultat preporađanja je postizanje većeg unutarnjeg mira, emocionalne protočnosti, stanja preporođenosti, … moglo bi se reći da djeluje na naše tijelo i emocionalno-psihološko stanje kao što djeluje restartanje na kompjuter.",
    timePosted: "2023-12-01T16:30:00",
    isUnread: false,
  },
  {
    user: "Mislav Berić",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-11-22T15:30:00",
    isUnread: false,
  },
  {
    user: "Antonia Mihanović",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-11-22T10:30:00",
    isUnread: false,
  },
  {
    user: "Matea Kukulj",
    notificationTitle: "Napisana introspekciju za predavanje 1.1",
    timePosted: "2023-12-09T14:30:00",
    isUnread: false,
  },
  {
    user: "Referada IPD Centra",
    notificationTitle:
      "Poštovani, otvorene su prijave za fakultativnu radionicu Preporađanje je vrlo moćna i jednostavna tehnika rada na emocijama i traumama koje nosimo potisnute u sebi i koja se danas radi u cijelom svijetu te ne zahtijeva nikakvo predznanje niti pripremu. Rezultat preporađanja je postizanje većeg unutarnjeg mira, emocionalne protočnosti, stanja preporođenosti, … moglo bi se reći da djeluje na naše tijelo i emocionalno-psihološko stanje kao što djeluje restartanje na kompjuter.",
    timePosted: "2023-12-01T16:30:00",
    isUnread: false,
  },
];
