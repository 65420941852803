import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Success from "../../assets/img/Success.png";
import LoadingIcon from "../../assets/svg/LoadingIcon.svg";
import Modal from "../../components/Modal/Modal.jsx";
import SearchStudents from "../attendees/searchStudents/SearchStudents";
import {
  fetchCities,
  fetchData,
  fetchGroups,
  fetchSearchData,
  fetchWI,
} from "../attendees/utils";
import { useAuthentication } from "../hooks/useAuthentication";
import TableAttendees from "../TableAttendees/index.jsx";
import styles from "../TableAttendees/Table.module.css";
import { exportUsers } from "./api/exportUsers";

const Export = () => {
  const navigate = useNavigate();
  const programsWithGroups = ["1", "3", "4", "5", "6", "13", "14", "11", "12"];
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [students, setStudents] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [intensives, setIntensives] = useState([]);
  const [groups, setGroups] = useState([]);
  const { authToken } = useAuthentication();
  const [mjestoUpisa, setMjestoUpisa] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [polazniciCount, setPolazniciCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState({
    value: "all",
    label: "Svi polaznici",
  });
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedWorkshop, setSelectedWorkshop] = useState([]);
  const [selectedIntensive, setSelectedIntensive] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (selectedProgram.value === "22" || selectedProgram.value === "33") {
      setSelectedGroup({
        value: null,
        label: "Sve grupe",
      });
      if (authToken) fetchWI(authToken, setWorkshops, setIntensives);
    }
  }, [authToken, selectedProgram]);

  const debouncedFetchSearchData = useMemo(() => {
    return _.debounce(
      (
        currentPage,
        authToken,
        setStudents,
        setPolazniciCount,
        searchResults
      ) => {
        fetchSearchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          searchResults,
          false
        );
      },
      400
    );
  }, []);

  useEffect(() => {
    const city = selectedOption.map((option) => option.label);
    if (searchResults.length > 0) {
      if (authToken)
        debouncedFetchSearchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          searchResults
        );
    } else {
      if (authToken)
        fetchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          false,
          city,
          selectedProgram.value,
          selectedGroup.value,
          selectedWorkshop.value,
          selectedIntensive.value
        );
    }
  }, [
    currentPage,
    authToken,
    selectedOption,
    selectedProgram,
    selectedGroup,
    polazniciCount,
    selectedWorkshop,
    selectedIntensive,
    searchResults,
  ]);

  useEffect(() => {
    if (authToken) fetchCities(authToken, setMjestoUpisa);
  }, [authToken]);

  useEffect(() => {
    if (searchResults.length > 0) setCurrentPage(1);
  }, [searchResults]);

  useEffect(() => {
    if (
      selectedProgram.value !== null &&
      selectedProgram.value !== "22" &&
      selectedProgram.value !== "33"
    ) {
      if (authToken) fetchGroups(selectedProgram, authToken, setGroups);
    }
  }, [selectedProgram, selectedOption, authToken, currentPage]);

  const navigateToPolaznici = () => {
    navigate("/polaznici");
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
    navigate("/polaznici");
  };

  const modalButtons = [
    {
      label: "U redu",
      action: hideModal,
      className: "modal-btn-success",
    },
  ];

  const handleGrupaChange = async (selectedOption) => {
    setSelectedGroup(selectedOption);
  };

  const handleProgramChange = async (selectedOption) => {
    setSelectedProgram(selectedOption);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const handleWorkshopChange = async (selectedOption) => {
    setSelectedWorkshop(selectedOption);
  };

  const handleIntensiveChange = async (selectedOption) => {
    setSelectedIntensive(selectedOption);
  };

  const handleUserSelection = (selectedRows) => {
    const usersNow = students.map((student) => student.id);

    const updatedUsers = [...users, ...usersNow].filter((user) =>
      selectedRows.includes(user)
    );
    setUsers([...users, ...usersNow]);
    setSelectedUsers([...new Set(updatedUsers)]);
  };

  const sviPolaznici = [
    { value: "all", label: "Svi polaznici" },
    { value: "1", label: "Polaznici RAR" },
    { value: "3", label: "Polaznici OR1" },
    { value: "4", label: "Polaznici OR2" },
    { value: "5", label: "Polaznici OR3" },
    { value: "6", label: "Polaznici OR4" },
    { value: "13", label: "Polaznici TP1" },
    { value: "14", label: "Polaznici TP2" },
    { value: "11", label: "Polaznici TT5" },
    { value: "12", label: "Polaznici TT6" },
    { value: "all", label: "Polaznici VT" },
    { value: "16", label: "RPO" },
    { value: "15", label: "Roditeljstvo 0-6" },
    { value: "all", label: "Pauzirani program" },
    { value: "22", label: "Polaznici radionica" },
    { value: "33", label: "Polaznici Inteziva" },
    { value: "44", label: "Arhivirani polaznici" },
  ];

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      display: "flex",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      color: "#4D4D4D",
      height: "40px",
      width: "200px",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      borderRadius: "4px",
      border: "1px solid #D1D9E2 !important",
      boxShadow: "none",
      cursor: "pointer",
      "&:focus": {
        border: "0 !important",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#5F6D7EC7",
        fontWeight: "500",
        fontSize: "16px",
        fontFamily: "Inter",
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
        ":hover": {
          backgroundColor: "#F2F2F2",
        },
      };
    },
  };

  const MultiselectPlaceStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#FFFFFF",
      color: "#4D4D4D",
      marginLeft: "15px",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      width: "300px",
      borderRadius: "8px",
      border: "1px solid #D1D9E2 !important",
      padding: "2px 0px",
      cursor: "pointer",
      boxShadow: "none",
      "&:focus": {
        border: "0 !important",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      padding: "5px",
      display: "flex",
      alignItems: "center",
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      ":hover": {
        backgroundColor: "#F2F2F2",
      },
    }),
  };
  const MultiselectPlaceOption = ({ innerProps, label, isSelected }) => {
    return (
      <div
        {...innerProps}
        style={{
          display: "flex",
          gap: "12px",
          cursor: "pointer",
          padding: "12px 16px",
        }}
      >
        <input
          type="checkbox"
          checked={isSelected}
          readOnly
          style={{
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
        />
        <span
          style={{
            color: "rgba(95, 109, 126, 0.78)",
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "22px",
          }}
        >
          {label}
        </span>
      </div>
    );
  };
  const handleExportUsers = async () => {
    try {
      setIsLoading(true);
      if (selectedUsers.length === 0) {
        return;
      }
      const data = {
        userIds: selectedUsers,
      };
      const response = await exportUsers(data, authToken);
      const blob = new Blob([response], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "exported_users.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Cleanup
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Greška prilikom exporta polaznika:", error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <SearchStudents setSearchResults={setSearchResults} />

      <Box display="flex">
        <Typography
          sx={{
            marginTop: "20px",
            color: "#4D4E4E",
            fontSize: "30px",
            fontWeight: "600",
          }}
        >
          Export podataka:
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="48px"
        width="80%"
      >
        <Box display="flex" alignItems="center" gap="40px">
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "22px",
                fontFamily: "Inter",
                color: "#4D4E4E",
              }}
            >
              Mjesto upisa
            </Typography>

            <Select
              isClearable={false}
              options={mjestoUpisa}
              value={selectedOption}
              isMulti
              onChange={handleSelectChange}
              styles={MultiselectPlaceStyles}
              isSearchable={false}
              components={{
                Option: MultiselectPlaceOption,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <Select
            defaultValue={sviPolaznici[0]}
            options={sviPolaznici}
            onChange={handleProgramChange}
            styles={selectStyles}
            isSearchable={false}
            components={{ IndicatorSeparator: () => null }}
          />

          {programsWithGroups.includes(selectedProgram.value) && (
            <Select
              defaultValue={groups[0]}
              options={groups}
              onChange={handleGrupaChange}
              styles={selectStyles}
              isSearchable={false}
              components={{ IndicatorSeparator: () => null }}
            />
          )}
          {selectedProgram.value === "22" && (
            <Select
              defaultValue={workshops[0]}
              options={workshops}
              onChange={handleWorkshopChange}
              styles={selectStyles}
              isSearchable={false}
              components={{ IndicatorSeparator: () => null }}
            />
          )}
          {selectedProgram.value === "33" && (
            <Select
              defaultValue={{ label: "Svi intenzivi" }}
              options={intensives}
              onChange={handleIntensiveChange}
              styles={selectStyles}
              isSearchable={false}
              components={{ IndicatorSeparator: () => null }}
            />
          )}
        </Box>
      </Box>
      <div className={styles.exportTable}>
        <TableAttendees
          data={students}
          rowsPerPage={10}
          showCheckbox={true}
          handleSelectionChange={handleUserSelection}
          selectionMode="multiple"
          showAvatars={true}
          archive={true}
          polazniciCount={polazniciCount}
          setPolazniciCount={setPolazniciCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.cancelButton} onClick={navigateToPolaznici}>
          Odustani
        </button>
        <button className={styles.nextButton} onClick={handleExportUsers}>
          Export podataka
        </button>
      </div>
      {isLoading && <Modal image={LoadingIcon} description="Učitavanje..." />}
      {isModalOpen && (
        <Modal
          image={Success}
          description="Podaci o polaznicima uspješno exportani!"
          buttons={modalButtons}
        />
      )}
    </div>
  );
};

export default Export;
