import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
export const getAllSupervisions = async (accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/supervision`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
