import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const updateDefaultCity = async (cityId, accessToken) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/updateCalendarCity?cityId=${cityId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating default calendar city!", error);
    throw error;
  }
};
