const UserIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 8.594a4 4 0 100-8 4 4 0 000 8zm0 0c-3.535 0-6.4 2.149-6.4 4.8m6.4-4.8c3.534 0 6.4 2.149 6.4 4.8"
        stroke="current"
        strokeWidth={1.2}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default UserIcon;
