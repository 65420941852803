import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArchiveIcon from "../../assets/svg/ArchiveIcon.svg";
import UserAvatarIcon from "../../assets/svg/UserAvatarIcon.svg";
import BEPagination from "../../components/PaginationWithBE/BEPagination";
import { useCreateEventState } from "../../context/CreateEventContext";
import { useUserState } from "../../context/UserContext";
import { fetchData } from "../attendees/utils";
import { useAuthentication } from "../hooks/useAuthentication";
import { assignUsersToMentor } from "../voditelji/api/assignUsersToMentor";
import styles from "./Table.module.css";

const TableAttendees = ({
  archive,
  data,
  showCheckbox,
  groupData,
  onRowClick,
  selectionMode,
  showAvatars = true,
  handleSelectionChange,
  userIdsIMentor,
  showButtonRow,
  onClickOdustani,
  polazniciCount,
  currentPage,
  setCurrentPage,
  setStudents,
  isCreateNewGroup,
  isCreateEvent,
  isAssignMentor,
  handleDodajPolaznika,
  setIsModalOpen,
  idGroup,
  setPolazniciCount,
  cityId,
  programId,
  group,
}) => {
  const { authToken } = useAuthentication();
  const { userData } = useUserState();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [selectedRows, setSelectedRows] = useState([]);
  const { createEventData, updateCreateEventData } = useCreateEventState();
  const [eventPolazniciData, setEventPolazniciData] = useState();
  const updatedPolaznici = data.map((polaznik) => {
    const program =
      polaznik.groups &&
      polaznik.groups
        .slice(0, 2)
        .map((group) => group.name.split(" ")[1])
        .join(", ");

    const grupa =
      polaznik.groups &&
      polaznik.groups
        .slice(0, 2)
        .map((group) => group.name)
        .join(", ");

    return {
      ...polaznik,
      program,
      grupa,
    };
  });

  useEffect(() => {
    if (userIdsIMentor && userIdsIMentor.length > 0) {
      setSelectedRows(userIdsIMentor);
    }
  }, [userIdsIMentor]);

  const handleCheckboxChange = (id) => {
    let updatedRows;
    if (selectionMode === "single") {
      updatedRows = [id];
    } else {
      const isSelected = selectedRows.includes(id);
      updatedRows = isSelected
        ? selectedRows.filter((rowId) => rowId !== id)
        : [...selectedRows, id];
    }
    setSelectedRows(updatedRows);
    if (handleSelectionChange) {
      handleSelectionChange(updatedRows);
    }
  };

  const handleSelectAll = () => {
    let updatedRows;
    if (selectionMode === "single") {
      updatedRows = [data[0]?.id];
    } else {
      const allIds = data.map((el) => el?.id);
      const filteredIds = allIds.filter((id) => !selectedRows.includes(id));
      const repeatedIds = allIds.filter((id) => selectedRows.includes(id));
      const deleteIds = selectedRows.filter((id) => !repeatedIds.includes(id));
      updatedRows = [...deleteIds, ...filteredIds];
    }
    setSelectedRows(updatedRows);
    if (handleSelectionChange) {
      handleSelectionChange(updatedRows);
    }
  };

  const handleEvent = (id) => {
    if (onRowClick) {
      onRowClick(id);
    }
  };
  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber, () => {
      fetchData(
        currentPage,
        authToken,
        setStudents,
        setPolazniciCount,
        cityId,
        programId,
        group
      );
    });
  };

  const handleDodajPolaznikaToEvent = () => {
    updateCreateEventData({
      ...createEventData,
      polazniciData: eventPolazniciData,
    });
    navigate(-1);
  };
  return (
    <>
      <table className={styles.table}>
        <thead className={styles.tableRowHeader}>
          <tr>
            {showCheckbox && <th className={styles.tableHeader}></th>}
            {archive ? (
              <th className={styles.tableHeader}>
                <span className={styles.selected}>Označeno:</span>{" "}
                {[...new Set(selectedRows)].length}{" "}
                <span
                  className={styles.selectAll}
                  onClick={() => handleSelectAll()}
                >
                  Odaberi sve
                </span>
              </th>
            ) : (
              <th className={styles.tableHeader}>Ime i prezime</th>
            )}
            <th className={styles.tableHeader}>Aktivni program/Grupa</th>
            <th className={styles.tableHeader}>Email</th>
            <th className={styles.tableHeader}>Broj mobitela</th>
          </tr>
        </thead>
        <tbody style={{ border: "1px solid #D1D9E2" }}>
          {updatedPolaznici.map((el, index) => (
            <tr key={index ? el?.id : el?.element?.id}>
              {showCheckbox && (
                <td className={styles.tableCell}>
                  <input
                    type="checkbox"
                    checked={
                      el &&
                      selectedRows.includes(el?.id ? el.id : el?.element?.id)
                    }
                    onChange={() =>
                      handleCheckboxChange(el?.id ? el?.id : el?.element?.id)
                    }
                    className={styles.customCheckbox}
                  />
                </td>
              )}
              <td
                onClick={() => {
                  handleEvent(el?.id);
                }}
                className={styles.tableCell}
                style={{
                  verticalAlign: "middle",
                  color: "#2E3646",
                  cursor: onRowClick ? "pointer" : "default",
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {el?.isArchived && (
                  <img src={ArchiveIcon} alt="" style={{ marginRight: 5 }} />
                )}
                {showAvatars && (
                  <img
                    style={{}}
                    src={
                      el?.imageUrl
                        ? el?.imageUrl
                        : el?.element?.imageUrl
                        ? el?.element?.imageUrl
                        : UserAvatarIcon
                    }
                    alt="user-avatar"
                    className={styles.avatars}
                  />
                )}
                {el?.firstName
                  ? el?.firstName
                  : el?.element?.firstName
                  ? el?.element?.firstName
                  : "---"}{" "}
                {el?.lastName
                  ? el?.lastName
                  : el?.element?.lastName
                  ? el?.element?.lastName
                  : ""}
              </td>
              <td className={styles.programCell}>
                <div className={styles.programCellContent}>
                  <p className={styles.programText}>
                    {groupData ? (
                      <>
                        {groupData ? groupData?.programName : "Nema podataka!"}
                      </>
                    ) : (
                      <>
                        {el?.programNames ? (
                          <>
                            {el?.programNames.length === 0
                              ? "---"
                              : el?.programNames.length === 1
                              ? `${el?.programNames?.[0]}`
                              : el?.programNames.length > 1
                              ? `${el?.programNames?.[0]}, ${el?.programNames?.[1]}`
                              : "---"}
                          </>
                        ) : (
                          <>
                            {el?.activePrograms ? (
                              <>
                                {el?.activePrograms.length === 0
                                  ? "---"
                                  : el?.activePrograms.length === 1
                                  ? `${el?.activePrograms?.[0]}`
                                  : el?.activePrograms.length > 1
                                  ? `${el?.activePrograms?.[0]}, ${el?.activePrograms?.[1]}`
                                  : "---"}
                              </>
                            ) : (
                              "Nema podataka"
                            )}
                          </>
                        )}
                      </>
                    )}
                  </p>
                  <p className={styles.groupText}>
                    {groupData ? (
                      <>{groupData ? groupData.name : "Nema podataka!"}</>
                    ) : (
                      <>
                        {el?.grupe?.groups ? (
                          <>
                            {el?.grupe?.groups.length === 0
                              ? "---"
                              : el?.grupe?.groups.length === 1
                              ? `${el?.grupe?.groups?.[0]?.name}`
                              : el?.grupe?.groups.length > 1
                              ? `${el?.grupe?.groups?.[0]?.name}, ${el?.grupe?.groups?.[1]?.name}`
                              : "---"}
                          </>
                        ) : (
                          <>
                            {el?.activeGroups ? (
                              <>
                                {el?.activeGroups.length === 0
                                  ? "---"
                                  : el?.activeGroups.length === 1
                                  ? `${
                                      el?.activeGroups?.[0] !== null
                                        ? el?.activeGroups?.[0]
                                        : "---"
                                    }`
                                  : el?.activeGroups.length > 1
                                  ? `${
                                      el?.activeGroups?.[0] !== null
                                        ? el?.activeGroups?.[0]
                                        : "---"
                                    }, ${
                                      el?.activeGroups?.[1] !== null
                                        ? el?.activeGroups?.[1]
                                        : "---"
                                    }`
                                  : "---"}
                              </>
                            ) : (
                              "Nema podataka"
                            )}
                          </>
                        )}
                      </>
                    )}
                  </p>
                </div>
              </td>
              <td className={styles.tableCell}>
                {el?.element?.emailAddress
                  ? el?.element?.emailAddress
                  : el?.emailAddress}
              </td>
              <td className={styles.tableCell}>
                {el?.element?.phoneNumber
                  ? el?.element?.phoneNumber
                  : el?.phoneNumber}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.paginationContainer}>
        <BEPagination
          currentPage={currentPage}
          totalPageCount={Math.ceil(polazniciCount / 10)}
          handlePaginationClick={handlePaginationClick}
        />
      </div>
      {showButtonRow && (
        <div className={styles.editModeButtonscontainer}>
          <div className={styles.cancelMentorButton} onClick={onClickOdustani}>
            <div className={styles.cancelMentorButtonText}>Odustani </div>
          </div>
          <div
            className={styles.saveButton}
            onClick={() => {
              isCreateNewGroup
                ? navigate("/grupe/nova-grupa")
                : isCreateEvent
                ? handleDodajPolaznikaToEvent()
                : isAssignMentor
                ? assignUsersToMentor(
                    groupId,
                    selectedRows,
                    userData?.id,
                    authToken,
                    navigate
                  )
                : handleDodajPolaznika(
                    selectedRows,
                    authToken,
                    idGroup,
                    setIsModalOpen
                  );
            }}
          >
            <div className={styles.saveButtonText}>Spremi promjene</div>
          </div>
        </div>
      )}
    </>
  );
};

export default TableAttendees;
