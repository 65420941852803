import PropTypes from "prop-types";
import React from "react";
import { formatDate } from "../../polazniciPregled/utils/utils";
import styles from "./profileDetails.module.css";

const UserProfile = ({ userData }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>
        {userData.firstName} {userData.lastName}
      </h1>
      <div className={styles.columns}>
        <div className={styles["left-column"]}>
          <table>
            <tbody>
              <TableRow
                label="Datum rođenja"
                value={userData.dateOfBirth && formatDate(userData.dateOfBirth)}
              />
              <TableRow label="Spol" value={userData.gender} />
              <TableRow label="Mjesto stanovanja" value={userData.city} />
              <TableRow label="Adresa stanovanja" value={userData.address} />
              <TableRow label="Država stanovanja" value={userData.country} />
              <TableRow
                label="Grad u kojem pohađa program"
                value={userData.programCity}
              />
              <TableRow label="Stručna sprema" value={userData.qualification} />
              <TableRow label="Zvanje" value={userData.title} />
              <TableRow label="Identifikacijski broj" value={userData.OIB} />
              <TableRow label="Broj mobitela" value={userData.phoneNumber} />
              <TableRow label="E-mail adresa" value={userData.emailAddress} />
              <TableRow label="Položeni programi na IPD-u" value="--" />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const TableRow = ({ label, value }) => (
  <tr>
    <td className={styles["span"]}>{label}</td>
    <td className={styles["spanData"]}>{value}</td>
  </tr>
);

UserProfile.propTypes = {
  userData: PropTypes.object.isRequired,
};

export default UserProfile;
