import { useState } from "react";
import Help from "../../../assets/img/Help.png";
import Modal from "../../../components/Modal/Modal";
import Checkbox from "../../../components/checkbox/Checkbox";
import { useUserState } from "../../../context/UserContext";
import { useAuthentication } from "../../hooks/useAuthentication";
import styles from "../TerapeutProfil/EditProfile.module.css";
import { editMyTherapistProfile } from "../api/editMyTherapistProfile";
import { handleInputChange } from "./utils";

const EditUserInformation = ({
  onSave,
  onCancel,
  availableCitiesLive,
  availableLive,
  availableOnline,
  biography,
  setTherapistProfile,
}) => {
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({
    availableCitiesLive: availableCitiesLive,
    availableLive: availableLive,
    availableOnline: availableOnline,
    biography: biography,
  });
  const handleSave = () => {
    editMyTherapistProfile(
      userData.id,
      formValues,
      authToken,
      setTherapistProfile
    );
    onSave(onSave);
  };
  const handleCancel = () => {
    onCancel();
  };
  return (
    <div>
      <div className={styles.formGroup}>
        <label className={styles.Label} htmlFor="residence">
          Gradovi u kojima radiš terapije uživo
        </label>
        <input
          type="text"
          id="residence"
          name="availableCitiesLive"
          className={styles.inputField}
          value={formValues.availableCitiesLive}
          onChange={(e) =>
            handleInputChange(
              e.target.name,
              e.target.value,
              e.target.type,
              e.target.checked,
              setFormValues,
              formValues
            )
          }
        />
      </div>
      <div className={styles.containerTherapy}>
        <h1 className={styles.terapijaTitle}>Terapije</h1>
        <div className={styles.card}>
          <div className={styles.row}>
            <div className={styles.textLeft}>Uživo</div>
            <div className={styles.textRight}>Online</div>
          </div>
          <div className={styles.rowTwo}>
            <Checkbox
              name="availableLive"
              value={formValues.availableLive}
              onChange={(e) =>
                handleInputChange(
                  e.target.name,
                  e.target.value,
                  e.target.type,
                  e.target.checked,
                  setFormValues,
                  formValues
                )
              }
            />
            <Checkbox
              name="availableOnline"
              value={formValues.availableOnline}
              onChange={(e) =>
                handleInputChange(
                  e.target.name,
                  e.target.value,
                  e.target.type,
                  e.target.checked,
                  setFormValues,
                  formValues
                )
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.containerBio}>
        <h1 className={styles.terapijaTitle}>Biografija</h1>
        <textarea
          className={styles.textarea}
          placeholder="Unesite biografiju"
          name="biography"
          value={formValues.biography}
          onChange={(e) =>
            handleInputChange(
              e.target.name,
              e.target.value,
              e.target.type,
              e.target.checked,
              setFormValues,
              formValues
            )
          }
        />
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.cancelButton}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Odustani
        </button>
        <button className={styles.nextButton} onClick={handleSave}>
          Spremi promjene
        </button>
      </div>
      {showModal && (
        <Modal
          image={Help}
          title={
            "Jeste li sigurni da želite napustiti uređivanje bez spremanja?"
          }
          description={
            "Ukoliko ne spremite novo unesene podatke, vaši će podatci ostati isti."
          }
          buttons={[
            {
              label: "Napusti bez spremanja",
              action: () => {
                setShowModal(false);
                handleCancel();
              },
              className:
                "modal-btn-deletePage modal-btn-discontinue-deletePage",
            },
            {
              label: "Spremi i napusti",
              action: () => handleSave(),
              className: "modal-btn-deletePage modal-btn-success",
            },
          ]}
        />
      )}
    </div>
  );
};

export default EditUserInformation;
