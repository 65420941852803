import SearchIcon from "../../assets/svg/SearchIcon.svg";
import UserMenu from "../userMenu/UserMenu";
import styles from "./Header.module.css";

const Header = ({ title, showSearchBox = true }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.topRow}>
        <span className={styles.title}>{title}</span>
        <div className={styles.userRow}>
          {/* <button
            className={styles.notificationBtn}
            onClick={() => navigate("/primljene-obavijesti")}
          >
            <div className={styles.bellColumn}>
              <img alt="" src={Alert} className={styles.bell} />
              <div className={styles.pill}>{UnreadNotifications.length}</div>
            </div>
          </button> */}
          <UserMenu />
        </div>
      </div>
      {showSearchBox && (
        <label>
          <img alt="search" src={SearchIcon} className={styles.searchIcon} />
          <input
            type="search"
            placeholder="Pretraži"
            className={styles.searchInput}
          />
        </label>
      )}
    </div>
  );
};

export default Header;
