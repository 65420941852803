import { Badge } from "@mui/material";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "../../../../assets/img/CalendarArrow.png";
import Dot from "../../../../assets/img/CalendarDot.png";
import BellLineIcon from "../../../../assets/svg/BellLineIcon";
import ChevronRightIcon from "../../../../assets/svg/ChevronRightIcon";
import styles from "./RightContainer.module.css";

function RightContainer({ onClick, userId }) {
  const navigate = useNavigate();
  function fakeFetch(year, month, { signal }) {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        let daysToHighlight;
        if (year === 2023 && month === 10 - 1) {
          daysToHighlight = [3, 13, 14];
        } else if (year === 2023 && month === 11 - 1) {
          daysToHighlight = [1, 15, 25];
        } else {
          daysToHighlight = [];
        }
        resolve({ daysToHighlight });
      }, 500);

      signal.onabort = () => {
        clearTimeout(timeout);
        reject(new DOMException("aborted", "AbortError"));
      };
    });
  }
  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    const isSelected =
      !props.outsideCurrentMonth &&
      highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={isSelected ? <img alt="" src={Dot} /> : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }
  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([]);

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    const year = date.year();
    const month = date.month();
    fakeFetch(year, month, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          throw error;
        }
      });
    requestAbortController.current = controller;
  };
  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };
  return (
    <div className={styles.rightContainer}>
      <div className={styles.calendarWrapper}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            className={styles.calendar}
            sx={{
              "& .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)":
                {
                  border: "none",
                  backgroundColor: "#CB9C2D",
                },
              "& .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                {
                  border: "none",
                  backgroundColor: "#CB9C2D",
                },
              "& .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover":
                {
                  border: "none",
                  backgroundColor: "#CB9C2D",
                },
              "& .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                {
                  border: "none",
                  backgroundColor: "#CB9C2D",
                },
              "& .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover":
                {
                  border: "none",
                  backgroundColor: "#CB9C2D",
                },
            }}
            loading={isLoading}
            onMonthChange={handleMonthChange}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                highlightedDays,
              },
            }}
          />
        </LocalizationProvider>
        <button
          className={styles.calendarBtn}
          onClick={() => navigate("/kalendaripd")}
        >
          Školski kalendar <img alt="" src={Arrow} />
        </button>
      </div>
      {userId === "Polaznik" || userId === "Voditelj" ? (
        <>
          {/* <p className={styles.upcomingTitle}>Nadolazeći eventi</p>
          <div className={styles.upcomingEventsContainer}>
            {UpcomingEventsData.map((item, index) => (
              <EventsCard
                key={index}
                color={item.color}
                title={item.title}
                date={item.date}
                time1={item.time1}
                time2={item.time2}
                avatar={item.avatar}
                name={item.name}
              />
            ))}
          </div> */}
        </>
      ) : (
        <div className={styles.roomsUsageContainer} onClick={onClick}>
          <div className={styles.rooms}>
            <BellLineIcon className={styles.roomsUsageIcon} />
            <div className={styles.roomsUsageDivider} />
            <div className={styles.roomsUsageTextContainer}>
              <p className={styles.roomsUsageText}>korištenje soba</p>
              <ChevronRightIcon className={styles.roomsUsageIcon} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RightContainer;
