import React from "react";
import "./OdobrenjeTable.css";

function RenderTableHeader({ columnNames = [] }) {
  if (!Array.isArray(columnNames)) {
    console.error("columnNames is not an array:", columnNames);
    return null;
  }

  return (
    <tr>
      {columnNames.map((columnName, index) => (
        <th
          key={index}
          className="table"
          style={{
            backgroundColor: "#F8F9FB",
            fontWeight: 600,
            color: "#818C99",
          }}
        >
          {columnName}
        </th>
      ))}
    </tr>
  );
}

export default RenderTableHeader;
