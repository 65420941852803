import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const exportObracunSoba = async (
  accessToken,
  userId,
  startDate,
  endDate
) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Both startDate and endDate are required.");
    }
    const response = await axios.get(
      `${apiUrl}/api/exportStatisticsForUserId/${userId}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching obracun sobe:", error);
    throw error;
  }
};

export const handleObracunSoba = async (
  accessToken,
  userId,
  startDate,
  endDate,
  firstName,
  lastName
) => {
  try {
    const response = await exportObracunSoba(
      accessToken,
      userId,
      startDate,
      endDate
    );
    const blob = new Blob([response], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `exported_obracun:${firstName} ${lastName}_${startDate}-${endDate}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Greška prilikom exporta obračuna sobe:", error);
  }
};
