import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getSearchGroupsReferada = async (
  page,
  limit,
  accessToken,
  aktivne,
  search
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/groups-referada?page=${page}&limit=${limit}&search=${search}&aktivne=${aktivne.value}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
