import React from "react";
import "./IntrospekcijeTable.css";

function RenderTableHeader({ columnNames = [] }) {
  if (!Array.isArray(columnNames)) {
    console.error("columnNames is not an array:", columnNames);
    return null;
  }
  return (
    <tr>
      {columnNames.map((columnName, index) => (
        <>
          <th
            key={index}
            className="table"
            style={{ backgroundColor: "#F8F9FB" }}
          >
            <>
              {index !== 0 ? (
                <div className="introspekcije-header-column">
                  {columnName}
                  <div className="introspekcije-header-separator">
                    Napisana <div className="introspekcije-table-divider " />
                    Pročitana
                  </div>
                </div>
              ) : (
                <>{columnName}</>
              )}
            </>
          </th>
        </>
      ))}
    </tr>
  );
}

export default RenderTableHeader;
