import React, { useState } from "react";
import Close from "../../../../../assets/img/ExitIcon.png";
import styles from "./Modal.module.css";

function AGSModal({ onClose, prijava }) {
  const [selectedOption, setSelectedOption] = useState("");

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className={styles.overlay}>
      <button className={styles.closeIcon} onClick={onClose}>
        <img alt="" src={Close} />
      </button>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Prijava na grupnu superviziju</div>
          <div className={styles.label}>
            Prije nego se prijaviš, potrebno je označiti kako ćeš sudjelovati na
            superviziji.
          </div>
          <div className={styles.checkboxWrapper}>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                className={styles.checkboxInput}
                value={true}
                checked={selectedOption === "true"}
                onChange={handleCheckboxChange}
              />
              <span className={styles.checkboxCustom} />
              Na superviziji izlažem svoj case
            </label>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                className={styles.checkboxInput}
                value={false}
                checked={selectedOption === "false"}
                onChange={handleCheckboxChange}
              />
              <span className={styles.checkboxCustom} />
              Na superviziji ne izlažem svoj case
            </label>
          </div>
          <div className={styles.btnRow}>
            <button className={styles.cancelBtn} onClick={onClose}>
              Odustani
            </button>
            <button
              className={styles.acceptBtn}
              onClick={() => prijava(selectedOption)}
            >
              Prijavi se
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AGSModal;
