import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const isUserSupervisor = async (supervisionId, userId, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/isSupervisionSupervisor?supervisionId=${supervisionId}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching is supervisor data", error);
    throw error;
  }
};
