import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Back from "../../assets/img/BackArrow.png";
import { useAuthentication } from "../hooks/useAuthentication";
import styles from "./EvidencijaPage.module.css";
import PredavanjaTable from "./components/NastupnoPredavanjeTable/PredavanjeTable.jsx";
import {
  OR1ColumnNames,
  OR2ColumnNames,
  OR3ColumnNames,
  OR4ColumnNames,
  combinePresentations,
  fetchNastupnaPredavanja,
  fetchPresentations,
} from "./util.js";

function AddNastupnoPredavanjePage() {
  const { authToken } = useAuthentication();
  const navigate = useNavigate();
  const [nastupnaPredavanja, setNastupnaPredavanja] = useState([]);
  const [nastupnaPredavanjaOR1, setNastupnaPredavanjaOR1] = useState([]);
  const [nastupnaPredavanjaOR2, setNastupnaPredavanjaOR2] = useState([]);
  const [nastupnaPredavanjaOR3, setNastupnaPredavanjaOR3] = useState([]);
  const [nastupnaPredavanjaOR4, setNastupnaPredavanjaOR4] = useState([]);
  const [presentations, setPresentations] = useState([]);

  useEffect(() => {
    if (authToken) fetchPresentations(authToken, setPresentations);
  }, [authToken]);

  useEffect(() => {
    if (authToken) fetchNastupnaPredavanja(authToken, setNastupnaPredavanja);
  }, [authToken]);

  useEffect(() => {
    if (nastupnaPredavanja && presentations) {
      const arrayOR1 = nastupnaPredavanja.filter(
        (item) => item.programId === 3
      );
      const arrayOR2 = nastupnaPredavanja.filter(
        (item) => item.programId === 4
      );
      const arrayOR3 = nastupnaPredavanja.filter(
        (item) => item.programId === 5
      );
      const arrayOR4 = nastupnaPredavanja.filter(
        (item) => item.programId === 6
      );
      const combinedOR1 = combinePresentations(presentations, arrayOR1);
      const combinedOR2 = combinePresentations(presentations, arrayOR2);
      const combinedOR3 = combinePresentations(presentations, arrayOR3);
      const combinedOR4 = combinePresentations(presentations, arrayOR4);

      setNastupnaPredavanjaOR1(combinedOR1);
      setNastupnaPredavanjaOR2(combinedOR2);
      setNastupnaPredavanjaOR3(combinedOR3);
      setNastupnaPredavanjaOR4(combinedOR4);
    }
  }, [nastupnaPredavanja, presentations]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.description}>
        <div
          className={styles.navigationRow}
          onClick={() => navigate("/evidencija")}
        >
          <img alt="" src={Back} className={styles.backArrow} />
          <span>Natrag na evidenciju</span>
        </div>
        <span className={styles.predavanjeTitle}>
          Dodavanje nastupnog predavanja{" "}
        </span>
        <span className={styles.paragraph}>
          Za aktualnu 5. i 6. godinu nastupna predavanja su obavezna i preduvjet
          su za diplomu kao i CS2. Potrebno je spremiti 20-30 min smisleno
          predavanje koje produbljuje temu s predavanja i daje nove informacije,
          a opet kroz svoje osobno iskustvo i uvide.
        </span>
        <br />
        <span className={styles.paragraph}>
          Na prvoj godini je dovoljno svjedočanstvo bez puno novih informacija.
          Mogu biti po dva predavača isti dan s dvije različite teme.
        </span>
      </div>
      <div className={styles.tableWrapper}>
        <PredavanjaTable
          data={nastupnaPredavanjaOR1}
          columnNames={OR1ColumnNames}
          backgroundColor={"#fffded"}
          setPresentations={setPresentations}
          setNastupnaPredavanja={setNastupnaPredavanja}
        />
        <PredavanjaTable
          data={nastupnaPredavanjaOR2}
          columnNames={OR2ColumnNames}
          backgroundColor={"#FFF4F0"}
          setPresentations={setPresentations}
          setNastupnaPredavanja={setNastupnaPredavanja}
        />
        <PredavanjaTable
          data={nastupnaPredavanjaOR3}
          columnNames={OR3ColumnNames}
          backgroundColor={"#F2FFF6"}
          setPresentations={setPresentations}
          setNastupnaPredavanja={setNastupnaPredavanja}
        />
        <PredavanjaTable
          data={nastupnaPredavanjaOR4}
          columnNames={OR4ColumnNames}
          backgroundColor={"#F2FFF6"}
          setPresentations={setPresentations}
          setNastupnaPredavanja={setNastupnaPredavanja}
        />
      </div>
    </div>
  );
}

export default AddNastupnoPredavanjePage;
