import { useEffect, useRef, useState } from "react";
import SearchIcon from "../../assets/svg/SearchIcon.svg";
import { useUserState } from "../../context/UserContext";
import Header from "../Header/Header";
import useWindowResize from "../Hooks/ResizeHook";
import MobileHeader from "../MobileHeader/MobileHeader";
import styles from "./Layout.module.css";

const Layout = ({ children, title, showSearchBox, isDashboard, isOsvrti }) => {
  const { role } = useUserState();
  const [showModal, setShowModal] = useState(false);
  const getTitle = (role) => {
    if (isDashboard && role === "Referada") {
      return "Dobrodošli u referadu!";
    }
    if (isOsvrti && role === "Referada") {
      return "Osvrti na voditelje i učitelje";
    }
    const defaultTitle = isDashboard
      ? "Dobrodošli u školu!"
      : isOsvrti
      ? "Osvrti na voditelje"
      : title;

    return title || defaultTitle;
  };
  const searchRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showModal &&
        searchRef.current &&
        !searchRef.current.contains(event.target)
      ) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal]);
  const conditionalTitle = getTitle(role);
  const breakpoint = 1280;
  const width = useWindowResize();
  return (
    <div className={styles.wrapper}>
      <div className={styles.mobileHeader}>
        <MobileHeader onMobileSearchOpen={() => setShowModal(true)} />
      </div>
      <div className={styles.headerContainer}>
        <Header title={conditionalTitle} showSearchBox={showSearchBox} />
      </div>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>{conditionalTitle}</h1>
        {(isDashboard || showSearchBox !== false) && (
          <>
            {width > breakpoint ? (
              <label>
                <img
                  alt="search"
                  src={SearchIcon}
                  className={styles.searchIcon}
                />
                <input
                  type="search"
                  placeholder="Pretraži"
                  className={styles.searchInput}
                />
              </label>
            ) : (
              <>
                {showModal && (
                  <label ref={searchRef}>
                    <img
                      alt="search"
                      src={SearchIcon}
                      className={styles.mobileSearchIcon}
                    />
                    <input
                      type="search"
                      placeholder="Pretraži"
                      className={styles.mobileSearchInput}
                    />
                  </label>
                )}
              </>
            )}
          </>
        )}
      </div>
      {children}
    </div>
  );
};
export default Layout;
