import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getSchoolYear = async (accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/schoolYear`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting school year", error);
    throw error;
  }
};
