export const ostaliVoditeljiData = [
  {
    imageUrl: "",
    firstName: "Mislav",
    lastName: "Berić",
    userID: 1,
  },
  {
    imageUrl: require("../../../../../assets/img/AntoniaAvatar.png"),
    firstName: "Antonia-Dora",
    lastName: "Mandić",
    userID: 2,
  },
  {
    imageUrl: require("../../../../../assets/img/AnteAvatar.png"),
    firstName: "Ante",
    lastName: "Antunović",
    userID: 3,
  },
  {
    imageUrl: require("../../../../../assets/img/JosipaAvatar.png"),
    firstName: "Josipa",
    lastName: "Delić",
    userID: 4,
  },
];
