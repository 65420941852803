import React, { useEffect, useState } from "react";
import Export from "../../assets/img/Export.png";
import { useAuthentication } from "../hooks/useAuthentication";
import { formatDate } from "../polazniciPregled/utils/utils";
import { handlePotpuniObracunSoba } from "./API/exportPotpuniObracunKoristenjaSoba";
import { getPotpuniObracunSoba } from "./API/fetchPotpuniObracunSoba";
import styles from "./ObracunSoba.module.css";
import ObracunComponent from "./components/ObracunComponent/ObracunComponent";
import DatePicker from "./components/SingleInputDatePicker/DatePicker";
import { potpuniObracunKoristenjaSobaColumnNames } from "./utils/columnNames";

function PotpuniObracunKoristenjaSoba() {
  const today = new Date();
  const initialDate = today.toISOString().split("T")[0];
  const [potpuniObracunSoba, setPotpuniObracunSoba] = useState({});
  const [obracun, setObracun] = useState([]);
  const [startDate, setStartDate] = useState(initialDate);
  const [endDate, setEndDate] = useState(initialDate);
  const { authToken } = useAuthentication();
  const handleDateChange = (newDates) => {
    setStartDate(newDates[0]);
    setEndDate(newDates[1]);
  };
  useEffect(() => {
    if (authToken)
      getPotpuniObracunSoba(authToken, startDate, endDate, (data) => {
        setPotpuniObracunSoba(data);
      });
  }, [authToken, startDate, endDate]);
  useEffect(() => {
    if (
      potpuniObracunSoba.statistics &&
      Array.isArray(potpuniObracunSoba.statistics)
    ) {
      setObracun((prevObracun) => {
        const newArray = potpuniObracunSoba.statistics.map((statistic) => {
          if (statistic && statistic.user) {
            return {
              cancelledReservations: statistic.cancelledReservations || 0,
              percentCancelled: statistic.percentCancelled || "0",
              totalSpent: statistic.totalSpent || "0",
              firstName: statistic.user.firstName || "",
              id: statistic.user.id || 1,
              lastName: statistic.user.lastName || "",
              userType: statistic.user.userType || "",
            };
          }
          return null;
        });
        return newArray.filter(Boolean);
      });
    }
  }, [potpuniObracunSoba]);
  return (
    <div className={styles.obracunWrapper}>
      <span className={styles.title}>Potpuni obračun korištenja soba</span>
      <span className={styles.label}>Vremenski raspon</span>
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        onDateChange={handleDateChange}
      />
      <ObracunComponent
        title={`Potpuni obračun za period (${formatDate(
          startDate
        )} - ${formatDate(endDate)})`}
        alert={
          "Napomena: Zakupitelji koji za dati period nemaju terapija rezerviranih putem IPD Kalendara neće biti prikazani u tabeli ispod!"
        }
        columnNames={potpuniObracunKoristenjaSobaColumnNames}
        data={obracun}
        backgroundColor={"#87AD77"}
        isObracunPoSobama
        isPotpuniObracun
        showSum
      />
      <button
        className={styles.exportBtn}
        onClick={() => handlePotpuniObracunSoba(authToken, startDate, endDate)}
      >
        Export tablice <img alt="" src={Export} />
      </button>
    </div>
  );
}

export default PotpuniObracunKoristenjaSoba;
