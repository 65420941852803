import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getMyEvidentionData = async (accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/myEvidention`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching my evidention data", error);
    throw error;
  }
};

export const getUserEvidentionData = async (evidentionID, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/getUsersEvidention?userId=${evidentionID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user evidention data", error);
    throw error;
  }
};
