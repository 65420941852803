import React from "react";
import { PozivniBrojevi } from "../../utils";
import styles from "./PozivniDropdown.module.css";

function PozivniDropdown({ selectedValue, handleSelectChange }) {
  const handleChange = (event) => {
    const selectedOption = PozivniBrojevi.find(
      (option) => option.value === event.target.value
    );
    handleSelectChange(selectedOption);
  };
  return (
    <div>
      <select
        id="pozivni"
        value={selectedValue}
        onChange={handleChange}
        className={styles.mobileDropdown}
      >
        <option value="" selected disabled>
          Odaberi
        </option>
        {PozivniBrojevi.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default PozivniDropdown;
