import Calendar from "../../../assets/img/CalendarIcon.png";
import ChevronRightIcon from "../../../assets/svg/ChevronRightIcon";
import StudentIcon from "../../../assets/svg/StudentIcon.svg";
import styles from "../Dashboard.module.css";

const CarouselCard = ({
  title,
  studentsNumber,
  image,
  onClick,
  userId,
  date,
}) => {
  const formattedDate = new Date(date)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .join(".");
  return (
    <div
      className={
        userId === "Polaznik"
          ? styles.carouselPolaznikCard
          : `${styles.carouselCard} ${styles.overlayContainer}`
      }
      onClick={onClick}
    >
      <div className={styles.carouselImage}>
        <img src={image} alt="Description" className={styles.image} />
        <div className={styles.overlay}>
          <div className={styles.overlayText}>Pregledaj program</div>
          <ChevronRightIcon className={styles.chevronIcon} />
        </div>
      </div>
      <div className={styles.carouselItemTextContainer}>
        <h3 className={styles.carouselItemHeading}>{title}</h3>
        <div className={styles.carouselItemTextRow}>
          {userId === "Polaznik" ? (
            <img
              alt="students"
              src={Calendar}
              className={styles.carouselItemIcon}
            />
          ) : (
            <img
              alt="students"
              src={StudentIcon}
              className={styles.carouselItemIcon}
            />
          )}
          <p className={styles.carouselItemText}>
            {userId === "Polaznik"
              ? formattedDate + "."
              : `Broj polaznika: ${studentsNumber}`}
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarouselCard;
