export const pojedinaObavijest = [
  {
    user: "Referada IPD Centra",
    dateTimePosted: "2023-12-10T15:30:00",
    paragraph: [
      "Poštovani, otvorene su prijave za fakultativnu radionicu Preporađanje. To je vrlo moćna i jednostavna tehnika rada na emocijama i traumama koje nosimo potisnute u sebi i koja se danas radi u cijelom svijetu te ne zahtijeva nikakvo predznanje niti pripremu. Rezultat preporađanja je postizanje većeg unutarnjeg mira, emocionalne protočnosti, stanja preporođenosti, … moglo bi se reći da djeluje na naše tijelo i emocionalno-psihološko stanje kao što djeluje restartanje na kompjuter.",
      "Kako se radi preporađanje?",
      "Način rada podrazumijeva stručno vođeno intenzivno ritmično disanje bez pauze koje je inače karakteristično za bebe, životinje, ili kod odraslih prilikom spavanja. Prilikom procesa preporađanja i inducirane hiperventilacije dolazi do promjena u biokemiji tijela, veće simpatičke aktiviranosti autonomnog živčanog sustava, snažnog porasta energetskog naboja u energetskom tijelu što dovodi do tipičnih tjelesnih senzacija spazma ili stiskanja pojedinih mišića. Posljedica toga je nekontrolirano podizanje davno potisnutih emocija u njihova ekspresija što poslije radionice daje osjećaj rasterećenja. Obzirom da se radi u većoj grupi, svako je duboko u svom procesu i praksa pokazuje da nikome nije neugodno pustiti se u ono što se podigne.",
      "Saznaj više ovdje:",
    ],
    link: "https://ipd-center.eu/cjeloviti-osobni-razvoj/fakultativni-programi",
  },
];
