import axios from "axios";
import { postData } from "../utils";
import { getEvents } from "./getEvents";
const apiUrl = process.env.REACT_APP_API_URL;

export const createEvent = async (
  eventType,
  formValues,
  accessToken,
  startDate,
  endDate,
  selectedRooms,
  setEvents,
  setIsCreateOpen,
  reRender,
  setReRender
) => {
  try {
    const newData = postData(eventType, formValues);
    const response = await axios.post(`${apiUrl}/api/createEventIPD`, newData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      setIsCreateOpen(false);
      getEvents(
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0],
        selectedRooms,
        accessToken,
        setEvents
      );
      setReRender(!reRender);
    }
  } catch (error) {
    console.error("Error creating event:", error);
    throw error;
  }
};
