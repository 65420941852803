const ChevronUpIcon = (props) => {
  return (
    <svg
      width={14}
      height={8}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 7l6-6 6 6" stroke="current" />
    </svg>
  );
};

export default ChevronUpIcon;
