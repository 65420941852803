import { Radio } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUserById } from "../../djelatnikProfil/api/getUserById.js";
import { useAuthentication } from "../../hooks/useAuthentication.js";
import PostojećiKorisnikStepOne from "./ContentComponents/AddUserFirstStep/PostojećiKorisnikStepOne.jsx";
import PostojećiKorisnikStepTwo from "./ContentComponents/AddUserSecondStep/StepTwo.jsx";
import styles from "./dodajDjelatnika.module.css";

const DodajDjelatnika = () => {
  const [selectedOption, setSelectedOption] = useState("option1");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const { authToken } = useAuthentication();

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await getUserById(selectedUserId[0], authToken);

        setSelectedUser(response);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    if (selectedUserId?.length > 0 && authToken) getUser();
  }, [authToken, selectedUserId]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleSelectedUserIdFromChild = (selectedUserId) => {
    setSelectedUserId(selectedUserId);
  };
  return (
    <div>
      {selectedOption !== "option2" && currentStep !== 2 && (
        <div className={styles.radioContainer}>
          <label className={styles.radioLabel}>
            <Radio
              value="option1"
              checked={selectedOption === "option1"}
              onChange={handleOptionChange}
              className={
                selectedOption === "option1"
                  ? `${styles.customRadio} ${styles.customRadioChecked}`
                  : styles.customRadio
              }
            />
            <span className={styles.label}>Postojeći korisnik</span>
          </label>
          {/* <label className={styles.radioLabel}>
            <Radio
              value="option2"
              checked={selectedOption === "option2"}
              onChange={handleOptionChange}
              className={
                selectedOption === "option2"
                  ? `${styles.customRadio} ${styles.customRadioChecked}`
                  : styles.customRadio
              }
            />
            <span className={styles.label}>Novi korisnik</span>
          </label> */}
        </div>
      )}
      {selectedOption === "option1" && (
        <div>
          {currentStep === 1 && (
            <PostojećiKorisnikStepOne
              onNextStep={handleNextStep}
              onSelectedUserId={handleSelectedUserIdFromChild}
            />
          )}
          {currentStep === 2 && (
            <PostojećiKorisnikStepTwo
              onPreviousStep={handlePreviousStep}
              selectedUser={selectedUser}
              handlePrevious={handlePreviousStep}
            />
          )}
        </div>
      )}
      {/* {selectedOption === "option2" && <NoviKorisnik />} */}
    </div>
  );
};

export default DodajDjelatnika;
