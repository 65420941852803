import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getUserEntryByType = async (eventType, userId, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/getUserEntryByType?type=${eventType}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user entry data", error);
    throw error;
  }
};
export const getSupervisionEntry = async (userId, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/getSupervisionEntry?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user entry data", error);
    throw error;
  }
};
