import React, { useState } from "react";
import Modal from "../../../../components/Modal/Modal";

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const modalButtons = [
    {
      label: "U redu",
      action: hideModal,
      className: "modal-btn-success",
    },
  ];

  return { isModalOpen, showModal, hideModal, modalButtons };
};

export const ModalWrapper = ({ isOpen, image, description, buttons }) => {
  return (
    <Modal
      isOpen={isOpen}
      image={image}
      description={description}
      buttons={buttons}
    />
  );
};
