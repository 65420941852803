import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getAllGroupsReferada = async (
  page,
  limit,
  cityIds,
  programId,
  accessToken,
  aktivne
) => {
  try {
    let apiUrlWithParams = `${apiUrl}/api/groups-referada?page=${page}&limit=${limit}&aktivne=${aktivne.value}`;
    const queryParams = [];
    if (cityIds !== null) {
      queryParams.push(`cityIds=${cityIds}`);
    }
    if (programId !== null) {
      queryParams.push(`programId=${programId}`);
    }
    if (queryParams.length > 0) {
      apiUrlWithParams += `&${queryParams.join("&")}`;
    }

    const response = await axios.get(apiUrlWithParams, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
