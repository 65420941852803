import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const addEvidentionEntry = async (
  eventId,
  userId,
  eventType,
  accessToken
) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/addEvidentionEntry`,
      {
        programId: null,
        eventId: eventId,
        userId: userId,
        eventType: eventType,
        otherInfo: null,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error posting evidention entry data", error);
    throw error;
  }
};
