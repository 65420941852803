import React, { useEffect, useState } from "react";
import EvaluacijeAccordions from "./Accordions/EvaluacijeAccordions";
import ObracunAccordions from "./Accordions/ObracunAccordions";
import CertificateContainer from "./CertificateContainer/CertificateContainer";
import styles from "./Supervizije.module.css";
import RenderTable from "./Table/RenderTable";

function Supervizije({
  userId,
  polaznikLevel,
  data,
  deleteFlag,
  setDeleteFlag,
  authToken,
  evidentionUserID,
  change,
  setChange,
}) {
  const minSupervizijeSatiPraktičar = 60;
  const minSupervizijeSatiTerapeut = 150;
  const [supervizijeArray, setSupervizijeArray] = useState([]);
  const [obracunInfo, setObracunInfo] = useState([]);
  const [
    evaluationOfIndividualSupervisionsArray,
    setEvaluationOfIndividualSupervisionsArray,
  ] = useState([]);
  useEffect(() => {
    if (data) {
      const array = Object.values(data);
      setSupervizijeArray([
        { evaluationOfIndividualSupervisions: array[0] },
        { supervisorConsent: array[1] },
        { groupSupervision: array[2] },
        { individualSupervision: array[3] },
      ]);
    }
  }, [data]);

  const currentIndividualneSati =
    supervizijeArray?.[3]?.individualSupervision.individualSupervision +
    supervizijeArray?.[3]?.individualSupervision.therapistTrijada +
    supervizijeArray?.[3]?.individualSupervision.supervisantAGS;
  const remainingIndividualneSati =
    supervizijeArray?.[3]?.individualSupervision.minAttendance -
    currentIndividualneSati;
  const currentGrupneSati =
    supervizijeArray?.[2]?.groupSupervision.groupSupervisionSectionCount +
    supervizijeArray?.[2]?.groupSupervision.tp2Count +
    supervizijeArray?.[2]?.groupSupervision.tt5Count +
    supervizijeArray?.[2]?.groupSupervision.tt6Count;
  const remainingGrupneSati =
    supervizijeArray?.[2]?.groupSupervision.minAttendance - currentGrupneSati;
  useEffect(() => {
    if (supervizijeArray.length > 0) {
      const groupSupervision = supervizijeArray[2]?.groupSupervision || {};
      const individualSupervision =
        supervizijeArray[3]?.individualSupervision || {};
      const evaluationOfIndividualSupervisions =
        supervizijeArray[0]?.evaluationOfIndividualSupervisions || [];
      const groupSupervisionTopicsArray = [
        {
          name: "Grupna supervizija",
          hours: groupSupervision.groupSupervisionSectionCount || 0,
          isNameClickable: true,
          eventType: "group_supervision",
        },
        {
          name: "TP2",
          hours: groupSupervision.tp2Count || 0,
          isNameClickable: true,
          eventType: "tp2",
        },
        {
          name: "TT5",
          hours: groupSupervision.tt5Count || 0,
          isNameClickable: true,
          eventType: "tt5",
        },
        {
          name: "TT6",
          hours: groupSupervision.tt6Count || 0,
          isNameClickable: true,
          eventType: "tt6",
        },
      ];
      const individualSupervisionTopicsArray = [
        {
          name: "Individualna supervizija 1 na 1",
          hours: individualSupervision.individualSupervision || 0,
          isNameClickable: true,
          eventType: "individual_supervision",
        },
        {
          name: "Supervizant na AGS-u",
          hours: individualSupervision.supervisantAGS || 0,
          isNameClickable: true,
          eventType: "ags_supervisant",
        },
        {
          name: "Terapeut na Trijadi",
          hours: individualSupervision.therapistTrijada || 0,
          isNameClickable: true,
          eventType: "trijada_therapist",
        },
      ];

      setObracunInfo([
        {
          title: "Individualne supervizije - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: individualSupervisionTopicsArray,
              maxTopObracun:
                supervizijeArray?.[3]?.individualSupervision.minAttendance,
            },
          ],
        },
        {
          title: "Grupne supervizije - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: groupSupervisionTopicsArray,
              maxBottomObracun:
                supervizijeArray?.[2]?.groupSupervision?.minAttendance,
            },
          ],
        },
      ]);
      setEvaluationOfIndividualSupervisionsArray([
        {
          title: "Evaluacija individualnih supervizija",
          modules: [
            {
              topics: evaluationOfIndividualSupervisions,
              showTable: true,
            },
          ],
        },
      ]);
    }
  }, [supervizijeArray]);
  return (
    <div className={styles.raniRazvojPrvaSekcija2}>
      <div>
        <div>
          <h1 className={styles.raniRazvojHeader}>Supervizije</h1>
          <p className={styles.raniRazvojDescription}>
            Za postizanje statusa{" "}
            {polaznikLevel === "praktičar" ? "Praktičara" : "Psihoterapeuta"}{" "}
            ITP-a potrebno je ostvariti minimalno{" "}
            {polaznikLevel === "praktičar"
              ? minSupervizijeSatiPraktičar
              : minSupervizijeSatiTerapeut}{" "}
            sati supervizija od čega min{" "}
            {polaznikLevel === "praktičar" ? 20 : 40} sati individualnih
            supervizija {polaznikLevel === "praktičar" && ""}{" "}
            {/*+ sati Metodologije*/}i{" "}
            {polaznikLevel === "praktičar" ? 40 : 110} sati grupnih supervizija{" "}
            {polaznikLevel === "praktičar" && ""}
            {/*+ sati Metodologije*/}.
          </p>
          <p className={styles.raniRazvojDescription2}>
            Moje trenutno stanje sati supervizija: <br /> 1) Individualne
            supervizije {polaznikLevel === "praktičar" && ""}
            {/*+ Metodologija*/} - Trenutno stanje: {currentIndividualneSati}h
            (Nedostaje{" "}
            {remainingIndividualneSati <= 0 ? 0 : remainingIndividualneSati}h){" "}
            {polaznikLevel !== "praktičar" && ""}
            {/*
              "(u ovaj obračun se ne računaju sati Terapeut na Trijadi)"*/}
            <br />
            2) Grupna supervizija {polaznikLevel === "praktičar" && ""}
            {/*+ Metodologija*/} - Trenutno stanje: {currentGrupneSati}h
            (Nedostaje {remainingGrupneSati <= 0 ? 0 : remainingGrupneSati}h)
            <br /> <br />
          </p>
        </div>
        <span className={styles.label}>Suglasnost supervizora</span>
        <RenderTable
          data={supervizijeArray?.[1]?.supervisorConsent}
          role={userId}
          evidentionUserID={evidentionUserID}
          change={change}
          setChange={setChange}
        />
        <span className={styles.label}>
          Preuzmi svoju potvrdu o statusu edukanta u školi ITP-a
        </span>
        <CertificateContainer />
        <ObracunAccordions
          data={obracunInfo}
          userId={userId}
          deleteFlag={deleteFlag}
          setDeleteFlag={setDeleteFlag}
          evidentionUserID={evidentionUserID}
        />
        <EvaluacijeAccordions
          userId={userId}
          data={evaluationOfIndividualSupervisionsArray}
          evidentionUserID={evidentionUserID}
          change={change}
          setChange={setChange}
        />
      </div>
    </div>
  );
}

export default Supervizije;
