export const navigatePolaznikNext = (
  teorijskiDio,
  sectionIndexNumber,
  setSectionIndexNumber,
  setModuleIndexNumber,
  navigate,
  id
) => {
  const resourceIds = teorijskiDio.reduce((acc, module) => {
    const moduleResourceIds = module.resources.map((resource) => resource.id);
    return [...acc, ...moduleResourceIds];
  }, []);

  const currentIndex = resourceIds.indexOf(sectionIndexNumber);

  if (currentIndex !== -1 && currentIndex < resourceIds.length - 1) {
    const newIndex = currentIndex + 1;
    const nextResourceId = resourceIds[newIndex];
    const nextResource = teorijskiDio.find((module) =>
      module.resources.some((resource) => resource.id === nextResourceId)
    );

    if (nextResource) {
      setSectionIndexNumber(nextResourceId);
      setModuleIndexNumber(nextResource.resources[0].theoreticalModuleId);
      navigate(
        `/pojediniProgram-section/${nextResource.resources[0].theoreticalModuleId}/${nextResourceId}/${id}`
      );
    }
  }
};

export const navigatePolaznikPrevious = (
  teorijskiDio,
  sectionIndexNumber,
  setSectionIndexNumber,
  setModuleIndexNumber,
  navigate,
  previousRoute,
  id
) => {
  const resourceIds = teorijskiDio.reduce((acc, module) => {
    const moduleResourceIds = module.resources.map((resource) => resource.id);
    return [...acc, ...moduleResourceIds];
  }, []);

  const currentIndex = resourceIds.indexOf(sectionIndexNumber);

  if (currentIndex > 0) {
    const newIndex = currentIndex - 1;
    const previousResourceId = resourceIds[newIndex];
    const previousResource = teorijskiDio.find((module) =>
      module.resources.some((resource) => resource.id === previousResourceId)
    );

    if (previousResource) {
      setSectionIndexNumber(previousResourceId);
      setModuleIndexNumber(previousResource.resources[0].theoreticalModuleId);
      navigate(
        `/pojediniProgram-section/${previousResource.resources[0].theoreticalModuleId}/${previousResourceId}/${id}`
      );
    }
  } else if (sectionIndexNumber === resourceIds[0]) {
    navigate({ pathname: previousRoute });
  }
};
export const openFullScreen = () => {
  const pdfContainer = document.querySelector(".pdf-container");
  if (pdfContainer) {
    if (pdfContainer.requestFullscreen) {
      pdfContainer.requestFullscreen();
    } else if (pdfContainer.webkitRequestFullscreen) {
      pdfContainer.webkitRequestFullscreen();
    } else if (pdfContainer.msRequestFullscreen) {
      pdfContainer.msRequestFullscreen();
    }
  }
};
export const toggleTeorijskiSection = (
  index,
  teorijskiExpandedSections,
  setTeorijskiExpandedSections
) => {
  const newExpandedSections = [...teorijskiExpandedSections];
  newExpandedSections[index] = !newExpandedSections[index];
  setTeorijskiExpandedSections(newExpandedSections);
};
