const ChevronRightIcon = (props) => {
  return (
    <svg
      width={8}
      height={14}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 1l6 6-6 6" stroke="current" />
    </svg>
  );
};

export default ChevronRightIcon;
