import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
export const updateGroup = async (groupId, data, accessToken, navigate) => {
  try {
    const response = await axios.put(`${apiUrl}/api/group/${groupId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};
