import React from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../../assets/img/AlertIcon.png";
import BackArrow from "../../assets/img/BackArrow.png";
import IPDLogo from "../../assets/svg/IPDLogo.svg";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import UserMenu from "../../components/userMenu/UserMenu";
import { useUserState } from "../../context/UserContext";
import MojObracunKoristenjaSoba from "./MojObracunKoristenjaSoba";
import styles from "./ObracunSoba.module.css";
import PotpuniObracunKoristenjaSoba from "./PotpuniObracunKoristenjaSoba";
import PregledKoristenjaSoba from "./PregledKoristenjaSoba";
// import PregledPotrosnjePoGradovima from "./PregledPotrosnjePoGradovima";

function ObracunSoba() {
  const navigate = useNavigate();
  const { role } = useUserState();
  return (
    <>
      <div className={styles.nav}>
        <div
          className={styles.backToHome}
          onClick={() => navigate("/dashboard")}
        >
          <img src={BackArrow} alt="" className={styles.backArrow} />
          <h2 className={styles.h2}>Natrag na Početnu</h2>
        </div>
        <div className={styles.logo}>
          <img src={IPDLogo} alt="" />
        </div>
        <div className={styles.user}>
          <div className={styles.userRow}>
            <button className={styles.notificationBtn}>
              <img alt="" src={Alert} />
            </button>
            <UserMenu />
          </div>
        </div>
      </div>
      <MobileHeader />
      <div className={styles.wrapper}>
        {role !== "Referada" && <MojObracunKoristenjaSoba />}
        {role === "Referada" && (
          <>
            <PregledKoristenjaSoba />
            <PotpuniObracunKoristenjaSoba />
            {/* <PregledTotalnogObracunaZaSobu /> */}
            {/* <PregledPotrosnjePoGradovima /> */}
          </>
        )}
      </div>
    </>
  );
}

export default ObracunSoba;
