export const InputStyle = {
  padding: 7,
  border: "1px solid #D8DBDF",
  borderRadius: 8,
  textAlign: "left",
  color: "#5F6D7E",
  boxShadow: "4px 4px 64px 0px #5F6D7E1A",
  fontFamily: "Inter",
  fontSize: 15,
  fontWeight: 500,
  lineHeight: 1.5,
  letterSpacing: " -0.001em",
  width: 270,
  cursor: "pointer",
  backgroundColor: "#FFF",
  boxSizing: "border-box",
};

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    height: "40px",
    width: 270,
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "4px",
    border: "1px solid #D1D9E2 !important",
    boxShadow: "none",
    cursor: "pointer",
    "&:focus": {
      border: "0 !important",
    },
  }),
  option: (styles) => {
    return {
      ...styles,
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#F2F2F2",
      },
    };
  },
};

export const typeSelect = [
  { value: "video/mp4", label: "Video" },
  { value: "application/pdf", label: "PDF" },
  { value: "audio/mp3", label: "Audio" },
];
