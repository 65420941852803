import React from "react";
import { Countdown } from "./Countdown";
import styles from "./Modal.module.css";

function Modal({
  image,
  title,
  description,
  buttons = [],
  fullWidth,
  countdown = false,
}) {
  return (
    <div
      className={styles.modalOverlay}
      style={{
        width: fullWidth ? "100%" : "85%",
        left: fullWidth ? "0%" : "15%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {countdown && <Countdown />}
      <div className={styles.modalWrapper}>
        <div className={styles.infoModal}>
          <img className={styles.modalImage} src={image} alt="Modal" />
          <h3 className={styles.modalTitle}>{title}</h3>
          <p className={styles.modalDescription}>{description}</p>
        </div>
        <div className={styles.modalButtons}>
          {buttons.map((button, index) => (
            <button
              key={index}
              onClick={button.action}
              className={button.className}
            >
              {button.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Modal;
