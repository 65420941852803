import { getAllCities } from "../../api/getAllCities";

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    alignContent: "center",
    backgroundColor: "#FFFFFF",
    color: "#5F6D7E",
    width: "200px",
    height: "40px",
    padding: "8px 16px",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "8px",
    border: "1px solid #D1D9E2;",
    boxShadow: "4px 4px 64px 0px rgba(95, 109, 126, 0.10);",
    cursor: "pointer",
    "&:focus": {
      border: "0 !important",
    },
    "@media screen and (max-width: 900px)": {
      width: "500px",
    },
    "@media screen and (max-width: 550px)": {
      width: "400px",
    },
    "@media screen and (max-width: 450px)": {
      width: "340px",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#F2F2F2",
      },
    };
  },
};
export const groupsSelectData = [
  {
    value: null,
    label: "Sve grupe",
  },
  { value: [1], label: "Grupe RAR-a" },
  { value: [2], label: "Grupe eRAR-a" },
  { value: [3, 4, 5, 6], label: "Grupe OR-a" },
  { value: [7, 8, 9, 10], label: "Grupe eOR-a" },
  { value: [11, 12], label: "Grupe TT-a" },
  { value: [13, 14], label: "Grupe TP-a" },
  { value: [17], label: "Grupe VT-a" },
  { value: [15], label: "Roditeljstvo" },
];

export const groupsOnlineSelectData = [
  {
    value: null,
    label: "Sve grupe",
  },
  { value: [2], label: "Grupe eRAR-a" },
  { value: [7, 8, 9, 10], label: "Grupe eOR-a" },
];

export const aktivneOptions = [
  { label: "Aktivne", value: true },
  { label: "Arhivirane", value: false },
];

export const fetchData = async (setAllCities, accessToken) => {
  const formattedCityOptions = await getAllCities(accessToken);
  setAllCities([
    { value: null, label: "----" },
    ...formattedCityOptions.map((city) => ({
      label: city.cityName,
      value: city.id,
    })),
  ]);
};
export const handleCitySelectChange = (
  selectedOption,
  setSelectedCityOption,
  setCurrentPage
) => {
  setCurrentPage(1);
  setSelectedCityOption(selectedOption);
};
export const handleProgramChange = async (
  selectedOption,
  setSelectedProgram,
  setCurrentPage
) => {
  setCurrentPage(1);
  setSelectedProgram(selectedOption);
};
export const handleAktivneChange = async (
  selectedOption,
  setSelectedAktivne,
  setCurrentPage
) => {
  setCurrentPage(1);
  setSelectedAktivne(selectedOption);
};
