import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const dodajZakupitelja = async (userId, accessToken) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/assingUserAsZakupitelj`,
      {
        userId: userId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error adding user as zakupitelj:", error);
    throw error;
  }
};

export const ukloniZakupitelja = async (userId, accessToken) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/api/removeUserAsZakupitelj`,
      {
        data: {
          userId: userId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error removing user as zakupitelj:", error);
    throw error;
  }
};
