import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const fetchDataByQueryId = async (url, id, accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}${url}?id=${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};

const fetchDataByParamsId = async (url, id, accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};

export const getFacultativeById = async (id, accessToken) => {
  return fetchDataByQueryId("/api/facultativeWorkshop", id, accessToken);
};

export const getLjetniById = async (id, accessToken) => {
  return fetchDataByParamsId("/api/intensive", id, accessToken);
};

export const getWeekendById = async (id, accessToken) => {
  return getLjetniById(id, accessToken);
};

export const getSupervisionById = async (id, accessToken) => {
  return fetchDataByParamsId("/api/supervision", id, accessToken);
};

export const getModuliById = async (id, accessToken) => {
  return fetchDataByParamsId("/api/eModuleLive", id, accessToken);
};

export const getLjetniUsersById = async (
  id,
  accessToken,
  isWeekend = false
) => {
  const url = `/api/intensive?isWeekend=${isWeekend}&id=${id}`;
  return fetchDataByParamsId(url, id, accessToken);
};

export const getWeekendUsersById = async (id, accessToken) => {
  return getLjetniUsersById(id, accessToken, true);
};

export const getSupervisionUsersById = async (id, accessToken) => {
  return fetchDataByParamsId("/api/supervision", id, accessToken);
};

export const getModuliUsersById = async (id, accessToken) => {
  return fetchDataByParamsId("/api/eModuleLive", id, accessToken);
};
