import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getGroupIntrospekcija = async (
  groupId,
  accessToken,
  setIntrospekcijeData
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/introspectionByGroup?groupId=${groupId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      setIntrospekcijeData(response.data);
    }
  } catch (error) {
    console.error("Error fetching data for introspekcije:", error);
    throw error;
  }
};
