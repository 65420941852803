import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const deleteGroup = async (groupId, accessToken) => {
  try {
    const response = await axios.delete(`${apiUrl}/api/group?id=${groupId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error deleting group:", error);
    throw error;
  }
};
