import axios from "axios";
import { postData } from "./AddEventDialog/utils";
const apiUrl = process.env.REACT_APP_API_URL;
export const getWorkshops = () => {
  return axios.get(`${apiUrl}/workshops`);
};

export const getGroups = () => {
  return axios.get(`${apiUrl}/groups`);
};

export const getEvents = (room) => {
  return axios.post(`${apiUrl}/eventsRoom/`, { room: [room] });
};

export const postAddEvent = (eventData) => {
  return axios
    .post(`${apiUrl}/event`, eventData)
    .then((res) => console.log(res.data));
};
export const putEditEvent = (id, eventData) => {
  return axios.put(`${apiUrl}/event?id=${id}`, eventData);
};

export const deleteEvent = (id) => {
  return axios.delete(`${apiUrl}/event/?id=${id}`);
};
//  staro, treba mijenjati

export const getCalendarData = async (
  accessToken,
  startYear,
  endYear,
  setCalendarData
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/masterCalendar?dateFrom=${startYear}-08-01&dateTo=${endYear}-07-31`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const resData = response.data;
    setCalendarData(resData);
  } catch (error) {
    console.error("Error fetching group users data:", error);
    throw error;
  }
};

export const createEvent = async (eventType, formValues, accessToken) => {
  try {
    const data = postData(eventType, formValues);
    const response = await axios.post(`${apiUrl}/api/createMasterEvent`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error creating master calendar event", error);
    throw error;
  }
};
export const updateEvent = async (formValues, accessToken) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/updateMasterEvent`,
      {
        eventType: formValues.eventType,
        eventId: formValues.id,
        date: formValues.date,
        timeFrom: formValues.timeFrom,
        timeTo: formValues.timeTo,
        isPublished: formValues.isPublished,
        masterAbbreviation: formValues.masterAbbreviation,
        groupId: formValues.groupId,
        roomId: formValues.roomId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating master calendar event", error);
    throw error;
  }
};
export const deleteMasterEvent = async (eventType, eventId, accessToken) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/api/deleteMasterCalendarEvent?eventId=${eventId}&eventType=${eventType}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error deleting master calendar event", error);
    throw error;
  }
};
export const getRegularWorkshopsForModule = async (
  modulId,
  accessToken,
  setRegularWorkshops
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/getReguarWorkshopsForModule?id=${modulId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = response.data;
    setRegularWorkshops(data);
  } catch (error) {
    console.error("Error fetching regular workshops for module", error);
    throw error;
  }
};

export const getModulesForProgram = async (
  programId,
  accessToken,
  setModulesForProgram
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/getModulesForProgram?id=${programId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = response.data;
    setModulesForProgram(data);
  } catch (error) {
    console.error("Error fetching modules for program", error);
    throw error;
  }
};

export const getInteractiveLessonsForProgram = async (
  programId,
  accessToken,
  setInteractiveLessons
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/getInteractiveLessonsForProgram?id=${programId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = response.data;
    setInteractiveLessons(data);
  } catch (error) {
    console.error("Error fetching interactive lessons for program", error);
    throw error;
  }
};

export const getGroupByProgramId = async (
  programId,
  accessToken,
  setGroupsByProgramId
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/groupByProgramId?programId=${programId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = response.data;
    setGroupsByProgramId(data);
  } catch (error) {
    console.error("Error fetching interactive lessons for program", error);
    throw error;
  }
};

export const bulkPublishEvents = async (
  interactiveLessons,
  regularWorkshops,
  meetingWithMentor,
  accessToken
) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/bulkpublishevents`,
      {
        interactiveLessons: interactiveLessons,
        regularWorkshops: regularWorkshops,
        meetingWithMentor: meetingWithMentor,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error publishing events: ", error);
    throw error;
  }
};
