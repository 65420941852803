import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const GetMyMentorshipsInGroup = async (groupId, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/GetMyMentorshipsInGroup/${groupId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching mentor data", error);
    throw error;
  }
};
