import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserAvatarIcon from "../../../../../assets/svg/UserAvatarIcon.svg";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import DjelatniciCard from "../../../components/djelatniciCard";
import { handleDodajDjelatnika } from "./API";
import styles from "./StepTwo.module.css";
import {
  getFirstName,
  getLastName,
  postojeciOptions,
  terapeutiOptions,
} from "./utils";

const PostojećiKorisnikStepTwo = ({
  selectedUser,
  formData,
  handlePrevious,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedTerapeutiOption, setSelectedTerapeutiOption] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { authToken } = useAuthentication();
  const navigate = useNavigate();
  const toggleOption = (option) => {
    setSelectedOption((prevSelectedOption) =>
      prevSelectedOption === option.id ? null : option.id
    );
    setIsButtonDisabled(false);
  };

  const toggleTerapeutiOption = (option) => {
    setSelectedTerapeutiOption((prevSelectedOption) =>
      prevSelectedOption === option.id ? null : option.id
    );
    setIsButtonDisabled(false);
  };

  const unifiedData = {
    firstName: formData ? getFirstName(formData.name) : selectedUser?.firstName,
    lastName: formData ? getLastName(formData.name) : selectedUser?.lastName,
    email: formData ? formData.emailAddress : selectedUser?.emailAddress,
    profilePhoto: formData ? formData.image : selectedUser?.imageUrl,
  };
  return (
    <div>
      <div className={styles.column}>
        <span className={styles.mainLabel}> Tip korisnika:</span>
        <span className={styles.subLabel}>Postojeći korisnik</span>
      </div>
      <div className={styles.cardContainer}>
        <DjelatniciCard
          firstName={unifiedData?.firstName}
          lastName={unifiedData?.lastName}
          email={unifiedData?.email}
          profilePhoto={unifiedData?.profilePhoto || UserAvatarIcon}
          showExpandIcon={false}
          customStyles={{
            cursor: "default",
          }}
        />
      </div>
      <div className={styles.selectRole}>
        <div className={styles.roleHeading}>
          Ovdje odaberite koju će ulogu korisnik imati u sustavu<br></br> (ako
          ste zadovoljni trenutnom ulogom ne trebate ništa odabrati):
        </div>
        {postojeciOptions.map((option) => (
          <div key={option.id} className={styles.radioRow}>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                name="selectedOption"
                value={option.id}
                checked={selectedOption === option.id}
                onChange={() => {
                  toggleOption(option);
                }}
                className={styles.radio}
              />
              {option.label}
            </label>
          </div>
        ))}
      </div>
      <div className={styles.selectRole}>
        <div className={styles.roleHeading}>
          Ovdje odaberite koju ulogu korisnik ima na stranici terapeuti <br />
          (služi za prikaz uloge na stranici terapeuta i nema veze sa Školskim
          timom):
        </div>
        {terapeutiOptions.map((option) => (
          <div key={option.id} className={styles.radioRow}>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                name="selectedTerapeutiOption"
                value={option.id}
                checked={selectedTerapeutiOption === option.id}
                onChange={() => {
                  toggleTerapeutiOption(option);
                }}
                className={styles.radio}
              />
              {option.label}
            </label>
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.cancelButton} onClick={handlePrevious}>
          Odustani
        </button>
        <button
          className={`${styles.addButton} ${
            isButtonDisabled ? styles.disabledButton : ""
          }`}
          disabled={isButtonDisabled}
          onClick={() => {
            handleDodajDjelatnika(
              selectedUser.id,
              selectedOption,
              selectedTerapeutiOption,
              authToken,
              navigate
            );
          }}
        >
          Dodaj djelatnika
        </button>
      </div>
    </div>
  );
};

export default PostojećiKorisnikStepTwo;
