import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const deleteGroupTeacher = async (groupId, teacherId, accessToken) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/api/group/${groupId}/user/${teacherId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in deleteGroupTeacher:", error);
    throw error;
  }
};
