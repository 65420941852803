import axios from "axios";
import { formatDate } from "../../polazniciPregled/utils/utils";
const apiUrl = process.env.REACT_APP_API_URL;

export const exportPotpuniObracunSoba = async (
  accessToken,
  startDate,
  endDate
) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Both startDate and endDate are required.");
    }
    const response = await axios.get(
      `${apiUrl}/api/exportPotpuniObracun?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching potpuni obracun sobe:", error);
    throw error;
  }
};

export const handlePotpuniObracunSoba = async (
  accessToken,
  startDate,
  endDate,
  setLoading
) => {
  try {
    const response = await exportPotpuniObracunSoba(
      accessToken,
      startDate,
      endDate
    );
    const blob = new Blob([response], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `Potpuni_obracun_${formatDate(startDate)}-${formatDate(
      endDate
    )}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    setLoading(false);
  } catch (error) {
    setLoading(false);

    console.error("Greška prilikom exporta potpunog obračuna sobe:", error);
  }
};
