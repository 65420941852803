import React from "react";
import Icon from "../../../../assets/img/InfoIcon.png";
import ObracunTable from "../ObracunTable/ObracunTable";
import styles from "./ObracunComponent.module.css";

function ObracunComponent({
  title,
  label,
  alert,
  columnNames,
  data,
  isPotrosnjaPoGradovima,
  backgroundColor,
  isPotpuniObracun,
  showSum,
  isObracunPoSobama,
}) {
  return (
    <div
      className={styles.wrapper}
      style={{ backgroundColor: backgroundColor }}
    >
      {title && <span className={styles.title}>{title}</span>}{" "}
      {label && <span className={styles.label}>{label}</span>}
      {!isPotpuniObracun && alert && (
        <div className={styles.alert}>
          <img alt="" src={Icon} className={styles.alertIcon} />
          {alert}
        </div>
      )}
      <ObracunTable
        columnNames={columnNames}
        backgroundColor={backgroundColor}
        title={title}
        label={label}
        data={data}
        isPotpuniObracun={isPotpuniObracun}
        isPotrosnjaPoGradovima={isPotrosnjaPoGradovima}
        showSum={showSum}
        isObracunPoSobama={isObracunPoSobama}
      />
    </div>
  );
}

export default ObracunComponent;
