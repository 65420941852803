import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Success from "../../../../../assets/img/Success.png";
import Modal from "../../../../../components/Modal/Modal";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import { postMentorReview } from "../API/postMentorComment";
import styles from "./OdobrenjeKomentar.module.css";

function OdobrenjeKomentar({
  onClose,
  firstName,
  lastName,
  userId,
  applicationId,
  mentorReview,
}) {
  const { authToken } = useAuthentication();
  const navigate = useNavigate();
  const [review, setReview] = useState(mentorReview || "");
  const [showSuccess, setShowSuccess] = useState(false);
  useEffect(() => {
    setReview(mentorReview || "");
  }, [mentorReview]);
  return (
    <div>
      <div className={styles.modalWrapper}>
        <div className={styles.infoModal}>
          <p className={styles.title}>Preporuka za upis više godine</p>
          <p className={styles.subtitle}>
            Polaznik: {`${firstName} ${lastName}` || "Mislav Berić"}
          </p>
          <div className={styles.inputWrapper}>
            <span className={styles.label}>
              Upiši svoj komentar/preporuku za upis odabranog polaznika
            </span>
            <textarea
              placeholder="Upiši"
              className={styles.input}
              name="review"
              value={review}
              onChange={
                !mentorReview ? (e) => setReview(e.target.value) : undefined
              }
              disabled={Boolean(mentorReview)}
            />
          </div>
          {!mentorReview && (
            <div className={styles.btnRow}>
              <button onClick={() => onClose()} className={styles.cancelBtn}>
                Odustani
              </button>
              <button
                className={styles.successBtn}
                onClick={() =>
                  postMentorReview(
                    authToken,
                    userId,
                    applicationId,
                    review,
                    setShowSuccess(true)
                  )
                }
              >
                Dodaj komentar
              </button>
            </div>
          )}
        </div>
      </div>
      {showSuccess && (
        <Modal
          image={Success}
          description={
            "Tvoj komentar je uspješno dodan i odsada je vidljiv referadi."
          }
          buttons={[
            {
              label: "U redu",
              action: () => navigate("/dokumenti"),
              className: "modal-btn-success",
            },
          ]}
        />
      )}
    </div>
  );
}

export default OdobrenjeKomentar;
