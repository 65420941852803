import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const paymentApiUrl = process.env.REACT_APP_API_URL_PAYMENT;

export const getIntrospectionText = async (url, accessToken) => {
  try {
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching pojedini program:", error);
    return [];
  }
};

export const updateIntrospection = async (reqData, accessToken) => {
  try {
    const response = await axios.put(`${apiUrl}/api/introspection`, reqData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error updating introspection:", error);
    return [];
  }
};

export const createIntrospection = async (reqData, accessToken) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/userInIntrospection`,
      reqData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error updating introspection:", error);
    return [];
  }
};

export const getMyMentor = async (userId, programId, accessToken) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/group/myMentor`,
      { userId: userId, programId: programId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching pojedini program:", error);
    return [];
  }
};

export const createNotification = async (reqData, accessToken) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/notificationWithPrimatelji`,
      reqData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error in creating notification:", error);
    return [];
  }
};

export const updateNotify = async (notificationData, accessToken) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/userInIntrospection`,
      notificationData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error updating notification allow property:", error);
    return [];
  }
};

export const sendEmailToPolaznikMentor = async (notificationData) => {
  try {
    const response = await axios.post(
      `${paymentApiUrl}/api/sendEmailToPolaznikMentor`,
      {
        notificationData: notificationData,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending email: ", error);
    throw error;
  }
};
