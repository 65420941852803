import React, { useEffect, useRef, useState } from "react";
import AddIcon from "../../../../assets/img/AddIcon.png";
import Dots from "../../../../assets/img/Dots.png";
import styles from "./ZakupiteljButton.module.css";

function ZakupiteljButton({
  onClick,
  isZakupitelj,
  zakupiteljType,
  onClickRemoveZakupitelj,
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const dropdownRefs = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDropdown &&
        dropdownRefs.current &&
        !dropdownRefs.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);
  return (
    <div>
      {isZakupitelj ? (
        <>
          <div
            className={styles.selectedZakupiteljBtn}
            onClick={handleDropdown}
          >
            <span>{zakupiteljType}</span>
            <img alt="" src={Dots} />
          </div>
          {showDropdown && (
            <div
              className={styles.dropdownWrapper}
              ref={dropdownRefs}
              onClick={onClickRemoveZakupitelj}
            >
              <span>Ukloni iz zakupitelja</span>
            </div>
          )}
        </>
      ) : (
        <div className={styles.addPolaznikZakupiteljButton} onClick={onClick}>
          <img
            src={AddIcon}
            alt="add icon"
            className={styles.addPolaznikZakupiteljButtonIcon}
          />
          Dodaj u zakupitelje
        </div>
      )}
    </div>
  );
}

export default ZakupiteljButton;
