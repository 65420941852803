import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const deleteEModule = async (id, accessToken) => {
  try {
    const response = await axios.delete(`${apiUrl}/api/eModuleLive/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error deleting EModule: ", error);
    throw error;
  }
};
