export const noviOptions = [
  { id: 1, label: "Zakupitelj" },
  { id: 3, label: "Terapeut IPD-a" },
  { id: 4, label: "Diplomirani voditelj" },
  { id: 7, label: "Učitelj" },
];

export const postojeciOptions = [
  { id: 3, label: "Terapeut IPD-a" },
  { id: 4, label: "Diplomirani voditelj" },
  { id: 5, label: "Supervizor" },
  { id: 7, label: "Učitelj" },
  { id: 8, label: "Polaznik VT-a" },
];

export const terapeutiOptions = [
  { id: 1, label: "Terapeut" },
  { id: 2, label: "Praktičar" },
  { id: 3, label: "Supervizor" },
];

export const getFirstName = (fullName) => {
  return fullName.split(" ")[0];
};
export const getLastName = (fullName) => {
  const fullNameArray = fullName.split(" ");
  return fullNameArray.length > 1 ? fullNameArray.slice(1).join(" ") : "";
};
