import Quill from "quill";
import "quill/dist/quill.snow.css";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowDown from "../../assets/img/ArrowDown.png";
import styles from "./SlanjeObavijesti.module.css";
import {
  CentriData,
  GprData,
  GprNazivData,
  GrupaData,
  MjestaData,
  PrimateljData,
  ProgramData,
} from "./__mocks__/SlanjeObavijestiData";

function SlanjeObavijesti() {
  const [options, setOptions] = useState({
    primatelj: false,
    mjesta: false,
    program: false,
    gpr: false,
    gprNaziv: false,
    grupa: false,
    centar: false,
  });
  const [selected, setSelected] = useState({
    primatelj: "",
    mjesta: [],
    program: [],
    gpr: "",
    gprNaziv: "",
    grupa: "",
    centar: "",
  });

  const handleOptionClick = (key, option) => {
    setSelected({ ...selected, [key]: option });
    setOptions({ ...options, [key]: false });
  };

  const handleMultipleOptionClick = (label, option) => {
    const currentSelection = selected[label] || [];
    const updatedSelection = currentSelection.includes(option)
      ? currentSelection.filter((item) => item !== option)
      : [...currentSelection, option];

    setSelected({ ...selected, [label]: updatedSelection });
    setOptions({ ...options, [label]: false });
  };

  const toggleOptions = (key) => {
    setOptions({
      primatelj: false,
      mjesta: false,
      program: false,
      gpr: false,
      gprNaziv: false,
      grupa: false,
      centar: false,
      [key]: !options[key],
    });
  };

  const navigate = useNavigate();
  const quillRef = useCallback((node) => {
    if (node) {
      new Quill(node, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ font: ["serif", "monospace"] }],
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "blockquote"],
            [{ color: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link", "image"],
          ],
        },
      });
    }
  }, []);

  const resetOptions = () => {
    setOptions({
      primatelj: false,
      mjesta: false,
      program: false,
      centar: false,
      gpr: false,
      gprNaziv: false,
      grupa: false,
    });
  };

  return (
    <div className={styles.wrapper}>
      <form action="submit">
        <div className={styles.firstRow}>
          {Object.entries({
            Primatelj: PrimateljData,
          }).map(([label, data]) => (
            <div className={styles.item} key={label}>
              <label htmlFor="primatelj" className={styles.label}>
                {label}
              </label>
              <button
                type="button"
                className={styles.dropdown}
                onClick={() => toggleOptions(label)}
              >
                {selected[label] || "Odaberi"} <img src={ArrowDown} alt="" />
              </button>

              {options[label] && (
                <div className={styles.options}>
                  <div className={styles.dropdownScroll}>
                    {data.map((option) => (
                      <button
                        key={option}
                        onClick={() => handleOptionClick(label, option)}
                        className={styles.option}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
          {Object.entries({
            "Mjesto upisa": MjestaData,
            "Godina školovanja/Program": ProgramData,
          }).map(([label, data]) => (
            <div className={styles.item} key={label}>
              <label htmlFor={label} className={styles.label}>
                {label}
              </label>
              <button
                type="button"
                className={styles.dropdown}
                onClick={() => toggleOptions(label)}
                disabled={
                  (label === "Mjesto upisa" ||
                    label === "Godina školovanja/Program") &&
                  selected.Primatelj === "IPD Centar"
                }
              >
                {selected[label] && selected[label].length > 0
                  ? selected[label].join(", ")
                  : "Odaberi"}{" "}
                <img src={ArrowDown} alt="" />
              </button>
              {options[label] && (
                <div className={styles.options}>
                  <div className={styles.dropdownScroll}>
                    {data.map((option) => (
                      <button
                        key={option}
                        onClick={() => handleMultipleOptionClick(label, option)}
                        className={`${styles.option} ${
                          (selected[label] || []).includes(option)
                            ? styles.selected
                            : ""
                        }`}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          className={
            selected.Primatelj === "GPR" ? styles.newRow : styles.hidden
          }
        >
          <div className={styles.item}>
            <label htmlFor="gpr" className={styles.label}>
              Gpr
            </label>
            <button
              type="button"
              className={styles.dropdown}
              onClick={() => toggleOptions("gpr")}
            >
              {selected.gpr || "Odaberi"} <img src={ArrowDown} alt="" />
            </button>
            {options.gpr && (
              <div className={styles.optionsGpr}>
                {GprData.map((option) => (
                  <button
                    key={option}
                    type="button"
                    onClick={() => handleOptionClick("gpr", option)}
                    className={styles.option}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className={styles.item}>
            <label htmlFor="gprNaziv" className={styles.label}>
              Naziv
            </label>
            <button
              type="button"
              className={styles.dropdown}
              onClick={() => toggleOptions("gprNaziv")}
            >
              {selected.gprNaziv || "Odaberi"} <img src={ArrowDown} alt="" />
            </button>
            {options.gprNaziv && (
              <div className={styles.optionsGpr}>
                {GprNazivData.map((option) => (
                  <button
                    key={option}
                    type="button"
                    onClick={() => handleOptionClick("gprNaziv", option)}
                    className={styles.option}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div
          className={
            selected.Primatelj === "Grupa" ? styles.newRow : styles.hidden
          }
        >
          <div className={styles.item}>
            <label htmlFor="grupa" className={styles.label}>
              Grupa
            </label>
            <button
              type="button"
              className={styles.dropdown}
              onClick={() => toggleOptions("grupa")}
            >
              {selected.grupa || "Odaberi"} <img src={ArrowDown} alt="" />
            </button>
            {options.grupa && (
              <div className={styles.optionsGpr}>
                {GrupaData.map((option) => (
                  <button
                    key={option}
                    onClick={() => handleOptionClick("grupa", option)}
                    className={styles.option}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div
          className={
            selected.Primatelj === "IPD Centar" ? styles.newRow : styles.hidden
          }
        >
          <div className={styles.item}>
            <label htmlFor="centri" className={styles.label}>
              Centar
            </label>
            <button
              type="button"
              className={styles.dropdown}
              onClick={() => toggleOptions("centar")}
            >
              {selected.centar || "Odaberi"} <img src={ArrowDown} alt="" />
            </button>
            {options.centar && (
              <div className={styles.optionsGpr}>
                {CentriData.map((option) => (
                  <button
                    key={option}
                    onClick={() => handleOptionClick("centar", option)}
                    className={styles.option}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.secondRow}>
          <div className={styles.predmet}>
            <label htmlFor="primatelj" className={styles.label}>
              Predmet
            </label>
            <input
              type="text"
              placeholder="Upiši"
              className={styles.predmetInput}
              onClick={resetOptions}
            />
          </div>
        </div>
        <div className={styles.lastRow}>
          <div
            ref={quillRef}
            style={{
              height: "400px",
              width: "100%",
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            }}
            className={styles.quill}
          ></div>
        </div>
        <div className={styles.btns}>
          <button
            className={styles.btn1}
            onClick={() => navigate("/dashboard")}
          >
            Odustani
          </button>
          <button className={styles.btn2} type="submit">
            Pošalji obavijest
          </button>
        </div>
      </form>
    </div>
  );
}

export default SlanjeObavijesti;
