import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Success from "../../../assets/img/Success.png";
import Modal from "../../../components/Modal/Modal.jsx";
import { useAuthentication } from "../../hooks/useAuthentication";
import TeacherCard from "../components/teacherCard/TeacherCard";
import { createEdukant } from "./api/createEdukant";
import { createEdukator } from "./api/createEdukator";
import { getUloga } from "./api/getUloga";
import styles from "./UrediUloge.module.css";
import {
  CustomOption,
  MultiValueContainer,
  options,
  selectStyles,
} from "./utils.js";

const UrediUloge = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSecondDisabled, setIsSecondDisabled] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedUloga, setSelectedUloga] = useState({
    value: null,
    label: "Odaberi ulogu",
  });
  const [selectedEdukanti, setSelectedEdukanti] = useState(null);
  const { groupData, teachersData } = location.state;
  const userOptions = teachersData.map((teacher) => ({
    label: `${teacher?.firstName} ${teacher?.lastName}`,
    value: teacher?.id,
  }));

  const handleZatvori = () => {
    setIsSuccessModalOpen(false);
    handleCancel();
  };

  const handleCancel = () => {
    const groupId = groupData?.id;
    navigate(`/grupe/${groupId}`);
  };

  const handleSaveChanges = async () => {
    if (selectedUloga.value === "Edukator") {
      const requestData = {
        groupId: groupData?.id,
        userIds:
          selectedUloga.value === "Edukator"
            ? selectedEdukanti.map((edukant) => edukant.value)
            : null,
      };
      try {
        await createEdukator(requestData, authToken);
        setIsSuccessModalOpen(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      const requestData = {
        groupid: groupData?.id,
        userId:
          selectedUloga.value === "Edukant" ? selectedEdukanti.value : null,
      };
      try {
        await createEdukant(requestData, authToken);
        setIsSuccessModalOpen(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleFirstSelectChange = (selectedOption) => {
    if (selectedOption.value === null) {
      setIsSecondDisabled(true);
      setSelectedEdukanti(null);
    } else {
      setIsSecondDisabled(false);
      setSelectedEdukanti(null);
    }
    setSelectedUloga(selectedOption);
  };

  const handleSecondSelectChange = (selectedOption) => {
    setSelectedEdukanti(selectedOption);
  };

  useEffect(() => {
    if (
      (selectedUloga.value === "Edukator" ||
        selectedUloga.value === "Edukant" ||
        selectedUloga.value === null) &&
      (selectedEdukanti === null || selectedEdukanti.length === 0)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [selectedEdukanti, selectedUloga]);

  useEffect(() => {
    const fetchUloga = async () => {
      const response = await getUloga(groupData?.id, authToken);
      if (response.Role === "edukator") {
        setSelectedUloga(options[0]);
        setIsSecondDisabled(false);
      } else if (response === "edukant") {
        setSelectedUloga(options[1]);
        setIsSecondDisabled(false);
      } else {
        setSelectedUloga({
          value: null,
          label: "Odaberi ulogu",
        });
      }
    };

    if (authToken) fetchUloga();
  }, [authToken]);

  return (
    <div>
      <div className={styles.groupHeadContainer}>
        <div className={styles.groupHeadTextContainer}>
          <h3 className={styles.groupTitle}>
            Grupa {groupData?.name ? groupData?.name : "(nema imena)"}
          </h3>
          <p className={styles.groupDateText}>
            Datum kreiranja:{" "}
            {groupData?.createdDate ? groupData?.createdDate : "nepoznato"}
          </p>
        </div>
      </div>
      <div className={styles.leadersTitleContainer}>
        <h3 className={styles.leadersTitle}>Voditelji grupe</h3>
        <p className={styles.leadersNumber}>
          {teachersData ? teachersData.length : "?"}
        </p>
      </div>
      <div className={styles.voditeljiContainer}>
        {teachersData.map((teacher, index) => (
          <TeacherCard
            key={index}
            {...teacher}
            photo={teacher?.imageUrl}
            email={teacher?.emailAddress}
            biography={teacher?.bio}
          />
        ))}
      </div>
      <div className={styles.selectContainer}>
        <div className={styles.formGroup}>
          <label className={styles.Label} htmlFor="uloga">
            Moja uloga
          </label>
          <Select
            options={options}
            styles={selectStyles}
            isSearchable={false}
            components={{ IndicatorSeparator: () => null }}
            value={selectedUloga}
            placeholder="Odaberi ulogu"
            onChange={handleFirstSelectChange}
          />

          <div className={styles.formGroup}>
            {selectedUloga.value === "Edukator" ? (
              <>
                <label className={styles.Label} htmlFor="teacherName">
                  Edukanti
                </label>
                <Select
                  options={userOptions}
                  value={selectedEdukanti}
                  styles={isSecondDisabled ? styles.disabled : selectStyles}
                  isSearchable={false}
                  components={{
                    Option: CustomOption,
                    IndicatorSeparator: null,
                    MultiValueContainer,
                  }}
                  placeholder="Odaberi"
                  isMulti={true}
                  isDisabled={isSecondDisabled}
                  onChange={handleSecondSelectChange}
                />
              </>
            ) : (
              <>
                <label className={styles.Label} htmlFor="teacherName">
                  Edukatori
                </label>
                <Select
                  options={userOptions}
                  value={selectedEdukanti}
                  styles={isSecondDisabled ? styles.disabled : selectStyles}
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: null,
                  }}
                  placeholder="Odaberi"
                  isDisabled={isSecondDisabled}
                  onChange={handleSecondSelectChange}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.cancelButton} onClick={handleCancel}>
          Odustani
        </button>
        <button
          className={`${styles.nextButton} ${
            isDisabled ? styles.disabledBtn : ""
          }`}
          onClick={handleSaveChanges}
          disabled={isDisabled}
        >
          Spremi promjene
        </button>
      </div>
      {isSuccessModalOpen && (
        <Modal
          image={Success}
          description="Uspješno ste promijenili uloge!"
          buttons={[
            {
              label: "Zatvori",
              action: handleZatvori,
              className:
                "modal-btn-deletePage modal-btn-discontinue-deletePage",
            },
          ]}
        />
      )}
    </div>
  );
};

export default UrediUloge;
