import { deleteGroupTeacher } from "./deleteGroupTeacher";
import { getGroup } from "./getGroup";

export const apiCallToRemoveTeacher = async (
  selectedItemIndex,
  groupId,
  accessToken,
  setTeachersData,
  setTeacherRemoveModal,
  setIsAllowed
) => {
  try {
    const teacherId = selectedItemIndex;
    await deleteGroupTeacher(groupId, teacherId, accessToken);
    const fetchedGroupData = await getGroup(groupId, accessToken, setIsAllowed);
    const updatedTeachersData = fetchedGroupData.voditelji;
    setTeachersData(updatedTeachersData);
    setTeacherRemoveModal(false);
  } catch (error) {
    console.error("Error deleting teacher:", error);
  }
};
