import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getApplicationsData = async (accessToken, setApplicationsData) => {
  try {
    const response = await axios.get(`${apiUrl}/api/MyProgramApplications`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const resData = response.data;
    setApplicationsData(resData.response);
  } catch (error) {
    console.error("Error fetching applications data:", error);
    throw error;
  }
};
