import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthentication } from "../scenes/hooks/useAuthentication";

export const rolesArray = [
  {
    id: 1,
    name: "Zakupitelj",
  },
  {
    id: 2,
    name: "Polaznik",
  },
  {
    id: 3,
    name: "Terapeut",
  },
  {
    id: 4,
    name: "Voditelj",
  },
  {
    id: 5,
    name: "Supervizor",
  },
  {
    id: 6,
    name: "Referada",
  },
  {
    id: 7,
    name: "Učitelj",
  },
  {
    id: 8,
    name: "Polaznik VT-a",
  },
];
const UserContext = createContext();
const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [role, setRole] = useState("");

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("authToken") || ""
  );
  const { authToken } = useAuthentication();
  useEffect(() => {
    setAccessToken(authToken);
  }, [authToken]);

  useEffect(() => {
    if (accessToken)
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/user`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(async (data) => {
          await axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/role/${data.data.roleId}`,
              {
                headers: { Authorization: `Bearer ${accessToken}` },
              }
            )
            .then((response) => {
              setRole(response.data.name);
            })
            .catch(() => alert("Nisi registrirani korisnik"));
          setUserData(data.data);
        });
  }, [authToken]);

  return (
    <UserContext.Provider
      value={{
        userData,
        accessToken,
        setAccessToken,
        role,
        setRole,
        setUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const useUserState = () => useContext(UserContext);
export default UserProvider;
