import React from "react";
import "./ObracunTable.css";

const cityPrice = [
  { cityIds: [1, 2, 6], price: [8, 9] }, //hrvatska
  { cityIds: [4], price: [7, 8] }, //srbija
  { cityIds: [3], price: [9, 10] }, //slovenija
  { cityIds: [7], price: [0] }, //online
];

function RenderData({
  data = [],
  isPotrosnjaPoGradovima,
  isPotpuniObracun,
  showSum,
  isObracunPoSobama,
  cityRoomReservationsNumber,
}) {
  let dataToRender = [];
  if (isObracunPoSobama) {
    dataToRender = data;
  } else {
    dataToRender = data?.map((item) => item.obracun).flat();
  }

  const cityUkupno = [
    { cityId: 1, ukupnoPrice: 0 },
    { cityId: 2, ukupnoPrice: 0 },
    { cityId: 3, ukupnoPrice: 0 },
    { cityId: 4, ukupnoPrice: 0 },
    { cityId: 5, ukupnoPrice: 0 },
    { cityId: 6, ukupnoPrice: 0 },
    { cityId: 7, ukupnoPrice: 0 },
  ];

  const numberOfReservationsByCity = [
    { cityId: 1, numberOfReservations: 0 },
    { cityId: 2, numberOfReservations: 0 },
    { cityId: 3, numberOfReservations: 0 },
    { cityId: 4, numberOfReservations: 0 },
    { cityId: 5, numberOfReservations: 0 },
    { cityId: 6, numberOfReservations: 0 },
    { cityId: 7, numberOfReservations: 0 },
  ];

  const updateReservationsByCity = (dataToRender) => {
    Object.values(dataToRender).forEach((roomArray) => {
      if (Array.isArray(roomArray)) {
        const cityId = roomArray[0]?.room?.cityId;

        if (cityId !== undefined && cityId !== null) {
          const cityEntry = numberOfReservationsByCity.find(
            (entry) => entry.cityId === cityId
          );
          if (cityEntry) {
            cityEntry.numberOfReservations += roomArray.length;
          }
        }
      }
    });
  };

  updateReservationsByCity(dataToRender);

  let ukupnoRez = 0;
  numberOfReservationsByCity.forEach((item) => {
    ukupnoRez += item.numberOfReservations;
  });

  const sums = {
    ukupnoRezervacija: !isPotpuniObracun ? ukupnoRez : 0,
    ukupnoEuri: 0,
    brojOtkazanihRezervacija: 0,
    ukupnoKoristenje: 0,
    postotakOtkazanihRezervacija: 0,
  };

  if (!isPotpuniObracun) {
    const processedCities = new Set();

    Object.entries(dataToRender || {}).forEach(([roomName, roomData]) => {
      sums.ukupnoEuri += roomData?.length * roomData[0]?.room?.price;

      if (!processedCities.has(roomData[0].room?.cityId)) {
        sums.brojOtkazanihRezervacija +=
          roomData[0].room?.cancelledAppointments;
        processedCities.add(roomData[0].room?.cityId);
      }
    });

    sums.postotakOtkazanihRezervacija =
      ukupnoRez > 0
        ? (
            (sums.brojOtkazanihRezervacija / sums.ukupnoRezervacija) *
            100
          ).toFixed(2)
        : "0.00";
  } else {
    Object.entries(dataToRender || {}).forEach(([cityName, cityData]) => {
      sums.ukupnoRezervacija += cityData.length;
      const cityEntry = cityUkupno.find(
        (c) => c.cityId === cityData[0]?.city?.id
      );

      if (cityEntry) {
        cityEntry.ukupnoPrice += cityData.reduce(
          (acc, city) => acc + (city.city?.price || 0),
          0
        );
        sums.ukupnoEuri += cityEntry.ukupnoPrice;
      }
    });
  }

  return (
    <>
      {Object.entries(dataToRender || {}).map(([roomName, roomData]) => {
        const cityId = roomData[0]?.city?.id;
        const cityPriceObj = cityPrice.find((city) =>
          city.cityIds.includes(cityId)
        );
        const priceList = cityPriceObj ? cityPriceObj.price.join(", ") : "N/A";

        return (
          <tr key={roomName}>
            <td
              style={{
                fontWeight: "bold",
                textAlign: "left",
                textTransform: "capitalize",
              }}
            >
              {roomName}
            </td>
            <td>{roomData.length || 0}</td>
            <td>
              {isPotpuniObracun ? priceList : roomData[0]?.room?.price || 0}
            </td>
            <td>
              {isPotpuniObracun
                ? cityUkupno.filter((city) => city.cityId === cityId)[0]
                    ?.ukupnoPrice
                : roomData.length * (roomData[0]?.room?.price || 0)}
            </td>
            {!isPotpuniObracun && (
              <>
                <td>{roomData[0]?.room?.cancelledAppointments || 0}</td>
                <td>
                  {(numberOfReservationsByCity.find(
                    (entry) => entry.cityId === roomData[0]?.room?.cityId
                  )?.numberOfReservations === 0
                    ? 0.0
                    : (roomData[0].room?.cancelledAppointments /
                        numberOfReservationsByCity.find(
                          (entry) => entry.cityId === roomData[0].room?.cityId
                        )?.numberOfReservations) *
                      100
                  ).toFixed(2)}
                  %
                </td>
              </>
            )}
          </tr>
        );
      })}

      {showSum && (
        <tr style={{ backgroundColor: "#F4F4F4", fontWeight: 600 }}>
          <td style={{ textAlign: "left", fontWeight: 600 }}>
            {isPotpuniObracun ? "UKUPNO KORIŠTENJE" : "UKUPNO"}
          </td>
          <td>{sums.ukupnoRezervacija}</td>
          <td>{"/"}</td>
          <td>{sums.ukupnoEuri}</td>
          {!isPotpuniObracun && (
            <>
              <td>{sums.brojOtkazanihRezervacija}</td>
              <td>{sums.postotakOtkazanihRezervacija}%</td>
            </>
          )}
          {isPotrosnjaPoGradovima && <td></td>}
        </tr>
      )}
    </>
  );
}

export default RenderData;
