import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Arrow from "../../../assets/img/ArrowDown.png";
import Background from "../../../assets/img/ProgramBackground.png";

export const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  justifyContent: "center",
  width: "100%",
  gap: 30,
}));
export const Header = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  height: 200,
}));
export const ImageButton = styled(`button`)(({ selectedImage }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 155,
  width: 213,
  position: "absolute",
  left: 56,
  bottom: "-26px",
  border: "8px solid white",
  borderRadius: 16,
  backgroundColor: "#F3F2F2",
  "&:hover": {
    border: "8px solid #F3F2F2",
    cursor: "pointer",
  },
  backgroundImage: selectedImage ? `url(${selectedImage})` : "none",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));
export const BackgroundImage = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 155,
  width: "90vw",
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));
export const ProgramImage = styled(Box)(({ backgroundimage }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 155,
  width: 213,
  backgroundColor: "white",
  backgroundImage: `url(${backgroundimage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "absolute",
  left: 56,
  bottom: "-26px",
}));
export const Title = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "90%",
  fontFamily: "Inter",
  fontSize: 28,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#4E4E4E",
  paddingTop: 10,
}));

export const UrediButton = styled(`button`)(({ disabled }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 40,
  paddingLeft: 100,
  paddingRight: 100,
  border: "1px solid #D8DBDF",
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "center",
  color: disabled ? "#A0A0A0" : "#5F6D7E",
  borderRadius: 4,
  transition: "backgroundColor 300ms ease-in-out",
  cursor: disabled ? "not-allowed" : "pointer",
  backgroundColor: disabled ? "#E8E8E8" : "transparent",
  "&:hover": {
    backgroundColor: disabled ? "#E8E8E8" : "#D8DBDF",
    color: disabled ? "#A0A0A0" : "#4E4E4E",
  },
}));
export const InputWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "baseline",
  flexDirection: "column",
  width: "60%",
  gap: 30,
  "@media screen and (max-width:800px)": {
    width: "100%",
  },
}));

export const SectionTitle = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#5F6D7E",
}));

export const InputContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "baseline",
  flexDirection: "column",
  width: "100%",
  boxSizing: "border-box",
}));

export const Input = styled(`input`)(({ disabled }) => ({
  padding: 8,
  border: "1px solid #D8DBDF",
  borderRadius: 8,
  textAlign: "left",
  color: "#5F6D7E",
  boxShadow: "4px 4px 64px 0px #5F6D7E1A",
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.6,
  letterSpacing: " -0.001em",
  width: "100%",
  cursor: disabled ? "not-allowed" : "pointer",
  backgroundColor: disabled && "#F4F4F4",
  boxSizing: "border-box",
}));

export const DatePlaceRow = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  gap: 36,
  "@media screen and (max-width:750px)": {
    flexDirection: "column",
  },
}));

export const SmallInputContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "baseline",
  flexDirection: "column",
  width: "48%",
  "@media screen and (max-width:750px)": {
    width: "100%",
  },
}));

export const TextArea = styled(`textarea`)(() => ({
  width: "100%",
  padding: 16,
  border: "1px solid #D8DBDF",
  borderRadius: 8,
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.6,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#5F6D7E",
  marginTop: "-30px",
  boxSizing: "border-box",
}));

export const TitleBold = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontSize: 20,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "left",
  color: "#4D4D4D",
}));

export const EditFooter = styled(Box)(() => ({
  display: "flex",
  width: "95%",
  paddingBottom: 20,
  justifyContent: "space-between",
  alignItems: "center",
  gap: 16,
  position: "sticky",
  bottom: 0,
  left: 0,
  boxSizing: "border-box",
  "@media screen and (max-width:800px)": {
    width: "100%",
    justifyContent: "center",
    position: "fixed",
    paddingLeft: 30,
    paddingRight: 30,
  },
  "@media screen and (max-width:430px)": {
    paddingLeft: 20,
    paddingRight: 20,
  },
}));
export const EditButton = styled(`button`)(
  ({ backgroundColor, color, disabled }) => ({
    display: "flex",
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    paddingTop: 25,
    paddingBottom: 25,
    border: "transparent",
    fontFamily: "Inter",
    fontSize: 15,
    fontWeight: 600,
    letterSpacing: " -0.001em",
    textAlign: "center",
    color: disabled ? "#FFFFFF" : color,
    cursor: disabled ? "not-allowed" : "pointer",
    borderRadius: 4,
    backgroundColor: disabled ? "#CBBA92" : backgroundColor,
    "&:hover": {
      opacity: 0.89,
    },
    "@media screen and (max-width:800px)": {
      width: "40%",
    },
    "@media screen and (max-width:430px)": {
      width: "50%",
    },
  })
);
export const Select = styled(`select`)(() => ({
  padding: 8,
  border: "1px solid #D8DBDF",
  borderRadius: 8,
  textAlign: "left",
  color: "#5F6D7E",
  boxShadow: "4px 4px 64px 0px #5F6D7E1A",
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.6,
  letterSpacing: " -0.001em",
  width: "104%",
  backgroundColor: "white",
  appearance: "none",
  WebkitAppearance: "none",
  backgroundImage: `url(${Arrow})`,
  backgroundPositionX: "97%",
  backgroundPositionY: "center",
  backgroundRepeat: "no-repeat",
}));

export const VoditeljiContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  gap: 24,
  overflow: "hidden",
  paddingTop: 20,
}));

export const AddVoditeljButton = styled(`button`)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 243,
  height: 340,
  paddingLeft: 50,
  borderRadius: 8,
  paddingRight: 50,
  border: "1px solid #E2E2E2",
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#E2E2E2",
    cursor: "pointer",
  },
}));

export const SectionTitleRow = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #D1D9E2",
  width: "95%",
  cursor: "pointer",
  boxSizing: "border-box",
  "@media screen and (max-width:800px)": {
    width: "100%",
  },
}));
export const ButtonColumn = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: " -0.001em",
  textAlign: "center",
  color: "#828D9A",
}));

export const TableContainer = styled(Box)(() => ({
  width: "90%",
}));

export const SectionDropdown = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  width: "40vw",
}));

export const DropdownArrow = styled(`img`)(({ rotated }) => ({
  transition: "transform 0.3s ease-in-out",
  transform: rotated ? "rotate(180deg)" : "rotate(0deg)",
}));
