import ChevronRightIcon from "../../../assets/svg/ChevronRightIcon.svg";
import styles from "../Dashboard.module.css";

const Card = ({ number, title, icon, onClick }) => {
  return (
    <div className={styles.card} onClick={onClick}>
      <img alt="card icon" src={icon} className={styles.iconContainer} />
      <div className={styles.cardTextContainer}>
        <h3 className={styles.cardNumberText}>{number}</h3>
        <div className={styles.cardTitleContainer}>
          <p className={styles.cardText}>{title}</p>
          <img
            alt="arrow"
            src={ChevronRightIcon}
            className={styles.arrowIcon}
          />
        </div>
      </div>
    </div>
  );
};
export default Card;
