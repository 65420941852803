import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

export async function makePDFCertificate(inputPdfPath, userName, title = "") {
  const pdfBytes = await fetch(inputPdfPath).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(pdfBytes);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  pdfDoc.setTitle(title);

  const { width, height } = firstPage.getSize();
  const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const fontSize = 31;
  const maxWidth = 300;
  const lineHeight = fontSize + 5;

  function splitTextIntoLines(text, font, size, maxWidth) {
    const words = text.split(" ");
    const lines = [];
    let currentLine = "";

    for (const word of words) {
      const testLine = currentLine ? `${currentLine} ${word}` : word;
      const textWidth = font.widthOfTextAtSize(testLine, size);
      if (textWidth <= maxWidth) {
        currentLine = testLine;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }

    if (currentLine) lines.push(currentLine);
    return lines;
  }

  const nameLines = splitTextIntoLines(
    userName,
    helveticaBold,
    fontSize,
    maxWidth
  );
  const totalTextHeight = nameLines.length * lineHeight;
  const startY = height - 350 + totalTextHeight / 2;

  nameLines.forEach((line, index) => {
    const lineWidth = helveticaBold.widthOfTextAtSize(line, fontSize);
    const lineX = (width - lineWidth) / 2;

    firstPage.drawText(line, {
      x: lineX,
      y: startY - index * lineHeight,
      size: fontSize,
      color: rgb(80 / 255, 79 / 255, 79 / 255),
      font: helveticaBold,
    });
  });

  const today = new Date();
  const formattedDate = `${today.getDate().toString().padStart(2, "0")}.${(
    today.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}.${today.getFullYear()}.`;
  firstPage.drawText(`${formattedDate}`, {
    x: 100,
    y: height - 705,
    size: 12,
    color: rgb(80 / 255, 79 / 255, 79 / 255),
    font: helveticaFont,
  });

  const modifiedPdfBytes = await pdfDoc.save();
  return new Blob([modifiedPdfBytes], { type: "application/pdf" });
}
