export const sviPolaznici = [
  { value: "all", label: "Svi polaznici" },
  { value: "1", label: "Polaznici RAR" },
  { value: "2", label: "Polaznici OR1" },
  { value: "3", label: "Polaznici OR2" },
  { value: "4", label: "Polaznici OR3" },
  { value: "5", label: "Polaznici OR4" },
  { value: "6", label: "Polaznici TP1" },
  { value: "7", label: "Polaznici TP2" },
  { value: "8", label: "Polaznici TT5" },
  { value: "9", label: "Polaznici TT6" },
  { value: "10", label: "Polaznici VT" },
  { value: "11", label: "RPO" },
  { value: "12", label: "Roditeljstvo 0-6" },
  { value: "13", label: "Pauzirani program" },
  { value: "14", label: "Polaznici radionica" },
  { value: "15", label: "Polaznici Inteziva" },
  { value: "16", label: "Arhivirani polaznici" },
];
export const selectStyles = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    height: "40px",
    width: "200px",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "4px",
    border: "1px solid #D1D9E2 !important",
    boxShadow: "none",
    cursor: "pointer",
    "&:focus": {
      border: "0 !important",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#F2F2F2",
      },
    };
  },
};
export const MultiselectPlaceStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    marginLeft: "15px",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    width: "300px",
    borderRadius: "8px",
    border: "1px solid #D1D9E2 !important",
    padding: "2px 0px",
    cursor: "pointer",
    boxShadow: "none",
    "&:focus": {
      border: "0 !important",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    padding: "5px",
    display: "flex",
    alignItems: "center",
    color: "#5F6D7EC7",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    ":hover": {
      backgroundColor: "#F2F2F2",
    },
  }),
};

export const MultiselectPlaceOption = ({ innerProps, label, isSelected }) => {
  return (
    <div
      {...innerProps}
      style={{
        display: "flex",
        gap: "12px",
        cursor: "pointer",
        padding: "12px 16px",
      }}
    >
      <input
        type="checkbox"
        checked={isSelected}
        readOnly
        style={{
          width: "20px",
          height: "20px",
          cursor: "pointer",
        }}
      />
      <span
        style={{
          color: "rgba(95, 109, 126, 0.78)",
          fontFamily: "Inter",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "22px",
        }}
      >
        {label}
      </span>
    </div>
  );
};
export const handleSelectChange = (selectedOption, setSelectedOption) => {
  setSelectedOption(selectedOption);
};
