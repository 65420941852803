import React from "react";
import Back from "../../assets/img/BackArrow.png";
import styles from "../Pagination/Pagination.module.css";

function BEPagination({
  currentPage,
  totalPageCount,
  handlePaginationClick,
  customWidth,
}) {
  const handleClick = (event) => {
    const pageNumber = Number(event.currentTarget.id);
    handlePaginationClick(pageNumber);
  };

  const pageNumbers = [];
  const maxDisplayedPages = 5;
  let startPage = 1;
  if (totalPageCount <= maxDisplayedPages) {
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(i);
    }
  } else {
    startPage = Math.max(currentPage - Math.floor(maxDisplayedPages / 2), 1);
    const endPage = Math.min(startPage + maxDisplayedPages - 1, totalPageCount);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  }
  return (
    <div>
      <div style={{ width: customWidth ? customWidth : "90%" }}>
        <div
          className={styles.btnRow}
          style={{ justifyContent: "space-around" }}
        >
          <div
            className={
              currentPage === 1 ? styles.disabledBtn : styles.navigationRow
            }
            style={{ marginRight: 24 }}
            onClick={() => {
              const prevPage = currentPage > 1 ? currentPage - 1 : 1;
              handlePaginationClick(prevPage);
            }}
          >
            <img className="arrow-btn-korisnici" alt="arrow right" src={Back} />
            <span>Prev</span>
          </div>
          <div className={styles.numberContainer}>
            {pageNumbers.map((number) => (
              <div
                key={number}
                id={number.toString()}
                onClick={handleClick}
                className={
                  currentPage === number
                    ? styles.activeNumber
                    : styles.pageNumber
                }
              >
                {number}
              </div>
            ))}
          </div>
          <div
            className={styles.navigationRow}
            style={{ marginLeft: 24 }}
            onClick={() => {
              const nextPage = Math.min(currentPage + 1, totalPageCount);
              handlePaginationClick(nextPage);
            }}
          >
            <span>Next</span>
            <img alt="" src={Back} style={{ transform: "rotate(180deg)" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BEPagination;
