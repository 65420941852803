import { useEffect } from "react";
import { auth } from "../../firebase";
import { useAuthentication } from "./useAuthentication";
export const useRefreshToken = () => {
  const { refreshUserToken } = useAuthentication();
  useEffect(() => {
    const interval = setInterval(
      () => refreshUserToken(auth.currentUser),
      30 * 60 * 1000
    );
    return () => clearInterval(interval);
  }, [refreshUserToken]);
};
