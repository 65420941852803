import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded";
import React, { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Down from "../../../assets/img/ArrowDown.png";
import Arrow from "../../../assets/img/BackArrow.png";
import useWindowResize from "../../../components/Hooks/ResizeHook.jsx";
import { useUserState } from "../../../context/UserContext.js";
import { useAuthentication } from "../../hooks/useAuthentication";
import {
  Back,
  BackBox,
  BackRow,
  Content,
  ContentRow,
  DropdownArrow,
  SectionDropdown,
  SectionTitle,
  SectionTitleRow,
  Title,
  Wrapper,
} from "../ProgramContainer/ProgramContainer.styles";
import { fetchProgramData, getIconByType } from "../utils.js";
import IntrospekcijeContainer from "./IntrospekcijeContainer";
import "./Section.css";
import {
  BackgroundImage,
  DefaultDescriptionWrapper,
  Description,
  DescriptionWrapper,
  Header,
  InfoContainer,
  ProgramImage,
  SectionNavigation,
  SekcijaButton,
  SmallTitle,
} from "./Section.styles";
import {
  navigatePolaznikNext,
  navigatePolaznikPrevious,
  toggleTeorijskiSection,
} from "./utils.js";
import { getSignature } from "./zoom/api/getSignature";
import { testConfig } from "./zoom/zoomConfig";

function Section() {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const location = useLocation();
  const [previousRoute, setPreviousRoute] = useState(
    location?.state?.previousRoute
  );
  const [isAllowed, setIsAllowed] = useState(null);

  const [programData, setProgramData] = useState({});

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const [title, setTitle] = useState(location?.state?.title);
  const navigate = useNavigate();
  const { program_id, moduleId, id } = useParams();

  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const userId = role;
  useEffect(() => {
    if (authToken)
      fetchProgramData(authToken, id, setProgramData, setIsAllowed);
  }, [authToken, program_id, userId, userData]);
  const indexNumber = parseInt(program_id, 10);
  const moduleNumber = parseInt(moduleId, 10);
  const [sectionIndexNumber, setSectionIndexNumber] = useState(indexNumber);
  const [moduleIndexNumber, setModuleIndexNumber] = useState(moduleNumber);
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setSectionIndexNumber(indexNumber);
  }, [indexNumber, moduleNumber]);

  const handlePolaznikSectionNavigation = (index) => {
    navigate(`/pojediniProgram-section/${moduleId}/${index}/${id}`);
  };
  const [teorijskiExpandedSections, setTeorijskiExpandedSections] = useState(
    []
  );
  const [contentTitle, setContentTitle] = useState("");
  const [contentDescription, setContentDescription] = useState("");
  const [contentImage, setContentImage] = useState("");
  const [contentType, setContentType] = useState("");
  const [isZoom, setIsZoom] = useState(false);
  useEffect(() => {
    const sectionIndexNumber = indexNumber;
    const section = theoreticalModules?.find((item) =>
      item.resources.some((resource) => resource.id === sectionIndexNumber)
    );
    if (section) {
      const contentItem = section.resources?.find(
        (content) => content.id === sectionIndexNumber
      );
      if (contentItem && contentItem.name) {
        const title = contentItem.name;
        setContentTitle(title);
      } else {
        console.log("Content title not found for the given sectionIndex.");
      }
      if (contentItem && contentItem.resourceType) {
        const description = contentItem.url;
        const type = contentItem.resourceType;
        const image = contentItem.url;
        const zoom = contentItem.zoom;
        setIsZoom(zoom);
        setContentDescription(description);
        setContentImage(image);
        setContentType(type);
      } else {
        console.log(
          "Content description not found for the given sectionIndex."
        );
      }
    } else {
      console.log("Section not found for the given sectionIndex.");
    }
  }, [program_id, programData]);
  const client = ZoomMtgEmbedded.createClient();
  async function initializeZoom() {
    try {
      const meetingSDKElement = document.getElementById("meetingSDKElement");
      if (meetingSDKElement) {
        meetingSDKElement.style.display = "block";
      }
      const endContainer = document.querySelector(".endContainer");

      document.querySelector(".zoom-container").style.border = "none";

      setTimeout(() => {
        document.querySelector(".zoom-container").style.backgroundColor =
          "#eeeeee";
      }, 7000);

      setTimeout(() => {
        endContainer.style.visibility = "visible";
        endContainer.style.position = "relative";
        endContainer.style.width = "90%";
      }, 10000);

      const reqBody = {
        topic: testConfig.topic,
        password: testConfig.password,
        userIdentity: userData.firstName + " " + userData.lastName,
        sessionKey: testConfig.sdkKey,
        roleType: testConfig.role,
        mn: testConfig.meetingNumber,
      };
      const signature = await getSignature(reqBody, authToken);

      client
        .init({
          zoomAppRoot: meetingSDKElement,
          language: "en-US",
          leaveUrl: testConfig.leaveUrl,
          customize: {
            setting: {
              popper: { disableDraggable: true },
              isResizable: false,
              // viewSizes: {
              //   default: {
              //     width: 840,
              //     height: 0,
              //   },
              // },
            },
          },
        })
        .then(() => {
          client
            .join({
              sdkKey: testConfig.sdkKey,
              signature,
              meetingNumber: testConfig.meetingNumber,
              password: testConfig.password,
              userName: userData.firstName + " " + userData.lastName,
            })
            .then(() => {
              console.log("Joined Zoom meeting successfully");
            })
            .catch((error) => {
              console.log("Error joining Zoom meeting:", error);
            });
          client
            .updateVideoOptions({
              popper: { disableDraggable: true },
              isResizable: false,
              viewSizes: {
                default: {
                  width: 800,
                },
              },
            })
            .catch((error) => {
              console.log("Error modifying Zoom meeting:", error);
            });
        })
        .catch((error) => {
          console.log("Error initializing Zoom client:", error);
        });
    } catch (error) {
      console.error("Error in initializeZoom:", error);
    }
  }

  const theoreticalModules = programData?.theoreticalModules?.map((module) => ({
    ...module,
    resources: module?.resources?.filter(
      (resource) => resource?.resourceType !== "image/jpeg"
    ),
  }));

  const breakpoint = 1024;
  const width = useWindowResize();
  return (
    <Wrapper>
      <BackBox onClick={() => navigate(-1)}>
        <img alt="" src={Arrow} />
        <Back>Natrag</Back>
      </BackBox>

      <Title>{title}</Title>
      <SectionNavigation>
        {width > breakpoint && (
          <>
            <BackRow
              onClick={() =>
                navigatePolaznikPrevious(
                  theoreticalModules,
                  sectionIndexNumber,
                  setSectionIndexNumber,
                  setModuleIndexNumber,
                  navigate,
                  previousRoute,
                  id
                )
              }
            >
              <img alt="" src={Arrow} />
              <Back>Prethodno</Back>
            </BackRow>
            <SmallTitle color={"#5F6D7E"}>
              {contentTitle ? contentTitle : ""}
            </SmallTitle>
            <BackRow
              onClick={() =>
                navigatePolaznikNext(
                  theoreticalModules,
                  sectionIndexNumber,
                  setSectionIndexNumber,
                  setModuleIndexNumber,
                  navigate,
                  id
                )
              }
            >
              <img alt="" src={Arrow} style={{ transform: "rotate(180deg)" }} />
              <Back>Sljedeće</Back>
            </BackRow>
          </>
        )}
        {width < breakpoint && (
          <>
            <div className="section-navigation-breakpoint">
              <BackRow
                onClick={() =>
                  navigatePolaznikPrevious(
                    theoreticalModules,
                    sectionIndexNumber,
                    setSectionIndexNumber,
                    setModuleIndexNumber,
                    navigate,
                    previousRoute,
                    id
                  )
                }
              >
                <img alt="" src={Arrow} />
                <Back>Prethodno</Back>
              </BackRow>
              <BackRow
                onClick={() =>
                  navigatePolaznikNext(
                    theoreticalModules,
                    sectionIndexNumber,
                    setSectionIndexNumber,
                    setModuleIndexNumber,
                    navigate,
                    id
                  )
                }
              >
                <img
                  alt=""
                  src={Arrow}
                  style={{ transform: "rotate(180deg)" }}
                />
                <Back>Sljedeće</Back>
              </BackRow>
            </div>
            <SmallTitle color={"#5F6D7E"}>
              {contentTitle ? contentTitle : ""}
            </SmallTitle>
          </>
        )}
      </SectionNavigation>
      {contentTitle === "Uvod" && (
        <Header>
          <BackgroundImage />
          <ProgramImage src={contentImage} />
        </Header>
      )}
      <InfoContainer>
        {contentTitle === "Uvod" && (
          <DescriptionWrapper>
            <Description>{contentDescription}</Description>
            <SekcijaButton>Dalje</SekcijaButton>
          </DescriptionWrapper>
        )}
        {contentTitle !== "Uvod" &&
          !contentTitle.toLowerCase().includes("introspekcija") &&
          !isZoom && (
            <div className="section-wrapper">
              <DefaultDescriptionWrapper
                isVideo={contentType.includes("video")}
              >
                {isContentLoaded ? (
                  contentDescription ? (
                    contentType.includes("video") ? (
                      <iframe
                        src={`${contentDescription}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                        width="900"
                        height={contentType.includes("video") ? "600" : "400"}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        title="0.3. (720p) Razvojni procesi"
                      />
                    ) : contentType.includes("pdf") ? (
                      <div className="pdf-container">
                        <iframe
                          src={contentDescription}
                          type="application/pdf"
                          height="100%"
                          width="100%"
                          title="pdf"
                        />
                      </div>
                    ) : contentType.includes("image") ? (
                      <div>
                        <img alt="" src={contentDescription} />
                      </div>
                    ) : (
                      <Description>{contentDescription}</Description>
                    )
                  ) : (
                    <Description>Missing file</Description>
                  )
                ) : null}
              </DefaultDescriptionWrapper>
              <SekcijaButton
                onClick={() =>
                  navigatePolaznikNext(
                    theoreticalModules,
                    sectionIndexNumber,
                    setSectionIndexNumber,
                    setModuleIndexNumber,
                    navigate,
                    id
                  )
                }
                style={{
                  marginTop: contentType.includes("video") ? "16px" : "170px",
                }}
              >
                Dalje
              </SekcijaButton>
            </div>
          )}
        {contentTitle.toLocaleLowerCase().includes("introspekcija") && (
          <IntrospekcijeContainer description={contentDescription} />
        )}
        {isZoom && (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div className="zoom-container" id="meetingSDKElement">
              <span id="join-p">Pridruži se zoom sastanku</span>
              <button
                onClick={() => {}}
                // onClick={() => initializeZoom()}
                class="join-btn"
                id="zoomButton"
              >
                Pridruži se
              </button>
            </div>
            <div className="endContainer">
              <span id="end-p">Isključili ste se iz sastanka?</span>
              <button onClick={() => window.location.reload()} class="end-btn">
                Ponovno spajanje
              </button>
            </div>
          </div>
        )}
        {!contentTitle.toLocaleLowerCase().includes("introspekcija") && (
          <div className="modulesSubmodules-container-section">
            <h1 className="header-section">
              {userId === "Referada" ? "Teorijski Dio" : "Sadržaj programa"}
            </h1>
            {theoreticalModules?.map((item, index) => (
              <SectionDropdown key={index}>
                <SectionTitleRow
                  onClick={() =>
                    toggleTeorijskiSection(
                      index,
                      teorijskiExpandedSections,
                      setTeorijskiExpandedSections
                    )
                  }
                >
                  <SectionTitle>{item.name}</SectionTitle>
                  <DropdownArrow
                    alt=""
                    src={Down}
                    rotated={teorijskiExpandedSections[index]}
                  />
                </SectionTitleRow>
                {teorijskiExpandedSections[index] && (
                  <div>
                    {item.resources
                      .filter((resource) => resource.name !== "false")
                      .map((resource, resourceIndex) => (
                        <ContentRow key={resourceIndex}>
                          {getIconByType(resource.resourceType)}
                          <Content
                            onClick={() => {
                              handlePolaznikSectionNavigation(resource.id);
                              window.scrollTo(0, 100);
                            }}
                          >{`${resource.name}`}</Content>
                        </ContentRow>
                      ))}
                  </div>
                )}
              </SectionDropdown>
            ))}
          </div>
        )}
      </InfoContainer>
    </Wrapper>
  );
}

export default Section;
