import React, { useEffect, useState } from "react";
import OsobniPsihoterapijskiRadAccordions from "./Accordions/OsobniPsihoterapijskiRadAccordions";
import styles from "./OsobniPsihoterapeutskiRad.module.css";

function OsobniPsihoterapeutskiRad({
  userId,
  polaznikLevel,
  data,
  setDeleteFlag,
  deleteFlag,
  evidentionUserID,
}) {
  const minOsobniPsihoRadSatiTerapeut = 250;
  const minOsobniPsihoRadSatiPraktičar = 150;
  const [osobniPsihoterapeutskiRadArray, setOsobniPsihoterapeutskiRadArray] =
    useState([]);
  const [obracunInfo, setObracunInfo] = useState([]);
  useEffect(() => {
    if (data) {
      const array = Object.values(data);
      setOsobniPsihoterapeutskiRadArray([
        { individualTherapy: array[0] },
        { facultativeProgram: array[1] },
      ]);
    }
  }, [data]);
  useEffect(() => {
    if (osobniPsihoterapeutskiRadArray.length > 0) {
      const individualTherapy =
        osobniPsihoterapeutskiRadArray[0]?.individualTherapy || {};
      const fakultativniProgram =
        osobniPsihoterapeutskiRadArray[1]?.facultativeProgram || {};
      const individualTherapyTopicsArray = [
        {
          name: "Individualne programske terapije (Školski kalendar)",
          hours: individualTherapy.automatic || 0,
          isNameClickable: true,
          eventType: "therapy_automatic",
        },
        {
          name: "Moje upisane individualne programske terapije",
          hours: individualTherapy.manual || 0,
          isNameClickable: true,
          eventType: "therapy_manual",
        },
      ];
      const facultativeProgramTopicsArray = [
        {
          name: "Fakultativne radionice",
          hours: fakultativniProgram.facultativeWorkshop || 0,
          isNameClickable: true,
          eventType: "facultative_workshop",
        },
        {
          name: "Intenzivi",
          hours: fakultativniProgram.intensive || 0,
          isNameClickable: true,
          eventType: "intensive",
        },
      ];
      setObracunInfo([
        {
          title: "Individualni psihoterapijski rad (IPR) - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: individualTherapyTopicsArray,
              maxTopObracun:
                osobniPsihoterapeutskiRadArray?.[0]?.individualTherapy
                  .minAttendance,
            },
          ],
        },
        {
          title:
            "Grupni psihoterapijski rad (GPR) - fakultativni program - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: facultativeProgramTopicsArray,
              maxBottomObracun:
                osobniPsihoterapeutskiRadArray?.[1]?.facultativeProgram
                  ?.minAttendance,
            },
          ],
        },
      ]);
    }
  }, [osobniPsihoterapeutskiRadArray]);

  const currentIndividualneSati =
    osobniPsihoterapeutskiRadArray?.[0]?.individualTherapy.automatic +
    osobniPsihoterapeutskiRadArray?.[0]?.individualTherapy.manual;

  const remainingIndividualneSati =
    osobniPsihoterapeutskiRadArray?.[0]?.individualTherapy.minAttendance -
    currentIndividualneSati;

  const currentFakultativniSati =
    osobniPsihoterapeutskiRadArray?.[1]?.facultativeProgram
      ?.facultativeWorkshop +
    osobniPsihoterapeutskiRadArray?.[1]?.facultativeProgram?.intensive;

  const remainingFakultativniSati =
    osobniPsihoterapeutskiRadArray?.[1]?.facultativeProgram?.minAttendance -
    currentFakultativniSati;
  return (
    <div className={styles.raniRazvojPrvaSekcija2}>
      <h1 className={styles.raniRazvojHeader}>
        Osobni psihoterapijski rad (OPR)
      </h1>

      <p className={styles.raniRazvojDescription}>
        Za postizanje statusa{" "}
        {polaznikLevel === "psihoterapeut" ? "Psihoterapeuta" : "Praktičara"}{" "}
        ITP-a potrebno je ostvariti minimalno{" "}
        {polaznikLevel === "psihoterapeut"
          ? minOsobniPsihoRadSatiTerapeut
          : minOsobniPsihoRadSatiPraktičar}{" "}
        sati OPR-a od čega {polaznikLevel === "psihoterapeut" ? 100 : 60} sati
        IPR-a (programskih terapija) i{" "}
        {polaznikLevel === "psihoterapeut" ? 150 : 90} sati GPR-a.
      </p>

      <p className={styles.raniRazvojDescription2}>
        Moje trenutno stanje OPR-a: <br /> Individualni psihoterapijski rad
        (IPR) - {currentIndividualneSati}h{" "}
        {polaznikLevel !== "rar" &&
          `(Nedostaje ${
            remainingIndividualneSati <= 0 ? 0 : remainingIndividualneSati
          }h)`}
        <br />
        Grupni psihoterapijski rad (GPR) (fakultativni program) -{" "}
        {currentFakultativniSati}h{" "}
        {polaznikLevel !== "rar" &&
          `(Nedostaje ${
            remainingFakultativniSati <= 0 ? 0 : remainingFakultativniSati
          }h)`}
      </p>
      <div>
        <OsobniPsihoterapijskiRadAccordions
          data={obracunInfo}
          userId={userId}
          setDeleteFlag={setDeleteFlag}
          deleteFlag={deleteFlag}
          evidentionUserID={evidentionUserID}
        />
      </div>
    </div>
  );
}

export default OsobniPsihoterapeutskiRad;
