export const fetchGroups = async (
  getGroupsILead,
  authToken,
  getGroupEvidention,
  setGroupEvidention,
  setGroups,
  setIsLoading
) => {
  const response = await getGroupsILead(authToken);
  const groupIds = response?.regularGroups?.map((group) => group.id);
  if (groupIds.length > 0) {
    const evidention = await getGroupEvidention(groupIds, authToken);
    setGroupEvidention(evidention);
    setGroups(true);
  }
  setIsLoading(false);
};
