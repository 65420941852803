import { Box } from "@mui/material";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Archive from "../../assets/img/ArchiveIcon.png";
import Modal from "../../components/Modal/Modal.jsx";
import SearchStudents from "../attendees/searchStudents/SearchStudents.jsx";
import {
  fetchCities,
  fetchData,
  fetchGroups,
  fetchSearchData,
  fetchWI,
} from "../attendees/utils";
import { useAuthentication } from "../hooks/useAuthentication";
import TableAttendees from "../TableAttendees/index.jsx";
import styles from "../TableAttendees/Table.module.css";
import {
  InputRow,
  Label,
  Row,
  TableArhiviraj,
  Title,
  Wrapper,
} from "./Arhiviraj.styles.js";
import { handleOpenModal } from "./Handlers.js";
import {
  MultiselectPlaceOption,
  MultiselectPlaceStyles,
  selectStyles,
} from "./utils.js";

const Arhiviraj = () => {
  const navigate = useNavigate();
  const programsWithGroups = ["1", "3", "4", "5", "6", "13", "14", "11", "12"];
  const [workshops, setWorkshops] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [intensives, setIntensives] = useState([]);
  const [students, setStudents] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [polazniciCount, setPolazniciCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState([]);
  const { authToken } = useAuthentication();
  const [mjestoUpisa, setMjestoUpisa] = useState([]);
  const [selectedUserUuid, setSelectedUserUuid] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    title: "",
    description: "",
    buttons: [],
  });

  const [selectedProgram, setSelectedProgram] = useState({
    value: "all",
    label: "Svi polaznici",
  });
  const [selectedWorkshop, setSelectedWorkshop] = useState({
    value: "all",
    label: "Sve radionice",
  });
  const [selectedIntensive, setSelectedIntensive] = useState({
    value: "all",
    label: "Svi intenzivi",
  });
  const [selectedGroup, setSelectedGroup] = useState({
    value: "all",
    label: "Sve grupe",
  });
  const handleGrupaChange = async (selectedOption) => {
    setSelectedGroup(selectedOption);
  };

  const handleIntensiveChange = async (selectedOption) => {
    setSelectedIntensive(selectedOption);
  };

  const handleWorkshopChange = async (selectedOption) => {
    setSelectedWorkshop(selectedOption);
  };

  const handleProgramChange = async (selectedOption) => {
    setSelectedProgram(selectedOption);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    if (selectedProgram.value === "22" || selectedProgram.value === "33") {
      setSelectedGroup({
        value: null,
        label: "Sve grupe",
      });
      if (authToken) fetchWI(authToken, setWorkshops, setIntensives);
    }
  }, [authToken, selectedProgram]);

  const debouncedFetchSearchData = useMemo(() => {
    return _.debounce(
      (
        currentPage,
        authToken,
        setStudents,
        setPolazniciCount,
        searchResults
      ) => {
        fetchSearchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          searchResults,
          false
        );
      },
      400
    );
  }, []);

  useEffect(() => {
    const city = selectedOption.map((option) => option.label);
    if (searchResults.length > 0) {
      if (authToken)
        debouncedFetchSearchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          searchResults
        );
    } else {
      if (authToken)
        fetchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          false,
          city,
          selectedProgram.value,
          selectedGroup.value,
          selectedWorkshop.value,
          selectedIntensive.value
        );
    }
  }, [
    currentPage,
    authToken,
    selectedOption,
    selectedProgram,
    selectedGroup,
    polazniciCount,
    selectedWorkshop,
    selectedIntensive,
    searchResults,
  ]);
  useEffect(() => {
    if (authToken) fetchCities(authToken, setMjestoUpisa);
  }, [authToken]);

  useEffect(() => {
    if (searchResults.length > 0) setCurrentPage(1);
  }, [searchResults]);

  useEffect(() => {
    if (
      selectedProgram.value !== null &&
      selectedProgram.value !== "22" &&
      selectedProgram.value !== "33"
    ) {
      if (authToken) fetchGroups(selectedProgram, authToken, setGroups);
    }
  }, [selectedProgram, selectedOption, authToken, currentPage]);

  const sviPolaznici = [
    { value: "all", label: "Svi polaznici" },
    { value: "1", label: "Polaznici RAR" },
    { value: "3", label: "Polaznici OR1" },
    { value: "4", label: "Polaznici OR2" },
    { value: "5", label: "Polaznici OR3" },
    { value: "6", label: "Polaznici OR4" },
    { value: "13", label: "Polaznici TP1" },
    { value: "14", label: "Polaznici TP2" },
    { value: "11", label: "Polaznici TT5" },
    { value: "12", label: "Polaznici TT6" },
    { value: "all", label: "Polaznici VT" },
    { value: "16", label: "RPO" },
    { value: "15", label: "Roditeljstvo 0-6" },
    { value: "all", label: "Pauzirani program" },
    { value: "22", label: "Polaznici radionica" },
    { value: "33", label: "Polaznici Inteziva" },
    { value: "44", label: "Arhivirani polaznici" },
  ];

  const handleUserSelection = (selectedRows) => {
    const usersNow = students.map((student) => student.id);

    const updatedUsers = [...users, ...usersNow].filter((user) =>
      selectedRows.includes(user)
    );
    setUsers([...users, ...usersNow]);
    setSelectedUserUuid([...new Set(updatedUsers)]);
  };

  return (
    <div>
      <SearchStudents students={students} setSearchResults={setSearchResults} />
      <Box display="flex">
        <Title>Arhiviraj polaznika:</Title>
      </Box>
      <Wrapper>
        <Row>
          <InputRow>
            <Label>Mjesto upisa</Label>
            <Select
              isClearable={false}
              options={mjestoUpisa}
              value={selectedOption}
              isMulti
              onChange={handleSelectChange}
              styles={MultiselectPlaceStyles}
              isSearchable={false}
              components={{
                Option: MultiselectPlaceOption,
                IndicatorSeparator: () => null,
              }}
            />
          </InputRow>
          <Select
            defaultValue={sviPolaznici[0]}
            options={sviPolaznici}
            onChange={handleProgramChange}
            styles={selectStyles}
            isSearchable={false}
            components={{ IndicatorSeparator: () => null }}
          />

          {programsWithGroups.includes(selectedProgram.value) && (
            <Select
              defaultValue={groups[0]}
              options={groups}
              onChange={handleGrupaChange}
              styles={selectStyles}
              isSearchable={false}
              components={{ IndicatorSeparator: () => null }}
            />
          )}
          {selectedProgram.value === "22" && (
            <Select
              defaultValue={workshops[0]}
              options={workshops}
              onChange={handleWorkshopChange}
              styles={selectStyles}
              isSearchable={false}
              components={{ IndicatorSeparator: () => null }}
            />
          )}
          {selectedProgram.value === "33" && (
            <Select
              defaultValue={intensives[0]}
              options={intensives}
              onChange={handleIntensiveChange}
              styles={selectStyles}
              isSearchable={false}
              components={{ IndicatorSeparator: () => null }}
            />
          )}
        </Row>
      </Wrapper>
      <TableArhiviraj>
        <TableAttendees
          data={students}
          showCheckbox={true}
          handleSelectionChange={handleUserSelection}
          showAvatars={true}
          archive={true}
          polazniciCount={polazniciCount}
          setPolazniciCount={setPolazniciCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </TableArhiviraj>
      <div className={styles.buttonContainer}>
        <button
          className={styles.cancelButton}
          onClick={() => navigate("/polaznici")}
        >
          Odustani
        </button>
        <button
          className={
            selectedUserUuid.length === 0
              ? styles.nextButtonDisabled
              : styles.nextButton
          }
          onClick={() =>
            handleOpenModal(
              selectedUserUuid,
              setModalContent,
              authToken,
              setShowModal,
              setSelectedUserUuid,
              navigate
            )
          }
          disabled={selectedUserUuid.length === 0}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {selectedUserUuid.length > 1
            ? "Arhiviraj polaznike"
            : "Arhiviraj polaznika"}
          <img alt="" src={Archive} />
        </button>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.title}
          description={modalContent.description}
          buttons={modalContent.buttons}
          fullWidth
        />
      )}
    </div>
  );
};

export default Arhiviraj;
