import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import Modal from "../../components/Modal/Modal";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import { getActiveProgramsByUserId, programsByIds } from "../myProfile/utils";
import styles from "./PolazniciPregled.module.css";
import AddIcon from "./assets/AddIcon.svg";
import backToArrow from "./assets/BackToArrow.svg";
import ExpandIcon from "./assets/ExpandIcon.svg";
import groupsIcon from "./assets/GroupsIcon.svg";
import ProfileIcon from "./assets/ProfileIcon.svg";
import AddUserToModal from "./components/AddUserToModal/AddUserToModal";
import ArhiviraniButton from "./components/ArhiviraniButton/ArhiviraniButton";
import EditMode from "./components/EditMode/EditMode";
import ZakupiteljButton from "./components/ZakupiteljButton/ZakupiteljButton";
import { fetchData } from "./utils/fetchData";
import {
  formatDate,
  handleGetUserEvidention,
  handleSuccessRemoveZakupiteljClick,
} from "./utils/utils";

const apiUrl = process.env.REACT_APP_API_URL;

const backgroundColorMap = {
  RAR: "#87AD76",
  OR: "#CBA855",
  TT: "#A35254",
  TP: "#D64D51",
  Roditeljstvo: "#7BBA9F",
  VT: "#B8834F",
  RPO: "#3A54A1",
};

const PolazniciPregled = () => {
  const [userData, setUserData] = useState("");
  const { pojediniPolaznikID } = useParams();
  const [userInGroups, setUserInGroups] = useState("");
  const { role } = useUserState();
  const { authToken } = useAuthentication();
  const userId = role;
  const [isModalZakupiteljOpen, setIsModalZakupiteljOpen] = useState(false);
  const [mjestoUpisaOptions, setMjestoUpisaOptions] = useState([]);
  const [allPrograms, setAllPrograms] = useState([]);
  const [isModalGrupaOpen, setIsModalGrupaOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [isZakupitelj, setIsZakupitelj] = useState();
  const [zakupiteljType, setZakupiteljType] = useState("");
  const [polozeniProgrami, setPolozeniProgram] = useState([]);
  const [activePrograms, setActivePrograms] = useState("");
  const [isArhiviraj, setIsArhiviraj] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [notAllowed, setNotAllowed] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    title: "",
    description: "",
    buttons: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken)
      fetchData(
        pojediniPolaznikID,
        authToken,
        setUserData,
        setUserInGroups,
        setPolozeniProgram,
        setIsZakupitelj,
        setMjestoUpisaOptions,
        setAllPrograms,
        setZakupiteljType,
        setNotAllowed
      );
  }, [pojediniPolaznikID, authToken]);

  useEffect(() => {
    const getActive = async () => {
      await getActiveProgramsByUserId(
        apiUrl,
        authToken,
        setActivePrograms,
        pojediniPolaznikID
      );
    };
    if (authToken) getActive();
  }, [authToken]);

  const getBackgroundColor = (name) => {
    return backgroundColorMap[name] || "";
  };
  function filterDuplicates(array) {
    const uniqueNames = new Set();
    return array.filter((obj) => {
      if (!uniqueNames.has(obj.name)) {
        uniqueNames.add(obj.name);
        return true;
      }
      return false;
    });
  }

  return (
    <div>
      {notAllowed ? (
        <div
          style={{
            display: "flex",
            gap: 10,
            backgroundColor: "#ffebeb",
            width: "100%",
            height: 40,
            borderRadius: 6,
            fontSize: 15,
            fontFamily: "Inter",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "500",
            color: "#5f6d7e",
            marginTop: 20,
          }}
        >
          <img alt="" src={InfoIcon} height={18} width={18} />
          Pregled ovog polaznika vam trenutno nije omogućen!
        </div>
      ) : (
        <>
          <div className={styles.backToContainer} onClick={() => navigate(-1)}>
            <img
              src={backToArrow}
              alt="go back"
              className={styles.backToArrow}
            />
            <h1 className={styles.backToh1}>Natrag na listu polaznika</h1>
          </div>
          <div className={styles.background} />
          <div
            className={styles.imageContainer}
            style={{ backgroundImage: `url(${userData?.imageUrl}` }}
          />
          <div className={styles.container}>
            {!showEditMode && (
              <div className={styles.headingContainer}>
                <h1 className={styles.heading}>
                  {userData?.firstName} {userData?.lastName}
                </h1>
                <div className={styles.headingButtonContainer}>
                  {userData?.isArchived && userId === "Referada" ? (
                    <ArhiviraniButton
                      onClick={() => {
                        setShowEditMode(true);
                        setIsArhiviraj(true);
                        setIsEdit(false);
                      }}
                    />
                  ) : (
                    <>
                      {userId === "Polaznik" &&
                        activePrograms &&
                        activePrograms.length > 0 &&
                        filterDuplicates(
                          activePrograms.map((item) => {
                            const programId = item.id;
                            const prefix = programsByIds.find((program) =>
                              program.value.includes(programId)
                            );
                            return {
                              color: getBackgroundColor(prefix.label),
                              name: `Polaznik ${
                                prefix === undefined ? "" : prefix.label + "-a"
                              }`,
                            };
                          })
                        ).map((item, index) => (
                          <div
                            className={styles.badge}
                            key={index}
                            style={{ backgroundColor: item.color }}
                          >
                            <p className={styles.badgeText}>{item.name}</p>
                          </div>
                        ))}
                      {userId === "Voditelj" &&
                        activePrograms &&
                        activePrograms.length > 0 &&
                        filterDuplicates(
                          activePrograms.map((item) => {
                            const programId = item.id;
                            const prefix = programsByIds.find((program) =>
                              program.value.includes(programId)
                            );
                            return {
                              color: getBackgroundColor(prefix.label),
                              name: `Voditelj ${
                                prefix === undefined ? "" : prefix.label + "-a"
                              }`,
                            };
                          })
                        ).map((item, index) => (
                          <div
                            className={styles.badge}
                            key={index}
                            style={{ backgroundColor: item.color }}
                          >
                            <p className={styles.badgeText}>{item.name}</p>
                          </div>
                        ))}
                      {userId === "Referada" && (
                        <ZakupiteljButton
                          onClick={() => setIsModalZakupiteljOpen(true)}
                          isZakupitelj={isZakupitelj}
                          zakupiteljType={zakupiteljType}
                          onClickRemoveZakupitelj={() =>
                            handleSuccessRemoveZakupiteljClick(
                              setModalContent,
                              setSuccessModal,
                              pojediniPolaznikID,
                              authToken,
                              setUserData,
                              setUserInGroups,
                              setPolozeniProgram,
                              setIsZakupitelj,
                              setMjestoUpisaOptions,
                              setAllPrograms,
                              setZakupiteljType
                            )
                          }
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {showEditMode ? (
              <EditMode
                userData={userData}
                mjestoUpisaOptions={mjestoUpisaOptions}
                allPrograms={allPrograms}
                onClickOdustani={() => setShowEditMode(false)}
                isArhiviraj={isArhiviraj}
                isEdit={isEdit}
              />
            ) : (
              <>
                <div className={styles.columns}>
                  <div className={styles["left-column"]}>
                    <table>
                      <tbody>
                        <tr>
                          <td className={styles["span"]}>Datum rođenja</td>
                          <td className={styles["spanData"]}>
                            {formatDate(userData?.dateOfBirth)}{" "}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles["span"]}>Spol</td>
                          <td className={styles["spanData"]}>
                            {userData?.gender}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles["span"]}>Mjesto stanovanja</td>
                          <td className={styles["spanData"]}>
                            {userData?.city}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles["span"]}>Adresa stanovanja</td>
                          <td className={styles["spanData"]}>
                            {userData?.address}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles["span"]}>Država stanovanja</td>
                          <td className={styles["spanData"]}>
                            {userData?.country}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles["span"]}>
                            Grad u kojem pohađa program
                          </td>
                          <td className={styles["spanData"]}>
                            {userData?.programCity}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles["span"]}>Stručna sprema</td>
                          <td className={styles["spanData"]}>
                            {userData?.qualification}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles["span"]}>Zvanje</td>
                          <td className={styles["spanData"]}>
                            {userData?.title}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles["span"]}>
                            Identifikacijski broj
                          </td>
                          <td className={styles["spanData"]}>
                            {userData?.OIB}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles["span"]}>Broj mobitela</td>
                          <td className={styles["spanData"]}>
                            {userData?.phoneNumber}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles["span"]}>E-mail adresa</td>
                          <td className={styles["spanData"]}>
                            {userData?.emailAddress}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles["span"]}>
                            Položeni programi na IPD-u
                          </td>
                          <td className={styles["spanData"]}>
                            {polozeniProgrami.map((program, index) => (
                              <span key={program}>
                                {program}
                                {index < polozeniProgrami.length - 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={styles["right-column"]}>
                    <div className={styles.content}>
                      {userId === "Referada" && (
                        <button
                          className={styles.urediBtn}
                          onClick={() => {
                            setShowEditMode(true);
                            setIsArhiviraj(false);
                            setIsEdit(true);
                          }}
                        >
                          Uredi profil
                        </button>
                      )}
                      <button
                        className={styles.evidencijaBtn}
                        onClick={() =>
                          handleGetUserEvidention(
                            navigate,
                            pojediniPolaznikID,
                            ` ${userData?.firstName} ${userData?.lastName}`
                          )
                        }
                      >
                        Pogledaj evidenciju{" "}
                        <img src={ExpandIcon} alt="expand icon" />
                      </button>
                      <div className={styles.groupContent}>
                        <div className={styles.groupH1}> Član grupa </div>
                        <img src={groupsIcon} alt="group icon" />
                      </div>
                      {userInGroups.length === 0 ? (
                        <>
                          <div className={styles.iconContainer}>
                            <img src={ProfileIcon} alt="profile icon" />
                          </div>
                          <p className={styles.groupText}>
                            Polaznik trenutno ne pripada nijednoj grupi.
                          </p>
                          {userId === "Referada" && (
                            <button
                              className={styles.addToGroupBtn}
                              onClick={() => setIsModalGrupaOpen(true)}
                            >
                              Dodaj u grupu <img src={AddIcon} alt="add icon" />
                            </button>
                          )}
                        </>
                      ) : (
                        <div className={styles.groupsBadges}>
                          {userInGroups.map((item, index) => (
                            <div className={styles.groupBadge} key={index}>
                              <p className={styles.groupBadgeText}>
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {isModalZakupiteljOpen && (
            <AddUserToModal
              onClose={() => setIsModalZakupiteljOpen(false)}
              setIsModalZakupiteljOpen={setIsModalZakupiteljOpen}
              setModalContent={setModalContent}
              setSuccessModal={setSuccessModal}
              pojediniPolaznikID={pojediniPolaznikID}
              title={"Dodaj polaznika u zakupitelje"}
              addPolaznikToZakupitelj={true}
              data={userData}
              setUserData={setUserData}
              setUserInGroups={setUserInGroups}
              setPolozeniProgram={setPolozeniProgram}
              setIsZakupitelj={setIsZakupitelj}
              setMjestoUpisaOptions={setMjestoUpisaOptions}
              setAllPrograms={setAllPrograms}
              setZakupiteljType={setZakupiteljType}
            />
          )}
          {isModalGrupaOpen && (
            <AddUserToModal
              onClose={() => setIsModalGrupaOpen(false)}
              title={"Dodaj polaznika u grupu"}
              addPolaznikToGroup={true}
              data={userData}
            />
          )}
          {successModal && (
            <Modal
              fullWidth={true}
              image={modalContent.image}
              title={modalContent.title}
              description={modalContent.description}
              buttons={modalContent.buttons}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PolazniciPregled;
