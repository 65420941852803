import React from "react";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "../../../../assets/svg/ChevronRightIcon.js";
import DocumentIcon from "../../../../assets/svg/DocumentIcon.js";
import styles from "./GroupCard.module.css";

function GroupCard({ title, route }) {
  const navigate = useNavigate();
  return (
    <div className={styles.cardContainer} onClick={() => navigate(route)}>
      <DocumentIcon className={styles.cardIcon} />
      <div className={styles.cardDivider} />
      <div className={styles.cardTextContainer}>
        <p className={styles.cardText}>{title}</p>
        <ChevronRightIcon className={styles.cardIcon} />
      </div>
    </div>
  );
}

export default GroupCard;
