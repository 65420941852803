import React from "react";
import Text from "../../../../../assets/img/TextIcon.png";
import { SupervizijeSuglasnostMock } from "../Table/MockData";
import styles from "./CertificateContainer.module.css";

function CertificateContainer() {
  return (
    <div className={styles.wrapper}>
      {SupervizijeSuglasnostMock.length === 2 ? (
        <>
          <div className={styles.column}>
            <div className={styles.row}>
              <img alt="" className={styles.icon} src={Text} />
              <span className={styles.label}>Certificate (ENG)</span>
            </div>
            <button className={styles.btn}>Download</button>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <img alt="" className={styles.icon} src={Text} />
              <span className={styles.label}>Potvrda (HRV)</span>
            </div>
            <button className={styles.btn}>Preuzmi</button>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <img alt="" className={styles.icon} src={Text} />
              <span className={styles.label}>Potrdilo(SLO)</span>
            </div>
            <button className={styles.btn}>Prenesi</button>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <img alt="" className={styles.icon} src={Text} />
              <span className={styles.label}>Uverenje(SRP)</span>
            </div>
            <button className={styles.btn}>Preuzmi</button>
          </div>
        </>
      ) : (
        <span className={styles.empty}>
          Potvrdu ćeš moći preuzeti nakon što dobiješ suglasnost supervizora
        </span>
      )}
    </div>
  );
}

export default CertificateContainer;
