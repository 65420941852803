import { useEffect, useState } from "react";
import Prijavnice from "../../../../assets/img/PolaznikPrijavnice.png";
import { useAuthentication } from "../../../hooks/useAuthentication";
import Carousel from "../components/Carousel";
import { getAllWeekend } from "./api/getAllWeekend";
import styles from "./VikendIntenzivi.module.css";

function VikendIntenzivi({ onClick }) {
  const [procesni, setProcesni] = useState([]);
  const [povratakSebi, setPovratakSebi] = useState([]);
  const [odnosi, setOdnosi] = useState([]);
  const { authToken } = useAuthentication();

  useEffect(() => {
    const fetchFW = async () => {
      try {
        const response = await getAllWeekend(authToken);
        const procesniIntensiveFiltered = response.filter(
          (item) => item.intensiveType === "procesni intensive"
        );
        const povratakSebiFiltered = response.filter(
          (item) => item.intensiveType === "povratak sebi"
        );
        const odnosiFiltered = response.filter(
          (item) => item.intensiveType === "odnosi"
        );
        setProcesni(procesniIntensiveFiltered);
        setPovratakSebi(povratakSebiFiltered);
        setOdnosi(odnosiFiltered);
      } catch (error) {
        console.error("Error fetching regulations:", error);
      }
    };

    if (authToken) fetchFW();
  }, []);
  return (
    <div className="subpage-wrapper">
      <div className={styles.title}>Procesni intenzivi</div>
      {procesni.length > 0 ? (
        <Carousel data={procesni} onClick={onClick} />
      ) : (
        <div className={styles.emptyWrapper}>
          <img alt="" src={Prijavnice} className={styles.emptyIcon} />
          <span className={styles.emptySpan}>
            Trenutno nema aktivnih intenziva
          </span>
        </div>
      )}
      <div className={styles.title}>Povratak sebi</div>
      {povratakSebi.length > 0 ? (
        <Carousel data={povratakSebi} onClick={onClick} />
      ) : (
        <div className={styles.emptyWrapper}>
          <img alt="" src={Prijavnice} className={styles.emptyIcon} />
          <span className={styles.emptySpan}>
            Trenutno nema aktivnih intenziva
          </span>
        </div>
      )}
      <div className={styles.title}>Odnosi</div>
      {odnosi.length > 0 ? (
        <Carousel data={odnosi} onClick={onClick} />
      ) : (
        <div className={styles.emptyWrapper}>
          <img alt="" src={Prijavnice} className={styles.emptyIcon} />
          <span className={styles.emptySpan}>
            Trenutno nema aktivnih intenziva
          </span>
        </div>
      )}
    </div>
  );
}

export default VikendIntenzivi;
