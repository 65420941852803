import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ArchiveIcon from "../../../../assets/svg/ArchiveIcon.svg";
import CrossIcon from "../../../../assets/svg/CrossIcon";
import UserIcon from "../../../../assets/svg/UserIcon";
import BEPagination from "../../../../components/PaginationWithBE/BEPagination";
import { useGroupState } from "../../../../context/CreateGroupContext";
import { fetchCities } from "../../../attendees/utils";
import { useAuthentication } from "../../../hooks/useAuthentication";
import SearchGroups from "../../utils/SearchGroups/SearchGroups";
import { fetchGroupData, fetchGroupSearchData } from "../../utils/utils";
import styles from "./GroupsTable.module.css";
import {
  aktivneOptions,
  groupsOnlineSelectData,
  groupsSelectData,
  handleAktivneChange,
  handleCitySelectChange,
  handleProgramChange,
  selectStyles,
} from "./utils";

const GroupsTable = () => {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const { resetGroupData } = useGroupState();
  const [allGroupsReferada, setAllGroupsReferada] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfGroups, setNumberOfGroups] = useState(0);
  const [aktivne, setAktivne] = useState({ label: "Aktivne", value: true });
  const [selectedCity, setSelectedCity] = useState({
    value: null,
    label: "Select...",
  });
  const [searchGroupsResults, setSearchGroupsResults] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState({
    value: [3, 4, 5, 6],
    label: "Grupe OR-a",
  });
  useEffect(() => {
    if (authToken) fetchCities(authToken, setAllCities);
  }, [authToken]);

  const debouncedFetchSearchData = useMemo(() => {
    return _.debounce(
      (
        currentPage,
        authToken,
        setAllGroupsReferada,
        setNumberOfGroups,
        searchGroupsResults
      ) => {
        fetchGroupSearchData(
          currentPage,
          authToken,
          aktivne,
          setAllGroupsReferada,
          setNumberOfGroups,
          searchGroupsResults
        );
      },
      400
    );
  }, [aktivne]);

  useEffect(() => {
    if (searchGroupsResults.length > 0) {
      if (authToken)
        debouncedFetchSearchData(
          currentPage,
          authToken,
          setAllGroupsReferada,
          setNumberOfGroups,
          searchGroupsResults
        );
    } else {
      if (authToken)
        fetchGroupData(
          currentPage,
          selectedCity.value,
          selectedProgram.value,
          authToken,
          aktivne,
          setAllGroupsReferada,
          setNumberOfGroups
        );
    }
  }, [
    authToken,
    currentPage,
    selectedCity,
    selectedProgram,
    searchGroupsResults,
    aktivne,
  ]);

  useEffect(() => {
    if (searchGroupsResults.length > 0) setCurrentPage(1);
  }, [searchGroupsResults]);

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber, () => {
      if (authToken)
        fetchGroupData(pageNumber, authToken, setAllGroupsReferada);
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.groupsSearchbar}>
        <SearchGroups setSearchResults={setSearchGroupsResults} />
      </div>

      <div className={styles.topItemsContainer}>
        <div className={styles.selectContainer}>
          <div className={styles.selectWithLabel}>
            <p className={styles.selectLabel}>Mjesto upisa:</p>
            <Select
              isClearable={false}
              options={allCities}
              value={selectedCity}
              onChange={(selectedOption) =>
                handleCitySelectChange(
                  selectedOption,
                  setSelectedCity,
                  setCurrentPage
                )
              }
              isSearchable={false}
              styles={selectStyles}
              components={{ IndicatorSeparator: () => null }}
            />
          </div>
          <Select
            placeholder={"Grupe OR-a"}
            options={
              selectedCity.value === 7
                ? groupsOnlineSelectData
                : groupsSelectData
            }
            isSearchable={false}
            styles={selectStyles}
            components={{ IndicatorSeparator: () => null }}
            onChange={(selectedOption) =>
              handleProgramChange(
                selectedOption,
                setSelectedProgram,
                setCurrentPage
              )
            }
          />
          <Select
            options={aktivneOptions}
            defaultValue={aktivneOptions[0]}
            isSearchable={false}
            styles={selectStyles}
            components={{ IndicatorSeparator: () => null }}
            onChange={(selectedOption) =>
              handleAktivneChange(selectedOption, setAktivne, setCurrentPage)
            }
          />
        </div>
        <div
          onClick={() => {
            resetGroupData();
            navigate("/grupe/nova-grupa");
          }}
          className={styles.createGroupButton}
        >
          <p className={styles.createGroupButtonText}>Kreiraj grupu</p>
          <CrossIcon className={styles.createGroupButtonIcon} />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeaderRow}>
              <th className={styles.groupNameHeaderCell}>
                <p className={styles.headerCellLabel}>Ime grupe</p>
              </th>
              <th className={styles.leaderHeaderCell}>
                <p className={styles.headerCellLabel}>Voditelji/Učitelji</p>
              </th>
              <th className={styles.attendeesHeaderCell}>
                <p className={styles.headerCellLabel}>Polaznici</p>
              </th>
              <th className={styles.groupNameHeaderCell} />
            </tr>
          </thead>
          <tbody>
            {allGroupsReferada &&
              allGroupsReferada.map((item, index) => (
                <tr className={styles.tableDataRow} key={index}>
                  <td>
                    <div className={styles.tableNameCell}>
                      <p className={styles.tableNameText}>
                        {item.isArchived && <img src={ArchiveIcon} alt="" />}
                        {item.name ? <span>{item.name}</span> : "(nema imena)"}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableLeadersCell}>
                      <div className={styles.leadersContainer}>
                        <div className={styles.leadersPhotosContainer}>
                          {item.voditelji &&
                            item.voditelji
                              .slice(0, 6)
                              .map((voditelj, index) => (
                                <div
                                  className={styles.emptyProfilePhoto}
                                  key={index}
                                >
                                  {voditelj.imageUrl ? (
                                    <img
                                      alt=""
                                      src={voditelj.imageUrl}
                                      className={styles.avatarIcon}
                                    />
                                  ) : (
                                    <UserIcon
                                      className={styles.profilePhotoIcon}
                                    />
                                  )}
                                  <div
                                    className={styles.userPhotoTooltip}
                                    key={index}
                                  >
                                    <p className={styles.tooltipText}>
                                      {voditelj.firstName
                                        ? voditelj.firstName
                                        : "(nema podataka)"}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          {item.voditelji.length > 1 && (
                            <p className={styles.voditeljiSpan}>
                              {item.voditelji[0].firstName} +{" "}
                              {item.voditelji.length - 1} drugih
                            </p>
                          )}
                        </div>
                        <div className={styles.leadersTextContainer}></div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableAttendeesCell}>
                      <div className={styles.attendeesPhotosContainer}>
                        {item.polaznici &&
                          item.polaznici.slice(0, 6).map((polaznik, index) => (
                            <div
                              className={styles.emptyProfilePhoto}
                              key={index}
                            >
                              {polaznik.imageUrl ? (
                                <img
                                  alt=""
                                  src={polaznik.imageUrl}
                                  className={styles.avatarIcon}
                                />
                              ) : (
                                <UserIcon className={styles.profilePhotoIcon} />
                              )}
                              <div
                                className={styles.userPhotoTooltip}
                                key={index}
                              >
                                <p className={styles.tooltipText}>
                                  {polaznik.firstName
                                    ? polaznik.firstName
                                    : "(nema podataka)"}
                                </p>
                              </div>
                            </div>
                          ))}
                        {item.polaznici && item.polaznici.length > 6 && (
                          <div className={styles.emptyProfilePhoto}>
                            <p className={styles.tooltipText}>{`+${
                              item.polaznici.length - 6
                            }`}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableButtonCell}>
                      <div
                        onClick={() => {
                          const isArchived = item.isArchived;
                          navigate(`/grupe/${item.id}`, {
                            state: { isArchived },
                          });
                        }}
                        className={styles.seeMoreButton}
                      >
                        <p className={styles.seeMoreButtonText}>Prikaži više</p>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className={styles.paginationContainer}>
        <BEPagination
          currentPage={currentPage}
          totalPageCount={Math.ceil(numberOfGroups / 10)}
          handlePaginationClick={handlePaginationClick}
        />
      </div>
    </div>
  );
};
export default GroupsTable;
