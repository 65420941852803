import { Typography } from "@mui/material";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import SearchStudents from "../../../../attendees/searchStudents/SearchStudents";
import {
  fetchCities,
  fetchData,
  fetchGroups,
  fetchSearchData,
  fetchWI,
  handleGrupaChange,
  handleIntensiveChange,
  handleProgramChange,
  handleSelectChange,
  handleWorkshopChange,
  MultiselectPlaceOption,
  MultiselectPlaceStyles,
  onlinePolaznici,
  programsWithGroups,
  selectStyles,
  sviPolaznici,
} from "../../../../attendees/utils";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import TableAttendees from "../../../../TableAttendees";
import styles from "./PostoječiKorisnikStepOne.module.css";

const PostojećiKorisnikStepOne = ({ onNextStep, onSelectedUserId }) => {
  const [students, setStudents] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUserUuids, setSelectedUserUuids] = useState([]);
  const [polazniciCount, setPolazniciCount] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [workshops, setWorkshops] = useState([]);
  const [groups, setGroups] = useState([]);
  const [intensives, setIntensives] = useState([]);
  const { authToken } = useAuthentication();
  const navigate = useNavigate();
  const [mjestoUpisa, setMjestoUpisa] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState({
    value: null,
    label: "Svi polaznici",
  });
  const [selectedWorkshop, setSelectedWorkshop] = useState({
    value: null,
    label: "Sve radionice",
  });
  const [selectedIntensive, setSelectedIntensive] = useState({
    value: null,
    label: "Svi intenzivi",
  });
  const [selectedGroup, setSelectedGroup] = useState({
    value: null,
    label: "Sve grupe",
  });

  useEffect(() => {
    if (selectedProgram.value === "22" || selectedProgram.value === "33") {
      setSelectedGroup({
        value: null,
        label: "Sve grupe",
      });
      if (authToken) fetchWI(authToken, setWorkshops, setIntensives);
    }
  }, [authToken, selectedProgram]);

  const debouncedFetchSearchData = useMemo(() => {
    return _.debounce(
      (
        currentPage,
        authToken,
        setStudents,
        setPolazniciCount,
        searchResults
      ) => {
        fetchSearchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          searchResults,
          true
        );
      },
      400
    );
  }, [authToken]);

  useEffect(() => {
    const city = selectedOption.map((option) => option.label);
    if (searchResults.length > 0) {
      if (authToken)
        debouncedFetchSearchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          searchResults
        );
    } else {
      if (authToken)
        fetchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          true,
          city,
          selectedProgram.value,
          selectedGroup.value,
          selectedWorkshop.value,
          selectedIntensive.value
        );
    }
  }, [
    currentPage,
    authToken,
    selectedOption,
    selectedProgram,
    selectedGroup,
    polazniciCount,
    selectedWorkshop,
    selectedIntensive,
    searchResults,
  ]);

  useEffect(() => {
    if (authToken) fetchCities(authToken, setMjestoUpisa);
  }, [authToken]);

  useEffect(() => {
    if (searchResults.length > 0) setCurrentPage(1);
  }, [searchResults]);

  useEffect(() => {
    if (
      selectedProgram.value !== null &&
      selectedProgram.value !== "22" &&
      selectedProgram.value !== "33"
    ) {
      if (authToken) fetchGroups(selectedProgram, authToken, setGroups);
    }
  }, [selectedProgram, selectedOption, authToken, currentPage]);

  const handleUserSelection = (selectedRows) => {
    const usersNow = students.map((student) => student.id);

    const updatedUsers = [...users, ...usersNow].filter((user) =>
      selectedRows.includes(user)
    );
    setUsers([...users, ...usersNow]);
    setSelectedUserUuids([...new Set(updatedUsers)]);
    onSelectedUserId([...new Set(updatedUsers)]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.searchContainer}>
          <SearchStudents setSearchResults={setSearchResults} />
        </div>
        <div className={styles.filteri}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "22px",
              fontFamily: "Inter",
              color: "#4D4E4E",
              width: "130px",
              textAlign: "center",
              marginRight: "-20px",
            }}
          >
            Mjesto upisa
          </Typography>
          <Select
            isClearable={false}
            options={mjestoUpisa}
            value={selectedOption}
            isMulti
            onChange={(selectedOptions) =>
              handleSelectChange(
                selectedOptions,
                setSelectedOption,
                setCurrentPage
              )
            }
            styles={MultiselectPlaceStyles}
            isSearchable={false}
            components={{
              Option: MultiselectPlaceOption,
              IndicatorSeparator: () => null,
            }}
          />

          <Select
            defaultValue={
              selectedOption.some(
                (option) => option.label === "Online" && option.value === 7
              )
                ? onlinePolaznici[0]
                : sviPolaznici[0]
            }
            options={
              selectedOption.some(
                (option) => option.label === "Online" && option.value === 7
              )
                ? onlinePolaznici
                : sviPolaznici
            }
            onChange={(selectedOption) =>
              handleProgramChange(
                selectedOption,
                setSelectedProgram,
                setCurrentPage
              )
            }
            styles={selectStyles}
            isSearchable={false}
            components={{ IndicatorSeparator: () => null }}
          />

          {programsWithGroups.includes(selectedProgram.value) && (
            <Select
              defaultValue={groups}
              options={groups}
              onChange={(selectedOption) =>
                handleGrupaChange(
                  selectedOption,
                  setSelectedGroup,
                  setCurrentPage
                )
              }
              styles={selectStyles}
              isSearchable={false}
              components={{ IndicatorSeparator: () => null }}
            />
          )}
          {selectedProgram.value === "22" && (
            <Select
              defaultValue={workshops}
              options={workshops}
              onChange={(selectedOption) =>
                handleWorkshopChange(
                  selectedOption,
                  setSelectedWorkshop,
                  setCurrentPage
                )
              }
              styles={selectStyles}
              isSearchable={false}
              components={{ IndicatorSeparator: () => null }}
            />
          )}
          {selectedProgram.value === "33" && (
            <Select
              defaultValue={intensives}
              options={intensives}
              onChange={(selectedOption) =>
                handleIntensiveChange(
                  selectedOption,
                  setSelectedIntensive,
                  setCurrentPage
                )
              }
              styles={selectStyles}
              isSearchable={false}
              components={{ IndicatorSeparator: () => null }}
            />
          )}
        </div>
        <TableAttendees
          data={students}
          showAvatars={true}
          showCheckbox={true}
          polazniciCount={polazniciCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setStudents={setStudents}
          setPolazniciCount={setPolazniciCount}
          selectionMode="single"
          handleSelectionChange={handleUserSelection}
        />
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.cancelButton}
          onClick={() => navigate("/djelatnici")}
        >
          Odustani
        </button>
        <button
          className={styles.nextButton}
          onClick={() => onNextStep()}
          disabled={!(selectedUserUuids.length > 0)}
          style={
            selectedUserUuids.length > 0 ? null : { backgroundColor: "#b5995b" }
          }
        >
          Dodaj djelatnika
        </button>
      </div>
    </div>
  );
};

export default PostojećiKorisnikStepOne;
